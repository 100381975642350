import { messagesStore } from "@store";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { userStore } from "src/store/user.store";

export const MessageStoreComp = observer(() => {
  const navigate = useNavigate();

  useEffect(() => {
    if (userStore.currentUser?.username) {
      messagesStore.createSubscriptions(userStore.currentUser.username, navigate);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.currentUser])

  return (<></>)
});