import {
  faMessage,
  faPeopleGroup,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorFallback } from "@shared/components";
import { Container, Navbar } from "react-bootstrap";
import { ErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import styles from "./app-footer.module.css";
import { messagesStore } from "@store";
import { observer } from "mobx-react-lite";

export const AppFooter = observer(() => {
  const navigate = useNavigate();

  const openMessages = () => {
    navigate('/messages');
  };
  const openSearch = () => {
    navigate('/search');
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Navbar className={styles.footer} fixed='bottom' bg='dark'>
        <Container className={styles.footerLinks}>
          <Navbar.Brand className={styles.footerLink} onClick={openSearch}>
            <FontAwesomeIcon icon={faSearch} /> Search
          </Navbar.Brand>
          <Navbar.Brand className={styles.footerLink} onClick={openMessages}>
            <FontAwesomeIcon icon={faMessage} /> Messages
            {messagesStore.messagesCout > 0 && (
              <span className={styles.count}>{messagesStore.messagesCout}</span>
            )}
          </Navbar.Brand>
          <Navbar.Brand className={styles.footerLink}>
            <FontAwesomeIcon icon={faPeopleGroup} /> Popular
          </Navbar.Brand>
        </Container>
      </Navbar>
    </ErrorBoundary>
  );
});
