import { search } from "@graphql/docs/queries"
import { SearchResults, UserPicture } from "@graphql/graphql"
import { apolloClient } from "src/apollo-client"

const isUserPicture = (user: UserPicture | null) : user is UserPicture => user !== null && user !== undefined;

export const searchUsers =async (searchString: string): Promise<SearchResults> => {
  const res = await  apolloClient.query<{search: SearchResults}>({
    query: search,
    variables: {
      input: {
        search: searchString,
        userLimit: 20,
        cityLimit: 0,
        postLimit: 0,
        hashtagLimit: 0,
      },
    },
  });
  const searchResults: SearchResults = {
    __typename: 'SearchResults',
    userItems: res.data.search?.userItems?.filter(isUserPicture) ?? [],
  }
  return searchResults;
}