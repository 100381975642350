import styles from "./dot-indicator.module.css";

type Props = {
  curentIndex: number,
  setCurrentIndex: (i: number) => void,
  type: 'primary-light-bg'|'primary-dark-bg'|'gray-dark-bg'|'gray-light-bg',
  framed?: boolean,
  style: 'dot'|'line',
  size: 'lg'|'md'
  className?: string,
  length: number
}

export const DotIndicator = ({
  curentIndex, setCurrentIndex, className, type, framed, style, size, length
}: Props) => {
  let fullClassName = className ? `${className} ${styles['dot-indicator']}` : styles['dot-indicator'];
  const arr = new Array(length).fill(0);

  let itemActiveClass = ''
  
  if (!framed) {
    itemActiveClass = styles[`dot-indicator__item_active_${type}-clear`];
    fullClassName += ` ${styles[`dot-indicator_${type}-clear`]}`;
    fullClassName += ` ${styles['dot-indicator_clear']}`;
    fullClassName += ` ${styles[`dot-indicator_style_${style}-${size}_clear`]}`;
  } else {
    itemActiveClass = styles[`dot-indicator__item_active_${type}`];
    fullClassName += ` ${styles[`dot-indicator_${type}`]}`;
  }

  fullClassName += ` ${styles[`dot-indicator_style_${style}-${size}`]}`;

  return <div className={fullClassName}>
    {arr.map((_, i) => (
      <div
        className={i === curentIndex ? itemActiveClass : ''}
        key={i}
        onClick={() => setCurrentIndex(i)}/>
    ))}
  </div>
}