import { getShortNumber } from "@shared/helpers/get-short-number";
import { Avatar } from "../avatar/avatar.comp";
import styles from "./avatar-group.module.css";

type Props = {
  userPictures: string[]
  plusNumber?: number
}

export const AvatarGroup= ({ userPictures, plusNumber }: Props) => {
  return <div className={styles['avatar-group']}>
    {userPictures.map((url, i) => <Avatar key={i} size='xs' url={url} />)}
    {!!plusNumber && <Avatar size='xs' number={getShortNumber(plusNumber).toString()} />}
  </div>
}