import { MenuItem } from "@ui-kit/components/drop-down/modele/menu-item";
import { useTranslation } from "react-i18next";

export const useExtraItemsMenu = () => {

  const { t } = useTranslation('common');

  const extraItemsMenu: MenuItem[] = [
  {
    key: 'wall',
    type: 'menu-item',
    title: t('userMenu.wall'),
    grayIcon: 'grid'
  },
  {
    key: 'search',
    type: 'menu-item',
    title: t('userMenu.search'),
    grayIcon: 'search',
  },
  {
    key: 'map',
    type: 'menu-item',
    title: t('userMenu.map'),
    grayIcon: 'map',
  },
  {
    disabled: true,
    key: 'broadcast',
    type: 'menu-item',
    title: t('userMenu.broadcast'),
    grayIcon: 'radio',
  },
  {
    disabled: true,
    key: 'notification',
    type: 'menu-item',
    title: t('userMenu.notification'),
    grayIcon: 'bell',
  },
  {
    key: 'messages',
    type: 'menu-item',
    title: t('userMenu.messages'),
    grayIcon: 'message-circle',
  },
  { key: 'hr-2', type: 'hr' }
  ]
  return extraItemsMenu;
}