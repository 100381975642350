import { commentListStore } from "@store";

export const deleteCommentFn = (
  commentId: string,
  postId: string,
  setCommentsCounter: React.Dispatch<React.SetStateAction<number | undefined>>
) => async (confirmDelete: boolean) => {
  if (!confirmDelete) return;

  try {
    commentListStore.deleteCommentFn(commentId);
    setCommentsCounter((prev) => (prev !== 1 && prev) ? prev - 1 : undefined);
    commentListStore.removeComent(postId, commentId);
  } catch (err) {
    console.error('error deleting post:', err);
  }
}