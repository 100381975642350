import { deleteCommentFn } from "@home/helpers/delete-comment";
import { ErrorFallback } from "@shared/components";
import { MAX_COMMENT_CHARACTERS } from "@shared/data";
import { useFormState } from "@shared/hooks";
import { commentListStore, modalCustomDialogsStore, toastsStore } from "@store";
import { observer } from "mobx-react-lite";
import { FormEventHandler, useEffect, useState } from "react";
import { Button, Form, ListGroup, ListGroupItem, Stack } from "react-bootstrap";
import { Loader } from "react-bootstrap-typeahead";
import { ErrorBoundary } from "react-error-boundary";
import { CommentItem } from "./comment-item.comp";
import styles from "./comment.module.css";
import { Post } from "@graphql/graphql";
import { userStore } from "src/store/user.store";
import { useTranslation } from "react-i18next";

type Props = {
  post: Post;
  setCommentsCounter: React.Dispatch<React.SetStateAction<number | undefined>>;
};

const initialState = { text: '' };

export const CommentList = observer(({ post, setCommentsCounter }: Props) => {
  const { formState, setInput, setFormState } = useFormState(initialState);
  const [validated, setValidated] = useState(false);

  const { comments, loading, fetchComments, nextTokens } = commentListStore;
  const [loadMore, setLoadMore] = useState(false);
  const { showDialog } = modalCustomDialogsStore;

  const { t } = useTranslation('main');

  useEffect(() => {
    if (post?.id) {
      fetchComments(post?.id).catch((err: any) => toastsStore.addErrorToast(t('toasts.error.loadListComment')));
      setValidated(false);
    }
  }, [fetchComments, post.id]);

  useEffect(() => {
    if(loadMore && post?.id) {
      fetchComments(post.id)
        .then(() => setLoadMore(false))
        .catch((err: any) => toastsStore.addErrorToast(t('toasts.error.loadListComment')));
    }
  }, [loadMore]);

  const handleCommentDelete = async (commentId: string) => {
    showDialog('', {
      typeIcon: 'delete',
      title: t('dialogs.deleteComment.title'),
      message: t('dialogs.deleteComment.message'),
      firstButtonText: t('dialogs.deleteComment.firstButton'),
      firstButtonColor: 'primary',
      secondButtonText: t('dialogs.deleteComment.secondButton'),
      secondButtonColor: 'secondary-color',
      confirmAction: deleteCommentFn(commentId, post.id ?? '', setCommentsCounter),
    });
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (formState.text.length > MAX_COMMENT_CHARACTERS) {
      toastsStore.addErrorToast(t('toasts.error.maxLenComment'));
      setValidated(false);
    } else {
      postComment().catch((err: any) => toastsStore.addErrorToast(t('toasts.error.loadListComment')));
      setValidated(true);
    }
  };

  const postComment = async () => {
    setFormState(initialState);
    commentListStore.postComment({
      text: formState.text,
      postId: post.id,
    })
    setCommentsCounter((prev) => prev ? prev + 1 : 1);
  }

  if (!post?.id) {
    return <Loader />
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ListGroup>
        {comments[post.id]?.map(
          (comment: any) =>
            comment && (
              <CommentItem
                key={comment?.id}
                comment={comment}
                fetchComments={() => fetchComments(post.id ?? '')}
                handleCommentDelete={handleCommentDelete}
              />
            )
        )}
        {(!!loading) &&
        <ListGroupItem variant='secondary'>
          <Loader />
        </ListGroupItem>}
        {(nextTokens[post.id] !== null && !loading) &&
          <ListGroupItem variant='secondary'>
            <a className={styles.moreLink} onClick={() => setLoadMore(true)}>{t('showMore')}</a>
          </ListGroupItem>
        }
        {userStore.currentUser &&
          <ListGroupItem variant='secondary'>
            <Form onSubmit={handleSubmit} validated={validated} >
              <Stack direction='horizontal' gap={2}>
                <Form.Group className='w-100' controlId='formBasicEmail'>
                  <Form.Control
                    as='textarea'
                    rows={1}
                    placeholder={t('inputComment.placeholder')}
                    value={formState.text}
                    className='me-auto ms-0'
                    onChange={(event) => setInput('text', event.target.value)}
                    isInvalid={formState.text.length > MAX_COMMENT_CHARACTERS}
                  />
                  <Form.Control.Feedback type='invalid'>
                  {t('inputComment.feedback.count', {count: MAX_COMMENT_CHARACTERS})}
                  </Form.Control.Feedback>
                </Form.Group>
                <Button
                  type='submit'
                  variant='primary'
                  disabled={!formState.text}
                >
                  Add
                </Button>
              </Stack>
            </Form>
          </ListGroupItem>
        }
      </ListGroup>
    </ErrorBoundary>
  );
});
