import { createDate } from "./createDate"

export const getMonthNames = (region: string = 'default') => {
  const monthNames: {
    month: ReturnType<typeof createDate>['month'],
    monthShort: ReturnType<typeof createDate>['monthShort'],
    monthIndex: ReturnType<typeof createDate>['monthIndex'],
    date: ReturnType<typeof createDate>['date'],
  }[]=Array.from({length: 12});

  monthNames.forEach((_, i) => {
    const {month, monthIndex, monthShort, date} = createDate({region, date:
      new Date(new Date().getFullYear(),
      new Date().getMonth() + i,
      new Date().getDate())})

      monthNames[monthIndex]={month, monthIndex, monthShort, date}
  })

  return monthNames;
}