import { useState, useEffect, useRef } from "react";
import styles from "./notification.module.css";
import { observer } from "mobx-react-lite";
import { Loader } from "@ui-kit/components/";
import {  Header } from "@shared/components";
import { Menu } from "@ui-kit/components/drop-down/comp/dropdown-menu/menu.comp";
import { useNotificationMenu } from "./useNotificationMenu";
import { MenuItem } from "@ui-kit/components/drop-down/modele/menu-item";
import { PushNotification } from "./components/push.comp";
import { EmailNotification } from "./components/email.comp";
import { useTranslation } from "react-i18next";

enum NotificationPageState{
  pageNotification,
  pagePush,
  pageEmail,
}

type Props = {
  onBack: () => void;
};


export const NotificationSettings = observer(({onBack}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentItem, setItem] = useState<MenuItem|null>(null)
  const [pageState, setPageState] = useState<NotificationPageState>(NotificationPageState.pageNotification);

  const [isTabletMobile, setIsTabletMobile] = useState(window.innerWidth <= 1080 ? true : false );
  const { menuItems, getInitialMenuState } = useNotificationMenu();

  const { t } = useTranslation('settings');

  const handleOnBack = () => {
    setPageState(NotificationPageState.pageNotification);
  }

  useEffect(() => {
    if (!currentItem) return;

    if (currentItem.key === 'push') {
      return setPageState(NotificationPageState.pagePush)
    }

    if (currentItem.key === 'email') {
      return setPageState(NotificationPageState.pageEmail)
    }
  }, [currentItem])


 if (isLoading) {
      return (<div className={styles['loading']}>
                <Loader loaderType='line' />
            </div>
  )}

  return (
    <section className={styles['profile-notification']}>
    {pageState === NotificationPageState.pageNotification && (
      <>
        <Header
          title={t('notifications.header')}
          className={styles['header-settigs']}
          hideAvatar={true}
          hideBackArrow={window.innerWidth > 1079}
          onBackClick={onBack}
        />
        <Menu className={styles['drop-menu-setting']}
          withoutShadow={true}
          withoutScroll={true}
          indicateSelection={true}
          items={menuItems}
          onClick={(item) => setItem(item)}
        />
      </>
    )}
    {pageState === NotificationPageState.pagePush && (
      <PushNotification onBack={handleOnBack}/>
    )}
    {pageState === NotificationPageState.pageEmail && (
      <EmailNotification onBack={handleOnBack}/>
    )}
  </section>
);
});