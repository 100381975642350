import { useState } from "react";
import { FallbackProps } from "react-error-boundary";
import styles from "./error-fallback.module.css";
import {Icon} from "@home/components/icon/icon.comp"

export function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  const [show, setShow] = useState(true);

  if (!show) {
    return null;
  }

  return (
    <div className={styles.toastContainer}>
      <div className={styles.toast}>
          <button onClick={() => setShow(false)} className={styles['button_toast-close']}>
          <Icon size='xxs-plus' icon='x' />
          </button>
          <div>{error.message}</div>
      </div>
    </div>
  );
}