import chadImg from "@assets/images/chad.png";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorFallback } from "@shared/components";
import { DateUtil } from "@shared/utils";
import { toastsStore } from "@store";
import { useEffect, useState } from "react";
import { Button, Stack } from "react-bootstrap";
import { ErrorBoundary } from "react-error-boundary";
import styles from "./post-item-header.module.css";
import { Link } from "react-router-dom";
import { Post } from "@graphql/graphql";
import { userStore } from "src/store/user.store";

type Props = {
  post: Post;
  handlePostDelete: (id: string) => {};
  city?: string | null;
};

export const PostItemHeader = ({ post, handlePostDelete, city }: Props) => {
  const [picture, setPicture] = useState<string | null>(null);

  useEffect(() => {
    setPicture(post.userPicture?.picture?.url ?? null);
  }, [post?.userPicture?.picture?.url])

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Stack direction='horizontal' className={styles.postInfo} gap={2}>
        <Link className={styles.userWallLink} to={`/wall/${post.username}`}>
          <img
            src={picture ?? chadImg}
            className={styles.profilePic}
            alt='profile'
          />
          <strong>{post?.username}</strong>
        </Link>
        <span>·</span>
        <span>{DateUtil.getTimeDiff(new Date(post?.createdAt ?? '').toISOString())}</span>
        {!!city && <span className='ms-auto'>{city}</span>}
        {post.username === userStore.currentUser?.username && (
          <Button
            size='sm'
            variant='secondary'
            className={!!city ? 'ms-2' : 'ms-auto'}
            onClick={() => handlePostDelete(post.id ?? '')}
          >
            <FontAwesomeIcon icon={faTrashCan} />
          </Button>
        )}
      </Stack>
    </ErrorBoundary>
  );
};
