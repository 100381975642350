import styles from "./header-buttom-current-user.module.css";
import { Button } from "@ui-kit";
import { useNavigate } from "react-router-dom";
import { userStore } from "@store";
import { useTranslation } from "react-i18next";

export const HeaderButtomCurrentUser = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('wall');

  return <>
    {!!userStore.currentUser?.username && <div className={styles.bottom}>
      <Button
        className={styles['settings-button']}
        onClick={() => navigate('/profile')}
        iconType='settings'
        hierarchy='secondary-gray'
      >
        {t('button.editProfile')}
      </Button>
      <Button
        onClick={() => navigate('/saved')}
        hierarchy='secondary-gray'
        iconType='bookmark'
      />
    </div>}
  </>
}