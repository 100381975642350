import { UserPicture } from "@graphql/graphql";
import styles from "./trend-item.module.css";
import { Avatar } from "@ui-kit/components/avatar/avatar.comp";
import { getFormatedTime } from "@shared/helpers/get-formated-time";
import { getShortNumber } from "@shared/helpers/get-short-number";
import { Icon } from "@home/components/icon/icon.comp";

type Props = {
  title: string,
  user: UserPicture,
  date: number,
  views: number,
}

export const TrendItem = ({ title, user, date, views }: Props) => {
  return <div className={styles.item}>
    <h3 className={styles.item__title}>{title}</h3>
    <div className={styles.item__bottom}>
      <Avatar
        url={user.picture?.url ?? undefined}
        alt={user.username ?? undefined}
        size='xs'
        statusIcon={user?.isOnline ? 'online' : undefined}
      />
      <p className={styles.item__fullname}>{user.firstname} {user.lastname}</p>
      <p className={styles.item__time}>{getFormatedTime(date)}</p>
      <div className={styles.item__views}>
        <Icon icon='eye' color='#98A2B3' size='xxs' />
        {getShortNumber(views)}
      </div>
    </div>
  </div>
}