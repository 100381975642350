export const PATHES_WITH_RIGHT = [
  "login",
  "registration",
  "confirm-recover",
  "old",
  "chat",
  "add-city",
  "search",
  "",
  "post",
  "wall",
  "followers",
  "following",
  "saved",
  "notifications"
];