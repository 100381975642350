import React, { createRef } from "react";
import styles from "./code-input.module.css";
import { Digit } from "./comp/digit.comp";

type Props = {
  size: 'sm'|'md'|'lg'
  label?: string,
  hintText?: string,
  text: string,
  digits: 4|6,
  placeholder?: string
  onchange: React.Dispatch<React.SetStateAction<string>>
  className?: string,
}

const DIGITS = '0123456789'.split('');

export const CodeInput = ({
  size, label, hintText, digits, text, placeholder, onchange, className
}: Props) => {
  const [inputRefs, setInputRefs] = React.useState<any>([]);

  React.useEffect(() => {
    // add or remove refs
    setInputRefs((elRefs: any) =>
      new Array(digits).fill(undefined)
        .map((_, i) => elRefs[i] || createRef()),
    );
  }, [digits]);

  const _onChange = (str: string) => {
    const code = str
      ? str
        .split('')
        .filter((ch) => DIGITS.includes(ch))
        .join('')
        .substring(0, digits)
      : '';
    if (inputRefs?.[code.length]) {
      inputRefs[code.length]?.current?.focus();
    }
    onchange(code);
  }

  const _changeChar = (key: string, i: number) => {
    if (key === 'Backspace') {
      _onChange(text.substring(0, text.length - 1));
    } else if (!Number.isNaN(key) && +key >= 0 && +key <= 9) {
      _onChange(`${text}${key}`);
    } else {
      _onChange(text)
    }
  }

  return (
    <div className={`${styles.inputBlock} ${className ? className : ''}`}>
      {label ?? <h4 className={styles.phoneTitle}>{label}</h4>}
      <div className={`${styles['code-block']} ${size === 'sm' ? styles['code-block_sm'] : ''}`}>
        {Array(digits === 6 ? 7 : 4).fill('').map((_, i) => (
          ((digits !== 6) || (i !== 3)) ? <Digit
            key={digits === 4 || i < 3 ? i : i - 1}
            size={size}
            placeholder={placeholder?.split('')?.[i]}
            value={text.split('')?.[digits === 4 || i < 3 ? i : i - 1]}
            onChange={(key) => _changeChar(key, i)}
            inputRef={inputRefs[digits === 4 || i < 3 ? i : i - 1]}
          /> : <div className={styles.dash} key='-'>-</div>
        ))}
      </div>
      {hintText ?? <h4 className={styles.phoneTitle}>{label}</h4>}
    </div>
  )
}