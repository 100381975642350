import { useEffect, useState } from "react";
import styles from "./what-map.module.css";
import { observer } from "mobx-react-lite";
import map from "@assets/images/map.svg";
import { Header } from "@shared/components";
import { useTranslation } from "react-i18next";

type Props = {
  onBack: () => void;
};

export const WhatMap = observer(({onBack}: Props) => {

  const { t } = useTranslation('help');

  const [isTabletMobile, setIsTabletMobile] = useState(window.innerWidth <= 1079 ? true : false );

  return (
<>
  <Header
    className={styles['header-help']}
    title={t('main.menu.whatMap')}
    hideAvatar={true}
    hideBackArrow={window.innerWidth > 1079}
    onBackClick={onBack}
  />
  <article className={styles['help-ecitty']}>
    <div className={styles['image-container']}>
        <img className={styles['help-image']} src={map} alt='Ecitty map' />
    </div>

    <p className={styles['help-text']}>{t('whatMap.intro')}</p>

    <section>
      <h6 className={styles['help-title']}>{t('whatMap.visualExploration.header')}</h6>
      <p className={styles['help-text']}>{t('whatMap.visualExploration.text')}</p>
    </section>

    <section>
      <h6 className={styles['help-title']}>{t('whatMap.informationExchange.header')}</h6>
      <p className={styles['help-text']}>{t('whatMap.informationExchange.text')}</p>
    </section>

    <section>
      <h6 className={styles['help-title']}>{t('whatMap.discoveringCities.header')}</h6>
      <p className={styles['help-text']}>{t('whatMap.discoveringCities.text')}</p>
    </section>

    <section>
      <h6 className={styles['help-title']}>{t('whatMap.communityFormation.header')}</h6>
      <p className={styles['help-text']}>{t('whatMap.communityFormation.text')}</p>
    </section>

    <section>
      <h6 className={styles['help-title']}>{t('whatMap.interactivity.header')}</h6>
      <p className={styles['help-text']}>{t('whatMap.interactivity.textPartOne')}</p>
      <p className={styles['help-text']}>{t('whatMap.interactivity.textPartTwo')}</p>
    </section>
  </article>
</>
);
});
