import { useEffect, useState, useCallback } from "react";
import styles from "./help.module.css";
import { observer } from "mobx-react-lite";
import { Header } from "@shared/components/header/header.comp";
import { Menu } from "@ui-kit/components/drop-down/comp/dropdown-menu/menu.comp";
import { useHelpMenu } from "./components/useHelpMenu";
import { MenuItem } from "@ui-kit/components/drop-down/modele/menu-item";
import { WhatEcitty, WhatMap, WhatPost, WhatWall } from "./components";
import { BottomMenu } from "@ui-kit/components/bottom-menu/bottom-menu.comp";
import { useTranslation } from "react-i18next";


enum HelpPageState{
  pageWhatEcitty,
  pageWhatMap,
  pageWhatWall,
  pageWhatPost,
}

export const HelpPage = observer(() => {

  const { t } = useTranslation('help');

  const [currentItem, setItem] = useState<MenuItem>({ key: 'ecitty', type: 'menu-help' });
  const [pageState, setPageState] = useState<HelpPageState>(HelpPageState.pageWhatEcitty);

  const [isTabletMobile, setIsTabletMobile] = useState(window.innerWidth <= 1080);
  const [isMenuVisible, setIsMenuVisible] = useState(true);

  const { menuItems, getInitialMenuState } = useHelpMenu();

  const handleOnBack = () => {
    setIsMenuVisible(true);
  }

  const handleMenuItemClick =  useCallback((item: MenuItem) => {
    setItem(item);
    setIsMenuVisible(window.innerWidth <= 1080 ? false : true);

    if (window.innerWidth <= 1080) {
      setIsMenuVisible(false);
    }

    switch (item.key) {
      case 'ecitty':
        setPageState(HelpPageState.pageWhatEcitty);
        break;
      case 'map':
        setPageState(HelpPageState.pageWhatMap);
        break;
      case 'wall':
        setPageState(HelpPageState.pageWhatWall);
        break;
      case 'post':
        setPageState(HelpPageState.pageWhatPost);
        break;
      default: return;
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1080) {
        setIsTabletMobile(false);
        setIsMenuVisible(true);
      } else {
        setIsTabletMobile(true);
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderHelpPage = () => {
    switch (pageState) {
      case HelpPageState.pageWhatEcitty:
        return <WhatEcitty onBack={handleOnBack} />
      case HelpPageState.pageWhatMap:
        return <WhatMap onBack={handleOnBack} />;
      case HelpPageState.pageWhatWall:
        return <WhatWall onBack={handleOnBack} />;
      case HelpPageState.pageWhatPost:
        return <WhatPost onBack={handleOnBack} />;
      default:
        return null;
    }
  };

  return (<>
    <section className={styles['help-page']}>
      {isMenuVisible && (
        <section className={styles['help-menu']}>
          <Header
            className={styles['header-help']}
            title={t('main.header')}
            hideAvatar={true}
          />
          <Menu className={styles['drop-menu-help']}
            withoutShadow={true}
            withoutScroll={true}
            indicateSelection={true}
            items={menuItems}
            onClick={handleMenuItemClick}
          />
        </section>
      )}
      {isTabletMobile && !isMenuVisible && (
        <section className={styles['help-item']}>
          {renderHelpPage()}
        </section>
      )}
      {isMenuVisible && !isTabletMobile && (
        <section className={styles['help-item']}>
          {renderHelpPage()}
        </section>
      )}
    </section>
    <BottomMenu
      className={styles['bottom-menu']}
      onCreateClick={() => {}}/>
  </>)
});

