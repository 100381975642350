import { useState, useEffect, useRef } from "react";
import styles from "./input.module.css";
import { MenuItem } from "../drop-down/modele/menu-item";
import { Avatar } from "../avatar/avatar.comp";

type Props = {
  label?: string,
  text: string,
  type: "text"|"password"|"phone"|"number"|"search"|"gender"|"date"|"user",
  placeholder?: string,
  onchange: (str: string) => void,
  className?: React.HTMLAttributes<HTMLDivElement>|string,
  supportingText?: string;
  disabled?: boolean;
  hasErrors?: boolean;
  errorsMess?: string;
  item?: MenuItem;
  onFocus?: () => void;
  onBlur?: () => void;
  value?: string;
}

export const InputComponent = ({label, value, text, type, placeholder, onchange, className, disabled, item, onFocus: onFocusAction, onBlur: onBlurAction}: Props) => {
  const [formatedValue, setFormatedValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    return setFormatedValue(text);
  }, [text])

  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onchange(e.target.value);
  }

  const onFocus = () => { setIsFocused(true); onFocusAction && onFocusAction() }
  const onBlur = () => { setIsFocused(false); onBlurAction && onBlurAction() }

  return (
    <div className={`${styles.inputBlock} ${className ? className : ''}`}>
      {!!label && <h4 className={styles.phoneTitle}>{label}</h4>}
      <input
        ref={inputRef}
        onFocus={onFocus}
        onBlur={onBlur}
        className={`
          ${styles.input}
          ${type === 'search' ? styles.input_search : ''}
          ${type === 'user' ? styles.input_user : ''}
        `}
        value={(isFocused || !item) ? formatedValue : '' || value ? value : formatedValue}
        onChange={_onChange}
        placeholder={placeholder}
        disabled={disabled}
      />
      {!!item
        && !isFocused
        && <div className={styles.input__item}>
          <Avatar url={item.iconUrl} alt={item.title} size='xs' />
          <p className={styles['input__item-title']}>
            {item.title}
          </p>
          <p className={styles['input__item-sub-title']}>
            {item.subTitle}
          </p>
        </div>}
    </div>
  )
}