import { MenuItem } from "@ui-kit/components/drop-down/modele/menu-item";
import { useTranslation } from "react-i18next";

export const useHelpMenu = () => {

  const { t } = useTranslation('help');

  const initialMenuSettings: MenuItem[] = [
    {
      key: 'ecitty',
      type: 'menu-help',
      title: t('main.menu.whatEcitty'),
      showIcon: true,
      grayIcon: 'chevron-right'
    },
    {
      key: 'map',
      type: 'menu-help',
      title: t('main.menu.whatMap'),
      showIcon: true,
      grayIcon: 'chevron-right',
    },
    {
      key: 'wall',
      type: 'menu-help',
      title: t('main.menu.whatWall'),
      showIcon: true,
      grayIcon: 'chevron-right',
    },
    {
      key: 'post',
      type: 'menu-help',
      title: t('main.menu.whatPost'),
      showIcon: true,
      grayIcon: 'chevron-right',
    },
  ];

  const showKeys = ['ecitty', 'map', 'wall', 'post'];

  const getInitialMenuState = () => {
    return initialMenuSettings.filter((menuItem) => showKeys.includes(menuItem.key));
  };

  return { menuItems: initialMenuSettings, getInitialMenuState };
};
