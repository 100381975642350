import { MediaFile } from "@graphql/graphql";
import { MediaItemComponent } from "../media-item/media-item.comp";
import styles from "./media-more-items.module.css";

type Props = {
  mediaFiles: MediaFile[];
  onClick?: () => void;
  className?: string;
}

export const MediaMoreItemsComponent = ({ mediaFiles, onClick, className }: Props) =>
  <div
    onClick={() => { !!onClick && onClick(); }}
    className={className ? `${className} ${styles.more}` :styles.more}
    style={{
      cursor: !!onClick ? 'pointer' : 'default'
    }}>
    {!mediaFiles[4].type?.includes('video') && <MediaItemComponent
      className={className ? `${className} ${styles.more__picture}` : styles.more__picture}
      mediaFile={mediaFiles[4]} />}
    <div className={className ? `${className} ${styles.foreground}` : styles.foreground}>
      <div className={styles.plus}>
        +{mediaFiles.length - 4}
      </div>
    </div>
  </div>