
export const getNotificationMesssage = (type: string, t: any) => {
  switch(type) {
    case 'post-reaction':
      return t('notification:message.reaction.post');
    case 'comment-reaction':
      return t('notification:message.reaction.comment');
    case 'message':
      return t('notification:message.message');
    case 'message-reaction':
      return t('notification:message.reaction.message');
    case 'subscription':
      return t('notification:message.reaction.subscription');
    case 'tagged-post':
      return t('notification:message.reaction.taggetPost');
    case 'tagged-comment':
      return t('notification:message.reaction.taggetComment');
    case 'new-post':
      return t('notification:message.newPost');
    case 'answer-comment':
      return t('notification:message.reaction.answer');
    case 'comment':
      return t('notification:message.reaction.commentPost');
    default:
      return t('notification:message.reaction.reaction');
  }
}