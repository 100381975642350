import { Post } from "@graphql/graphql";
import { postListStore } from "@store";
import { userStore } from "src/store/user.store";

export async function makeReaction (
  post: Post,
  type?: string
) {
  const { posts } = postListStore
  const foundPost = posts?.find((pst) => pst?.id === post.id);
  if (!foundPost) return;
  
  const copyPost = { ...foundPost } as Post;
  const item = {...copyPost.reactions?.find((react) =>
    react?.username === userStore.currentUser?.username && react?.type === type,
  )}

  if (item?.id) {
    postListStore.deleteReaction(item.id);
  } else {
    postListStore.createReaction({
      postId: post.id ?? '',
      type: type ?? '',
    });
  }
}