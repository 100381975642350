import { useReducer } from "react";

export type Selection = { lat: number, lng: number } | null

export type Cords = { lat: number, lng: number }

export interface AddCityState {
  loading: boolean,
  error: string,
  city: string,
  placeId: string,
  longName: string,
  country: string,
  goToMyGeo: boolean,
  lang: string,
  selected: Selection,
  center: Cords,
  zoom: number,
};

const initialState: AddCityState = {
  loading: false,
  error: '',
  city: '',
  placeId: '',
  longName: '',
  country: '',
  goToMyGeo: false,
  lang: 'en',
  selected: null,
  center: { lat: 48.285572, lng: 69.894272 },
  zoom: 4,
};

export type AddCityAction =
  | { type: 'startLoading' }
  | { type: 'stopLoading' }
  | { type: 'setError', payload: string }
  | { type: 'setCity', payload: string }
  | { type: 'setPlaceId', payload: string }
  | { type: 'setLongName', payload: string }
  | { type: 'setCountry', payload: string }
  | { type: 'letsGoToMyGeo' }
  | { type: 'doNotGoToMyGeo' }
  | { type: 'setLang'; payload: string }
  | { type: 'setSelection'; payload: Selection }
  | { type: 'setCenter'; payload: Cords }
  | { type: 'setZoom'; payload: number };

export function addCityReducer(
  state: AddCityState,
  action: AddCityAction
): AddCityState {
  switch (action.type) {
    case 'startLoading':
      return { ...state, loading: true };
    case 'stopLoading':
      return { ...state, loading: false };
    case 'setError':
      return { ...state, error: action.payload };
    case 'setCity':
      return { ...state, city: action.payload };
    case 'setPlaceId':
      return { ...state, placeId: action.payload };
    case 'setLongName':
      return { ...state, longName: action.payload };
    case 'setCountry':
      return { ...state, country: action.payload };
    case 'letsGoToMyGeo':
      return { ...state, goToMyGeo: true };
    case 'doNotGoToMyGeo':
      return { ...state, goToMyGeo: false };
    case 'setLang':
      return { ...state, lang: action.payload };
    case 'setSelection':
      return { ...state, selected: action.payload };
    case 'setCenter':
      return { ...state, center: action.payload };
    case 'setZoom':
      return { ...state, zoom: action.payload };
    default:
      return state;
  }
}

export const useAddCityReducer = () => useReducer(addCityReducer, initialState);
