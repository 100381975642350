import flag from "@assets/images/flag.png";
import { PlacesAutocomplete } from "@add-city/components";
import { MapController } from "@add-city/types";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { citiesStore, toastsStore } from "@store";
import { useState, useEffect } from "react";
import { HookReturn, getGeocode, getLatLng } from "use-places-autocomplete";
import styles from "./g-map.module.css";
import { City } from "@graphql/graphql";
import { CityWithCords, getCitiesCords } from "@shared/helpers/get-cities-cords";
import { useTranslation } from "react-i18next";

interface Props {
  handleMapClick: (event: any) => void,
  setCityByCords: (lat: number, lng: number) => Promise<any>,
  city: string,
  addNewCity: () => void,
  hookReturn: HookReturn,
  mapController: MapController,
  buttonText: string,
}

export function GMap({
  handleMapClick, setCityByCords, city, addNewCity, hookReturn, mapController, buttonText
}: Props) {
  const [citiesCords, setCityCords] = useState<CityWithCords[]>([])
  const { t } = useTranslation('common');

  useEffect(() => {
    getCitiesCords(citiesStore.cities).then((data) => {
      setCityCords(data);
    }).catch((err) => {
      console.error(err);
      toastsStore.addErrorToast('Can not load cities cords!');
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citiesStore.cities])

  useEffect(() => { citiesStore.fetchCities().catch(() => toastsStore.addErrorToast(t('toasts.error.getLocalCity')))}, [])

  return (
    <>
      <div className={styles.placesContainer}>
        <PlacesAutocomplete
          city={city}
          setCityByCords={setCityByCords}
          addNewCity={addNewCity}
          hookReturn={hookReturn}
          mapController={mapController}
          buttonText={buttonText}
        />
      </div>

      <GoogleMap
        center={mapController.center}
        zoom={mapController.zoom}
        mapContainerClassName={styles.mapContainer}
        onClick={handleMapClick}
        options={{fullscreenControl: false, streetViewControl: false, mapTypeControl: false}}
      >
        {mapController.selected && <Marker position={mapController.selected} />}
        {citiesCords.map((cityItem) =>
          <Marker
            key={cityItem.place_id}
            position={{ lat: cityItem.lat, lng: cityItem.lng }}
            icon={flag}
            onClick={() => {
              setCityByCords(cityItem.lat, cityItem.lng).catch(() => toastsStore.addErrorToast(t('toasts.error.setLocalCity')))
            }}
            />
        )}
      </GoogleMap>
    </>
  );
}