import styles from "./constnt.module.css";

type Props = {
  className?: string,
  children: any,
}

export const Content = ({ className, children }: Props) => {
  className = className ?? '';

  return <div className={`${styles.content} ${className}`}>
    {children}
  </div>
}