export const getShortNumber = (val: number) => {
  if (val >= 1_000_000_000) {
    return `${Math.round(val / 100_000_000) / 10}b`;
  }
  if (val >= 1_000_000) {
    return `${Math.round(val / 100_000) / 10}m`;
  }
  if (val >= 1000) {
    return `${Math.round(val / 100) / 10}k`;
  }

  return val;
}