import { getWeekNumber } from "./getWeekNumber";

interface CreateDateProps{
  region?: string;
  date?: Date;
}

export const createDate = (data?: CreateDateProps) =>{
  const region = data?.region ?? 'default'
  const date = data?.date ?? new Date();

  const dayNumber = date.getDate();
  const day = date.toLocaleDateString(region, {weekday: 'long'});
  const dayNumberInWeek = date.getDay() + 1;
  const dayShort = date.toLocaleDateString(region, {weekday: 'short'});
  const year = date.getFullYear();
  const yearShort = date.toLocaleDateString(region, {year: '2-digit'});
  const month = date.toLocaleDateString(region, {month: 'long'});
  const monthShort = date.toLocaleDateString(region, {month: 'short'});
  const monthNumber = date.getMonth() + 1;
  const monthIndex = date.getMonth();
  const timesStamp = date.getTime();
  const week = getWeekNumber(date);

  return {
   date,
   dayNumber,
   day,
   dayNumberInWeek,
   dayShort,
   year,
   yearShort,
   month,
   monthShort,
   monthNumber,
   monthIndex,
   timesStamp,
   week
  }
}