export type ShareIconType = 'facebook'|'telegram'|'twitter'|'instagram';

export const getIconType = (type: ShareIconType) => {
  switch (type) {
    case 'facebook': return 'social-fb-grey'
    case 'telegram': return 'social-tg-grey'
    case 'twitter': return 'social-tw-grey'
    case 'instagram': return 'social-ig-grey'
    default: return 'social-fb-grey'
  }
}