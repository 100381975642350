import COUNTRY_PHONE from "@ui-kit/data/countrie-phone";

export type Phone = {
  code: string,
  number: string,
}

export const splitNumber = (phone: string): Phone => {
	const foundCode = COUNTRY_PHONE.find((code) => phone.substring(0, code.phone.length) === code.phone);
  return foundCode
    ? { code : phone.substring(0, foundCode.phone.length) , number : phone.substring(foundCode.phone.length) }
    : { code: '', number: '' };
}