import { BottomMenuItem } from "@ui-kit/components/bottom-menu/data/bottom-menu-item";
import { MAIN_MENU_KEYS } from "../components/main-menu/useMainMenu";

export const bottomMenuItems: BottomMenuItem[] = [
  {
    key: MAIN_MENU_KEYS.WALL,
    icon: 'grid',
  },
  {
    key: MAIN_MENU_KEYS.SEARCH,
    icon: 'search',
  },
  {
    key: MAIN_MENU_KEYS.BROADCAST,
    icon: 'radio',
    disabled: true,
  },
  {
    key: MAIN_MENU_KEYS.NOTIFICATION,
    icon: 'bell',
  },
  {
    key: MAIN_MENU_KEYS.MESSAGES,
    icon: 'message-circle',
  },
  {
    key: MAIN_MENU_KEYS.SETTINGS,
    icon: 'settings',
  }
];

const showKeys: string[] = [
  MAIN_MENU_KEYS.WALL,
  MAIN_MENU_KEYS.SEARCH,
  MAIN_MENU_KEYS.BROADCAST,
  MAIN_MENU_KEYS.SETTINGS
];

export const getBottomMenuItems = (hasUser: boolean) => {
  if(!hasUser) {
    return bottomMenuItems.filter((menuItem) => showKeys.includes(menuItem.key))
  }

  return bottomMenuItems;
}
