import { toastsStore } from "@store";
import { makeReaction } from "./make-reaction";
import { Post } from "@graphql/graphql";
import { userStore } from "src/store/user.store";
import { ReactionTypes } from "src/assets/reactions";

export const likePost = async (
  post: Post,
  type?: ReactionTypes
) => {
  if (!userStore.currentUser?.username) return;

  try {
    await makeReaction(post, type ?? 'like');
  } catch (err: any) {
    console.error('error liking post:', err);
    toastsStore.addErrorToast('Error liking post!');
  }
};