import { useLoadScript } from "@react-google-maps/api";
import { ErrorBoundary } from "react-error-boundary";
import LoadingOverlay from "react-loading-overlay-ts";
import { useLocation } from "react-router-dom";
import { AddCity } from "@add-city/components";
import { ErrorFallback } from "@shared/components";

export function AddCityPage() {
  const location = useLocation();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY ?? '',
    language: 'en',
    libraries: ['places'],
  });

  if (!isLoaded) return <LoadingOverlay spinner><div style={{width: '100svw', height: '100svw'}}></div></LoadingOverlay>
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <AddCity addToProfile={location?.state?.addToProfile} />
    </ErrorBoundary>
  );
}
