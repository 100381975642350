import styles from "../button.module.css";
import socialIcons from "../../../../assets/social-icons/index";
import { Button } from "../button.component";
import { ShareIconType, getIconType } from "./helpers/get-icon-type";

type Props = {
  type: ShareIconType
  onClick?: () => void;
  supportingText?: boolean;
  disabled?: boolean;
};

export const ShareButton = ({
  type,
  onClick,
  disabled,
  supportingText,
}: Props) => {


  if (!onClick) {
    onClick = () => {};
  }

  return (
    <Button hierarchy='secondary-gray' className={styles['button__max-width']} onClick={onClick}>
      <img src={socialIcons[getIconType(type)]} alt={type} />
      {supportingText && <span>{type[0].toUpperCase()}{type.substring(1)}</span>}
    </Button>
  );
};
