import { useEffect, useState } from "react";
import styles from "../notification.module.css";
import { observer } from "mobx-react-lite";
import { Header } from "@shared/components";
import { Checkbox, Toggle } from "@ui-kit";
import { userStore } from "@store";
import { useTranslation } from "react-i18next";

type Props = {
  onBack: () => void;
};

export const EmailNotification = observer(({onBack}: Props) => {
  const { currentUser } = userStore;
  const [isToggledMain, setIsToggledMain] = useState(currentUser?.emailNotify ?? false);
  const [isDisabled, setIsDisabled]=useState(!isToggledMain);
  const [notification, setNotification] = useState({
    emailNewNotify: currentUser?.emailNewNotify ?? false,
    emailNewMessagesNotify: currentUser?.emailNewMessagesNotify ?? false,
    emailEcittyUpdatesNotify: currentUser?.emailEcittyUpdatesNotify ?? false,
    emailEcittySurveyNotify: currentUser?.emailEcittySurveyNotify ?? false,
    emailEcittyRecommendationsNotify: currentUser?.emailEcittyRecommendationsNotify ?? false
  });

  const { t } = useTranslation('settings');

  const defaulPostNotify = (emailTopPostsNotify: string ) => {
    switch (emailTopPostsNotify) {
      case 'everyday':
        return t('notifications.email.messages.posts.checkboxDay');
        case 'every-week':
          return t('notifications.email.messages.posts.checkboxWeek');
      case 'off':
        return t('notifications.email.messages.posts.checkboxOff');
        default:
          return t('notifications.email.messages.posts.checkboxOff');
        }
      };

  const  emailTopPostsNotify=defaulPostNotify(currentUser?.emailTopPostsNotify ?? `${t('notifications.email.messages.posts.checkboxOff')}`)
  const [selectedCheckbox, setSelectedCheckbox] = useState(emailTopPostsNotify);

  const handleToggleEmail = async () => {
    const newToggleEmail = !isToggledMain;
    setIsDisabled(false);
    setIsToggledMain(newToggleEmail);
    try {
      await userStore.updateUserData({
        emailNotify: newToggleEmail
      });
      await userStore.me();
    } catch (err) {
      console.error(err);
    }
  }

  const handleToggle = async (key: keyof typeof notification, newValue: boolean) => {
    setNotification({ ...notification, [key]: newValue });

    try {
      await userStore.updateUserData({ [key]: newValue });
      await userStore.me();
    } catch (err) {
      console.error(err);
    }
  };

  const handleCheckboxChange = async (label: string) => {
    let postNotifyValue;
    switch (label) {
      case t('notifications.email.messages.posts.checkboxDay'):
        postNotifyValue = 'everyday';
        break;
      case t('notifications.email.messages.posts.checkboxWeek'):
        postNotifyValue = 'every-week';
        break;
      case t('notifications.email.messages.posts.checkboxOff'):
        postNotifyValue = 'off';
        break;
      default:
        postNotifyValue = 'off';
    }

    setSelectedCheckbox(label);
    try {
    await userStore.updateUserData({ emailTopPostsNotify: postNotifyValue });
    await userStore.me();
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!isToggledMain) {
      setIsDisabled(true);
    }
  }, [isToggledMain]);

  return (
  <>
    <Header
      className={styles['header-settigs']}
      title={t('notifications.email.header')}
      hideBackArrow={window.innerWidth > 1079}
      onBackClick={onBack}
      hideAvatar={true}
    />
    <section className={styles['email-notification-container']}>
      <div className={styles['email-notification-header']}>
        <Toggle
          initialState={isToggledMain}
          onToggle={handleToggleEmail}
          title={t('notifications.email.toggleEmail.title')}
          supportingText={t('notifications.email.toggleEmail.supportingText')}
        />
      </div>
      <p className={styles['header-email']}>
      {t('notifications.email.messages.header')}
      </p>
      <section className={styles['email-notification-message']}>
        <Toggle
          initialState={notification.emailNewNotify}
          onToggle={(newToggleValue) => handleToggle('emailNewNotify', newToggleValue)}
          disabled={isDisabled}
          title={t('notifications.email.messages.toggleNotify')}
        />
        <Toggle
          initialState={notification.emailNewMessagesNotify}
          onToggle={(newToggleValue) => handleToggle('emailNewMessagesNotify', newToggleValue)}
          disabled={isDisabled}
          title={t('notifications.email.messages.toggleMessagesNotify')}
        />
        <p className={styles['title-posts-settings']}>
        {t('notifications.email.messages.posts.header')}
        </p>
        <div className={styles['email-notification-frequency']}>
          <Checkbox
            type='radio'
            label={t('notifications.email.messages.posts.checkboxDay')}
            checked={selectedCheckbox === t('notifications.email.messages.posts.checkboxDay')}
            onClick={()=>handleCheckboxChange(`${t('notifications.email.messages.posts.checkboxDay')}`)}
            disabled={isDisabled}
            isNotification={true}
          />
          <Checkbox
            type='radio'
            label={t('notifications.email.messages.posts.checkboxWeek')}
            checked={selectedCheckbox === t('notifications.email.messages.posts.checkboxWeek')}
            onClick={()=>handleCheckboxChange(`${t('notifications.email.messages.posts.checkboxWeek')}`)}
            disabled={isDisabled}
            isNotification={true}
          />
          <Checkbox
            type='radio'
            label={t('notifications.email.messages.posts.checkboxOff')}
            checked={selectedCheckbox === t('notifications.email.messages.posts.checkboxOff')}
            onClick={()=>handleCheckboxChange(`${t('notifications.email.messages.posts.checkboxOff')}`)}
            disabled={isDisabled}
            isNotification={true}
          />
        </div>
      </section>
      <p className={styles['header-email']}>
      {t('notifications.email.messages.ecity.header')}
      </p>
      <section className={styles['email-notification-posts']}>
        <Toggle
          initialState={notification.emailEcittyUpdatesNotify}
          onToggle={(newToggleValue) => handleToggle('emailEcittyUpdatesNotify', newToggleValue)}
          disabled={isDisabled}
          title={t('notifications.email.messages.ecity.toggleUpdates')}
        />
        <Toggle
          initialState={notification.emailEcittySurveyNotify}
          onToggle={(newToggleValue) => handleToggle('emailEcittySurveyNotify', newToggleValue)}
          disabled={isDisabled}
          title={t('notifications.email.messages.ecity.toggleSurvey')}
        />
        <Toggle
          initialState={notification.emailEcittyRecommendationsNotify}
          onToggle={(newToggleValue) => handleToggle('emailEcittyRecommendationsNotify', newToggleValue)}
          disabled={isDisabled}
          title={t('notifications.email.messages.ecity.toggleRecommend')} />
      </section>
    </section>
  </>
);
});