import "moment/locale/ru";
import moment from "moment";


const locale = localStorage.getItem('i18nextLng') || 'ru';

if (locale === 'ru' || locale === 'kk') {
  moment.updateLocale(locale, {
    relativeTime : {
      future: "через %s",
      past:   "%s",
      s  : '0 сек.',
      ss : '%d сек.',
      m:  "1 мин.",
      mm: "%d мин.",
      h:  "1 час.",
      hh: "%d час.",
      d:  "1 дн.",
      dd: "%d дн.",
      w:  "1 нед.",
      ww: "%d нед.",
      M:  "1 мес.",
      MM: "%d мес.",
      y:  "1 год",
      yy: "%d лет"
    }
  });
} else {
  moment.updateLocale('en', {
    relativeTime: {
      future: "in %s",
      past: "%s ago",
      s: 'a few sec.',
      ss: '%d sec.',
      m: "a min.",
      mm: "%d min.",
      h: "an hour",
      hh: "%d hours",
      d: "a day",
      dd: "%d days",
      w: "a week",
      ww: "%d weeks",
      M: "a mon.",
      MM: "%d mon.",
      y: "a year",
      yy: "%d years"
    }
  });
}

export const getMessageTime = (number?: number) => {
  const dateMs = number ?? 0;
  const date = new Date(dateMs);
  return moment(date).fromNow();
}