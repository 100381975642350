import { Avatar } from "../avatar/avatar.comp";
import { Text } from "../text/text.comp";
import styles from "./avatar-label-group.module.css";

type Props = {
  url?: string,
  username?: string,
  fullName: string,
  className?: string,
  size?: "sm"|"md"|"lg"|"xl",
  statusIcon?: "online"|"false"|"company",
}

export const AvatarLabelGroup = ({
  url, username, fullName, className, size, statusIcon
}: Props) => {
  if (!size) {
    size = 'md'
  }
  return <div className={`${styles.group} ${className ?? ''}`}>
    <Avatar size={size} url={url} alt={username ?? fullName} statusIcon={statusIcon} />
    <div className={styles.right}>
      <Text className={styles['full-name']}>{fullName}</Text>
      {!!username && <Text className={styles.username}>@{username}</Text>}
    </div>
  </div>
}