import { useEffect } from "react";
import styles from "./message-list.module.css";
import { messagesStore } from "@store";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import { observer } from "mobx-react-lite";
import { UUID_REGEXP } from "@shared/data";
import { Message } from "@graphql/graphql";
import { userStore } from "src/store/user.store";
import { Loader } from "react-bootstrap-typeahead";

export const MessageList = observer(() => {
  const { id } = useParams();

  const usernameView = (message: Message) => {
    const firstname = message.userPicture?.firstname;
    const lastname = message.userPicture?.lastname;
    if (firstname && lastname) {
      return `${firstname} ${lastname}`
    }
    if (firstname) {
      return firstname
    }
    if (lastname) {
      return lastname
    }
    return message.username;
  }

  useEffect(() => {
    if (!id || !messagesStore.messages[id]) {
      return;
    }
    const notReadMessages = messagesStore.messages[id]
      .filter((msg) =>
        msg.username !== userStore.currentUser?.username
        && !msg.read?.includes(userStore.currentUser?.username ?? '-')
      );
    notReadMessages.forEach((msg) => messagesStore.readMessage(msg.id ?? ''));
    messagesStore.messagesCout -= notReadMessages.length;
  }, [messagesStore.messages])

  const isRead = (message: Message) =>
    message.username === userStore.currentUser?.username
    && message.read
    && message.read.length > 0;

  const isDelivered = (message: Message) =>
    message.username === userStore.currentUser?.username
    && message.delivered
    && message.delivered.length > 0
    && !isRead(message);

  if (!id || (UUID_REGEXP.test(id) && !messagesStore.messages[id]))
  return (
    <Loader />
  )

  return (
    <div className={styles.container}>
      {!!id && messagesStore.messages[id] && (UUID_REGEXP.test(id) ? messagesStore.messages[id] : []).map((message) =>
      <div
        key={message.id}
        className={
          userStore.currentUser?.username === message.username ?
            styles.sentMessageContainer :
            styles.receivedMessageContainer
        }
      >
        <p className={styles.senderText}>
          {usernameView(message)}
        </p>
        <div className={
          userStore.currentUser?.username === message.username ?
            styles.sentMessage :
            styles.receivedMessage
        }>
          <p>{message.text}</p>
          {isRead(message) && <FontAwesomeIcon icon={faCheckDouble} />}
          {isDelivered(message) && <FontAwesomeIcon icon={faCheck} />}
        </div>
      </div>)}
    </div>
  )
});
