import { Message } from "@graphql/graphql";
import { HIDE_TOAST_TIMEOUT, NOTIFICATION_TEXT_MAX_LENGTH } from "@shared/data";
import { makeAutoObservable } from "mobx";

export enum ToastType {
  Error, Warning, Info, Success, Copy, NotifyOn, NotifyOff,
}

class MessageNotificationsStore {
  notifyList: Message[] = [];
  timeOuts: {[key: string]: any} = {};

  constructor() {
    makeAutoObservable(this);
  }

  addMessage = (message: Message) => {
    if (message.id) {
      this.notifyList.push(message)
      this.autoHide(message.id);
    }
  }

  hideMessage = (id: string) => {
    this.notifyList = this.notifyList.filter((message) => message.id !== id);
  }

  hideAllMessages = () => {
    this.notifyList = [];
    this.timeOuts = [];
  }

  pauseHiding = (id?: string) => {
    if (id) {
      clearTimeout(
        this.timeOuts[id]
      )
    }
  }

  private autoHide(id: string) {
    this.timeOuts[id] = setTimeout(() => this.hideMessage(id), HIDE_TOAST_TIMEOUT)
  }
}

export const messageNotificationsStore = new MessageNotificationsStore();