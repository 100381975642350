import { MenuItem } from "@ui-kit/components/drop-down/modele/menu-item";
import { useTranslation } from "react-i18next";

export const useNotificationMenu = () => {
    const { t } = useTranslation('settings');

    const notificationMenu: MenuItem[] = [
        {
            key: 'push',
            type: 'menu-notification',
            title: t('notifications.menu.push'),
            showIcon: true,
            grayIcon: 'chevron-right'
        },
        {
            key: 'email',
            type: 'menu-notification',
            title: t('notifications.menu.email'),
            showIcon: true,
            grayIcon: 'chevron-right',
        }
    ];

    const showKeys = ['push', 'email'];

    const getInitialMenuState = (hasUser: boolean) => {
        if (!hasUser) {
            return notificationMenu.filter((menuItem) => showKeys.includes(menuItem.key));
        }
        return notificationMenu;
    };

    return { menuItems: notificationMenu, getInitialMenuState };
};
