import { UserPicture, UserToken } from "@graphql/graphql";
import styles from "./user-menu.module.css";
import { Menu } from "@ui-kit/components/drop-down/comp/dropdown-menu/menu.comp";
import { MenuItem } from "@ui-kit/components/drop-down/modele/menu-item";
import { useNavigate } from "react-router-dom";
import { modalCustomDialogsStore, userStore } from "@store";
import { useEffect, useState } from "react";
import { useExtraItemsMenu } from "./use-extra-items-menu";
import { useTranslation } from "react-i18next";

type Props = {
  user?: UserPicture|null|UserToken,
  userMenuState: [boolean, React.Dispatch<React.SetStateAction<boolean>>],
  onOverlay: (overlay: boolean) => void,
}

export const UserMenu = ({ user, userMenuState, onOverlay }: Props) => {
  const navigate = useNavigate();

  const extraItemsMenu = useExtraItemsMenu();
  const { t } = useTranslation('common');

  const [isMenuVisible, setMenuVisible] = userMenuState;
  const [isTabletMobile, setIsTabletMobile] = useState(window.innerWidth <= 1280);

  const items: MenuItem[] = [
    {
      key: 'header',
      type: 'header-user',
      iconUrl: user?.picture?.url ?? undefined,
      title: `${user?.firstname} ${user?.lastname}`,
      subTitle: `@${user?.username}`,
    },
    {
      key: 'profile',
      type: 'menu-item',
      grayIcon: 'user',
      title: t('userMenu.profile'),
    },
    {
      key: 'settings',
      type: 'menu-item',
      grayIcon: 'settings',
      title: t('userMenu.settings'),
    },
    {
      key: 'help',
      type: 'menu-item',
      grayIcon: 'help-circle',
      title: t('userMenu.help'),
    },
    {
      key: 'saved',
      type: 'menu-item',
      grayIcon: 'bookmark',
      title: t('userMenu.saved'),
    },
    { key: 'hr-1', type: 'hr' },
    {
      key: 'log-out',
      type: 'menu-item',
      grayIcon: 'log-out',
      title: t('userMenu.logOut'),
    },
  ];

  const menuItems = isTabletMobile ?
    [...items.slice(0, 2), ...extraItemsMenu, ...items.slice(2)] : items;

  useEffect(() => {
    const closeMenu = () => {
      setMenuVisible(false);
      onOverlay(false);
    }

    if (isMenuVisible) {
      setTimeout(() => {
        document.addEventListener('click', () => closeMenu(), { once: true });
      }, 0)
    if (isTabletMobile) {
      onOverlay(true);
      }
    }
  }, [isMenuVisible])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1280) {
        setIsTabletMobile(false);
      } else {
        setIsTabletMobile(true);
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onClick = (item: MenuItem) => {
    switch(item.key) {
      case 'profile':
        navigate({ pathname: `/wall/${user?.username}` });
        break;
      case 'settings':
        navigate({ pathname: '/profile' });
        break;
      case 'help':
        navigate({ pathname: '/help' })
        break;
      case 'saved':
        navigate({ pathname: '/saved' })
        break;
      case 'wall':
        navigate({ pathname: '/' })
        break;
      case 'search':
        navigate({ pathname: '/search' })
        break;
      case 'map':
        navigate({ pathname: '/map/map' })
        break;
      case 'messages':
        navigate({ pathname: '/messages' })
        break;
      case 'log-out':
        modalCustomDialogsStore.showDialog(undefined, {
          typeIcon: 'exit',
          title: t('dialogs.exit.title'),
          message: t('dialogs.exit.message'),
          firstButtonText: t('dialogs.exit.firstButton'),
          firstButtonColor: 'primary',
          secondButtonText: t('dialogs.exit.secondButton'),
          secondButtonColor: 'secondary-color',
          secondButtonDestructive: true,
          confirmAction: () => userStore.signOut(),
        });
        break;
    }
    onOverlay(false);
  }

  if (isMenuVisible) {
    return <>
      <Menu withoutScroll={true} className={styles['user-menu']} items={menuItems} onClick={onClick} />
        {isTabletMobile &&
        <Menu withoutScroll={true} isExtendUserMenu={isTabletMobile} className={styles['user-menu']} items={menuItems} onClick={onClick} />
          }
      </>
    }
  return <></>
}