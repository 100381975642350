import { useRef, useState, useEffect } from "react";
import styles from "./language.module.css";
import { observer } from "mobx-react-lite";
import { Header } from "@shared/components";
import { Checkbox, Loader } from "@ui-kit";
import { userStore } from "@store";
import { useTranslation } from "react-i18next";

const defaultLang = (appLang: string ) => {
  switch (appLang) {
    case 'ru':
      return 'Russian';
    case 'en':
      return 'English';
    case 'kk':
      return 'Qazaq tili';
    default:
      return 'Russian';
  }
};

type Props = {
  onBack: () => void;
};

export const LanguageSettings = observer(({onBack}: Props) => {
  const { currentUser } = userStore;
  const appLang = defaultLang(currentUser?.appLang ?? 'Russian');
  const [selectedCheckbox, setSelectedCheckbox] = useState(appLang);
  const [isTabletMobile, setIsTabletMobile] = useState(window.innerWidth <= 1080 ? true : false );
  const [isLoading, setIsLoading] = useState(false);

  const { t, i18n } = useTranslation('settings');

  const handleCheckboxChange = async (label: string) => {
    let appLangValue;
    switch (label) {
      case 'Russian':
        appLangValue = 'ru';
        break;
      case 'English':
        appLangValue = 'en';
        break;
      case 'Qazaq tili':
        appLangValue = 'kk';
        break;
      default:
        appLangValue = 'ru';
    }

    setSelectedCheckbox(label);
    try {
      setIsLoading(true);
      await userStore.updateUserData({ appLang: appLangValue });
      i18n.changeLanguage(appLangValue);
      window.location.reload();
    } catch (err) {
      console.error(err);
    }
    finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const loadUserData = async () => {
      try {
        await userStore.me();
      } catch {}
    };
    loadUserData();
  }, []);


  if (isLoading) {
    return (<div className={styles['loading']}>
              <Loader loaderType='line' />
          </div>
)}

  return (
<>
  <Header
    className={styles['header-settigs']}
    title={t('systemLanguage.header')}
    hideAvatar={true}
    hideBackArrow={window.innerWidth > 1079}
    onBackClick={onBack}
  />
  <section className={styles['language-settungs-container']}>
    <div className={styles['checkbox-container']}>
      <Checkbox
        type='radio'
        label='Russian'
        checked={selectedCheckbox === 'Russian'}
        onClick={()=>handleCheckboxChange('Russian')}
        isNotification={true}
      >
        <span>Русский</span>
      </Checkbox>
    </div>
    <div className={styles['checkbox-container']}>
      <Checkbox
        type='radio'
        label='English'
        checked={selectedCheckbox === 'English'}
        onClick={()=>handleCheckboxChange('English')}
        isNotification={true}
      >
        <span>English</span>
      </Checkbox>
    </div>
    <div className={styles['checkbox-container']}>
      <Checkbox
        type='radio'
        label='Qazaq tili'
        checked={selectedCheckbox === 'Qazaq tili'}
        onClick={()=>handleCheckboxChange('Qazaq tili')}
        isNotification={true}
      >
        <span>Қазақ тілі</span>
      </Checkbox>
    </div>
  </section>
</>
);
});