import { Comment } from "@graphql/graphql";
import { userStore } from "@store";
import { MenuItem } from "@ui-kit/components/drop-down/modele/menu-item";
import { useTranslation } from "react-i18next";

export enum COMMENT_MENU_KEYS {
  FOLLOW = 'follow',
  UNFOLLOW = 'unfollow',
  HIDE = 'hide',
  LINK = 'link',
  DELETE = 'delete',
  REPORT = 'report',
};

export const useCommentMenu = () => {
  const { t } = useTranslation('main');

  const getCommentMenu = (comment: Comment, username?: string): MenuItem[] => {
  let menuItems: MenuItem[] = [];

  if (username && username !== comment.username && !comment.follow) {
    menuItems.push({
      key: COMMENT_MENU_KEYS.FOLLOW,
      type: 'menu-item',
      grayIcon: 'user-check',
      title: t('commentMenu.follow'),
    });
  }

  if (username && username !== comment.username && comment.follow) {
    menuItems.push({
      key: COMMENT_MENU_KEYS.UNFOLLOW,
      type: 'menu-item',
      grayIcon: 'user-x',
      title: t('commentMenu.unfollow'),
    });
  }

  if (username) {
    menuItems.push({
      key: 'hide',
      type: 'menu-item',
      grayIcon: 'x',
      title: t('commentMenu.hide'),
    });
  }

  if (username) {
    menuItems.push({
      key: 'link',
      type: 'menu-item',
      grayIcon: 'link',
      title: t('commentMenu.link'),
    });
  }

  if (username && (
    username === comment.username
    || userStore.currentUser?.roles?.find((role) => role?.name === 'admin')
  )) {
    menuItems.push({
      key: 'delete',
      type: 'menu-item',
      grayIcon: 'trash-2',
      title: t('commentMenu.delete'),
    });
  }

  menuItems.push({
    key: 'report',
    type: 'menu-item',
    grayIcon: 'alert-circle',
    title: t('commentMenu.report'),
  });

  return menuItems;
  }
  return getCommentMenu;
}