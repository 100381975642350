import { MediaFile } from "@graphql/graphql";
import styles from "./media-item.module.css";
import ReactPlayer from "react-player";

type Props = {
  mediaFile: MediaFile,
  className?: string,
  width?: number,
  height?: number,
  controls?: boolean,
  onClick?: () => void,
}

export const MediaItemComponent = ({ mediaFile, className, width, height, controls, onClick }: Props) => {
  let classNameUnion = className ? `${styles['post-media']} ${className}` : styles['post-media'];
  controls = controls !== false

  classNameUnion += ` {width: ${width} !important; height: ${height} !important;}`

  return <>
    {mediaFile?.type?.includes('image') ? (
      <div
        onClick={() => onClick && onClick()}
        style={{
          backgroundImage: `url('${mediaFile?.url}')`,
          cursor: !!onClick ? 'pointer' : 'default',
        }}
        className={classNameUnion}
        />
    ) : (
      <ReactPlayer
        width={width}
        height={height}
        onClick={() => onClick && onClick()}
        style={{
          cursor: !!onClick ? 'pointer' : 'default',
        }}
        className={classNameUnion}
        controls={controls}
        url={mediaFile?.url ?? ''}
      />)}
  </>
}