import styles from './checkbox.module.css';

type Props = {
  onClick?: () => void;
  checked?: boolean;
  indeterminate?: boolean;
  size?: 'sm'|'md';
  label?: string;
  children?:  any;
  type?: 'radio'|'checkbox'|'check-circle';
  width?: string;
  disabled?: boolean;
  isNotification?: boolean;
}

export const Checkbox = ({
  onClick, checked, indeterminate, size, label, children, type, width, disabled, isNotification
}: Props) => {
  if (!type) { type = 'checkbox' }

  if (!size) { size = 'md' }

  if (disabled) { onClick = () => {} }

  const sizeLabelCssClass = styles[`label_${size}`];
  const sizeIconCssClass = styles[`icon_${size}`];

  const classCircle = type !== 'checkbox' ? styles.icon_circle : '';
  let disabledClass = disabled ? styles.icon_disabled : '';

  let classChecked = '';
  if (checked) {
    switch (type) {
      case 'check-circle':
        classChecked = styles[`icon__circle_checked-${size}`];
        break;
      case 'radio':
        classChecked = styles[`icon__radio_checked-${size}`];
        break;
      case 'checkbox':
      default:
        classChecked = indeterminate
          ? styles[`icon__checkbox_indeterminate-${size}`]
          : styles[`icon__checkbox_checked-${size}`];
    }
    classChecked += ` ${styles.icon__checked}`;
  }

  if (disabled && checked) {
    const typeAdd = type === 'check-circle'
      ? 'circle'
      : type === 'radio'
        ? 'radio'
        : 'checkbox';
    const disabledClassAdd = (type === 'checkbox' && indeterminate)
      ? styles[`icon__${typeAdd}_indeterminate_disabled-${size}`]
      : styles[`icon__${typeAdd}_checked_disabled-${size}`];
    disabledClass += ` ${disabledClassAdd}`;
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!disabled && onClick) {
      onClick();
    }
  };

  return (
    <div className={`${styles.container} ${isNotification ? styles.notification : "" }`} onClick={onClick} style={{maxWidth: width ?? "100%"}}>
      {isNotification &&
      <div className={styles.right}>
        {!!label && <div className={`${styles.label} ${sizeLabelCssClass} ${disabled ? styles['label_disabled']: ''}`}>{label}</div>}
        {!!children && <div className={styles.children}>{children}</div>}
      </div>}
      <input
        className={styles['input-checkbox']}
        type='checkbox'
        checked={checked}
        onChange={handleChange}
      />
      <div className={`${styles.icon} ${sizeIconCssClass} ${classCircle} ${classChecked} ${disabledClass}`} />
      {!isNotification && <div className={styles.right}>
        {!!label && <div className={`${styles.label} ${sizeLabelCssClass}`}>{label}</div>}
        {!!children && <div className={styles.children}>{children}</div>}
      </div>}
    </div>
  );
}