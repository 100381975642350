import chadImg from "@assets/images/chad.png";
import logoImg from "@assets/images/logo.svg";
import { ErrorFallback } from "@shared/components";
import { citiesStore, toastsStore } from "@store";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Button, Container, NavDropdown, Navbar, Stack } from "react-bootstrap";
import { ErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import styles from "./app-navigation.module.css";
import { City } from "@graphql/graphql";
import { userStore } from "src/store/user.store";
import { useTranslation } from "react-i18next";

export const AppNavigation = observer(() => {
  const navigate = useNavigate();
  const { cities, loading, onChangeCurrentCity, fetchCities, currentCity } = citiesStore;
  const [picture, setPicture] = useState('');
  const [cityList, setCityList] = useState<City[]>([])

  const { t } = useTranslation('main');

  useEffect(() => {
    fetchCities(true).catch((err: any) => toastsStore.addErrorToast(t('toasts.error.getCity')));
  }, [fetchCities])

  useEffect(() => {
    let userCities: City[] = [];

    const userMainCity = cities?.find((cityItem) => cityItem?.place_id === userStore.currentUser?.cityId)
    if(userStore.currentUser?.cityList) {
      userCities = userStore.currentUser?.cityList.filter(Boolean) as City[];
    }
    const resCityList = userMainCity ? [userMainCity, ...userCities] : userCities
    setCityList(resCityList.slice(0, 4));
  }, [cities, userStore.currentUser])

  useEffect(() => {
    if (userStore.currentUser?.picture?.url) {
      setPicture(userStore.currentUser.picture.url);
    }
  }, [userStore.currentUser]);

  const openUserProfile = () => {
    navigate('/profile');
  };

  const openUserWall = () => {
    navigate(`/wall/${userStore.currentUser?.username}`);
  };

  const addCity = () => {
    navigate('/add-city');
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Navbar className={styles.navHeader} bg='light' expand='lg'>
        <Container>
          {!!userStore.currentUser ?
          <Stack direction='horizontal' gap={2}>
            <img
              src={picture || chadImg}
              className={styles.profilePic}
              alt='chad'
            />
            <NavDropdown title={userStore.currentUser?.username} align='start'>
              <NavDropdown.Item onClick={openUserProfile}>
                My profile
              </NavDropdown.Item>
              <NavDropdown.Item onClick={openUserWall}>
                My wall
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={userStore.signOut}>Sign out</NavDropdown.Item>
            </NavDropdown>
          </Stack>
          : <Stack>
              <Button onClick={() => navigate('/login')} className={styles.loginButton} size='sm'>Login</Button>
            </Stack>}
          <Navbar.Brand
            onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
            className={`m-0 ${styles.logo}`}
          >
            <img src={logoImg} alt='Eccity logo' style={{height: '40px'}}/>
          </Navbar.Brand>
          <NavDropdown
            title={loading ? (t('loader')) : currentCity?.longName ?? 'city'}
            id='basic-nav-dropdown'
            align='end'
          >
            {cityList?.map((city, index) => (
              <NavDropdown.Item
                className={index === 0 ? styles.mainCity : ''}
                key={city?.id ?? index}
                onClick={() => {
                  if (city) {
                    onChangeCurrentCity(city);
                  }
                }}
              >
                {city?.longName}
              </NavDropdown.Item>
            ))}
            {userStore.currentUser && <NavDropdown.Divider />}
            <NavDropdown.Item onClick={addCity}>
              {userStore.currentUser ? t('navigation.selectOther') : t('navigation.selectCity')}
            </NavDropdown.Item>
          </NavDropdown>
        </Container>
      </Navbar>
      <div className={styles.backHeader}></div>
    </ErrorBoundary>
  );
});
