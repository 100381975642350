import styles from "./saerch-hashs.module.css";
import { useEffect, useState } from "react";
import { Loader } from "@ui-kit/components/";
import { HashTag } from "@graphql/graphql";
import { HashtagComponent } from "../hashtag/hashtag.comp";
import { dataRecommends } from "../../helpers/recommends";
import { citiesStore, toastsStore } from "@store";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

type Props = {
  initialToken: string | null;
  hashs: HashTag[];
  onHashtagClick: (hashtag: string) => void;
  onTabClick: (key: string) => void;
};

export const SearchHashs = observer(({ initialToken, hashs: initialHashs, onHashtagClick, onTabClick }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hashs, setHashs] = useState<HashTag[]>(initialHashs);
  const [loadMore, setLoadMore] = useState(false);
  const [nextToken, setNextToken] = useState<string | null>(initialToken);
  const [hasMoreData, setHasMoreData] = useState(true);

  const { currentCity } = citiesStore;
  const { t } = useTranslation('search');

  const handleHashtagClick = (hash: HashTag) => {
    if (hash.hashtag) onHashtagClick(hash.hashtag);
  };

  useEffect(() => {
    const scrollHandler = (e: any) => {
      const position = e.target.scrollTop + window.innerHeight + 10;
      if (position >= e.target.scrollHeight) {
        setLoadMore(true);
      }
    };

    document.body.addEventListener('scroll', scrollHandler, false);
    return () => {
      document.body.removeEventListener('scroll', scrollHandler);
    };
  }, [loadMore]);

  useEffect(() => {
    if (loadMore && hashs && nextToken !== null) {
      fetchHash()
        .then(() => setLoadMore(false))
        .catch(() => toastsStore.addErrorToast(t('toasts.error.hashs')));
    } else {
      setLoadMore(false);
    }
  }, [loadMore])

  const fetchHash = async () => {
    if (loadMore && nextToken !== null && hasMoreData) {
      setIsLoading(true);
      try {
        const result = await dataRecommends({
          place_id: currentCity?.place_id,
          nextToken: nextToken,
        });

        const newHash = result.hashtagItems?.filter((item): item is HashTag => item !== null) ?? [];
        if (newHash.length === 0 || result.nextToken === null) {
          setLoadMore(false);
          setHasMoreData(false);
        } else {
          setHashs(users => [...users, ...newHash]);
          setNextToken(result.nextToken ?? null);
        }
      } catch (error) {
        console.error('Ошибка:', error);
        toastsStore.addErrorToast(t('toasts.error.hashs'));
      } finally {
        setIsLoading(false);
        setLoadMore(false);
      }
    }
  };

  useEffect(() => {
    setHashs(initialHashs);
    setIsLoading(true);
    setHasMoreData(true);
  }, [initialHashs]);

  return (
    <section className={styles['search-hashs__section']}>
      {hashs.length > 0 &&
        <div className={styles['search-hashs__content']}>
          {hashs.map((hash) => (
            <HashtagComponent
              onClick={() => { handleHashtagClick(hash); onTabClick('posts'); }}
              hash={hash}
              key={hash.id}
            />
          ))}
        </div>
      }
      {!!isLoading &&
        <div className={styles.loader}>
          <Loader />
        </div>
      }
    </section>
  );
});