import styles from "./text.module.css";

type Props = {
  className?: string,
  children: any,
}

export const Text = ({ className, children }: Props) =>
  <p className={`${styles.text} ${className ?? className}`}>
    {children}
  </p>