import { FormEvent, useEffect, useState } from "react";
import { MessageList } from "@messages/component";
import { Button, Container, Form } from "react-bootstrap";
import { useParams } from "react-router";
import styles from "./chat-page.module.css";
import { messagesStore, toastsStore } from "@store";
import { sendNewMessage } from "@messages/helpers";
import { UUID_REGEXP } from "@shared/data/regs";
import { useNavigate } from "react-router-dom";
import { Header } from "@messages/component/header/header.comp";
import { observer } from "mobx-react-lite";
import { Chat, Message } from "@graphql/graphql";
import { userStore } from "src/store/user.store";

const isMessage = (message: Message | null) : message is Message => message !== null

export const ChatPage = observer(() => {
  const [message, setMessage] = useState('');
  const [chatState, setChatState] = useState<Chat | undefined | null>();
  const { id } = useParams();
  const navigate = useNavigate();

  const sendMessage = (e: FormEvent) => {
    e.preventDefault();
    if (message === '') {
      return;
    }
    if (id && UUID_REGEXP.test(id)) {
      sendNewMessage(message, id ?? '')
        .then(() => setMessage(''));
    } else {
      sendNewMessage(message, '', id)
        .then((chatId) => navigate(`/chat/${chatId}`))
        .then(() => setMessage(''));
    }
  }

  useEffect(() => {
    if(id && !UUID_REGEXP.test(id)) {
      setChatState(null)
    }
  }, [id])

  useEffect(() => {
    if(id && messagesStore.chatsLoaded && UUID_REGEXP.test(id) && !messagesStore.chats.find((chat) => chat.chatId === id)) {
      toastsStore.addErrorToast('Wrong chat ID!')
      navigate('/messages')
    };
    setChatState(messagesStore.chats.find((chat) => chat.chatId === id))
  }, [messagesStore.chats]);

  return (
    <>
      <Header username={
        id && UUID_REGEXP.test(id)
          ? (chatState?.usernames?.[0] === userStore.currentUser?.username
            ? chatState?.usernames?.[1]
            : chatState?.usernames?.[0]) ?? ''
          : id ?? ''
        } />
      <Container>
        <MessageList />
        <Form className={styles.formGroup} onSubmit={sendMessage}>
          <Form.Control
            type='text'
            value={message}
            placeholder='Enter your message here...'
            onChange={(event) => setMessage(event.target.value)}
          />
          <Button type='submit'>Send</Button>
        </Form>
      </Container>
    </>
  )
});
