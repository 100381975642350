import { ApolloQueryResult } from "@apollo/client";
import { getLikedPostsByUsername, getPostsByUsername, getSavedPosts, getUserComments } from "@graphql/docs/queries";
import { CommentWithPost, ModelCommentWithPostConnection, ModelPostConnection, ModelPostWithReactionConnection, Post, PostWithReaction } from "@graphql/graphql";
import { POSTS_LIMIT } from "@shared/data";
import { makeAutoObservable, runInAction } from "mobx";
import { apolloClient } from "src/apollo-client";
import { addSavedPost, removeAllSavedPosts, removeSavedPost } from "@graphql/docs/mutations";
import { toastsStore } from "./toasts.store";
import { getI18n } from "react-i18next";

export class WallPostsStore {
  posts?: Post[]|null = null;
  savedPosts?: Post[]|null = null;
  postsWithReactions?: PostWithReaction[]|null = null;
  userComments?: CommentWithPost[]|null = null;
  loading = false;
  nextToken?: string | null;
  storedUsername?: string;
  storedType?: 'posts'|'liked'|'comments';

  constructor () {
    makeAutoObservable(this);
  }

  getPosts = async (username: string, nextToken?: string | null): Promise<ApolloQueryResult<{getPostsByUsername: ModelPostConnection}>> =>
    apolloClient.query<{getPostsByUsername: ModelPostConnection}>({
      query: getPostsByUsername,
      variables: { input: {
        username,
        nextToken,
        limit: POSTS_LIMIT
      }}
    });

  getLikedPosts = async (username: string, nextToken?: string | null): Promise<ApolloQueryResult<{getLikedPostsByUsername: ModelPostWithReactionConnection}>> =>
    apolloClient.query<{getLikedPostsByUsername: ModelPostWithReactionConnection}>({
      query: getLikedPostsByUsername,
      variables: { input: {
        username,
        nextToken,
        limit: POSTS_LIMIT
      }}
    });

  getComments = async (username: string, nextToken?: string | null): Promise<ApolloQueryResult<{getUserComments: ModelCommentWithPostConnection}>> =>
    apolloClient.query<{getUserComments: ModelCommentWithPostConnection}>({
      query: getUserComments,
      variables: { input: {
        username,
        nextToken,
        limit: POSTS_LIMIT
      }}
    });

  fetchPosts = async (username: string, type?: 'posts'|'liked'|'comments') => {
    type = type ?? 'posts';
    this.setLoading(true);

    if (this.storedUsername !== username || this.storedType !== type) {
      this.nextToken = undefined;
      this.storedUsername = username;
      this.storedType = type;
    }

    if (this.nextToken === undefined) {
      this.posts = null;
      this.postsWithReactions = null;
      this.userComments = null;
    }

    try {
      switch(type) {
        case 'posts':
          const response = this.nextToken !== null
            ? await this.getPosts(username, this.nextToken)
            : { data: { getPostsByUsername: { items: [] as Post[], nextToken: null }}};
          const postItems = response.data.getPostsByUsername?.items.filter((post) => post !== null && post !== undefined) as Post[];
          this.nextToken = response.data.getPostsByUsername?.nextToken;

          this.setPosts(username, postItems);
          break;
        case 'liked':
          const responseLiked = this.nextToken !== null
            ? await this.getLikedPosts(username, this.nextToken)
            : { data: { getLikedPostsByUsername: { items: [] as PostWithReaction[], nextToken: null }}};
          const postLikedItems = responseLiked.data.getLikedPostsByUsername
            ?.items.filter((item) => item?.post !== null && item?.post !== undefined) as PostWithReaction[];
          this.nextToken = responseLiked.data.getLikedPostsByUsername?.nextToken;

          this.setPostsWithReactions(username, postLikedItems);
          break;
        case 'comments':
          const responseComments = this.nextToken !== null
            ? await this.getComments(username, this.nextToken)
            : { data: { getUserComments: { items: [] as CommentWithPost[], nextToken: null }}};
          const commentItems = responseComments.data.getUserComments
            ?.items?.filter((item) => item?.post !== null && item?.post !== undefined) as CommentWithPost[];
          this.nextToken = responseComments.data.getUserComments?.nextToken;

          this.setComments(username, commentItems);
          break;
      }
    } catch (error) {
      this.setLoading(false)
      console.error(error);
    }

    this.setLoading(false);
  };

  getSavedPosts = async (nextToken?: string | null): Promise<ApolloQueryResult<{getSavedPosts: ModelPostConnection}>> =>
    apolloClient.query<{getSavedPosts: ModelPostConnection}>({
      query: getSavedPosts,
      variables: { input: {
        nextToken,
        limit: POSTS_LIMIT
      }}
    });

  savePost = async (postId: string) => {
    const res = await apolloClient.mutate<{addSavedPost: Post}>({
      mutation: addSavedPost,
      variables: { id: postId }
    });

    return res.data?.addSavedPost;
  }

  removeSavedPost = async (postId: string) => {
    const res = await apolloClient.mutate<{removeSavedPost: Post}>({
      mutation: removeSavedPost,
      variables: { id: postId }
    });

    this.savedPosts = this.savedPosts?.filter((post) => post.id !== postId)

    return res.data?.removeSavedPost;
  }

  fetchSavedPosts = async (clear: boolean = false) => {
    try {
      if (clear) {
        this.nextToken = undefined;
        this.savedPosts = null;
      }
      const response = this.nextToken !== null
        ? await this.getSavedPosts(this.nextToken)
        : { data: { getSavedPosts: { items: [] as Post[], nextToken: null }}};
      const postItems = response.data.getSavedPosts?.items.filter((post) => post !== null && post !== undefined) as Post[];
      this.nextToken = response.data.getSavedPosts?.nextToken;

      this.setSavedPosts(postItems);
    } catch (error) {
      console.error(error);
    }
    this.setLoading(false);
  }

  setSavedPosts = (posts?: any[]) => {
    this.storedUsername = 'saved-posts';
    this.savedPosts = [...(this.savedPosts ?? []), ...(posts  ?? [])];
  }

  removeAllSavedPosts = async () => {
    const i18n = getI18n();
    try {
      await apolloClient.mutate<{removeAllSavedPosts: string[]}>({
        mutation: removeAllSavedPosts,
      });
      this.savedPosts = null;
    } catch {
      toastsStore.addErrorToast(i18n.t('common:toasts.error.clearListSave'))
    }
  }

  setPosts = (username?: string, posts?: any[]) => {
    if (username) {
      this.storedUsername = username;
      runInAction(() => {
        this.posts = [...(this.posts ?? []), ...(posts  ?? [])];
      });
    } else {
      runInAction(() => {
        this.posts = [...(posts  ?? [])];
      });
    }
  }

  setPostsWithReactions = (username?: string, posts?: any[]) => {
    if(username) {
      this.storedUsername = username;
      this.postsWithReactions = [...(this.postsWithReactions ?? []), ...(posts  ?? [])];
    } else {
      this.postsWithReactions = [...(posts  ?? [])];
    }
  }

  setComments = (username?: string, comments?: CommentWithPost[]) => {
    if(username) {
      this.storedUsername = username;
      this.userComments = [...(this.userComments ?? []), ...(comments  ?? [])];
    } else {
      this.userComments = [...(comments  ?? [])];
    }
  }

  setLoading = (value: boolean) => {
    this.loading = value;
  }
}

export const wallPostsStore = new WallPostsStore();