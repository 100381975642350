import { useState } from "react";
import styles from "./what-ecitty.module.css";
import { observer } from "mobx-react-lite";
import logo from "@assets/images/logo.svg";
import { Header } from "@shared/components";
import { useTranslation } from "react-i18next";

type Props = {
  onBack: () => void;
};

export const WhatEcitty = observer(({onBack}: Props) => {

  const { t } = useTranslation('help');

  const [isTabletMobile, setIsTabletMobile] = useState(window.innerWidth <= 1079 ? true : false );

  return (
<>
  <Header
    className={styles['header-help']}
    title={t('main.menu.whatEcitty')}
    hideAvatar={true}
    hideBackArrow={window.innerWidth > 1079}
    onBackClick={onBack}
  />
  <div className={styles['help-ecitty']}>
    <div className={styles['image-container']}>
        <img className={styles['help-image']} src={logo} alt='Ecitty logo' />
    </div>

    <p className={styles['help-text']}>{t('whatEcitty.intro')}</p>

    <section>
      <h6 className={styles['help-title']}>{t('whatEcitty.mainIdea.header')}</h6>
      <p className={styles['help-text']}>{t('whatEcitty.mainIdea.text')}</p>
    </section>

    <section>
      <h6 className={styles['help-title']}>{t('whatEcitty.feature.header')}</h6>
      <p className={styles['help-text']}>{t('whatEcitty.feature.textPartOne')}</p>
      <p className={styles['help-text']}>{t('whatEcitty.feature.textPartTwo')}</p>
      <p className={styles['help-text']}>{t('whatEcitty.feature.textPartThree')}</p>
    </section>

    <section>
      <h6 className={styles['help-title']}>{t('whatEcitty.conclusion.header')}</h6>
      <p className={styles['help-text']}>{t('whatEcitty.conclusion.text')}</p>
    </section>
  </div>
</>
);
});
