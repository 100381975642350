import { Trend, mockTrendData } from "@shared/data/trend";
import { makeAutoObservable } from "mobx";

class TrendStore {
  trendList: Trend[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  loadTrends = async () => {
    this.trendList = mockTrendData;
    return mockTrendData;
  }
}

export const trendStore = new TrendStore();
