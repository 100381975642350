import { LikeTypes } from "@home/data/like-types";
import { findLinksInText } from "@home/helpers";
import { deletePostFn } from "@home/helpers/delete-post";
import { likePost } from "@home/helpers/like-post";
import { ErrorFallback } from "@shared/components";
import { commentListStore, modalCustomDialogsStore, toastsStore } from "@store";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Card, Stack } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import { ErrorBoundary } from "react-error-boundary";
import ReactPlayer from "react-player/lazy";
import { CommentList } from "../../comment";
import { PostItemFooter } from "../post-item-footer/post-item-footer.comp";
import { PostItemHeader } from "../post-item-header/post-item-header.comp";
import styles from "./post-item.module.css";
import { Post } from "@graphql/graphql";
import { ReactionTypes } from "src/assets/reactions";
import { useTranslation } from "react-i18next";

type Props = {
  post: Post;
  city?: string | null;
};

export const PostItem = observer(({ post, city }: Props) => {
  const { comments, clearComments } = commentListStore;
  const [commentsOpen, setCommentsOpen] = useState<boolean>(false);
  const [commentsCounter, setCommentsCounter] = useState(post?.commentsLength ?? undefined);
  const { showDialog } = modalCustomDialogsStore;

  const { t } = useTranslation('main');

  useEffect(() => {
    if (comments[post.id ?? '']?.length) setCommentsCounter(comments[post.id ?? ''].length);
  }, [comments, post.id]);

  useEffect(() => {
    if (!commentsOpen) clearComments(post.id ?? '');
  }, [commentsOpen])

  const handleLikePost = async (type?: ReactionTypes) => {
    likePost(post, type).catch((err: any) => toastsStore.addErrorToast(t('toasts.error.likePost')));
  }

  const handleLikePostAsunc = (type?: ReactionTypes) => {
    handleLikePost(type)
      .catch((err: any) => toastsStore.addErrorToast(t('toasts.error.likePost')));
  }

  const handlePostDelete = async (post: Post) => {
    showDialog('', {
      typeIcon: 'warning',
      title: t('dialogs.deletePost.title'),
      message: t('dialogs.deletePost.message'),
      firstButtonText: t('dialogs.deletePost.firstButton'),
      firstButtonColor: 'primary',
      secondButtonText: t('dialogs.deletePost.secondButton'),
      secondButtonColor: 'secondary-color',
      confirmAction: deletePostFn(post)
    })
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Card className='p-3'>
        <PostItemHeader city={city} post={post} handlePostDelete={() => handlePostDelete(post)} />
        <Stack gap={2}>
          <Carousel
            className={styles.carousel}
            touch
            interval={null}
            controls={!!(post.media && post.media?.length > 1)}
            indicators={!!(post.media && post.media?.length > 1)}
          >
            {post.media?.length ?
              post.media.map((file, i) => file?.type?.includes('image') ? (
                <Carousel.Item className={styles.postMediaContainer} key={file?.url ?? i}>
                  <img
                    className={styles.postMedia}
                    src={file?.url ?? ''}
                    alt='post'
                  />
                </Carousel.Item>
              ) : (
                <Carousel.Item className={styles.postMediaContainer} key={file?.url ?? i}>
                  <ReactPlayer
                    className={styles.postMedia}
                    controls
                    url={file?.url ?? ''}
                  ></ReactPlayer>
                </Carousel.Item>
              ))
            : null}
          </Carousel>
          {!!post?.text && <div className='post-content'>
            <Card.Text dangerouslySetInnerHTML={{__html: findLinksInText(post?.text)}} />
          </div>}
          <PostItemFooter
            post={post}
            commentsCounter={commentsCounter ?? 0}
            handleLikePost={(type?: ReactionTypes) => handleLikePostAsunc(type)}
            setCommentsOpen={setCommentsOpen}
          />
        </Stack>
        {commentsOpen && (
          <CommentList post={post} setCommentsCounter={setCommentsCounter} />
        )}
      </Card>
    </ErrorBoundary>
  );
});
