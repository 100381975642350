import { search as searchQuery } from "@graphql/docs/queries";
import { Post, SearchResults, SearchInput, UserPicture, HashTag } from "@graphql/graphql";
import { apolloClient } from "src/apollo-client";

const isPost = (post: Post | null): post is Post => post !== null && post !== undefined;
const isUserPicture = (user: UserPicture | null): user is UserPicture => user !== null && user !== undefined;
const isHashtags = (hashtag: HashTag | null): hashtag is HashTag => hashtag !== null && hashtag !== undefined;

export const searchData = async ({ search, place_id, nextToken }: SearchInput): Promise<SearchResults> => {
    const res = await apolloClient.query<{ search: SearchResults }>({
        query: searchQuery,
        variables: {
            input: {
                search: search,
                place_id: place_id,
                nextToken: nextToken,
            },
        },
    });

    const searchResults: SearchResults = {
        __typename: 'SearchResults',
        userItems: res.data.search?.userItems?.filter(isUserPicture) ?? [],
        postItems: res.data.search?.postItems?.filter(isPost) ?? [],
        hashtagItems: res.data.search?.hashtagItems?.filter(isHashtags) ?? [],
        nextToken: res.data.search.nextToken ?? null,
    };

    return searchResults;
};