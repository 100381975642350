import { UserPicture } from "@graphql/graphql";
import { MenuItem } from "@ui-kit/components/drop-down/modele/menu-item";
import { useTranslation } from "react-i18next";

export enum PROFILE_MENU_KEYS {
  LINK = 'link',
  REPORT = 'report',
};

export const useProfileMenu = () => {

  const { t } = useTranslation('wall');

  const getProfileMenu = (user?: UserPicture, username?: string): MenuItem[] => {
  if (!user) {
    return []
  }

  let menuItems: MenuItem[] = [];

  if (username) {
    menuItems.push({
      key: PROFILE_MENU_KEYS.LINK,
      type: 'menu-item',
      grayIcon: 'link',
      title: t('menuItem.link'),
    });
  }

  menuItems.push({
    key: PROFILE_MENU_KEYS.REPORT,
    type: 'menu-item',
    grayIcon: 'alert-octagon',
    title: t('menuItem.report'),
  });

  return menuItems;
}
return getProfileMenu;
}
