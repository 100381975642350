import styles from "./new-messages-hr.module.css";
import { useTranslation } from "react-i18next";

export const NewMessagesHr = () => {
  const { t } = useTranslation('messenger');
  return <div className={styles['new-messages-hr']}>
    <div className={styles['new-messages-hr__line']}/>
    <p className={styles['new-messages-hr__text']}>{t('header.new')}</p>
    <div className={styles['new-messages-hr__line']}/>
  </div>
}
