import { Crop, centerCrop, makeAspectCrop } from "react-image-crop";

export function onImageLoad(
  e: any,
  setCrop: React.Dispatch<React.SetStateAction<Crop | undefined>>,
  setSize: React.Dispatch<React.SetStateAction<{
    width: number;
    height: number;
  }>>
) {
  const { naturalWidth: width, naturalHeight: height } = e.currentTarget

  const crop = centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 100,
      },
      14 / 4,
      width,
      height
    ),
    width,
    height
  )

  setCrop(crop);
  setSize({ width, height });

  return crop;
}