import { useState } from "react";
import styles from "./avatar-add-button.module.css";

type Props = {
  size?: 'xs'|'sm'|'md',
  className?: string,
  handleFileChange: (event: any) => Promise<void>|void,
}

export const AvatarAddButton = ({ size, className, handleFileChange }: Props) => {
  size = size ?? 'sm';
  className = className ? `${styles['avatar-add-button']} ${className}` : styles['avatar-add-button'];
  const labelClassName = `${styles['custom-file-upload']} ${styles[`custom-file-upload-${size}`]}`;

  const [file, setFile] = useState<string|undefined>();

  return <div className={className}>
    <form className={styles['form-picture']}>
      <label htmlFor='upload-media' className={labelClassName} />
      <input
        id="upload-media"
        className={styles.input}
        type="file"
        value={file}
        onChange={(event) => { setFile(event.target.value); handleFileChange(event); }}
      />
    </form>
  </div>
}