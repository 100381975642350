import { gql } from "@apollo/client";

export const updateRole = gql`mutation updateRole($input: UpdateRoleInput) {
    updateRole(input: $input){
        id
        name
        title
        type
        color
        textColor
        isVisible
    }
}`;
