import { LiveUser, mockLiveData } from "@shared/data/live-user";
import { makeAutoObservable } from "mobx";

class LiveStore {
  liveList: LiveUser[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  loadLiveList = async () => {
    this.liveList = mockLiveData;
    return mockLiveData;
  }
}

export const liveStore = new LiveStore();
