import styles from "./header-buttom-other-user.module.css";
import { UserPicture } from "@graphql/graphql";
import { AvatarGroup, Button } from "@ui-kit";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { followersStore, toastsStore, userStore } from "@store";
import { getChatList } from "@messages";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

type Props = {
  user?: UserPicture,
  reloadUserData: () => void,
}

export const HeaderButtomOtherUser = observer(({ user, reloadUserData }: Props) => {
  const [followers, setFollowers] = useState<UserPicture[]>([])
  const [whoFollow, setWhoFollow] = useState<UserPicture[]>([])
  const [number, setNumber] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation('wall');
  const navigate = useNavigate();

  useEffect(() => {
    loadData();
  }, [user?.username]);

  const loadData = () => {
    Promise.all([
      followersStore.getWhoFollowUsers(user?.username ?? undefined)
        .then(setWhoFollow)
        .catch(() => toastsStore.addErrorToast(t('toasts.error.loadData'))),
      userStore.getUserFollowersUsers(user?.username ?? undefined)
        .then(setFollowers)
        .catch(() => toastsStore.addErrorToast(t('toasts.error.loadData')))
    ]).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    const num = (whoFollow.length ?? 0) - 3;
    setNumber(num < 0 ? 0 : num);
  }, [whoFollow, userStore.currentUser]);

  const followUser = async () => {
    setLoading(true);
    const followers = await userStore.getUserFollowing(userStore.currentUser?.username ?? undefined);
    if (!user?.username || followers.some((followItem) => followItem?.follow === user.username)) {
      return;
    }
    await userStore.follow(user.username).catch(() => toastsStore.addErrorToast(t('toasts.error.followUser')));
    loadData();
    reloadUserData();
  }

  const unfollowUser = async () => {
    setLoading(true);
    const followers = await userStore.getUserFollowing(userStore.currentUser?.username ?? undefined);
    const followItem = followers.find((fItem) => fItem?.follow === user?.username)
    if (!user?.username || !followItem) {
      return;
    }
    await userStore.unfollow(user.username).catch(() => toastsStore.addErrorToast(t('toasts.error.unfollowUser')));
    loadData();
    reloadUserData();
  }

  const newChat = () => {
    getChatList(userStore.currentUser?.username ?? '').then((chatList) => {
      if(userStore.currentUser?.username && userStore.currentUser.username === user?.username) {
        return
      }
      const res = chatList.find((chat) => chat.usernames?.find((_user) => _user === user?.username));
      if (res) {
        navigate(`/chat/${res.chatId}`)
      } else {
        navigate(`/chat/${user?.username}`)
      }
    })
  }

  return <>
    {whoFollow.length > 0 ? <div className={styles.following}>
      <AvatarGroup plusNumber={number} userPictures={whoFollow.slice(0, 3).map((user) => user.picture?.url ?? '')} />
      <span className={styles.following__text}>{whoFollow.length > 1 ?
        t('message.whoFollow.count.few')
        : t('message.whoFollow.count.one')} @{user?.username}</span>
    </div> : <div className={styles.following}>
      <span className={styles.following__text}>{t('message.commonFollow.youHave')} @{user?.username} {t('message.commonFollow.notCommon')}</span>
    </div>}
    {!!userStore.currentUser?.username && <div className={styles.bottom}>
      {!followers.find((user) => user.username === userStore.currentUser?.username)
        ? <Button
            className={styles['follow-button']}
            onClick={followUser}
            loading={loading}
            iconType='plus'
          >
            {t('button.follow')}
          </Button>
        : <Button
            className={styles['follow-button']}
            onClick={unfollowUser}
            hierarchy='secondary-color'
            loading={loading}
          >
            {t('button.unfollow')}
          </Button>}
      <Button hierarchy='secondary-gray' iconType='message-circle' onClick={newChat}>{t('button.message')}</Button>
    </div>}
  </>
});
