import { Fragment, useEffect, useRef, useState } from "react";
import styles from "./message-list-container.module.css";
import { Chat, Message } from "@graphql/graphql";
import { messagesStore, userStore } from "@store";
import { splitMessages } from "src/messenger/helpers/split-messages";
import { observer } from "mobx-react-lite";
import { MessageComponent } from "src/messenger/components/message/message.comp";
import { NewMessagesHr } from "../new-messages-hr/new-messages-hr.comp";
import { REMOVE_NEW_MSG_HR_AFTER_MS } from "src/messenger/data/message.constants";

type Props = {
  chatId: string,
}

export const MessageListContainer = observer(({ chatId }: Props) => {
  const ref = useRef<any>();

  const [messageArray, setMessageArray] = useState<Message[][]>([]);
  const [chat, setChat] = useState<Chat>();
  const [newMsgIndex, setNewMsgIndex] = useState<number>(-1);

  useEffect(() => {
    setMessageArray(splitMessages(messagesStore.messages[chatId]
      ? [...messagesStore.messages[chatId]].reverse()
      : []
    ));
  }, [messagesStore.messages[chatId]]);

  useEffect(() => {
    setChat(messagesStore.chats.find((chat) => chat.chatId === chatId));
  }, [messagesStore.chats])

  const onRetryClick = (message: Message) => {
    messagesStore.sendMessage(
      {
        chatId,
        media: message.media,
        text: message.text,
      },
      message.id ?? undefined,
    )
  }

  useEffect(() => ref.current.scrollTo(0, ref.current.scrollHeight));

  useEffect(() => {
    if (!messageArray || messageArray.length === 0 || newMsgIndex !== -1) {
      return;
    }

    const index = messageArray.findIndex((messageList, i) => {
      return messageList.some((list) => !list.read?.includes(userStore.currentUser?.username ?? '-'))
        && messageArray?.at(i - 1)?.some((list) => list.read?.includes(userStore.currentUser?.username ?? '-'));
    });

    setNewMsgIndex(index);

    setTimeout(() => setNewMsgIndex(-1), REMOVE_NEW_MSG_HR_AFTER_MS);
  }, [chatId, messageArray]);

  return <div className={styles['message-list-container']} ref={ref}>
    {messageArray.map((messageList, i) =>
      <Fragment key={`fragment-${messageList[0].id}`}>
        {!!newMsgIndex && newMsgIndex === i && <NewMessagesHr />}
        <MessageComponent
          key={`msg-group-${messageList[0].id}`}
          onRetryClick={() => onRetryClick(messageList[0])}
          messages={messageList}
          isMe={messageList[0].username === userStore.currentUser?.username} />
      </Fragment>
    )}
    {((chat?.typing?.length === 1
      && !chat?.typing?.includes(userStore.currentUser?.username ?? '-'))
      || (!!chat?.typing?.length && chat.typing.length > 1))
      && <MessageComponent
          key={`msg-group-typing`}
          onRetryClick={() => {}}
          messages={[{
            __typename: 'Message',
            chatId: 'typing',
          }]}
          isMe={false} />}
  </div>
});
