import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import styles from "../notification.module.css";
import { Header } from "@shared/components";
import { Toggle } from "@ui-kit/components/toggle/toggle.comp";
import { userStore } from "@store";
import { useTranslation } from "react-i18next";

type Props = {
  onBack: () => void;
};

export const PushNotification = observer(({ onBack }: Props) => {
  const { currentUser } = userStore;

  const [isToggled, setIsToggled] = useState(currentUser?.pushNotify ?? false);

  const { t } = useTranslation('settings');

  const handleToggle = async () => {
    const newToggleValue = !isToggled;
    setIsToggled(newToggleValue);
    try {
      await userStore.updateUserData({
        pushNotify: newToggleValue
      });
      await userStore.me();
    } catch (err) {
      console.error(err);
  }
}


  return (
  <>
    <Header
      className={styles['header-settigs']}
      title={t('notifications.push.header')}
      hideBackArrow={window.innerWidth > 1079}
      onBackClick={onBack}
      hideAvatar={true}
    />
    <section className={styles['push-notification-settings']}>
      <div className={styles['push-notification-header']}>
        <Toggle
          initialState={isToggled}
          onToggle={handleToggle}
          title={t('notifications.push.toggle')}
          supportingText={t('notifications.push.supportingText')}
        />
      </div>
      <section className={styles['push-notification-settings']}></section>
    </section>
  </>
);
});