import { useEffect, useState } from "react";
import chadImg from "@assets/images/chad.png";
import styles from './chat-item.module.css';
import { Link } from "react-router-dom";
import { messagesStore } from "@store";
import { observer } from "mobx-react-lite";
import { Chat } from "@graphql/graphql";
import { userStore } from "src/store/user.store";

type Props = {
  chat: Chat,
}

export const ChatItem = observer(({ chat }: Props) => {
  const [picture, setPicture] = useState<string | undefined | null>();

  const getFirstname = () => chat.userPicture?.[0]?.firstname ?? ''
  const getLastname = () => chat.userPicture?.[0]?.lastname ?? ''

  useEffect(() => {
    setPicture(
      (chat.userPicture?.[0]?.username === userStore.currentUser?.username
        ? chat.userPicture?.[1]?.picture?.url
        : chat.userPicture?.[0]?.picture?.url) ?? undefined
      )
  }, [chat.userPicture]);

  return (
    <Link className={styles.chat} to={`/chat/${chat.chatId}`}>
      <img
        className={styles.userPicture}
        src={picture || chadImg}
        alt='user-profile'
      />
      {(!!getFirstname() || !!getLastname()) &&
        <p className={styles.userfirstlastname}>
          {!!getFirstname() && getFirstname()}{!!getLastname() && ' ' + getLastname()}
        </p>
      }
      <p className={styles.username}>@{chat.usernames?.filter((username) => username !== userStore.currentUser?.username)[0]}</p>
      {messagesStore
        .messages[chat.chatId ?? '']
        ?.filter((msg) => msg.username !== userStore.currentUser?.username
          && !msg.read?.includes(userStore.currentUser?.username ?? '-')
        )?.length > 0 &&
        <span className={styles.count}>
          {messagesStore.messages[chat.chatId ?? '']
            .filter((msg) => msg.username !== userStore.currentUser?.username
              && !msg.read?.includes(userStore.currentUser?.username ?? '-')
            ).length}
        </span>
      }
    </Link>
  )
});
