import { uploadFile } from "@shared/uploader/upload-file";
import { citiesStore } from "@store";
import { v4 as uuidv4 } from "uuid";

export const loadCoverPictureToDB = async (inputBmp: ImageBitmap, picture: any) => {
  const canvas = document.createElement('canvas');
  canvas.width = inputBmp.width;
  canvas.height = inputBmp.height;
  const ctx = canvas.getContext('bitmaprenderer');
  ctx?.transferFromImageBitmap(inputBmp);
  const blob = await new Promise((res: BlobCallback) => canvas.toBlob(res));

  if (!blob) {
    return;
  }

  const fileName = picture
    ? `${citiesStore.currentCity?.longName?.split(' ').join('')}-pic-${uuidv4()}-${citiesStore.currentCity?.place_id}`
    : '';

  var file = new File([blob], fileName, { type: 'image/jpeg' });

  const mediaFile = await uploadFile(fileName, file);

  if (mediaFile) {
    await citiesStore.updateCityData({
      place_id: citiesStore.currentCity?.place_id,
      picture: mediaFile,
    })
    citiesStore.currentCity = {...citiesStore.currentCity, picture: mediaFile}
  }
}