import { makeAutoObservable, runInAction } from "mobx";
import { citiesStore } from "./cities.store";
import { POSTS_LIMIT } from "@shared/data";
import { CreatePostInput, CreateReactionInput, GetPostInput, GetPostsByCityIdInput, Maybe, ModelPostConnection, Post, PostWithReaction, Reaction, EditPostInput } from "@graphql/graphql";
import { apolloClient } from "src/apollo-client";
import { getFollowingPosts, getPost, getPostsByCityId, getPostsByHashtag } from "@graphql/docs/queries";
import { toastsStore } from "./toasts.store";
import { createPost, createReaction, deletePost, deleteReaction, viewPost, editPost } from "@graphql/docs/mutations";
import { wallPostsStore } from "./wall-posts.store";
import { getI18n } from "react-i18next";


export class PostListStore {
  posts?: Post[] = [];
  cityId?: string;
  nextToken?: string | null;
  loading = false;
  selectedTab: 'all'|'follow' = 'all';
  viewedPostIds: string[] = [];

  getPosts = async (nextToken?: string | null): Promise<ModelPostConnection> => {
    const input: GetPostsByCityIdInput = {
      cityId: citiesStore.currentCity?.place_id,
      limit: POSTS_LIMIT,
      nextToken,
    };
    return (await apolloClient.query<{getPostsByCityId: ModelPostConnection}>({
      query: getPostsByCityId,
      variables: { input },
    })).data.getPostsByCityId;
  }

  getFollowingPosts = async (nextToken?: string | null): Promise<ModelPostConnection> => {
    const input: GetPostsByCityIdInput = {
      cityId: citiesStore.currentCity?.place_id,
      limit: POSTS_LIMIT,
      nextToken,
    };
    return (await apolloClient.query<{getFollowingPosts: ModelPostConnection}>({
      query: getFollowingPosts,
      variables: { input },
    })).data.getFollowingPosts;
  }

  constructor () {
    makeAutoObservable(this);
  }

  get t() {
    const i18n = getI18n();
    return i18n.isInitialized ? i18n.t.bind(i18n) : () => "";
  }

  fetchPostsByTab = async (selectedTab: 'all'|'follow'): Promise<{ items: Maybe<Post>[], nextToken?: string|null }> => {
    if (this.nextToken === null ) {
      return { items: [], nextToken: null };
    }

    if (selectedTab === 'all') {
      return await this.getPosts(this.nextToken);
    }

    if (selectedTab === 'follow') {
      return await this.getFollowingPosts(this.nextToken);
    }

    return { items: [], nextToken: null };
  }

  fetchPosts = async (clearList = false, selectedTab: 'all'|'follow' = 'all') => {
    if (!citiesStore.currentCity) return;

    if (
      citiesStore.currentCity?.place_id
      && (
        this.cityId !== citiesStore.currentCity?.place_id
        || selectedTab !== this.selectedTab
        || clearList
      )
    ) {
      this.posts = [];
      this.nextToken = undefined;
      this.cityId = citiesStore.currentCity?.place_id;
      this.selectedTab = selectedTab;
    }

    this.setLoading(true);

    try {
      const response = await this.fetchPostsByTab(this.selectedTab);
      const postItems = response.items.filter(Boolean) as Post[];
      this.nextToken = response.nextToken;

      this.setPosts(postItems);
    } catch (error) {
      console.error(error);
    }

    this.setLoading(false);
  };

  setPosts = (posts?: Post[], resetArray = false) => {
    if (resetArray) {
      this.posts = [];
    }
    if (posts) {
      this.posts = this.posts?.concat(posts);
    }
  }

  setLoading = (value: boolean) => {
    this.loading = value;
  }

  createPost = async (input: CreatePostInput): Promise<Post|null> => {
    try {
      const newPost = await apolloClient.mutate<{createPost: Post}>({
        mutation: createPost,
        variables: { input },
      })
      runInAction(() => {
        if (!newPost?.data?.createPost) {
          return;
        }
        this.posts = this?.posts ? [newPost.data.createPost as Post, ...this.posts] : [newPost.data.createPost];
      });
      return newPost.data?.createPost ?? null;
    } catch {
      toastsStore.addErrorToast(this.t('common:toasts.error.createPost'));
      return null;
    }
  }

  deletePost = async (id: string): Promise<Post|null> => {
    try {
      const delPost = await apolloClient.mutate<{deletePost: Post}>({
        mutation: deletePost,
        variables: { id },
      })
      runInAction(() => {
        if (!delPost.data?.deletePost) {
          return;
        }
        // this.posts = this.posts?.filter((post) => post.id !== id);
      });

      return delPost.data?.deletePost ?? null;
    } catch {
      toastsStore.addErrorToast(this.t('common:toasts.error.deletePost'));
      return null;
    }
  }

  createReaction = async (input: CreateReactionInput, storeType?: string): Promise<Reaction|null> => {
    try {
      const newReaction = await apolloClient.mutate<{createReaction: Reaction}>({
        mutation: createReaction,
        variables: { input },
      })
      if (!newReaction.data) {
        return null;
      }
      switch(storeType) {
        case 'wall':
          wallPostsStore.setPosts(undefined, wallPostsStore.posts?.map((post) => {
            if (post.id === newReaction.data?.createReaction?.postId) {
              post.reactions =
                [...(post.reactions ?? []), newReaction.data?.createReaction] as Reaction[];
            }
            return post;
          }));
          break;
        case 'likes':
          wallPostsStore.setPostsWithReactions(undefined, wallPostsStore.postsWithReactions?.map((item) => {
            if (item.post && item.post?.id === newReaction.data?.createReaction?.postId) {
              item.post.reactions =
                [...(item.post.reactions ?? []), newReaction.data?.createReaction] as Reaction[];
            }
            return {
              post: { ...item.post },
              reaction: { ...item.reaction },
            };
          }));
          break;
        default:
          runInAction(() => {
            this.posts = this.posts?.map((post) => {
              if (post.id === newReaction.data?.createReaction?.postId) {
                post.reactions =
                  [...(post.reactions ?? []), newReaction.data?.createReaction] as Reaction[];
              }
              return post;
            });
          });
      }
      return newReaction.data?.createReaction ?? null;
    } catch {
      toastsStore.addErrorToast(this.t('common:toasts.error.createReaction'));
      return null;
    }
  }

  deleteReaction = async (id: string, storeType?: string): Promise<Reaction|null> => {
    try {
      const delReaction = await apolloClient.mutate<{deleteReaction: Reaction}>({
        mutation: deleteReaction,
        variables: { id },
      })
      runInAction(() => {
        if (!delReaction.data) {
          return;
        }
        switch(storeType) {
          case 'wall':
            wallPostsStore.setPosts(undefined, wallPostsStore.posts?.map((post) => {
              if(post.id === delReaction.data?.deleteReaction?.postId) {
                post.reactions = post.reactions?.filter((reac) => reac?.id !== id);
              }
              return post;
            }))
            break;
          case 'likes':
            wallPostsStore.setPostsWithReactions(undefined, wallPostsStore.postsWithReactions?.map((item) => {
              if(item.post && item.post?.id === delReaction.data?.deleteReaction?.postId) {
                item.post.reactions = item.post.reactions?.filter((reac) => reac?.id !== id);
              }
              return {
                post: {...item.post},
                reaction: { ...item.reaction },
              } as PostWithReaction;
            }))
            break;
          default:
            this.posts = this.posts?.map((post) => {
              if(post.id === delReaction.data?.deleteReaction?.postId) {
                post.reactions = post.reactions?.filter((reac) => reac?.id !== id);
              }
              return post;
            });
        }
      });

      return delReaction.data?.deleteReaction ?? null;
    } catch {
      toastsStore.addErrorToast(this.t('common:toasts.error.deleteReaction'));
      return null;
    }
  }

   getPostById= async (
    id: string,
    commentId?: string|null,
    subCommentId?: string|null
  ): Promise<Post|null> => {
    let input: GetPostInput = { id };
    if (commentId) {
      input.commentId = commentId;
    }

    if (subCommentId) {
      input.subCommentId = subCommentId;
    }

    const resPost = (await apolloClient.query<{getPost: Post}>({
      query: getPost,
      variables: { input },
      fetchPolicy: 'network-only'
    })).data.getPost;

    const index = this.posts?.findIndex((post) => post.id === id) ?? -1;
    if(index === -1) {
      runInAction(() => {
        this.posts = this.posts
          ? [resPost, ...this.posts]
          : [resPost];
      });
    } else {
      runInAction(() => {
        if(this.posts) {
          this.posts[index] = resPost;
        }
      })
    };

    return resPost;
  }

  addViewPost = async (id: string) => {
    if (this.viewedPostIds.includes(id)) {
      return;
    }

    this.viewedPostIds.push(id);
    const resId = (await apolloClient.query<{viewPost: Post}>({
      query: viewPost,
      variables: { id },
    })).data.viewPost;

    return resId === id;
  }

  getPostsByHashtag = async (hashtag: string) => {
    const input = {
      hashtag,
      limit: POSTS_LIMIT,
    };
    return (await apolloClient.query({
      query: getPostsByHashtag,
      variables: { input },
    })).data.getPostsByHashtag;
  }

  editPost = async (input: EditPostInput): Promise<Post | null> => {
    try {
      const editedPost = await apolloClient.mutate<{ editPost: Post }>({
        mutation: editPost,
        variables: { input },
      });

      const updatedPost = editedPost.data?.editPost;

      runInAction(() => {
        if (!updatedPost) {
          return;
        }

        this.posts = this.posts?.map((post) => {
          if (post.id === updatedPost.id) {
            return updatedPost;
          }
          return post;
        }).filter((post): post is Post => post !== undefined);

        this.fetchPosts(true, this.selectedTab);
      });

      return updatedPost ?? null;
    } catch (error: any) {
      console.error('Error editing post:', error);
      if (error.networkError) {
        console.error('Network error:', error.networkError);
      }
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach(({ message, locations, path }: any) => {
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
        });
      }
      toastsStore.addErrorToast(this.t('common:toasts.error.editPost'));
      return null;
    }
  }
}

export const postListStore = new PostListStore();