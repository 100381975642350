import { Header } from "@shared/components";
import styles from "./notifications.module.css";
import { BottomMenu } from "@ui-kit/components/bottom-menu/bottom-menu.comp";
import { observer } from 'mobx-react-lite';
import { notificationsStore } from "src/store/notifications.store";
import { useEffect, useState } from "react";
import { followersStore, userStore } from "@store";
import { NotificationItem } from "./components/notification-item/notification-item.comp";
import { Loader } from "@ui-kit";
import { UserPicture } from "@graphql/graphql";
import { NoNotifications } from "./components/no-natifications/no-notifications.comp";
import { useTranslation } from "react-i18next";

export const NotificationsPage = observer(() => {
  const [loadMore, setLoadMore] = useState(false);
  const [following, setFollowing] = useState<UserPicture[]>([]);

  const { t } = useTranslation('notification');

  const {
    loadNotifications,
    loading,
    notificationList,
    readAllNotifications,
    nextToken,
  } = notificationsStore;

  useEffect(() => {
    if (!userStore.currentUser?.username) {
      return;
    }
    followersStore.getFollowing(userStore.currentUser?.username)
      .then(setFollowing);
    loadNotifications(true)
      .then(() => readAllNotifications())
      .then(() => notificationsStore.notificationsLength = 0);
  }, [userStore.currentUser]);

  useEffect(() => {
    if (loadMore && !loading) {
      setLoadMore(() => false);
      loadNotifications(false);
    }
  }, [loadMore]);

  const scrollHandler = (e: any) => {
    const position = e.target.scrollTop + window.innerHeight + 10;
    if (position >= e.target.scrollHeight) {
      setLoadMore(true);
    }
  }

  useEffect(() => {
    document.body.addEventListener('scroll', function(e) { scrollHandler(e) }, false);
    return () => {
      document.body.removeEventListener('scroll', scrollHandler);
    }
  }, []);

  return <>
    <div className={styles.notifications}>
      <Header title={t('header.main')} hideAvatar={true} className={styles.notifications__header} />
      <div className={styles.notifications__list}>
        {notificationList?.map((notification) =>
          <NotificationItem
            key={notification.id}
            item={notification}
            following={following} />)}
        {(loading || notificationList === null) && <div className={styles.notifications__loader}>
          <Loader />
        </div>}
        {!loading && notificationList !== null && notificationList?.length === 0
          && <NoNotifications />}
      </div>
    </div>
    <BottomMenu
      className={styles['bottom-menu']}  />
  </>
});
