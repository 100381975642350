import { hideComment, hidePost, hideReaction, showComment, showPost, showReaction } from "@graphql/docs/mutations";
import { Comment, Post, Reaction } from "@graphql/graphql";
import { toastsStore } from "@store";
import { apolloClient } from "src/apollo-client";

class HideService {
  hidePost = async (id?: string|null): Promise<Post|null> => {
    if (!id) {
      return null;
    }

    try {
      const res = await apolloClient.mutate<{hidePost: Post}>({
        mutation: hidePost,
        variables: { id },
      })
      return res.data?.hidePost ?? null
    } catch {
      toastsStore.addErrorToast("Не удалось скрыть пост");
      return null;
    }
  }

  showPost = async (id?: string|null): Promise<Post|null> => {
    if (!id) {
      return null;
    }

    try {
      const res = await apolloClient.mutate<{showPost: Post}>({
        mutation: showPost,
        variables: { id },
      })
      return res.data?.showPost ?? null
    } catch {
      toastsStore.addErrorToast("Не удалось показать показать");
      return null;
    }
  }

  hideComment = async (id?: string|null): Promise<Comment|null> => {
    if (!id) {
      return null;
    }

    try {
      const res = await apolloClient.mutate<{hideComment: Comment}>({
        mutation: hideComment,
        variables: { id },
      })
      return res.data?.hideComment ?? null
    } catch {
      toastsStore.addErrorToast("Не удалось скрыть комментарий");
      return null;
    }
  }

  showComment = async (id?: string|null): Promise<Comment|null> => {
    if (!id) {
      return null;
    }

    try {
      const res = await apolloClient.mutate<{showComment: Comment}>({
        mutation: showComment,
        variables: { id },
      })
      return res.data?.showComment ?? null
    } catch {
      toastsStore.addErrorToast("Не удалось показать комментарий");
      return null;
    }
  }

  hideReaction = async (id?: string|null): Promise<Reaction|null> => {
    if (!id) {
      return null;
    }

    try {
      const res = await apolloClient.mutate<{hideReaction: Reaction}>({
        mutation: hideReaction,
        variables: { id },
      })
      return res.data?.hideReaction ?? null
    } catch {
      toastsStore.addErrorToast("Не удалось скрыть реакцию");
      return null;
    }
  }

  showReaction = async (id?: string|null): Promise<Reaction|null> => {
    if (!id) {
      return null;
    }

    try {
      const res = await apolloClient.mutate<{showReaction: Reaction}>({
        mutation: showReaction,
        variables: { id },
      })
      return res.data?.showReaction ?? null
    } catch {
      toastsStore.addErrorToast("Не удалось показать реакцию");
      return null;
    }
  }
}

export const hideService = new HideService();