import { useEffect, useState } from "react";

export type FormState<T> = {
  [K in keyof T]: any;
};

export const useFormState = <T extends Record<string, any>>(
  initialState: T
): {
  formState: FormState<T>;
  setInput: (key: keyof T, value: any) => void;
  setFormState: React.Dispatch<React.SetStateAction<FormState<T>>>;
} => {
  const [formState, setFormState] = useState<FormState<T>>(initialState);

  function setInput(key: keyof T, value: any) {
    setFormState(prev => ({ ...prev, [key]: value }));
  }

  useEffect(() => {
    setFormState(initialState);
  }, [initialState]);

  return {
    formState,
    setInput,
    setFormState,
  };
};