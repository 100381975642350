import styles from "./follow-unfollow-button.module.css";
import { Follower, UserPicture } from "@graphql/graphql";
import { toastsStore, userStore } from "@store";
import { Button } from "@ui-kit";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  user?: UserPicture,
  reloadUserData?: () => void,
  className?: string,
  hidePlus?: boolean
}

export const FollowUnfollowButton = observer(({ user, reloadUserData, className, hidePlus }: Props) => {
  const [following, setFollowing] = useState<Follower[]>([])
  const [loading, setLoading] = useState<boolean>(true);

  const { t } = useTranslation('common');

  const loadData = () => {
    userStore.getUserFollowing(userStore.currentUser?.username ?? undefined)
      .then(setFollowing)
      .catch(() => toastsStore.addErrorToast(t('toasts.error.loadData')))
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    loadData();
  }, [userStore.currentUser])

  const followUser = async () => {
    setLoading(true);
    const followers = await userStore.getUserFollowing(userStore.currentUser?.username ?? undefined);
    if (!user?.username || followers.some((followItem) => followItem?.follow === user.username && followItem.username === userStore.currentUser?.username)) {
      return;
    }
    await userStore.follow(user.username).catch(() => toastsStore.addErrorToast(t('toasts.error.follow')));
    loadData();
    reloadUserData && reloadUserData();
  }

  const unfollowUser = async () => {
    setLoading(true);
    const followers = await userStore.getUserFollowing(userStore.currentUser?.username ?? undefined);
    const followItem = followers.find((fItem) => fItem?.follow === user?.username)
    if (!user?.username || !followItem) {
      return;
    }
    await userStore.unfollow(user.username).catch(() => toastsStore.addErrorToast(t('toasts.error.unfollow')));
    loadData();
    reloadUserData && reloadUserData();
  }

  return !following.find((follow) => follow.follow === user?.username)
    ? <Button
        className={className}
        onClick={followUser}
        loading={loading}
        iconType={hidePlus ? undefined : 'plus'}
      >
        {t('followPage.button.follow')}
      </Button>
    : <Button
        className={className}
        onClick={unfollowUser}
        hierarchy='secondary-color'
        loading={loading}
      >
        {t('followPage.button.unfollow')}
      </Button>
});
