import { MediaFile } from '@graphql/graphql';
import { useEffect, useState } from 'react';
import styles from './carousel.module.css';
import { DotIndicator } from './dot-indicator/dot-indicator.comp';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar, A11y, Controller } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { MediaItemComponent } from 'src/main-page/components/post/media-item/media-item.comp';

type Props = {
  items: MediaFile[],
  theme?: 'primary'|'dark'|'light',
  className?: string,
  startFrom?: number
  onClick?: (value: number) => void
}

export const Carousel = ({ items, theme, startFrom, className, onClick }: Props) => {
  const [curentIndex, setCurrentIndex] = useState(startFrom ?? 0);
  const [swiper, setSwiper] = useState<any>(null);

  useEffect(() => {
    if (swiper && swiper.activeIndex !== curentIndex) {
      swiper.slideTo(curentIndex);
    }
  }, [curentIndex]);

  useEffect(() => {
    if (swiper && curentIndex) {
      swiper.slideTo(curentIndex);
    }
  }, [swiper]);

  theme = theme ?? 'primary';
  const fullClassName = className ? `${className} ${styles.carousel}` : styles.carousel;

  if (!items || items.length <= 0) {
    return <></>
  }

  return <div className={fullClassName}>
    <div className={styles.pictures}>
      <Swiper
        className={styles.pictures}
        modules={[Navigation, Scrollbar, A11y, Controller]}
        onSwiper={setSwiper}
        controller={{ control: swiper }}
        spaceBetween={0}
        slidesPerView={1}
        scrollbar={{ draggable: true }}
        onSlideChange={(a: any) => setCurrentIndex(a.realIndex ?? 0)}
        
      >
        {items.map((item, i) => (
          <SwiperSlide key={item.key} className={styles.slide}>
            <MediaItemComponent
              onClick={() => { onClick && onClick(i); }}
              className={styles['media-file']}
              mediaFile={item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
    <div className={styles.constrols}></div>
    {items.length > 1 && <div className={styles['dot-indicator']}>
      <DotIndicator
        curentIndex={curentIndex}
        setCurrentIndex={setCurrentIndex}
        size='md'
        style='dot'
        type='gray-light-bg'
        framed={true}
        length={items.length}/>
    </div>}
  </div>
}