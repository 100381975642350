import { MediaItemComponent } from "src/main-page/components/post/media-item/media-item.comp";
import styles from "./mini-media-content.module.css";
import { MediaFile } from "@graphql/graphql";
import { MediaMoreItemsComponent } from "src/main-page/components/post/media-more-items/media-more-items.comp";

type Props = {
  media?: (MediaFile|null)[]|null;
}

export const MiniMediaContent = ({ media: nullableMedia }: Props) => {
  const media = nullableMedia?.filter(Boolean).filter((file) => file !== null);

  if (!media || media.length === 0) {
    return <></>
  }

  return <div className={styles['mini-media-content']}>
    {media.slice(0, 4).map((mediaFile) =>
      mediaFile && <MediaItemComponent
        controls={false}
        width={44}
        height={44}
        key={mediaFile.key}
        mediaFile={mediaFile}
        className={styles['mini-media-content__item']} />
    )}
    {media && media.length > 4 && media[5]
      && <MediaMoreItemsComponent
        key={media[5]?.key}
        mediaFiles={media as MediaFile[]}
        className={styles['mini-media-content__item']} />}
  </div>
}