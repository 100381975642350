import styles from "./no-chats.module.css";
import pointer from "../../../assets/icons/pointer.svg";
import { Button } from "@ui-kit";
import { useTranslation } from "react-i18next";

type Props = {
  onNewMessage: () => void,
}

export const NoChats = ({ onNewMessage }: Props) => {
  const { t } = useTranslation('messenger');

  return <div className={styles['no-chats']}>
    <img
      src={pointer}
      alt='pointer'
      className={styles['no-chats__img-mobile']}
       />
    <h3 className={styles['no-chats__title']}>
      {t('header.noChat')}
    </h3>
    <p className={styles['no-chats__text']}>
    {t('chat.noChat')}
    </p>
    <Button hierarchy='primary' size='lg' onClick={onNewMessage}>{t('button.writeMessage')}</Button>
  </div>
}