import Icons, { ReactionTypes } from "src/assets/reactions";
import styles from "./avatar.module.css";
import { ReactionComponent } from 'src/main-page/components/post/reaction/reaction.comp';
import { reaction } from 'mobx';

type Props = {
  url?: string|null,
  alt?: string,
  className?: string,
  pictureClass?: string,
  size?: "xxs"|"xs"|"sm"|"md"|"lg"|"xl"|"2xl"|"3xl"|"4xl",
  statusIcon?: "online"|"false"|"company",
  live?: boolean,
  number?: string
  onClick?: () => void,
  avatarReaction?: ReactionTypes,
}

export const Avatar = ({
  url, alt, className, size, statusIcon, live, onClick, pictureClass, number, avatarReaction,
}: Props) => {
  alt = alt ?? 'Avatar';
  className = className ?? '';
  className = number ? `${className} ${styles['avatar_number']}` : className;
  className = onClick ? `${className} ${styles['avatar_pointer']}` : className;
  size = size ?? 'md';
  statusIcon = statusIcon ?? 'false';

  let containerClass = styles[`avatar-container-${size}`];
  pictureClass = pictureClass
    ? `${pictureClass} ${styles[`avatar-picture-${size}`]}`
    : styles[`avatar-picture-${size}`];

  if (live) {
    containerClass += ` ${styles['avatar-container_online']}`
  }

  return (
    <div
      className={`${styles['avatar-container']} ${containerClass} ${className}`}
      onClick={() => {onClick && onClick()}}
    >
      {!!url && <img className={`${styles['avatar-picture']} ${pictureClass}`}
        src={url}
        alt={alt} />}
      {!!number && <p className={styles.number}>+{number}</p>}
      <div className={styles.live} />
      {statusIcon === 'online' && <div className={`${styles.online} ${styles[`online-${size}`]}`} />}
      {!!avatarReaction && <div className={styles.reaction}>
        <div className={styles.icon} style={{ backgroundImage: `url(${Icons[avatarReaction]})` }} />
      </div>}
    </div>
  )
}