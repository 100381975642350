import { Message } from "@graphql/graphql";
import { getTimeInMin } from "./get-time-in-mins";

export const splitMessages = (messages: Message[]): Message[][] => {
  const result: Message[][] = [];
  let index = 0;

  messages?.forEach((message) => {
    if (!result[index]) {
      result[index] = [];
    }

    if (!result[index]?.[0]) {
      result[index].push(message);
    } else if (
      result[index]?.[0].username === message.username
      && getTimeInMin(result[index]?.[0]?.createdAt ?? 0) === getTimeInMin(message.createdAt ?? 1) 
    ) {
      result[index].push(message);
    } else {
      index += 1;

      result[index] = [message];
    }
  });

  return result;
}