import { AddCityAction, AddCityState, Cords, Selection } from "@add-city/add-city.reducer";
import { MapController } from "@add-city/types";

export const useMapController = (reducer: [AddCityState, React.Dispatch<AddCityAction>]) => {
  const [state, dispatch] = reducer;

  return {
    selected: state.selected,
    center: state.center,
    zoom: state.zoom,
    setSelected: (selection: Selection) =>
      dispatch({ type: 'setSelection', payload: selection }),
    setCenter: (center: Cords) =>
      dispatch({ type: 'setCenter', payload: center }),
    setZoom: (zoom: number) => dispatch({ type: 'setZoom', payload: zoom }),
  } as MapController;
}