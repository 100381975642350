import styles from "./no-notifications.module.css";
import image from "../../../assets/images/no-notifications.svg";
import { useTranslation } from "react-i18next";

export const NoNotifications = () => {
  const { t } = useTranslation('notification');

  return <div className={styles['no-notifications']}>
    <img
      alt='no notifications'
      className={styles['no-notifications__img']}
      src={image} />
    <h3 className={styles['no-notifications__title']}>
      {t('header.noNotific')}
    </h3>
    <p className={styles['no-notifications__text']}>
    {t('message.noNotific')}
    </p>
  </div>
}