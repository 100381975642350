import premium from "../../../assets/icons/premium-icon.svg";
import styles from "./user-profile-header.module.css";
import { UserPicture } from "@graphql/graphql";
import { Avatar, Badge } from "@ui-kit";
import { NumberData } from "../number-data/number-data.comp";
import {  userStore } from "@store";
import { HeaderButtomOtherUser } from "../header-buttom-other-user/header-buttom-other-user.comp";
import { HeaderButtomCurrentUser } from "../header-buttom-current-user/header-buttom-current-user.comp";
import { useNavigate } from "react-router-dom";
import { PremiumIcon } from "@shared/components/premium-icon/premium-icon.comp";

type Props = {
  user?: UserPicture,
  reloadUserData: () => void,
}

export const UserProfileHeader = ({ user, reloadUserData }: Props) => {
  const navigate = useNavigate();

  const openFollowers = () => navigate(`/followers/${user?.username}`);
  const openFollowing = () => navigate(`/following/${user?.username}`);

  return <>
    <div className={styles['profile-header']}>
      <Avatar
        className={styles.avatar}
        pictureClass={styles['picture-avatar-class']}
        picture-avatar-class
        url={user?.picture?.url}
        statusIcon={user?.isOnline ? 'online' : undefined}
        size='4xl'
      />
      <div className={styles.right}>
        <h2 className={styles.fullname}>
          {user?.firstname} {user?.lastname}
          {!!user?.isPremiun && <PremiumIcon className={styles.premium} />}
        </h2>
        <div className={styles.numbers}>
          <NumberData number={user?.postsLength ?? 0} text='posts'/>
          <NumberData
            number={user?.followers ?? 0}
            text='followers'
            onClick={user?.followers && user.followers > 0 ? openFollowers : undefined}/>
          <NumberData
            number={user?.following ?? 0}
            text='following'
            onClick={user?.followers && user.followers > 0 ? openFollowing : undefined}/>
        </div>
        <div className={styles.roles}>
          {!!user?.city?.longName && <Badge color='primary'>{user?.city?.longName}</Badge>}
          {user?.roles?.map((role) => (
            <Badge
              key={role?.id}
              customColor={role?.color ?? undefined}
              customTextColor={role?.textColor ?? undefined}
            >
                {role?.title}
            </Badge>))}
        </div>
        {user?.username !== userStore.currentUser?.username
          ? <HeaderButtomOtherUser user={user} reloadUserData={reloadUserData} />
          : <HeaderButtomCurrentUser />}
      </div>
    </div>
    <div className={styles['profile-header-mobile']}>
      <Avatar
        className={styles.avatar}
        pictureClass={styles['picture-avatar-class']}
        picture-avatar-class
        url={user?.picture?.url}
        size='3xl'
      />
      <div className={styles['profile-header-mobile__data']}>
        <NumberData number={user?.postsLength ?? 0} text='posts'/>
        <NumberData
          number={user?.followers ?? 0}
          text='followers'
          onClick={user?.followers && user.followers > 0 ? openFollowers : undefined}/>
        <NumberData
          number={user?.following ?? 0}
          text='following'
          onClick={user?.following && user.following > 0 ? openFollowing : undefined}/>
      </div>
    </div>
    <div className={styles['profile-header-mobile-bottom']}>
      <h2 className={styles.fullname}>
        {user?.firstname} {user?.lastname}
        {!!user?.isPremiun && <PremiumIcon className={styles.premium} />}
      </h2>
      <div className={styles.roles}>
        {!!user?.city?.longName && <Badge color='primary'>{user?.city?.longName}</Badge>}
        {user?.roles?.map((role) => (
          <Badge
            key={role?.id}
            customColor={role?.color ?? undefined}
            customTextColor={role?.textColor ?? undefined}
          >
            {role?.title}
          </Badge>))}
      </div>
      {user?.username !== userStore.currentUser?.username
        ? <HeaderButtomOtherUser user={user} reloadUserData={reloadUserData} />
        : <HeaderButtomCurrentUser />}
    </div>
  </>
}