import { useState, useEffect } from "react";
import styles from "./search-page.module.css";
import { observer } from "mobx-react-lite";
import { TabItem, Tabs, InputComponent } from "@ui-kit/components/";
import { Header } from "@shared/components";
import { SummaryForYou } from "./components/summary-for-you/summary-for-you";
import { SearchPosts } from "./components/search-posts/search-posts";
import { SearchPeople } from "./components/search-people/search-people";
import { SearchHashs } from "./components/search-hashs/search-hashs";
import { useSearchParams } from "react-router-dom";
import { SEARCH_MIN_LENGTH, SEARCH_TIMEOUT_MS } from "@shared/data/search.constants";
import { HashTag, Post, UserPicture } from "@graphql/graphql";
import { dataRecommends } from "./helpers/recommends";
import { citiesStore } from "@store";
import {searchData} from "./helpers/search-data";
import { useTranslation } from "react-i18next";

let timeoutFn: any;

export const SearchPage = observer(() => {
  const { t } = useTranslation('search');

  const tabs = [
    { key: 'foryou', title: t('menu.forYou') },
    { key: 'people', title: t('menu.people') },
    { key: 'posts', title: t('menu.posts') },
    { key: 'hashtags', title: t('menu.hashtags') }
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [curentTab, setCurrentTab] = useState<TabItem>(tabs[0]);
  const [searchString, setSearchString] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [users, setUsers] = useState<UserPicture[]>([]);
  const [posts, setPosts] = useState<Post[]>([]);
  const [hashs, setHashs] = useState<HashTag[]>([]);
  const [nextToken, setNextToken] = useState<string | null>(null);
  const { currentCity } = citiesStore;

  const handleTabClick = (key: string) => {
    const selectedTab = tabs.find(tab => tab.key === key);
    if (selectedTab) {
      setCurrentTab(selectedTab);
    } else {
      console.error('tab not found');
    }
  };

  useEffect(() => {
    // Effect for currentCity
  }, [currentCity]);

  const handleHashtagClick = (hashtag: string) => {
    setSearchString(hashtag);
  };

  useEffect(() => {
    const qString = searchParams.get('q');
    if (qString) {
      setSearchString(qString);
    }
  }, [searchParams, setSearchString]);

  useEffect(() => {
    if (currentCity) {
      setIsLoading(true);

      if (!!timeoutFn) {
        clearTimeout(timeoutFn);
      }

      setUsers([]);
      setPosts([]);
      setHashs([]);

      timeoutFn = setTimeout(async () => {
        try {
          let result;
          if (searchString.trim() !== '' || searchString.length > SEARCH_MIN_LENGTH) {

            result = await searchData({search: searchString, place_id: currentCity?.place_id})

            if (!result) {
              setSearchString('');
            } else {
              setUsers(result?.userItems as UserPicture[] ?? []);
              setPosts(result?.postItems as Post[] ?? []);
              setHashs(result?.hashtagItems as HashTag[] ?? []);
            }
          } else {
            result = await dataRecommends({ place_id: currentCity?.place_id })
            setUsers(result?.userItems as UserPicture[] ?? []);
            setPosts(result?.postItems as Post[] ?? []);
            setHashs(result?.hashtagItems as HashTag[] ?? []);
            setNextToken(result?.nextToken ?? null);
          }

        } catch (error) {
          console.error('Ошибка:', error);
        } finally {
          setIsLoading(false);
        }
      }, SEARCH_TIMEOUT_MS);

      setSearchParams({ q: searchString });
    }
  }, [searchString, currentCity, setSearchParams]);

  return (
    <section className={styles['search-page']}>
      <div className={styles['search-header__container']}>
        <Header
          className={styles['search-header']}
          isMainPage={false}
          hideBackArrow={true}
          hideAvatar={true}
          isSearchPage={true}
          title=''
        >
          <InputComponent
            className={styles['search-input']}
            type='search'
            placeholder={t('search.placeholder')}
            value={searchString}
            onchange={(value) => setSearchString(value)}
            text={searchString}
          />
          <Tabs
            className={styles['search-tabs']}
            items={tabs}
            current={curentTab}
            onClick={(key: string) => setCurrentTab(tabs.find((tab) => tab.key === key) ?? tabs[0])}
          />
        </Header>
      </div>
      <div className={styles['search__container']}>
        {curentTab.key === 'foryou' && <SummaryForYou isLoading={isLoading} users={users} posts={posts} hashs={hashs} onTabClick={handleTabClick} onHashtagClick={handleHashtagClick} />}
        {curentTab.key === 'people' && <SearchPeople users={users} initialToken={nextToken}/>}
        {curentTab.key === 'posts' && <SearchPosts initialToken={nextToken} posts={posts}/>}
        {curentTab.key === 'hashtags' && <SearchHashs initialToken={nextToken} hashs={hashs} onHashtagClick={handleHashtagClick} onTabClick={handleTabClick}/>}
      </div>
    </section>
  );
});