export const USERNAME_IS_EXISTS =
  'User with this username is already registered!';
export const EMAIL_IS_EXISTS = 'User with this email is already registered!';
export const PHONE_IS_EXISTS = 'User with this phone is already registered!';

export const USER_NOT_FOUND = 'User not found!';
export const WRONG_PASSWORD = 'Wrong password!';

export const NOT_CONFIRM = 'Wrong confirmation code!';
export const DO_NOT_CONFIRM = 'Please confirm your phone number!';

export const CITY_NOT_FOUND = 'City not found!';
export const CITY_IS_EXISTS = 'City is already exists';
export const DONT_HAVE_PERMISION = 'You do not have permision!';

export const POST_NOT_FOUND = 'Post not found!';
export const COMMENT_NOT_FOUND = 'Comment not found!';
export const REACTION_NOT_FOUND = 'Reaction not found!';
export const REACTION_IS_EXISTS = 'Reaction is already exists!';

export const FOLLOWER_NOT_FOUND = 'Follower not found!';
export const FOLLOWER_IS_EXISTS = 'Follower is already exists!';
export const FOLLOW_DENY = 'You can not follow for this user!';

export const CHAT_NOT_FOUND = 'Chat not found!';
export const MESSAGE_NOT_FOUND = 'Message not found!';
export const CHAT_IS_EXISTS = 'Chat is already exists!';

export const IN_WAIT_LIST = 'Phone in wait list, please wait a few minutes';
export const NOT_SENT_SMS = 'Can not sent SMS to this phone number';
export const WRONG_PHONE = 'Wrong phone number for this user';

export const VERIFICATION_CODE = 'Your verification code ';

export const SAME_PASSWORD = 'You can not use the same password';
export const SAME_PHONE = 'You can not use the same phone number';
export const USER_PHONE_EXISTS =
  'This phone nuber already in use with other user';

export const CAN_NOT_SEND_EMAIL = 'Can not send email';
export const SAME_EMAIL = 'You can not use the same email';
export const USER_EMAIL_EXISTS = 'This email already in use with other user';
export const CAN_NOT_CHANGE_CITY = 'Can not change city';

export const ROLE_IS_EXISTS = 'Role is already exists';
export const ROLE_NOT_FOUND = 'Role not found!';

export const REPORT_NOT_FOUND = 'Report not found!';
export const CAN_NOT_CREATE_LINK_DATA = 'Can not create link';
export const LINK_DATA_NOT_FOUND = 'Link data not found';

export const CAN_NOT_SEND = 'Can not send message to this user';

export const NOTIFICATION_NOT_FOUND = 'Notification not found!';

export const WRONG_EMAIL_TYPE =
  'Wrong email type, use one of them: emailNewNotify, emailNewMessagesNotify, emailTopPostsNotify, emailEcittyUpdatesNotify, emailEcittySurveyNotify or emailEcittyRecommendationsNotify!';
export const WRONG_EMAIL_HEADER_TYPE =
  'Wrong email header picture, use one of them: code, email, info, message, news, notification or updates!';

export const BANNED_USER = 'User is banned!';

export const CITY_LIMIT = 'You can not create more cities!';
export const CITY_TIMEOUT = 'You can not create cities more than one a day!';
