import { LiveUser } from "@shared/data/live-user";
import styles from "./live-user-item.module.css";
import { Avatar, Badge } from "@ui-kit";
import { citiesStore } from "@store";
import { getShortNumber } from "@shared/helpers/get-short-number";
import { observer } from "mobx-react-lite";


type Props = {
  user: LiveUser,
  className?: string,
}

export const LiveUserItem = observer(({ user, className }: Props) => {
  className = className ? `${styles['live-user__item']} ${className}` : styles['live-user__item']

  const city = citiesStore.cities.find((city) => city.place_id === user.user.cityId);

  return <div className={className}>
    <div className={styles['live-user__left']}>
      <Avatar
        alt={user.user.username ?? undefined}
        url={user.user.picture?.url ?? undefined}
        size='lg'
        live={true}
      />
      <div className={styles['live-user__text']}>
        <div className={styles['live-user__fullname']}>
          {user.user.firstname} {user.user.lastname}
        </div>
        {!!city?.longName && <div className={styles['live-user__city']}>
          {city.longName}
        </div>}
      </div>
    </div>
    <div className={styles['live-user__right']}>
      <Badge className={styles.badge} color='error' icon='dot' size='md'>{getShortNumber(user.online)}</Badge>
    </div>
  </div>
});
