import { commentListStore, toastsStore } from "@store";
import { useCallback, useEffect, useState } from "react";
import { CommentComponent } from "../comment/comment.comp";
import { Comment } from "@graphql/graphql";
import { observer } from "mobx-react-lite";
import { Loader } from "@ui-kit/components/loader/loader.comp";
import styles from "./comment-list.module.css";
import { NoComments } from "../no-comments/no-comments.comp";

type Props = {
  className?: string,
  postId?: string|null,
  scrollParentLevel?: number,
  hideOnTabletAndMobile?: boolean,
  onFocusWithUsername: (username?: string) => void,
  mainComment?: Comment|null,
  mainSubComment?: Comment|null,
}

export const CommentList = observer(({
  className, postId, scrollParentLevel, hideOnTabletAndMobile, onFocusWithUsername, mainComment, mainSubComment,
}: Props) => {
  className = className ? `${className} ${styles['comment-list']}` : styles['comment-list'];
  if (hideOnTabletAndMobile) {
    className += `${className} ${styles['comment-list_hide']}`
  }

  const { setAnswerCommentId, clearComments, clearAnswerCommentId, fetchComments, loading, comments } = commentListStore;

  const [loadMore, setLoadMore] = useState(false);

  let scrollDiv: Document | ParentNode | null

  const ref = useCallback((node: any) => {
    if (node !== null) {
      scrollDiv = document.body;
      if (!!scrollParentLevel) {
        scrollDiv = node.parentNode;
        for (let i = 1; i < scrollParentLevel; i++) {
          if (scrollDiv?.parentNode) {
            scrollDiv = scrollDiv?.parentNode;
          } else {
            scrollDiv = document.body;
          }
        }
      }
      scrollDiv?.addEventListener('scroll', scrollHandler);
    }
  }, []);

  useEffect(() => {
    return () => {
      scrollDiv?.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  const scrollHandler = (e: any) => {
    if (postId && comments[postId]?.length) {
      if (e?.target?.documentElement) {
        const position = e.target.documentElement.scrollTop + window.innerHeight + 10
        if (position >= e.target.documentElement.scrollHeight ) {
          setLoadMore(true);
        }
      } else if(e?.target?.scrollTop) {
        const position = e.target.scrollTop + window.innerHeight + 10
        if (position >= e.target.scrollHeight ) {
          setLoadMore(true);
        }
      }
    }
  }

  useEffect(() => {
    if (!postId) {
      return;
    }

    clearComments(postId);
    clearAnswerCommentId();
    fetchComments(postId);
  }, []);

  useEffect(() => {
    if (loadMore && !loading && postId) {
      fetchComments(postId).then(() => setLoadMore(false)).catch((err: any) => toastsStore.addErrorToast("Can not get posts!"));
    }
  }, [loadMore]);

  const onAnswerClick = (commentId?: string|null, username?: string|null) => {
    if (commentId && username) {
      setAnswerCommentId(commentId);
      onFocusWithUsername(username);
    }
  }

  if (!postId) {
    return <></>
  }

  if ((!comments[postId] || comments[postId]?.length <= 0) && !loading) {
    return <div
      className={hideOnTabletAndMobile ? `${styles['no-comments']} ${styles['no-comments_hide']}` : styles['no-comments']}
    >
      <NoComments onFocus={() => onFocusWithUsername()} />
    </div>
  }

  return <div className={className} ref={ref}>
    {!!mainComment && <CommentComponent
        item={mainComment}
        key={mainComment.id}
        onAnswerClick={() => onAnswerClick(mainComment.id, mainComment.username)}
        onAnswerSubClick={(username: string) => onAnswerClick(mainComment.id, username)}
        subComment={mainSubComment} />}
    {comments[postId]?.map((comment: Comment) => (
      mainComment?.id !== comment.id && <CommentComponent
        item={comment}
        key={comment.id}
        onAnswerClick={() => onAnswerClick(comment.id, comment.username)}
        onAnswerSubClick={(username: string) => onAnswerClick(comment.id, username)} />
    ))}
    {loading && <Loader />}
  </div>
});
