import styles from "./summary-for-you.module.css";
import { Loader, LinkComponent } from "@ui-kit/components/";
import { useNavigate } from "react-router-dom";
import { Post, UserPicture, HashTag } from "@graphql/graphql";
import { FollowComponent } from "../../../follow-page/components/follow.comp";
import { PostComponent } from "src/main-page/components/post/post.comp";
import { HashtagComponent } from "../hashtag/hashtag.comp";
import { useTranslation } from "react-i18next";

type Props = {
    isLoading?: boolean;
    users: UserPicture[],
    posts: Post[],
    hashs: HashTag[],
    onTabClick: (key: string) => void,
    onHashtagClick: (hashtag: string) => void;
}

export const SummaryForYou = ({ isLoading, users, posts, hashs, onTabClick, onHashtagClick }: Props) => {
    const navigate = useNavigate();
    const { t } = useTranslation('search');

    const handleHashtagClick = (hash: HashTag) => {
        if(hash.hashtag) onHashtagClick(hash.hashtag);
      };

    if (isLoading) {
        return (
            <div className={styles['loading']}>
                <Loader loaderType='line' />
            </div>
        );
    }

    return (
        <section className={styles['search-for-you__section']}>
            {users.length > 0 && (
                <>
                    <div className={styles['search-for-you__people']}>
                        <h2 className={styles['title']}>{t('summary.people.header')}</h2>
                        {users.slice(0, 2).map((user) => (
                            <FollowComponent onClick={() => navigate(`/wall/${user.username}`)} user={user} key={user.username}/>
                        ))}
                        <LinkComponent className={styles['link']} click={() => onTabClick('people')}>{t('summary.link')}</LinkComponent>
                    </div>
                </>
            )}

            {posts.length > 0 && (
                <>
                    <div className={styles['search-for-you__posts']}>
                        {posts.slice(0, 2).map((post) => (
                            <PostComponent key={post.id} item={post} />
                        ))}
                        <LinkComponent className={styles['link__posts']} click={() => onTabClick('posts')}>{t('summary.link')}</LinkComponent>
                    </div>
                </>
            )}

            {hashs.length > 0 && (
                <>
                    <div className={styles['search-for-you__hashs']}>
                        <h2 className={`${styles['title']} ${styles['title__hashs']}`}>{t('summary.hash.header')}</h2>
                        {hashs.slice(0, 2).map((hash) => (
                            <HashtagComponent hash={hash} key={hash.id} onClick={() =>
                                {handleHashtagClick(hash)
                                onTabClick('posts')}}/>
                        ))}
                        <LinkComponent className={styles['link__hashs']} click={() => onTabClick('hashtags')}>{t('summary.link')}</LinkComponent>
                    </div>
                </>
            )}
        </section>
    )
};
