import { makeAutoObservable } from "mobx";

type ActionDialogSync = (value: boolean) => void;
type ActionDialogAsync = (value: boolean) => Promise<any>;

type ActionDialog = ActionDialogSync | ActionDialogAsync;

class ModalDialogsStore {
  isShowingDialog = false;
  title = 'title';
  message = 'message';
  action: ActionDialog = () => {};

  constructor() {
    makeAutoObservable(this);
  }

  showDialog = (title?: string, message?: string, action?: ActionDialog) => {
    if (title) {
      this.title = title;
    }
    if (message) {
      this.message = message;
    }
    if (action) {
      this.action = action;
    }

    this.isShowingDialog = true;
  }

  hideDialog = () => {
    this.isShowingDialog = false;
  }

  setAction = (action: ActionDialog) => {
    this.action = action;
  }
}

export const modalDialogsStore = new ModalDialogsStore();