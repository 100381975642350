import { makeAutoObservable } from "mobx";
import { ModalIconOption } from "@shared/components/modal/modal-custom.comp";
import {HierarchyType} from "@ui-kit/components/button/button.component";

type ActionCustomDialogSync = (value: boolean) => void;
type ActionCustomDialogAsync = (value: boolean) => Promise<any>;

type ActionDialog = ActionCustomDialogSync | ActionCustomDialogAsync;

export type DialogOptions = {
  typeIcon?: ModalIconOption,
  title?: string,
  message?: string,
  firstButtonText?: string,
  firstButtonColor?: HierarchyType,
  secondButtonText?: string,
  secondButtonColor?: HierarchyType,
  confirmAction?: ActionDialog,
  cancelAction?: ActionDialog,
  firstButtonDestructive?: boolean,
  secondButtonDestructive?: boolean,
}

class ModalCustomDialogsStore {
  isShowingDialog = false;
  title = 'title';
  message = 'message';
  typeIcon : ModalIconOption = null;
  confirmAction: ActionDialog = () => {};
  cancelAction: ActionDialog = () => {};
  children: any;
  firstButtonText = '';
  secondButtonText = '';
  firstButtonColor: HierarchyType = 'primary';
  secondButtonColor: HierarchyType = 'secondary-color';
  closedByBackdropClick = false;
  closedByEscape = false;
  firstButtonDestructive = false;
  secondButtonDestructive = false;

  constructor() {
    makeAutoObservable(this);
  }

  showDialog = ( children: any, options: DialogOptions ) => {
    this.title = options.title ?? '';
    this.message = options.message ?? '';
    this.typeIcon = options.typeIcon ?? null;
    this.children = children ?? null;
    this.firstButtonText = options.firstButtonText ?? '';
    this.firstButtonColor = options.firstButtonColor ?? 'primary';
    this.secondButtonText = options.secondButtonText ?? '';
    this.secondButtonColor = options.secondButtonColor ?? 'secondary-color';
    this.confirmAction = options.confirmAction ?? (() => {});
    this.cancelAction = options.cancelAction ?? (() => {});
    this.firstButtonDestructive = !!options.firstButtonDestructive;
    this.secondButtonDestructive = !!options.secondButtonDestructive;

    this.isShowingDialog = true;
  }

  closeOnBackdropClick = () => {
    this.closedByBackdropClick = true;
    this.hideDialog();
  };

  closeOnEscape = () => {
    this.closedByEscape = true;
    this.hideDialog();
  };

  hideDialog = () => {
    this.isShowingDialog = false;

    setTimeout(() => {
      this.closedByBackdropClick = false;
      this.closedByEscape = false;
    }, 0);
  }

  setActions = (confirmAction: ActionDialog, cancelAction: ActionDialog) => {
    this.confirmAction = confirmAction;
    this.cancelAction = cancelAction;
  }

  confirm = async () => {
    if (this.confirmAction) {
      await this.confirmAction(true);
    }
    this.hideDialog();
  };

  cancel = async () => {
    if (this.cancelAction) {
      await this.cancelAction(false);
    }
    this.hideDialog();
  };
}

export const modalCustomDialogsStore = new ModalCustomDialogsStore();