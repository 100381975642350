import { gql } from "@apollo/client";

export const getReactions = gql`query getReactions($postId: ID) {
    getReactions(postId: $postId){
        id
        postId
        type
        username
        isHiden
        createdAt
        updatedAt
    }
}`;
