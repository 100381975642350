import styles from "./tabs.module.css";

export type TabItem = {
  key: string
  title: string,
  disabled?: boolean,
}

type Props = {
  items: TabItem[],
  className?: string,
  current?: TabItem,
  onClick?: (key: string) => void
  size?: 'sm'|'md'
}

export const Tabs = ({ items, className, current, onClick, size }: Props) => {
  size = size ?? 'md'
  className = className ? `${styles.tabs} ${className}` : styles.tabs;
  className = `${className} ${styles[`tabs-${size}`]}`;

  return (<ul className={className}>
    {items.map((item) => (
      <li
        key={item.key}
        className={`${styles.item} ${item.key === current?.key ? styles.item_active : ''} ${!!item.disabled ? styles.item_disabled : ''}`}
        onClick={onClick && !item.disabled ? () => onClick(item.key) : () => {}}
      >
        {item.title}
      </li>
    ))}
  </ul>)
}