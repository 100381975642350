const removeWww = (url: string) => {
  if (url.substring(0, 4) === 'www.') {
    return url.substring(4)
  }
  return url;
}

export const getDomainNameFromUrl = (url: string) => {
  const urlArr = url.split('/');
  if (urlArr[0].substring(0, 4) === 'http') {
    return removeWww(urlArr[2]).split('?')[0];
  }

  return removeWww(urlArr[1]).split('?')[0];
}
