import { Button } from "@ui-kit";
import styles from "./no-comments.module.css";
import { useTranslation } from "react-i18next";

type Props = {
  onFocus: () => void
}

export const NoComments = ({ onFocus }: Props) => {

  const { t } = useTranslation('main');

  return <div className={styles['no-comments']}>
    <div className={styles['no-comments__image']}></div>
    <p className={styles['no-comments__text']}>
      {t('noComments.title')}
    </p>
    <Button
      className={styles['no-comments__button']}
      size='md'
      hierarchy='primary'
      iconType='edit-3'
      onClick={() => onFocus()}
    >
      {t('noComments.button')}
    </Button>
  </div>
}