import { Icon } from "@home/components/icon/icon.comp";
import { IconTypes } from "src/assets";
import styles from "./nav-button.module.css";

type Props = {
  icon: IconTypes,
  active: boolean,
  onClick: () => void,
  disabled?: boolean
  className?: string,
  showDot?: boolean,
}

export const NavButton = ({ icon, active, onClick, disabled, className, showDot }: Props) => {
  const fullClassName = className ? `${className} ${styles['nav-button']}` : styles['nav-button'];

  onClick = disabled ? () => {} : onClick;

  return <button className={fullClassName} onClick={onClick} disabled={!!disabled}>
    <Icon
      className={styles['nav-button-icon']}
      color={active ? '#367DFF' : disabled ? '#EAECF0' : '#344054'}
      icon={icon}
      />
    {!!showDot && <div className={styles['nav-button__dot']} />}
  </button>
}