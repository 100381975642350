import { Chat } from "@graphql/graphql";
import { messagesStore, userStore } from "@store";
import { MenuItem } from "@ui-kit/components/drop-down/modele/menu-item";
import { MESSAGE_MENU_KEYS } from "./use-message-menu";
import { reportUser } from "@shared/helpers/report-user";
import { useDeleteChat } from "./use-delete-chat";
import { messageNotificationsStore } from "src/store/mesage-notifications.store";

export const useMessageMenuClick = () => {
  const deleteChat = useDeleteChat();

function onMessageMenuClick(item: MenuItem, chat?: Chat): void {
  const user = chat?.userPicture?.find((username) => username !== userStore.currentUser?.username);
  if (!chat) {
    return;
  }
  switch(item.key) {
    case MESSAGE_MENU_KEYS.NOTIFY_ON:
      chat.chatId && messagesStore.switchNotification(chat.chatId, false);
      break;
    case MESSAGE_MENU_KEYS.NOTIFY_OFF:
      chat.chatId && messagesStore.switchNotification(chat.chatId, true);
      break;
    case MESSAGE_MENU_KEYS.REPORT:
      user && reportUser(user)
      break;
    case MESSAGE_MENU_KEYS.DELETE:
      user && chat.chatId && deleteChat(user, chat.chatId)
      break;
  }
  messageNotificationsStore.hideAllMessages()
}
return onMessageMenuClick;
}