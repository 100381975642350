import { LikeTypes } from "@home/data/like-types";
import { useState } from "react";
import { PostItemLikes } from "../post-item-likes/post-item-likes.comp";
import styles from "./reactions-block.module.css";
import { Post } from "@graphql/graphql";
import { userStore } from "src/store/user.store";
import { ReactionTypes } from "src/assets/reactions";

interface Props {
  post: Post,
  type: ReactionTypes,
  handleLikePost: (type?: ReactionTypes) => void;
}

export const ReactionsBlock = ({ post, type, handleLikePost }: Props) => {
  const [isLikedPost, setIsLikedPost] = useState<boolean>(
    post?.reactions?.some((item) =>
      (item?.username === userStore.currentUser?.username && item?.type === type)) ?? false
  );
  const [postLikeCount, setPostLikeCount] = useState<number>(
    post?.reactions?.filter((reaction) => reaction?.type === type).length ?? 0,
  );

  const hadleLikePostFn = () => {
    if(!userStore.currentUser) {
      return;
    }
    setPostLikeCount((prev) =>
      isLikedPost ? prev - 1 : prev + 1
    );
    setIsLikedPost((prev) => !prev);
    handleLikePost(type);
  }

  const getIconStyle = () => {
    switch (type) {
      case LikeTypes.Angry: return styles.iconAngry
      case LikeTypes.Dislike: return styles.iconFacepalm
      case LikeTypes.Heart: return styles.iconHeart
      case LikeTypes.Joy: return styles.iconJoy
      case LikeTypes.Sad: return styles.iconSad
      default: return styles.iconLike
    }
  }

  return (
    <>
      <span
        className={`${isLikedPost ? 'text-primary' : ''} ${isLikedPost}`}
        onClick={() => hadleLikePostFn()}
        id={styles.postLikeBtn}
      >
        <button
          className={`${styles.icon} ${getIconStyle()} ${isLikedPost ? styles.iconSelected : '' }`} />
      </span>
      <PostItemLikes
        likes={post.reactions
          ?.filter((reaction) => reaction?.type === type)
          .map((reaction) => reaction?.username ?? '') ?? []}
        count={postLikeCount} />
    </>
  )
}