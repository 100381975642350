import styles from "./right-menu-item.module.css";

type Props = {
  children: any
}

export const RightMenuItem = ({ children }: Props) => {
  return <div className={styles['right-menu-item']}>
    {children}
  </div>
}