import { AddCityState } from "@add-city/add-city.reducer";
import { FetchResult } from "@apollo/client";
import { createCity } from "@graphql/docs/mutations";
import { City, CreateCityInput } from "@graphql/graphql";
import { GqlInput } from "@shared/utils/gql-input";
import { apolloClient } from "src/apollo-client";

export const addCityToAws = async (state: AddCityState): Promise<FetchResult<{ createCity: City; }>> => {
  const { longName, city, placeId, country, lang } = state;

  return await apolloClient.mutate<{createCity: City}, GqlInput<CreateCityInput>>({
    mutation: createCity,
    variables: {
      input: {
        place_id: placeId,
        country,
        langs: {
          lang,
          name: longName,
          country,
        },
        longName,
        name: city,
        picture: null,
      },
    }
  })
}