import { MediaFile } from "@graphql/graphql";
import { getUserLocation } from "@shared/helpers";
import { FormState } from "@shared/hooks";
import { uploadFile } from "@shared/uploader/upload-file";
import { citiesStore, postListStore, toastsStore } from "@store";
import { userStore } from "src/store/user.store";
import { v4 as uuidv4 } from "uuid";

export async function createNewPost(
  formState: FormState<{text: string; media: string[]}>,
  files: File[],
  setInitialFormSate: () => void,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setActiveKey: React.Dispatch<React.SetStateAction<string>>
) {
  try {
    setLoading(true);

    const location = await getUserLocation();
    const lat = location ? location.latitude : null;
    const lng = location ? location.longitude : null;

    const newPost = { ...formState };

    let mediaFiles: MediaFile[] = [];
    if (files.length) {
      for(let i = 0; i < files.length; i++) {
        const fileName = formState.media[i] ? `${userStore.currentUser?.username}-${uuidv4()}` : "";
        const mediaFile = await uploadFile(fileName, files[i]);
        if (mediaFile) {
          mediaFiles.push(mediaFile);
        }
      }
    }

    await postListStore.createPost({
      text: newPost.text,
      cityId: citiesStore.currentCity?.place_id,
      lat,
      lng,
      media: mediaFiles,
    })
    setInitialFormSate();
    setLoading(false);
    setActiveKey('');
  } catch (err) {
    toastsStore.addErrorToast('Can not create the new post!');
    setLoading(false);
  }
}