import styles from "./menu.module.css";
import checkImg from "@assets/images/check.svg";
import userImg from "@assets/images/user.svg";
import { Fragment } from "react";
import { Icon, IconSize } from "@home/components/icon/icon.comp";
import { MenuItem } from "../../modele/menu-item";
import { ColoredDot } from "../colored-dot/colored-dot.comp";
import { Button } from "@ui-kit";
import { IconButton } from "@ui-kit/components/button/icon-button/icon-button.comp";
import { Badge } from "@ui-kit/components/badge/badge.comp";

type Props = {
  items: MenuItem[];
  onClick?: (item: MenuItem) => void;
  withoutScroll?: boolean;
  withoutShadow?: boolean;
  className?: string;
  itemClassName?: string
  checkClassName?: string
  indicateSelection?: boolean
  iconSize?: IconSize;
  isExtendUserMenu?: boolean;
  textCollor?: string;
  iconCollor?: string;
}

export const Menu = ({
  items,
  onClick,
  withoutScroll,
  withoutShadow,
  className,
  indicateSelection,
  itemClassName,
  checkClassName,
  iconSize,
  isExtendUserMenu,
}: Props) => {
  const cssClassTitle = (disabled: boolean = false) =>
    `${styles['item__title']} ${disabled ? styles['item__title_disabled'] : ''}`;
  const cssClassButtonTitle = (disabled: boolean = false) =>
    `${styles['item__button-title']} ${disabled ? styles['item__button-title_disabled'] : ''}`;
  const cssClassSubTitle = (disabled: boolean = false) =>
    `${styles['item__sub-title']} ${disabled ? styles['item__sub-title_disabled'] : ''}`;
    const cssClassSetTitle = (disabled: boolean = false) =>
    `${styles['item__set-title']} ${disabled ? styles['item__set-title_disabled'] : ''}`;

  checkClassName = checkClassName ? `${styles.item__check} ${checkClassName}` : styles.item__check;
  const _itemClassName = itemClassName ? `${styles.item} ${itemClassName}` : styles.item;

  return (
    <div className={`${styles['drop-menu']} ${!withoutScroll ? styles['drop-menu_scroll'] : ''} ${withoutShadow ? styles['drop-menu_shadow'] : ''} ${className ?? ''} ${isExtendUserMenu ? styles['drop-menu_extended'] : ''}`}>
      {items.map((item) => (
        <Fragment key={item.key}>
          {item.type === 'header' &&
            <>
              <div className={styles['header']}>{item.title}</div>
              <div className={styles.hr} />
            </>
          }

          {item.type === 'header-user' &&
            <>
              <div className={styles['user-header']}>
                <div className={styles['user-header__icon']}>
                  <img src={item.iconUrl ?? userImg} alt={item.subTitle} />
                </div>
                <div className={styles['user-header__info']}>
                  <div className={styles['user-header__fullname']}>{item.title}</div>
                  <div className={styles['user-header__username']}>{item.subTitle}</div>
                </div>
                {isExtendUserMenu && <IconButton iconType='chevron-right' size='lg' color='#98A2B3'/>}
              </div>
              <div className={styles.hr} />
            </>
          }

          {item.type === 'phone' &&
            <div className={_itemClassName} onClick={() => onClick && !item.disabled && onClick(item)}>
              <div className={cssClassTitle(item.disabled)}>
                {item.countryCode}
              </div>
              <div className={cssClassSubTitle(item.disabled)}>|</div>
              <div className={cssClassTitle(item.disabled)}>{item.title}</div>
              <div className={cssClassSubTitle(item.disabled)}>{item.subTitle}</div>
              {item.selected &&
                <div className={checkClassName}>
                  <img src={checkImg} alt='selected' />
                </div>
              }
            </div>
          }

          {item.type === 'user' &&
            <div className={_itemClassName} onClick={() => onClick && !item.disabled && onClick(item)}>
              {(!!item.coloredDot && !item.showIcon && !item.iconUrl) &&
                <ColoredDot type={item.coloredDot} />
              }
              {(item.showIcon || item.iconUrl) &&
                <div className={styles.item__icon}>
                  <img
                    className={styles.item__icon}
                    src={item.iconUrl ?? userImg}
                    alt={item.title} />
                </div>
              }

              <div className={cssClassTitle(item.disabled)}>{item.title}</div>
              <div className={cssClassSubTitle(item.disabled)}>{item.subTitle}</div>
              {item.selected &&
                <div className={checkClassName}>
                  <img src={checkImg} alt='selected' />
                </div>
              }
            </div>
          }

          {item.type === 'menu-item' &&
            <div className={itemClassName ? `${styles['menu-item']} ${itemClassName}` : styles['menu-item']}
              onClick={() => onClick && !item.disabled && onClick(item)}
            >
              {!!item.grayIcon && <Icon size={iconSize ?? 'xxs'} icon={item.grayIcon} color={indicateSelection && item.selected ? '#367DFF' : item.disabled ? '#EAECF0' : item.iconCollor ? item.iconCollor : undefined} />}
              <div
                className={`${cssClassTitle(item.disabled)} ${indicateSelection && item.selected ? styles.indicate : ''}`}
                style={{color: item.textCollor}}
              >
                {item.title}
              </div>
              {!!item.number
                && <Badge
                    size='sm'
                    color='error'
                    className={styles['menu-item__number']}
                  >
                    {item.number}
                  </Badge>}
            </div>
          }

          {item.type === 'button' &&
            <div className={_itemClassName} onClick={() => onClick && !item.disabled && onClick(item)}>
              {(item.grayIcon) &&
                <div className={styles.item__icon}>
                  <Icon icon={item.grayIcon} size='xxs-plus' color={item.disabled ? '#EAECF0' : item.iconCollor ? item.iconCollor : '#1E5BCB'} />
                </div>
              }

              <div className={cssClassButtonTitle(item.disabled)}>{item.title}</div>
              {item.selected &&
                <div className={checkClassName}>
                  <img src={checkImg} alt='selected' />
                </div>
              }
            </div>
          }
          {(item.type === 'menu-settings' || item.type === 'menu-help') &&
            <div className={`${styles['menu-settings']}`}
              onClick={() => onClick && !item.disabled && onClick(item)}
            >
              <div className={`${cssClassSetTitle(item.disabled)} ${indicateSelection && item.selected ? styles.indicate : ''}`}>
              {item.title}
              </div>
              {!!item.grayIcon && <Icon size='xxs-plus' icon={item.grayIcon} color={'#98A2B3'} />}
            </div>
          }

          {item.type === 'menu-notification' &&
            <div className={`${styles['menu-notification']}`}
              onClick={() => onClick && !item.disabled && onClick(item)}
            >
              <div className={`${cssClassSetTitle(item.disabled)} ${indicateSelection && item.selected ? styles.indicate : ''} ${styles['menu-notification_item']}`}>
              {item.key==='push' && <>
                <Icon size='xxs-plus' icon={'message-square'} color={item.iconCollor ? item.iconCollor : '#344054'}/>{item.title}
                </>}
                {item.key==='email' && <>
                <Icon size='xxs-plus' icon={'mail'} color={item.iconCollor ? item.iconCollor : '#344054'}/>{item.title}
                </>}
              </div>
              {!!item.grayIcon && <Icon size='xxs-plus' icon={item.grayIcon} color={item.iconCollor ? item.iconCollor : '#98A2B3'} />}
            </div>
          }

          {item.type === 'hr' &&
            <div className={styles.hr} />
          }

        </Fragment>
      ))}
    {isExtendUserMenu && <Button className={styles['user-menu__button']} size='md' iconType='plus'>Опубликовать</Button>}
    </div>
  )
}