import { IconTypes } from "src/assets";
import icon from "src/assets/gray-icons";
import styles from "./icon-button.module.css";

type Props = {
  iconType: IconTypes,
  color?: string,
  size?: "xs"|"sm"|"md"|"lg"|"xl"|"2xl",
  onClick?: () => void,
  className?: string,
}

export const IconButton = ({ iconType, color, size, onClick, className }: Props) => {
  className = className ? `${styles.button} ${className}` : styles.button;
  const Icon = icon[iconType];
  color = color ?? '#344054';
  size = size ?? 'sm';

  let sizePx = 0;
  switch (size) {
    case 'xs': sizePx = 16; break;
    case 'sm': sizePx = 18; break;
    case 'md': sizePx = 20; break;
    case 'lg': sizePx = 24; break;
    case 'xl': sizePx = 28; break;
    case '2xl': sizePx = 32; break;
  }

  return <button className={className} onClick={onClick ? onClick : () => {}}>
    <Icon stroke={color} width={`${sizePx}px`} height={`${sizePx}px`} />
  </button>
}