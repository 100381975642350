import { AppleButton, FacebookButton, GoogleButton } from "@ui-kit/components/button/social-buttons";
import styles from "./login-right.module.css";
import { Button } from "@ui-kit";
import { useNavigate } from "react-router-dom";
import { onGoogleClick } from "@shared/helpers/on-google-click";
import { useTranslation } from "react-i18next";

type Props = {
  className?: string,
}

const ENABLED_OAUTH = process.env.REACT_APP_DISABLE_OAUTH?.toLowerCase() !== 'true'

export const LoginRightComp = ({ className }: Props) => {
  const navigate = useNavigate();

  const { t } = useTranslation('main');

  return <div className={className ? `${className} ${styles['login-right']}` : styles['login-right']}>
    <h2 className={styles.title}>{t('loginRight.title')}</h2>
    {ENABLED_OAUTH && <>
      <GoogleButton className={styles.button} btnSmall={true} supportingText={true} isRegistration={true} onClick={onGoogleClick}/>
      <FacebookButton className={styles.button} btnSmall={true} supportingText={true} isRegistration={true} />
      <AppleButton className={styles.button} btnSmall={true} supportingText={true} isRegistration={true} />
    </>}
    <Button
      className={styles.button}
      hierarchy='secondary-gray'
      size='md'
      iconType='phone'
      onClick={() => navigate('registration')}
    >
      {t('loginRight.button.phone')}
    </Button>
    <div className={styles.footer}>
      <p>{t('loginRight.link.haveAccount')}</p>
      <Button
        hierarchy='link-color'
        size='md'
        onClick={() => navigate('login')}
      >
        {t('loginRight.button.enter')}
      </Button>
    </div>
  </div>
}