import { Icon } from "@home/components/icon/icon.comp";
import styles from "./crop-city-cover-dialog.module.css";
import ReactCrop, { Crop } from "react-image-crop";
import { useEffect, useState } from "react";
import "react-image-crop/dist/ReactCrop.css";
import { Button } from "@ui-kit";
import { onImageLoad } from "src/main-page/helpers/on-image-load";
import { useTranslation } from "react-i18next";

type Props = {
  onSuccsesCrop: (imageFile: ImageBitmap) => Promise<void>,
  image: string,
}

export const CropCityCoverDialog = ({ onSuccsesCrop, image }: Props) => {
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<Crop>()
  const [size, setSize] = useState({ width: 0, height: 0 })
  const [bitmapImg, setBitmapImg] = useState<ImageBitmap>();
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation('main');

  useEffect(() => {
    fetch(image)
      .then((res) => res.blob())
      .then((blop) => {
        const data = {
          x: size.width * (completedCrop?.x ?? 0) / 100,
          y: size.height * (completedCrop?.y ?? 0) / 100,
          width: size.width * (completedCrop?.width ?? 0) / 100,
          height: size.height * (completedCrop?.height ?? 0) / 100,
        };

        if ((data.height + data.y === 0) || (data.width + data.x === 0)) {
          return;
        }

        const bitmap = createImageBitmap(
          blop,
          data.x,
          data.y,
          data.width,
          data.height,
        )
        return bitmap;
      })
      .then(setBitmapImg);
  }, [completedCrop])

  useEffect(() => {
    const img = new Image();
    img.onload = (e: any) => onImageLoad(e, setCrop, setSize)
    img.src = image;
  }, [image]);

  return <div className={styles['crop-city-cover-dialog']}>
    <div className={styles['crop-city-cover-dialog__header']}>
      <Icon
        className={styles['crop-city-cover-dialog__icon']}
        size='xs'
        icon='crop' />
      <div className={styles['crop-city-cover-dialog__header-right']}>
        <h2 className={styles['crop-city-cover-dialog__title']}>
          {t('cropCover.header')}
        </h2>
        <p className={styles['crop-city-cover-dialog__text']}>
          {t('cropCover.recommend')}
        </p>
      </div>
    </div>
    <div className={styles['crop-city-cover-dialog__image-container']}>
      <ReactCrop
        className={styles['crop-city-cover-dialog__image-crop']}
        crop={crop}
        onChange={(c) => setCrop(c)}
        onComplete={(_, c) => setCompletedCrop(c)}
        aspect={14 / 4}
        locked={true}
      >
        <img className={styles['crop-city-cover-dialog__image-crop-img']} src={image} alt='city cover' />
      </ReactCrop>
    </div>
    <div className={styles['crop-city-cover-dialog__hr']} />
    <div className={styles['crop-city-cover-dialog__buttom']}>
      <Button
        className={styles['crop-city-cover-dialog__button']}
        size='lg'
        disabled={!bitmapImg}
        loading={loading}
        onClick={() => {
          setLoading(true);
          bitmapImg && onSuccsesCrop(bitmapImg)
            .then(() => setLoading(false))
            .finally(() => setLoading(false))
        }}
      >
        {t('cropCover.button.publish')}
      </Button>
    </div>
  </div>
}