import { hideNotification, readAllNotifications } from "@graphql/docs/mutations";
import { getNewNotificationLength, getNotifications } from "@graphql/docs/queries";
import { ModelNotificationConnection, Notification } from "@graphql/graphql";
import { makeAutoObservable } from "mobx";
import { apolloClient } from "src/apollo-client";
import { toastsStore } from "./toasts.store";
import { onNotify } from "@graphql/docs/subscriptions";
import { userStore } from "./user.store";
import { getI18n } from "react-i18next";
import { showNotification } from "@shared/helpers/notification";
import { getNotificationMesssage } from "src/notifications/helpers/get-notification-message";


class NotificationsStore {
  notificationList: Notification[]|null = null;
  notificationsLength: number = 0;
  nextToken?: string|null;
  loading = false;
  isStreamCreated = false;

  constructor() {
    makeAutoObservable(this);
    this.createStream();
  }

  createStream() {
    if (!this.isStreamCreated && userStore.currentUser) {
      this.createOnNotifyStream();
      this.isStreamCreated = true;
    }
  }

  get t() {
    const i18n = getI18n();
    return i18n.isInitialized ? i18n.t.bind(i18n) : () => "";
  }

  private getNotifications = async () => {
    const input = !!this.nextToken
      ? { limit: 15, nextToken: this.nextToken}
      : { limit: 15 };
    const res = await  apolloClient
      .query<{getNotifications: ModelNotificationConnection}>({
        query: getNotifications,
        variables: {
          input
        },
      });

    return res?.data?.getNotifications ?? [];
  }

  loadNotifications = async (clearList = false) => {
    if (clearList) {
      this.nextToken = undefined;
      this.notificationList = null;
    }

    if (this.nextToken !== null) {
      this.loading = true;
      const notificationData = await this.getNotifications();
      this.notificationList = [
        ...(this.notificationList ?? []),
        ...notificationData.items,
      ];
      this.nextToken = notificationData.nextToken;
    };

    this.loading = false;
  };

  readAllNotifications = async () => {
    try {
      await  apolloClient
        .mutate<{readAllNotifications: number}>({
          mutation: readAllNotifications,
        });
    } catch {}
  }

  getNotificationLength = async () => {
    const res = await  apolloClient
      .query<{getNewNotificationLength: number}>({
        query: getNewNotificationLength,
      });
    this.notificationsLength = res.data.getNewNotificationLength ?? 0;
  }

  hideNotification = async (id: string) => {
    this.notificationList = this.notificationList?.map((notification) =>
      notification.id === id
        ? { ...notification, isHide: true }
        : notification,
    ) ?? [];
    try {
      await apolloClient
        .mutate<{hideNotification: number}>({
          mutation: hideNotification,
          variables: { id },
        });
    } catch {
      toastsStore.addErrorToast(this.t('common:toasts.error.hide'));
      this.notificationList = this.notificationList.map((notification) =>
        notification.id === id
          ? { ...notification, isHide: false }
          : notification,
      )
    }
  }

  private createOnNotifyStream = () => {
    try {
      apolloClient.subscribe<{onNotify: Notification}>({ query: onNotify })
        .filter((value) =>
          !!value.data?.onNotify &&
          value.data.onNotify.username === userStore.currentUser?.username
        )
        .map((value) => value.data?.onNotify)
        .subscribe({
          next: (newNotification) => {
            if (!newNotification?.id) {
              return;
            }
            this.notificationList = [
              newNotification,
              ...(this.notificationList ?? [])
            ];
            this.notificationsLength += 1;
  
            showNotification(
              `${newNotification.from.firstname} ${newNotification.from.lastname}`,
              { body: getNotificationMesssage(newNotification.notifyType, this.t) }
            )
              .then((notification) => {
                notification?.addEventListener && notification.addEventListener('click', () => {
                  window.focus();
                  window.location.href = '/notifications/'
              });
            });
          },
          error: (error) => console.error(error),
        });
    } catch {}
  }
}

export const notificationsStore = new NotificationsStore();