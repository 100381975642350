import { useState } from "react";
import styles from "./fragment-window.module.css";
import { Header } from "../header/header.comp";
import { useTranslation } from "react-i18next";

type Props = {
  className?: string;
  children?: any;
  isShow?: boolean;
  onHide?: () => void;
}

export const FragmentWindow = ({ className, children, isShow, onHide }: Props) => {
  const [show, setShow] = useState(isShow);

  const { t } = useTranslation('common');

  className = className ? `${className} ${styles.fragment}` : styles.fragment;

  if (isShow) {
    if (isShow !== show) {
      setShow(true);
    }
  } else if (isShow !== show) {
    setTimeout(() => {
      if (!isShow) {
        setShow(false);
      }
    }, 500);
  }

  return <div className={isShow ? `${className} ${styles.fragment_show}` : className }>
    <Header title={t('createPost.title')} hideAvatar={true} onBackClick={() => onHide && onHide()} />
    <div className={styles.fragment__content}>{children}</div>
  </div>
}