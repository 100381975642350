import { recommends } from "@graphql/docs/queries";
import { apolloClient } from "src/apollo-client";
import { UserPicture, Post, HashTag, RecommendsInput, RecommendsResults } from "@graphql/graphql";

const isPost = (post: Post | null): post is Post => post !== null && post !== undefined;
const isUserPicture = (user: UserPicture | null): user is UserPicture => user !== null && user !== undefined;
const isHashtags = (hashtag: HashTag | null): hashtag is HashTag => hashtag !== null && hashtag !== undefined;

export const dataRecommends = async ({ place_id, nextToken }: RecommendsInput): Promise<RecommendsResults> => {
    const res = await apolloClient.query<{ recommends: RecommendsResults }>({
        query: recommends,
        variables: {
            input: {
                userLimit: 5,
                postLimit: 5,
                hashtagLimit: 5,
                place_id: place_id,
                nextToken: nextToken,
            },
        },
    });

    const recommendsResults: RecommendsResults = {
        __typename: 'RecommendsResults',
        userItems: res.data.recommends?.userItems?.filter(isUserPicture) ?? [],
        postItems: res.data.recommends?.postItems?.filter(isPost) ?? [],
        hashtagItems: res.data.recommends?.hashtagItems?.filter(isHashtags) ?? [],
        nextToken: res.data.recommends.nextToken ?? null,
    };
    return recommendsResults;
};