import styles from "./search-posts.module.css";
import { useState, useEffect } from "react";
import { Loader } from "@ui-kit/components/";
import { Post } from "@graphql/graphql";
import { PostComponent } from "src/main-page/components/post/post.comp";
import { citiesStore, toastsStore } from "@store";
import { dataRecommends } from "../../helpers/recommends";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

type Props = {
  initialToken: string | null,
  posts: Post[],
};

export const SearchPosts = observer(({ initialToken, posts: initialPosts }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [posts, setPosts] = useState<Post[]>(initialPosts);
  const [loadMore, setLoadMore] = useState(false);
  const [nextToken, setNextToken] = useState<string | null>(initialToken);
  const [hasMoreData, setHasMoreData] = useState(true);

  const { currentCity } = citiesStore;
  const { t } = useTranslation('search');

  useEffect(() => {
    const scrollHandler = (e: any) => {
      const position = e.target.scrollTop + window.innerHeight + 10;
      if (position >= e.target.scrollHeight) {
        setLoadMore(true);
      }
    };

    document.body.addEventListener('scroll', scrollHandler, false);
    return () => {
      document.body.removeEventListener('scroll', scrollHandler);
    };
  }, [loadMore]);

  useEffect(() => {
    if (loadMore && posts && nextToken !== null) {
      fetchPosts()
        .then(() => setLoadMore(false))
        .catch(() => toastsStore.addErrorToast(t('toasts.error.posts')));
    } else {
      setLoadMore(false);
    }
  }, [loadMore]);

  const fetchPosts = async () => {
    if (loadMore && nextToken !== null && hasMoreData) {
      setIsLoading(true);
      try {
        const result = await dataRecommends({
          place_id: currentCity?.place_id,
          nextToken: nextToken,
        });

        const newPosts = result.postItems?.filter((item): item is Post => item !== null) ?? [];
        if (newPosts.length === 0 || result.nextToken === null) {
          setLoadMore(false);
          setHasMoreData(false);
        } else {
          setPosts(posts => [...posts, ...newPosts]);
          setNextToken(result.nextToken ?? null);
        }
      } catch (error) {
        console.error('Ошибка:', error);
        toastsStore.addErrorToast(t('toasts.error.posts'));
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    setPosts(initialPosts);
    setIsLoading(true);
    setHasMoreData(true);
  }, [initialPosts]);

  return (
    <section className={styles['search-posts__section']}>
      {posts.length > 0 &&
        <>
          <div className={styles['search-posts__content']}>
            {posts.map((post) => (
              <PostComponent key={post.id} item={post} />
            ))}
          </div>
        </>
      }
      {!!isLoading && <div className={styles.loader}>
        <Loader />
      </div>}
    </section>
  );
});
