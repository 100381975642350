import { GoogleMapsResponse } from "@shared/models";
import { LOCALSTORAGE_KEY_LANG, toastsStore } from "@store";

export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_KEY ?? ''

export const getUserLocation = async () => new Promise<GeolocationCoordinates | null>((res) => {
  if (!!navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position) => {
      res(position.coords);
    }, () => {
      res(null)
    });
  } else {
    res(null)
  }
});

export const getFullCityByCords = async (lat: number, lng: number) => new Promise<any>((res) => {
  let fullCityName = '';

  // Use Google Maps API to reverse geocode the coordinates to get the city name
  if (window.google) {
    const geocoder = new window.google.maps.Geocoder();
    const latLng = new window.google.maps.LatLng(lat, lng);

   // const lang = localStorage.getItem(LOCALSTORAGE_KEY_LANG) ?? navigator.language ?? 'ru';
    const lang = localStorage.getItem('i18nextLng') ?? navigator.language;

    geocoder.geocode({ location: latLng, language: lang }, (results: GoogleMapsResponse[] | null, status: any) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const result = results?.filter((result) =>
          !!result?.types && result.types.includes('locality') && result.types.includes('political')
        )[0];

        if (result) {
          const addressComponents = result.address_components;
          const cityComponent = addressComponents.find((component: any) =>
            component.types.includes('locality')
          );
          const countryComponent = addressComponents.find((component: any) =>
            component.types.includes('country')
          );

          if (cityComponent) {
            fullCityName += cityComponent.long_name;
          }
          if (countryComponent) {
            fullCityName += `, ${countryComponent.long_name}`;
          }

          res({id: result.place_id, fullCityName, longName: cityComponent?.long_name, country: countryComponent?.long_name});
        } else {
          res({});
        }
      } else {
        res({});
      }
    }).catch((err: any) => toastsStore.addErrorToast('Can not use geocoder!'));
  } else {
    res({});
  }
});

export const getUserCity = async (): Promise<any> => {
  const location = await getUserLocation()
  if (!location) {
    return null;
  }

  const { latitude, longitude } = location;
  const city = await getFullCityByCords(latitude, longitude)
  return city;
};


