import styles from "./no-saved-posts.module.css";
import { useTranslation } from "react-i18next";

export const NoSavedPosts = () => {
  const { t } = useTranslation('common');

  return <div className={styles['no-saved-posts']}>
    <div className={styles['no-saved-posts__pictyre']} />
    <h3 className={styles['no-saved-posts__title']}>
     {t('savedPosts.noPosts.title')}
    </h3>
    <p className={styles['no-saved-posts__text']}>
    {t('savedPosts.noPosts.message')}
    </p>
  </div>
}