import { ErrorFallback } from "@shared/components";
import { useRef, useState } from "react";
import { Overlay, Tooltip } from "react-bootstrap";
import { ErrorBoundary } from "react-error-boundary";
import styles from "./post-item.module.css";

type Props = {
  likes: (string)[];
  count: number
};

export const PostItemLikes = ({ likes, count }: Props) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const onOtherClickHandler = () => {
    setShow(true);
    setTimeout(() => {
      window.onclick = () => {
        setShow(false);
        window.onclick = null;
      }
    }, 0)
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className={styles.postLikes}>
        <span className={styles.postLikesNums} ref={target} onClick={onOtherClickHandler}>{count > 0 ? count : ''}</span>
          <Overlay target={target.current} show={show} placement='bottom'>
            {(props) => (
              <Tooltip id='overlay-example' {...props}>
                {likes.map((name) => (<div key={name}>{name}</div>))}
              </Tooltip>
            )}
          </Overlay>
      </div>
    </ErrorBoundary>
  )
}