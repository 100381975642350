import { makeAutoObservable, runInAction } from "mobx";
import { userStore } from "./user.store";
import { apolloClient } from "src/apollo-client";
import { City, CreateCityInput, UpdateCityInput } from "@graphql/graphql";
import { listCities } from "@graphql/docs/queries";
import { createCity, updateCity } from "@graphql/docs/mutations";
import { toastsStore } from "./toasts.store";
import { findClosestCity } from "@shared/helpers/find-closest-city";
import { getI18n } from "react-i18next";

const LOCALSTORAGE_KEY_CITY = 'city';

class CitiesStore {
  cities: (City)[] = [];
  currentCity?: City;
  loading = false;
  localStorageCityId = '';

  constructor() {
    makeAutoObservable(this);
    this.localStorageCityId = localStorage.getItem(LOCALSTORAGE_KEY_CITY) ?? '';
  }

  get t() {
    const i18n = getI18n();
    return i18n.isInitialized ? i18n.t.bind(i18n) : () => "";
  }

  setLocalStorageCity = (cityId?: string | null) => {
    if (!cityId) {
      localStorage.removeItem(LOCALSTORAGE_KEY_CITY);
      return;
    }
    localStorage.setItem(LOCALSTORAGE_KEY_CITY, cityId);
    this.localStorageCityId = localStorage.getItem(LOCALSTORAGE_KEY_CITY) ?? '';
  };

  onChangeCurrentCity = async (city: City) => {
    const user = userStore.currentUser;
    this.setLocalStorageCity(city.place_id);
    this.currentCity = city;

    if (user?.cityId === city.place_id) return;

    if (city.place_id && user) {
      await userStore.selectCityInList(city.place_id);
    }
  };

  fetchCities = async (setCurentCity?: boolean) => {
    runInAction(() => (this.loading = true));
    try {
      const response = await apolloClient.query<{listCities: City}>({
        query: listCities,
      });
      const citiesItems = (response.data.listCities ?? []) as City[];
      runInAction(() => (this.cities = citiesItems));
      if (setCurentCity) {
        const foundedCity =
          citiesItems?.find(
            (foundCity) => foundCity?.place_id === this.localStorageCityId
          ) ?? (!!userStore.currentUser
              ? citiesItems.find((city) => city.place_id === userStore.currentUser?.cityId)
                ?? citiesItems?.at(0)
              : await findClosestCity(citiesItems)
                ?? citiesItems?.at(0));
        runInAction(() => {
          this.currentCity = foundedCity
          this.loading = false;
        });
        return { currentCity: foundedCity, cities: citiesItems };
      }
      return { currentCity: this.currentCity, cities: this.cities}
    } catch (error) {
      runInAction(() => (this.loading = false));
      console.error(error);
    }
  };

  addNewCity = async (input: CreateCityInput): Promise<City|null|undefined> => {
    try {
      const response = await apolloClient.mutate<{createCity: City}>({
        mutation: createCity,
        variables: { input },
      })
      if (response.data?.createCity) {
        runInAction(() => (
          this.cities = this.cities
            ? [...this.cities, (response.data?.createCity as City)]
            : [(response.data?.createCity as City)]
        ));
      }
      return response.data?.createCity;
    } catch {
      toastsStore.addErrorToast(this.t('common:toasts.error.createCity'));
    }
  }

  updateCityData =async (input: UpdateCityInput) => {
    try {
      const response = await apolloClient.mutate<{updateCity: City}>({
        mutation: updateCity,
        variables: { input },
      })

      if (response.errors) {
        toastsStore.addErrorToast(response.errors?.[0].message);
      }

      if (response.data?.updateCity) {
        runInAction(() => (this.cities = this.cities?.map((city) =>
          response.data?.updateCity && city?.place_id === response.data?.updateCity.place_id ? response.data?.updateCity : city
        )));
      }
      return response.data;
    } catch {
      toastsStore.addErrorToast(this.t('common:toasts.error.updateCity'));
    }
  }
}

export const citiesStore = new CitiesStore();
