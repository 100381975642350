import { messagesStore, userStore } from "@store";
import styles from "./chat-list.module.css";
import { ChatItem } from "../chat-item/chat-item.comp";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { InputComponent } from "@ui-kit/components/input/input.comp";
import { NoChats } from "../no-chats/no-chats.comp";
import { Button, Loader } from "@ui-kit";
import { IconButton } from "@ui-kit/components/button/icon-button/icon-button.comp";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Props = {
  selectedChat?: string,
  setSelectedChat: (chatId: string | undefined) => void,
  hideChatOnMobile: boolean,
  onNewMessage: () => void,
}

export const ChatList = observer(({ selectedChat, setSelectedChat, hideChatOnMobile, onNewMessage }: Props) => {
  const { chats, loadingChats } = messagesStore;

  const [search, setSearch] = useState('');
  const navigate = useNavigate();

  const { t } = useTranslation('messenger');

  const checkSelectedChat = async () => {
    const chat = chats.find((chat) => chat.chatId === selectedChat);
    if (!chat && selectedChat !== userStore.currentUser?.username) {
      const chatByUsername = chats.find((chat) => chat.usernames?.includes(selectedChat ?? '-'));
      if (chatByUsername?.chatId) {
        setSelectedChat(chatByUsername.chatId);
      }
    }
  }

  useEffect(() => { checkSelectedChat() }, [selectedChat, chats])

  const className = hideChatOnMobile
    ? `${styles['hide-chat-list']} ${styles['chat-list']}`
    : styles['chat-list'];

  const onBackClick = () => {
    navigate('/');
  };

  return <div className={className}>
    <div className={styles['chat-header']}>
      <div className={styles['header__title-container']}>
        <IconButton
          iconType='arrow-left'
          size='xl'
          onClick={onBackClick} />
        <h2 className={styles['chat-header__title']}>{t('header.main')}</h2>
      </div>
      {/* {!!chats.length && <InputComponent
        className={styles['chat-header__input']}
        type='search'
        text={search}
        placeholder='Поиск по чатам'
        onchange={setSearch} />} */}
      </div>
      {loadingChats
        ? <Loader />
        : <>
          {!!chats.length ?
            <div className={styles['chat-list']}>
              {chats
                .filter((chat) => chat.chatId)
                .filter((chat) => chat.lastMessage)
                .sort((a, b) =>
                  (b.lastMessage?.createdAt ?? 0) - (a.lastMessage?.createdAt ?? 0)
                )
                .map((chat) => (
                  <ChatItem
                    key={chat.chatId}
                    chat={chat}
                    onClick={() => setSelectedChat(chat.chatId ?? undefined)}
                    selected={selectedChat === chat.chatId}
                  />)
                )}
            </div> : <NoChats onNewMessage={onNewMessage} />}
        </>}
        {!loadingChats && !!chats.length
          && <div className={styles['chat-list__new-message-button']}>
            <Button onClick={onNewMessage}>
              {t('button.newMessage')}
            </Button>
          </div>}
  </div>
});
