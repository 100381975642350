import { getUserFollowersUsers, getUserFollowingUsers, getWhoFollowUsers } from "@graphql/docs/queries";
import { UserPicture } from "@graphql/graphql";
import { makeAutoObservable } from "mobx";
import { apolloClient } from "src/apollo-client";
import { toastsStore } from "./toasts.store";
import { userStore } from "./user.store";
import { getI18n } from "react-i18next";

export class FollowersStore {
  followers: UserPicture[] = [];
  following: UserPicture[] = [];
  loading = false;
  // nextToken?: string | null;
  storedUsername?: string;
  storedType?: boolean;

  constructor () {
    makeAutoObservable(this);
  }

  get t() {
    const i18n = getI18n();
    return i18n.isInitialized ? i18n.t.bind(i18n) : () => "";
  }

  getFollowers = async (username: string): Promise<UserPicture[]> => {
    try {
      const { data } = await apolloClient.query<{getUserFollowersUsers: UserPicture[]}>({
        query: getUserFollowersUsers,
        variables: { input: { username }}
      });
      return data.getUserFollowersUsers ?? [];
    } catch {
      toastsStore.addErrorToast(this.t('common:toasts.error.loadFollowing'));
      return [];
    }
  }

  getFollowing = async (username: string): Promise<UserPicture[]> => {
    try {
      const { data } = await apolloClient.query<{getUserFollowingUsers: UserPicture[]}>({
        query: getUserFollowingUsers,
        variables: { input: { username }}
      });
      return data.getUserFollowingUsers ?? [];
    } catch {
      toastsStore.addErrorToast(this.t('common:toasts.error.loadFollowing'));
      return [];
    }
  }

  getWhoFollowUsers =async (username?: string): Promise<UserPicture[]> => {
    if (!username) {
      return [];
    }
    try {
      const { data } = await apolloClient.query<{getWhoFollowUsers: UserPicture[]}>({
        query: getWhoFollowUsers,
        variables: { input: { username }}
      });
      return data.getWhoFollowUsers ?? [];
    } catch {
      toastsStore.addErrorToast(this.t('common:toasts.error.loadFollowing'));
      return [];
    }
  }

  fetchFoolows = async (username: string, isFollowing?: boolean) => {
    isFollowing = !!isFollowing;
    this.setLoading(true);

    if (this.storedUsername !== username || this.storedType !== isFollowing) {
      this.storedUsername = username;
      this.storedType = isFollowing;
      this.followers = [];
      this.following = [];
    }

    try {
      if (isFollowing) {
        this.following = await this.getFollowing(username);
      } else {
        this.followers = await this.getFollowers(username);
      }
    } catch (error) {
      console.error(error);
    }

    this.setLoading(false);
  };

  follow = async (username: string) => {
    try {
      await userStore.follow(username);
      const mapFn = (user: UserPicture) =>
        user.username === username ? ({ ...user, isFollow: true }) : user
      this.followers = this.followers.map(mapFn)
      this.following = this.following.map(mapFn)
      toastsStore.addSuccessToast(this.t('common:toasts.success.follow'));
    } catch {
      toastsStore.addErrorToast(this.t('common:toasts.error.follow'))
    }
  }

  unfollow = async (username: string) => {
    try {
      await userStore.unfollow(username);
      const mapFn = (user: UserPicture) =>
        user.username === username ? ({ ...user, isFollow: false }) : user
      this.followers = this.followers.map(mapFn);
      this.following = this.following.map(mapFn);
      toastsStore.addSuccessToast(this.t('common:toasts.success.unfollow'))
    } catch {
      toastsStore.addErrorToast(this.t('common:toasts.error.unfollow'))
    }
  }

  setLoading = (value: boolean) => {
    this.loading = value;
  }
}

export const followersStore = new FollowersStore();