import { UserPicture } from "@graphql/graphql";
import { UserModalItem } from "@shared/components/user-modal-item/user-modal-item.comp";
import { messagesStore, modalCustomDialogsStore } from "@store";
import { useTranslation } from "react-i18next";

export const useBlockUserFn = (user: UserPicture, setIsBlocked: (val: boolean) => void) => {
  const { t } = useTranslation('messenger');
  const handleBlockClick = () => {
    if (user.isBlocked) {
      modalCustomDialogsStore.showDialog(
        <UserModalItem item={user} />,
        {
          typeIcon: 'warning',
          title: t('dialogs.unblockUser.title'),
          message: t('dialogs.unblockUser.message'),
          secondButtonColor: 'primary',
          secondButtonText: t('dialogs.unblockUser.secondButton'),
          firstButtonColor: 'secondary-color',
          firstButtonText: t('dialogs.unblockUser.firstButton'),
          confirmAction: () => user.username && messagesStore.unblockUser(user.username).then(() => setIsBlocked(false))
        },
      );
    } else {
      modalCustomDialogsStore.showDialog(
        <UserModalItem item={user} />,
        {
          typeIcon: 'warning',
          title: t('dialogs.blockUser.title'),
          message: t('dialogs.blockUser.message'),
          secondButtonColor: 'primary',
          secondButtonDestructive: true,
          secondButtonText: t('dialogs.blockUser.secondButton'),
          firstButtonColor: 'secondary-color',
          firstButtonText: t('dialogs.blockUser.firstButton'),
          confirmAction: () => user.username && messagesStore.blockUser(user.username).then(() => setIsBlocked(true))
        },
      );
    }
  }
  return handleBlockClick;
}