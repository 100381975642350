import { gql } from "@apollo/client";

export const createCommentReaction = gql`mutation createCommentReaction($input: CreateCommentReactionInput) {
    createCommentReaction(input: $input){
        id
        postId
        type
        username
        isHiden
        createdAt
        updatedAt
    }
}`;
