import styles from "./uploading-file.module.css";
import { MediaItemComponent } from "src/main-page/components/post/media-item/media-item.comp";
import DownloadLoader from "../loader/download-loader/download-loader";
import { useEffect, useState } from "react";
import { FilesToUpload } from "src/main-page/components/create-post/create-post.component";
import { Icon } from "@home/components/icon/icon.comp";

type Props = {
  file: FilesToUpload,
  className?: string,
  precent?: number,
  status?: 'loading'|'loaded'|'ready',
  onClose?: () => void
}

export const UploadingFile = ({ file, className, precent, status, onClose }: Props) => {
  className = className ? `${styles['uploading-file']} ${className}` : styles['uploading-file'];
  status = status ?? 'ready';

  const [finished, setFinished] = useState(false);

  useEffect(() => {
    if (precent === undefined || precent === 100) {
      setTimeout(() => {
        setFinished(true);
      }, 2000);
    }
  }, [precent])

  return <div className={className}>
    <div className={
      !finished
        ? styles['uploading-file__loader']
        : `${styles['uploading-file__loader']} ${styles['uploading-file__loader_finished']}`
      }
    >
      <DownloadLoader value={precent ?? 100} loaderSize='sm' loaderType='download' />
    </div>
    {finished && <div className={styles['uploading-file__close']} onClick={onClose}>
      <Icon size='xxxs' icon='x' color='#475467' />
    </div>}
    <MediaItemComponent
      width={108}
      height={150}
      controls={false}
      className={styles['uploading-file__item']}
      mediaFile={{ key: file.url, url: file.url, type: file.type }} />
  </div>
}