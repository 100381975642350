import { ApolloClient, InMemoryCache, HttpLink, split } from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { API_URL_GQL, API_URL_WS } from "@shared/data/api.constants";
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from "@apollo/client/utilities";

const httpLink  = new HttpLink({
  uri: API_URL_GQL,
  headers: {
    'Apollo-Require-Preflight': 'true',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  }
});

export const SESSION_STORAGE_JWT_TOKEN = 'SESSION_STORAGE_JWT_TOKEN';

const wsLink = new WebSocketLink({
  uri: API_URL_WS,
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: () => {
      const token = sessionStorage.getItem(SESSION_STORAGE_JWT_TOKEN);
  
      return {
        'Authorization': token ? `Bearer ${token}` : 'Bearer -',
      };
    },
  }, 
});

const authLink = setContext((_, { headers }) => {
  const lang = localStorage.getItem('i18nextLng') ?? navigator.language;

  return {
    headers: {
      ...headers,
      'Apollo-Require-Preflight': 'true',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      lang,
    },
  }
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  authLink.concat(wsLink),
  authLink.concat(httpLink),
);

export const apolloClient = new ApolloClient({
  link: splitLink,
  connectToDevTools: true,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});
