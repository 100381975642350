export enum LikeTypes {
  Like = 'like',
  Heart = 'heart',
  Love = 'love',
  Joy = 'joy',
  Flushed = 'flushed',
  Sad = 'sad',
  Angry = 'angry',
  Facepalm = 'facepalm',
  Congrats = 'congrats',
  Dislike = 'dislike'
}
