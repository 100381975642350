import { useEffect, useState, useRef } from "react";

export type FormState<T> = {
  [K in keyof T]: any;
};

export const useFormEditState = <T extends Record<string, any>>(
  initialState: T
): {
  formState: FormState<T>;
  setInput: (key: keyof T, value: any) => void;
  setFormState: React.Dispatch<React.SetStateAction<FormState<T>>>;
} => {
  const [formState, setFormState] = useState<FormState<T>>(initialState);
  const initialStateRef = useRef(initialState);

  function setInput(key: keyof T, value: any) {
    setFormState(prev => ({ ...prev, [key]: value }));
  }

  useEffect(() => {
    if (initialStateRef.current !== initialState) {
      initialStateRef.current = initialState;
      setFormState(initialState);
    }
  }, []);

  return {
    formState,
    setInput,
    setFormState,
  };
};
