import { useMemo, useState } from "react";
import {createDate, createMonth, getMonthNames, getWeekDaysNames, getMonthNumberOfDays} from "./index";

interface UseCalendarProps {
  region?: string;
  selectedDate?: Date;
  firstWeekDayNumber?: number;
}

export const useCalendar = ({region = 'default', selectedDate: date}: UseCalendarProps) => {
  const DAYS_IN_WEEK = 7;

  const getYearsInterval = (year: number) => {
    const startYear = Math.floor(year / 10) * 10;
    return [...Array(10)].map((_, index) => startYear + index)};

  const [mode, setMode] = useState<'days' | 'months' | 'years'>('days');

  const [selectedDate, setSelectedDate] = useState(createDate({date}));
  const [selectedMonth, setSelectedMonth] = useState(createMonth({date: new Date(selectedDate.year, selectedDate.monthIndex), region}));
  const [selectedYear, setSelectedYear] = useState(selectedDate.year);
  const [selectedYearsInterval, setSelectedYearsInterval] = useState(getYearsInterval(selectedDate.year));

  const monthsNames = useMemo(() =>getMonthNames(region), []);
  const weekDaysNames = useMemo(() =>getWeekDaysNames(region), []);
  const days = useMemo(() => selectedMonth.createMonthDays(), [selectedMonth, selectedYear]);

  const calendarDays = useMemo(() => {
    const monthNumberOfDays = getMonthNumberOfDays(selectedMonth.monthIndex, selectedYear);

    const prevMonthDays = createMonth({
      date: new Date(selectedYear, selectedMonth.monthIndex - 1),
      region
    }).createMonthDays();

    const nextMonthDays = createMonth({
      date: new Date(selectedYear, selectedMonth.monthIndex + 1),
      region
    }).createMonthDays();

    const firstDay = days[0];
    const lastDay = days[monthNumberOfDays - 1];

    const numberOfPrevDays =
      firstDay.dayNumberInWeek - 2 < 0
        ? DAYS_IN_WEEK - (2 - firstDay.dayNumberInWeek)
        : firstDay.dayNumberInWeek - 2;

    const numberOfNextDays =
      DAYS_IN_WEEK - lastDay.dayNumberInWeek + 1 > 6
        ? DAYS_IN_WEEK - lastDay.dayNumberInWeek - (DAYS_IN_WEEK - 1)
        : DAYS_IN_WEEK - lastDay.dayNumberInWeek + 1;

    const totalCalendarDays = days.length + numberOfPrevDays + numberOfNextDays;

    const result = [];

    for (let i = 0; i < numberOfPrevDays; i += 1) {
      const inverted = numberOfPrevDays - i;
      result[i] = prevMonthDays[prevMonthDays.length - inverted];
    }

    for (let i = numberOfPrevDays; i < totalCalendarDays - numberOfNextDays; i += 1) {
      result[i] = days[i - numberOfPrevDays];
    }

    for (let i = totalCalendarDays - numberOfNextDays; i < totalCalendarDays; i += 1) {
      result[i] = nextMonthDays[i - totalCalendarDays + numberOfNextDays];
    }

    return result;
  }, [selectedMonth.year, selectedMonth.monthIndex, selectedYear]);

  const onclickArrow = (direction: 'left' | 'right') => {
    if (mode === 'years' && direction === 'left') {
      return setSelectedYearsInterval(getYearsInterval(selectedYearsInterval[0] - 10));
    }

    if (mode === 'years' && direction === 'right') {
      return setSelectedYearsInterval(getYearsInterval(selectedYearsInterval[0] + 10));
    }

    if (mode === 'months' && direction === 'left') {
      const year = selectedYear - 1;
      if (!selectedYearsInterval.includes(year)) setSelectedYearsInterval(getYearsInterval(year));
      return setSelectedYear(selectedYear - 1);
    }

    if (mode === 'months' && direction === 'right') {
      const year = selectedYear + 1;
      if (!selectedYearsInterval.includes(year)) setSelectedYearsInterval(getYearsInterval(year));
      return setSelectedYear(selectedYear + 1);
    }

    if (mode === 'days') {
        const monthIndex =
          direction === 'left' ? selectedMonth.monthIndex - 1 : selectedMonth.monthIndex + 1;
        if (monthIndex === -1) {
          const year = selectedYear - 1;
          setSelectedYear(year);
          if (!selectedYearsInterval.includes(year)) setSelectedYearsInterval(getYearsInterval(year));
          return setSelectedMonth(createMonth({ date: new Date(selectedYear - 1, 11), region }));
        }

        if (monthIndex === 12) {
          const year = selectedYear + 1;
          setSelectedYear(year);
          if (!selectedYearsInterval.includes(year)) setSelectedYearsInterval(getYearsInterval(year));
          return setSelectedMonth(createMonth({ date: new Date(year, 0), region }));
        }

        setSelectedMonth(createMonth({ date: new Date(selectedYear, monthIndex), region }));
      }
    }

    const setSelectedMonthIndex = (monthIndex: number) => {
        setSelectedMonth(createMonth({ date: new Date(selectedYear, monthIndex), region }));
      };

  return{
    state: {
      mode,
      calendarDays,
      weekDaysNames,
      monthsNames,
      selectedDate,
      selectedMonth,
      selectedYear,
      selectedYearsInterval
    },
    functions: {
      setMode,
      setSelectedDate,
      setSelectedYear,
      onclickArrow,
      setSelectedYearsInterval,
      setSelectedMonthIndex
    }
  }
}