import { messagesStore } from "@store";
import { createNewChat } from "./create-new-chat";

export const sendNewMessage = async (message: string, chatId: string, username?: string) => {
  if (username) {
    const chat = await createNewChat(username);

    if (chat?.chatId) {
      chatId = chat?.chatId;
    }
  }

  await messagesStore.createMessage({
    chatId,
    text: message,
  })

  return chatId;
}