import { Button } from "@ui-kit";
import styles from "./no-posts.module.css";
import { useTranslation } from "react-i18next";

type Props = {
  className?: string
  selectedTab: 'all'|'follow',
}

export const NoPostsComponent = ({ className, selectedTab }: Props) => {
  className = className ? `${className} ${styles['no-posts']}` : styles['no-posts']

  const { t } = useTranslation('main');

  const title = selectedTab === 'all'
    ? ''
    : t('noPosts.title.noPost')
  const text = selectedTab === 'all'
    ? t('noPosts.title.youFirst')
    : t('noPosts.title.followersNoPosts')

  return <div className={className}>
    {selectedTab === 'all' && <div className={styles['no-posts__picture']}></div>}
    <div className={styles['no-posts__footer']}>
      <h3 className={styles['no-posts__title']}>{title}</h3>
      <div className={styles['no-posts__text']}>{text}</div>
      {/* {selectedTab === 'all' && <Button
        size='md'
        iconType='plus'
        className={styles['no-posts__botton']}
      >
        {t('noPosts.button')}
      </Button>} */}
    </div>
  </div>
}