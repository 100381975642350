import { UserPicture } from "@graphql/graphql"

export type Trend = {
  title: string,
  user: UserPicture,
  date: number,
  views: number,
}

export const mockTrendData: Trend[] = [
  {
    user: {
      username: "olivia",
      firstname: "Olivia",
      lastname: "Rhye",
      cityId: "ChIJWQUt0lpGQ0IRM9RE0uBrC6k",
      picture: {
        key: "public/d372b1b5b44ffef41eed6ceb810ddf8a.jpeg",
        type: "image/jpeg",
        url: "https://ecitty.object.pscloud.io/public/d372b1b5b44ffef41eed6ceb810ddf8a.jpeg"
      }
    },
    date: new Date().getTime() - 1_000 * 60 * 4,
    title: "На электротехническом заводе собрали более 2 тысяч трансформаторов",
    views: 2232,
  },
  {
    user: {
      username: "pbaker",
      firstname: "Phoenix",
      lastname: "Baker",
      cityId: "ChIJCUa1fcSARUIRKJKx3Y0U-Zc",
      picture: {
        key: "public/a4147da7fea569d1940840845897657f.jpeg",
        type: "image/jpeg",
        url: "https://ecitty.object.pscloud.io/public/a4147da7fea569d1940840845897657f.jpeg"
      }
    },
    date: new Date().getTime() - 1_000 * 60 * 60 + 10300,
    title: "В Петропавловске улица Пушкина встала в огромной пробке",
    views: 984,
  },
  {
    user: {
      username: "drewcano",
      firstname: "Drew",
      lastname: "Cano",
      cityId: "ChIJCUa1fcSARUIRKJKx3Y0U-Zc",
      picture: {
        key: 'public/ab287460eef89ff5351e750f87e0634b.jpeg',
        type: 'image/jpeg',
        url: 'https://ecitty.object.pscloud.io/public/ab287460eef89ff5351e750f87e0634b.jpeg'
      }
    },
    date: new Date().getTime() - 1_000 * 60 * 60 * 24,
    title: "В Кокшетау два дроппера из Северо-Казахстанской области забрали у горожан 16 МЛН тенге",
    views: 745,
  },
  {
    user: {
      username: "craignat",
      firstname: "Natali",
      lastname: "Craig",
      cityId: "ChIJCUa1fcSARUIRKJKx3Y0U-Zc",
    },
    date: new Date().getTime() - 1_000 * 60 * 60 * 24 * 7 * 3 + 13233,
    title: "В Петропавловске полицейский спас упавшую с моста женщину",
    views: 354,
  },
  {
    user: {
      username: "pbaker",
      firstname: "Phoenix",
      lastname: "Baker",
      cityId: "ChIJCUa1fcSARUIRKJKx3Y0U-Zc",
      picture: {
        key: "public/a4147da7fea569d1940840845897657f.jpeg",
        type: "image/jpeg",
        url: "https://ecitty.object.pscloud.io/public/a4147da7fea569d1940840845897657f.jpeg"
      }
    },
    date: new Date().getTime() - 1_000 * 60 * 60 * 24 * 14 + 40303,
    title: "На севере Казахстана нашли погибшим мужчину, которого искали почти 3 месяца",
    views: 120,
  },
] 