import { Header } from "@shared/components/header/header.comp";
import styles from "./saved-posts.module.css";
import { Button, Loader } from "@ui-kit";
import { PostComponent } from "src/main-page/components/post/post.comp";
import { useEffect, useState } from "react";
import { modalCustomDialogsStore, toastsStore, userStore, wallPostsStore } from "@store";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { NoSavedPosts } from "./component/no-saved-posts.comp";
import { useTranslation } from "react-i18next";

export const SavedPosts = observer(() => {
  const [loadMore, setLoadMore] = useState<boolean>();

  const { loading, setLoading, fetchSavedPosts, savedPosts, nextToken, removeAllSavedPosts } = wallPostsStore;

  const location = useLocation();

  const { t } = useTranslation('common');

  const onRemoveAllSavedPosts = () => {
    modalCustomDialogsStore.showDialog(undefined, {
      typeIcon: 'delete',
      title: t('dialogs.deleteSavePost.title'),
      message: t('dialogs.deleteSavePost.message'),
      firstButtonText: t('dialogs.deleteSavePost.firstButton'),
      firstButtonColor: 'primary',
      secondButtonText: t('dialogs.deleteSavePost.secondButton'),
      secondButtonColor: 'secondary-color',
      secondButtonDestructive: true,
      confirmAction: removeAllSavedPosts,
    }
    )
  }

  const fetchPosts = () => {
    if (userStore.currentUser?.username) {
      fetchSavedPosts(true)
        .catch(() => toastsStore.addErrorToast(t('toasts.error.loadPost')));
    }
  }

  useEffect(() => {
    setLoading(true);
    if (userStore.currentUser?.username) {
      fetchPosts();
    } else {
      userStore.me().then(() => fetchPosts())
    }
  }, [])

  useEffect(() => {
    const scrollHandler = (e: any) => {
      const position = e.target.scrollTop + window.innerHeight + 10;
      if (position >= e.target.scrollHeight ) {
        setLoadMore(true);
      }
    }

    document.body.addEventListener('scroll', scrollHandler, false);
    return () => {
      document.body.removeEventListener('scroll', scrollHandler);
    };
  }, [location.pathname]);

  useEffect(() => {
    if (loadMore && userStore.currentUser?.username && nextToken) {
      fetchSavedPosts()
        .then(() => setLoadMore(false))
        .catch(() => toastsStore.addErrorToast(t('toasts.error.loadPost')));
    } else {
      setLoadMore(false);
    }
  }, [loadMore]);

  return <div className={styles['saved-posts']}>
    <Header title={t('savedPosts.header')} className={styles['saved-posts__header']} hideAvatar={true}>
      {!!savedPosts?.length && <Button
        className={styles['saved-posts__remove-all-button']}
        onClick={onRemoveAllSavedPosts}
        size='md'
        hierarchy='tertiary-gray'
        iconType='trash-2' />}
    </Header>
    {!!savedPosts?.length && savedPosts?.map((post) => (
      <PostComponent key={post.id} item={post} />
    ))}
    {!!loading && <div className={styles.loader}>
      <Loader />
    </div>}
    {!savedPosts?.length && !loading && <NoSavedPosts />}
  </div>
});
