import { gql } from "@apollo/client";

export const deleteReaction = gql`mutation deleteReaction($id: ID) {
    deleteReaction(id: $id){
        id
        postId
        type
        username
        isHiden
        createdAt
        updatedAt
    }
}`;
