import { Post } from "@graphql/graphql";
import { userStore } from "@store";
import { MenuItem } from "@ui-kit/components/drop-down/modele/menu-item";
import { useTranslation } from "react-i18next";

export enum POST_MENU_KEYS {
  FOLLOW = 'follow',
  UNFOLLOW = 'unfollow',
  HIDE = 'hide',
  LINK = 'link',
  DELETE = 'delete',
  REPORT = 'report',
  SAVE = 'save',
  REMOVE = 'remove',
  EDIT = 'edit'
};

export const usePostMenu = () => {
  const { t } = useTranslation('main');

  const getPostMenu = (post: Post, username?: string): MenuItem[] => {
  let menuItems: MenuItem[] = []

  if (username && (
    username === post.username
    || userStore.currentUser?.roles?.find((role) => role?.name === 'admin'))
    ) {
    menuItems.push({
      key: POST_MENU_KEYS.DELETE,
      type: 'menu-item',
      grayIcon: 'trash-2',
      title: t('postMenu.delete'),
    });
  }

  if (username && (username === post.username)) {
    menuItems.push({
      key: POST_MENU_KEYS.EDIT,
      type: 'menu-item',
      grayIcon: 'edit-3',
      title: t('postMenu.edit'),
    });
  }

  if (username && username !== post.username && !post.follow) {
    menuItems.push({
      key: POST_MENU_KEYS.FOLLOW,
      type: 'menu-item',
      grayIcon: 'user-check',
      title: t('postMenu.follow'),
    });
  }

  if (username && username !== post.username && post.follow) {
    menuItems.push({
      key: POST_MENU_KEYS.UNFOLLOW,
      type: 'menu-item',
      grayIcon: 'user-x',
      title: t('postMenu.unfollow'),
    });
  }

  if (username) {
    menuItems.push({
      key: POST_MENU_KEYS.HIDE,
      type: 'menu-item',
      grayIcon: 'x',
      title: t('postMenu.hide'),
    });
  }

  if (username) {
    menuItems.push({
      key: POST_MENU_KEYS.LINK,
      type: 'menu-item',
      grayIcon: 'link',
      title: t('postMenu.link'),
    });
  }

  menuItems.push({
    key: POST_MENU_KEYS.REPORT,
    type: 'menu-item',
    grayIcon: 'alert-circle',
    title: t('postMenu.report'),
  });

  if (username && !post.isSaved) {
    menuItems.push({
      key: POST_MENU_KEYS.SAVE,
      type: 'menu-item',
      grayIcon: 'bookmark',
      title: t('postMenu.save'),
    });
  }

  if (username && post.isSaved) {
    menuItems.push({
      key: POST_MENU_KEYS.REMOVE,
      type: 'menu-item',
      grayIcon: 'x-square',
      title: t('postMenu.remove'),
    });
  }

  return menuItems;
}

return getPostMenu;
}