import styles from "./message-list.module.css";
import { MessageListHeader } from "src/messenger/components/message-list-header/message-list-header.comp";
import { messagesStore, userStore } from "@store";
import { MessageListContainer } from "../message-list-container/message-list-container.comp";
import { CreateMessage } from "src/messenger/components/create-message/create-message.comp";
import { Loader } from "@ui-kit";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { UserPicture } from "@graphql/graphql";
import { BlockedChat } from "../blocked-chat/blocked-chat.comp";

type Props = {
  selectedChat: string,
  setUserInfo: React.Dispatch<React.SetStateAction<UserPicture | undefined>>,
  onBackClick: () => void,
}

export const MessageList = observer(({ selectedChat: _selectedChat, setUserInfo, onBackClick }: Props) => {
  const [selectedChat, setSelectedChat] = useState(_selectedChat);
  const [userPicture, setUserPicture] = useState(
    messagesStore.chats
      .find((chat) => chat.chatId === selectedChat)
      ?.userPicture
      ?.find((user) => user?.username && user?.username !== userStore.currentUser?.username)
  );

  const getUserPicture = async () => {
    const chat = messagesStore.chats.find((chat) => chat.chatId === selectedChat);
    const chatByUser = messagesStore.chats.find((chat) => chat.usernames?.includes(selectedChat));

    if (chat) {
      setUserPicture(
        messagesStore.chats
          .find((chat) => chat.chatId === selectedChat)
          ?.userPicture
          ?.find((user) => user?.username && user?.username !== userStore.currentUser?.username)
      )
    } else if (chatByUser) {
      setSelectedChat(chatByUser.chatId ?? '');
      setUserPicture(
        messagesStore.chats
          .find((chat) => chat.chatId === chatByUser.chatId)
          ?.userPicture
          ?.find((user) => user?.username && user?.username !== userStore.currentUser?.username)
      )
    } else if (!chat && !chatByUser) {
      const user = await userStore.getUserData(selectedChat);
      setUserPicture(user ?? undefined);
    }
  }

  useEffect(() => { getUserPicture(); }, [messagesStore.chats, selectedChat]);
  useEffect(() => { setSelectedChat(_selectedChat); }, [_selectedChat]);

  return <div className={styles['message-list']}>
    {messagesStore.loadingMessages
      ? <div className={styles['message-list__loader']}>
          <Loader />
        </div>
      : <>
        <MessageListHeader userPicture={userPicture} setUserInfo={setUserInfo} onBackClick={onBackClick} />
        <MessageListContainer chatId={selectedChat} />
        {!userPicture?.blockedYou
          ? <CreateMessage chatId={selectedChat} />
          : <BlockedChat />}
      </>}
  </div>
});
