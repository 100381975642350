import { UserPicture } from "@graphql/graphql";
import styles from "./follow.module.css";
import { Avatar, Button } from "@ui-kit";
import { followersStore, userStore } from "@store";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  user: UserPicture,
  onClick: () => void,
}

export const FollowComponent = ({ user, onClick }: Props) => {
  const [isFollow, setIsFollow] = useState(!!user.isFollow);

  const { t } = useTranslation('common');

  const onFollow = () => {
    if (typeof user.username === 'string') {
      followersStore.follow(user.username)
        .then(() => setIsFollow(true));
    }
  }

  const onUnfollow = () => {
    if (typeof user.username === 'string') {
      followersStore.unfollow(user.username)
        .then(() => setIsFollow(false));
    }
  }

  const onComponentClick = (e: any) => {
    if(!e.target.onclick) {
      onClick();
    }
  }

  return <div onClick={onComponentClick}>
    <div className={styles['follow-component']}>
      <Avatar
        size='lg'
        className={styles['follow-component__avatar']}
        alt={user.username ?? ''}
        url={user.picture?.url}
        statusIcon={user.isOnline ? 'online' : undefined}
      />
      <div className={styles['follow-component__main']}>
        <h2 className={styles['follow-component__fullname']}>
          {user.firstname ?? ''} {user.lastname ?? ''}
        </h2>
        <p className={styles['follow-component__city']}>{user.city?.longName}</p>
      </div>
      {userStore.currentUser?.username !== user.username && (!!isFollow
        ? <Button size='md' onClick={onUnfollow} hierarchy='secondary-color'>{t('followPage.button.unfollow')}</Button>
        : <Button size='md' onClick={onFollow}>{t('followPage.button.follow')}</Button>)}
    </div>
  </div>
}