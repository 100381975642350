import styles from "./share-dialog.module.css";
import { toastsStore } from "@store";
import { Button, InputComponent } from "@ui-kit";
import { ShareButton } from "@ui-kit/components/button/share-button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Props = {
  url: string
}

export const ShareDialog = ({ url }: Props) => {
  const { t } = useTranslation('common');

  const copy = async () => {
    try {
      await navigator.clipboard.writeText(url);
      toastsStore.addCopyToast(t('toasts.success.copyLink'));
    } catch (e) {
      toastsStore.addErrorToast(t('toasts.error.copyLink'));
    }
  }

  const facebookShare = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(url)}`, '_blank');
  }
  const telegramShare = () => {
    window.open(`https://t.me/share/url?text=Посмотри, что я нашел в Ecitty&url=${encodeURI(url)}`, "_blank");
  }
  const twitterShare = () => {
    window.open(`http://twitter.com/share?url=${encodeURI(url)}&text=Ecitty`, '_blank');
  }
  const instaShare = () => {
    // window.open(`https://t.me/share/url?url=${encodeURI(url)}&text=Посмотри, что я нашел в Ecitty`, "_blank");
  }

  return <div className={styles['share-dialog']}>
    <div className={styles['share-dialog__input-container']}>
      <InputComponent
        className={styles['share-dialog__input']}
        text={url}
        onchange={() => {}}
        type='text' />
      <Button
        className={styles['share-dialog__copy-btn']}
        hierarchy='tertiary-gray'
        iconType='copy'
        onClick={copy} />
    </div>
    <div className={styles['share-dialog__buttons']}>
      <div className={styles['share-dialog__buttons-line']}>
        <ShareButton supportingText={true} type='facebook' onClick={facebookShare} />
        <ShareButton supportingText={true} type='telegram' onClick={telegramShare} />
      </div>
      <div className={styles['share-dialog__buttons-line']}>
        <ShareButton supportingText={true} type='twitter' onClick={twitterShare} />
        <ShareButton supportingText={true} type='instagram' onClick={instaShare} />
      </div>
    </div>
  </div>
}