import { AddCityAction, AddCityState } from "@add-city/add-city.reducer";
import { LOCALSTORAGE_CITI_ID_KEY } from "@home/home-page";
import { citiesStore, toastsStore, modalCustomDialogsStore } from "@store";
import { NavigateFunction } from "react-router-dom";
import { addCityToAws } from "./add-city-to-aws";
import { City } from "@graphql/graphql";
import { userStore } from "src/store/user.store";
import i18n from 'i18next';

const goToMyGeoNewCity = async (
  existingCity: City | null | undefined,
  state: AddCityState,
  navigate: NavigateFunction,
) => {
  if (!userStore.currentUser) {
    return;
  }
  if (!existingCity) {
    const resCity = await addCityToAws(state);
    if (resCity?.data && resCity?.data.createCity) {
      const selectCity = resCity?.data.createCity;
      selectCity.__typename = 'City';
      citiesStore.onChangeCurrentCity(resCity?.data.createCity);
      existingCity = resCity.data.createCity;
    }
  }
  await userStore.updateUserData({
    cityId: existingCity?.place_id ?? '',
  });
  await userStore.me()
  navigate('/');
}

export const addNewCity = async (
  state: AddCityState,
  dispatch :React.Dispatch<AddCityAction>,
  navigate: NavigateFunction,
  addToProfile: boolean | undefined
) => {
  try {
    let existingCity = citiesStore.cities?.find((item: City) => item?.name === state.city);

    if (state.goToMyGeo) {
      await goToMyGeoNewCity(existingCity, state, navigate);
      return;
    }

    dispatch({ type: 'startLoading' });

    if (existingCity && !state.goToMyGeo) {
      citiesStore.onChangeCurrentCity(existingCity);

      dispatch({ type: 'stopLoading' });
      if (addToProfile) {
        navigate('/profile', { state: { cityId: existingCity.place_id } });
      } else {
        navigate('/');
      }
    } else {
      if (!userStore.currentUser) {
        toastsStore.addErrorToast(i18n.t('common: toasts.error.createCity'));
        navigate('/');
        return;
      }
      modalCustomDialogsStore.showDialog('', {
        typeIcon: 'info',
        title: i18n.t('common: youDiscovered.title'),
        message: i18n.t('common: youDiscovered.message'),
        firstButtonText: i18n.t('common: youDiscovered.buttonFirst'),
        firstButtonColor: 'primary',
        secondButtonText: '',
        secondButtonColor: 'primary',
        confirmAction: async () => addCityToBackend(
          state,
          dispatch,
          navigate,
          addToProfile,
        )
      })
    }

  } catch (err) {
    toastsStore.addErrorToast(i18n.t('common: toasts.error.createCity'));
    console.error(err)
  }
};

async function addCityToBackend(
  state: AddCityState,
  dispatch :React.Dispatch<AddCityAction>,
  navigate: NavigateFunction,
  addToProfile: boolean | undefined,
) {
  const resCity = await addCityToAws(state);
  if (resCity?.data && resCity?.data.createCity) {
    const selectCity = resCity?.data.createCity;
    selectCity.__typename = 'City';
    citiesStore.onChangeCurrentCity(resCity?.data.createCity);
    if (state.goToMyGeo && resCity?.data.createCity?.place_id) {
      localStorage.setItem(LOCALSTORAGE_CITI_ID_KEY, resCity.data.createCity.place_id);
    }
  }

  dispatch({ type: 'stopLoading' });
  if (addToProfile) {
    navigate('/profile', { state: { cityId: resCity.data?.createCity?.place_id } });
  } else {
    navigate('/');
  }
}