import { useEffect, useState } from "react";
import { useForm, SubmitHandler} from "react-hook-form";
import styles from "./persolal.module.css";
import { userStore, citiesStore, toastsStore, LOCALSTORAGE_KEY_LANG } from "@store";
import { observer } from "mobx-react-lite";
import { Button, InputFormComponent, Loader, Avatar} from "@ui-kit/components/";
import { useValidationRules } from "@ui-kit/components/input/helpers/useValidationRules";
import { Header } from "@shared/components";
import { User } from "src/profile-page/profile.page";
import { Icon } from "@home/components/icon/icon.comp";
import { MediaFileInput } from "@graphql/graphql";
import { MAX_FILE_SIZE } from "@shared/data";
import { uploadFile } from "@shared/uploader/upload-file";
import { useHandleConfirmAccount  } from "./helpers/useHandleConfirmAccount";
import { Calendar } from "@ui-kit/components/calendar/calendar.comp";
import { convertDateToSec} from "./helpers/convertDateToSec";
import { convertSecToDate } from "./helpers/convertSecToDate";
import {convertDateFromCalendar} from "./helpers/convertDateFromCalendar";
import { useTranslation } from "react-i18next";

type Props = {
  onBack: () => void;
};

export const PersonalSettings = observer(({onBack}: Props) => {
  const { currentUser } = userStore;
  const { cities, fetchCities } = citiesStore;
  const { handlePhoneChange, handleEmailChange, isConfirmEmail, isConfirmPhone, setIsConfirmPhoneInitial, setIsConfirmEmailInitial } = useHandleConfirmAccount();

  const validationRules = useValidationRules();

  const { t } = useTranslation('settings');

  let region = currentUser?.appLang ?? navigator.language;

  const [formData, setFormData] = useState<User | null>(null);
  const [isSetting, setIsSetting] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaved, setIsSaved] = useState(false);
  const [picture, setPicture] = useState<MediaFileInput>();
  const [citiesLoaded, setCitiesLoaded] = useState(false);

  const [isTabletMobile, setIsTabletMobile] = useState(window.innerWidth <= 1079 ? true : false );
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [selectedDate, selectDate] = useState(new Date());

  const getCityNameById = (cityId: string) => {
    const city = cities.find(city => city.place_id === cityId);
    return city?.name;
  };

  const defaultValues: User = {
    firstname: currentUser?.firstname ?? '',
    lastname: currentUser?.lastname ?? '',
    username: currentUser?.username ?? '',
    city: getCityNameById(currentUser?.cityId ?? '') ?? 'Loading...',
    phone: currentUser?.phone ?? '',
    email: currentUser?.email ?? '',
    gender: currentUser?.gender ?? '',
    dateOfBirth: convertSecToDate(String(currentUser?.dateOfBirth)) ?? '',
  };

  const {register, handleSubmit, control, watch, setValue, getValues, formState:{errors, isValid} } = useForm<User>({mode: 'all', defaultValues});

  const watchedFields = watch();
  const isChanged = JSON.stringify(watchedFields) !== JSON.stringify(defaultValues);

  const formsPrevent = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const formSubmitHandler: SubmitHandler<User> = (data: User) => {
    if (data.dateOfBirth) {
      data.dateOfBirth = convertDateToSec(data.dateOfBirth).toString();
    }
    setFormData(data);
  };
  useEffect(() => {
    if (formData) {
      saveUserData(formData);
    }
  }, [formData, setValue]);

  const saveUserData = async (data: User) => {
    const { firstname, lastname, dateOfBirth, gender } = data;
    setIsLoading(true);
    try {
      await userStore.updateUserData({
        firstname,
        lastname,
        dateOfBirth,
        gender
      });

      await userStore.me();

      setValue('firstname', data.firstname);
      setValue('lastname', data.lastname);
      setValue('gender', data.gender);
      setValue('dateOfBirth', convertSecToDate(String(data.dateOfBirth)));
      setIsSaved(true);
    } catch (err) {
      console.error(err);
      toastsStore.addErrorToast(t('toasts.error.save'));
    } finally {
        setIsLoading(false);
        toastsStore.addSuccessToast(t('toasts.success.save'));
    }
  };


  useEffect(() => {
    if (watchedFields.phone === currentUser?.phone) {
      setIsConfirmPhoneInitial(true);
    } else {
      setIsConfirmPhoneInitial(false);
    }

    if (watchedFields.email === currentUser?.email) {
      setIsConfirmEmailInitial(true);
    } else {
      setIsConfirmEmailInitial(false);
    }

  }, [setIsConfirmPhoneInitial, setIsConfirmEmailInitial]);

  const handleDateCalendar = (date: Date) => {
    const formattedDate = convertDateFromCalendar(date);
    setValue('dateOfBirth', formattedDate);
    setIsCalendarVisible(false);
  };

  const handleDateInput = () => {
    setIsCalendarVisible(prevState => !prevState)
  };

  const handleContactsChange =  () => {
    const newPhone = getValues('phone');
    const newEmail = getValues('email');

    if (newPhone && newPhone !== currentUser?.phone) {
      handlePhoneChange(newPhone)
    }
    else if (newEmail && newEmail !== currentUser?.email) {
      handleEmailChange(newEmail)
    }
  };

  useEffect(() => {
    if (!citiesLoaded) {
      setIsLoading(true);
      Promise.all([
        userStore.me(),
        fetchCities(),
      ]);
    }
  }, []);

  useEffect(() => {
    if(!!cities.length && !!currentUser) {
      setValue('firstname', currentUser?.firstname ?? '');
      setValue('lastname', currentUser?.lastname ?? '');
      setValue('username', currentUser?.username ?? '');
      setValue('city', getCityNameById(currentUser?.cityId ?? '') ?? 'Loading...');
      setValue('phone', currentUser?.phone ?? '');
      setValue('gender', currentUser?.gender ?? '');
      setValue('email', currentUser?.email ?? '');
      setValue('dateOfBirth', convertSecToDate(String(currentUser?.dateOfBirth)) ?? '');
      setIsLoading(false);
    }
  }, [cities])

  useEffect(() => {
    if (currentUser?.phone!=='') {
      setIsConfirmPhoneInitial(true);
    }
    if (currentUser?.email!==''){
      setIsConfirmEmailInitial(true);
    }
  }, [currentUser]);


  const handleFileChange = async (event: any) => {
    if (event.target.files) {
      const inputFile = event.target.files[0];
    if (!inputFile) return;

    if (inputFile.size > MAX_FILE_SIZE) {
      toastsStore.addErrorToast(t('toasts.error.file'));
      return;
    }

    const newPicture = {
      url: URL.createObjectURL(inputFile),
      type: inputFile.type,
    };

    setPicture(newPicture);

    const formData = new FormData();
    formData.append('avatar', inputFile);

    setIsLoading(true);

    try {
      const fileName = `${currentUser?.username}_profile_pic_${Date.now()}`;
      const uploadedMediaFile = await uploadFile(fileName, inputFile);

      if (uploadedMediaFile && uploadedMediaFile.url) {
        await userStore.updateUserData({
          picture: {
            url: uploadedMediaFile.url,
            type: uploadedMediaFile.type,
            key: fileName
          }
        });
        await userStore.me();
      } else {
        throw new Error('Failed to upload file');
      }
    } catch (error) {
      console.error(error);
      toastsStore.addErrorToast(t('toasts.error.avatar'));
    } finally {
      setIsLoading(false);
      toastsStore.addSuccessToast(t('toasts.success.save'));
    }
  }
}

if (isLoading) {
    return (<div className={styles['loading']}>
              <Loader loaderType='line' />
          </div>
)}

  return (
<>
  <Header
    className={styles['header-settigs']}
    title={t('personalInfo.header')}
    hideAvatar={true}
    hideBackArrow={window.innerWidth > 1079}
    onBackClick={onBack}
  />
  <section className={styles['profile-personal']}>
    <form className={styles['profile-avatar-block']}>
      <Avatar size='3xl' url={currentUser?.picture?.url ?? undefined} />
      <div className={styles['upload-container']}>
        <label htmlFor='upload-avatar-media' className={styles['avatar-file-upload']}>
          <Icon icon='upload' size='xxs'/>{t('personalInfo.downloadFoto')}
        </label>
        <input
          id='upload-avatar-media'
          className={styles.input}
          type='file'
          multiple={true}
          onChange={handleFileChange}
        />
      </div>
    </form>
    <form onSubmit={formsPrevent}>
      <div className={styles['username-first-block']}>
        <InputFormComponent
          isSaved={isSaved}
          label={t('personalInfo.nameInput.name')}
          control={control}
          type='text'
          placeholder={t('personalInfo.nameInput.placeholder')}
          hasErrors={!!errors.firstname}
          {...register('firstname', validationRules.firstname)}
          errorsMess={errors.firstname && errors.firstname.message}
        />
        <InputFormComponent
          isSaved={isSaved}
          label={t('personalInfo.lastNameInput.lastName')}
          control={control}
          type='text'
          placeholder={t('personalInfo.lastNameInput.placeholder')}
          hasErrors={!!errors.lastname}
          {...register('lastname', validationRules.lastname)}
          errorsMess={errors.lastname && errors.lastname.message}
        />
      </div>
      <div className={styles['username-second-block']}>
        <InputFormComponent
          disabled={true}
          label={t('personalInfo.city')}
          control={control}
          type='text'
          {...register('city')}
        />
        <InputFormComponent
          label={t('personalInfo.gender')}
          control={control}
          type='gender'
          {...register('gender')}
        />
        <InputFormComponent
          disabled={true}
          label={t('personalInfo.userNameInput.userName')}
          control={control}
          type='text'
          placeholder={t('personalInfo.userNameInput.placeholder')}
          hasErrors={!!errors.username}
          {...register('username', validationRules.username)}
          errorsMess={errors.username && errors.username.message}
          tips={'Это индивидуальное имя пользователя Пример: ecitty'}
        />
        <div className={styles['date-input-container']}>
        <InputFormComponent
          label={t('personalInfo.dateOfBirthInput.dateOfBirth')}
          control={control}
          isDate={true}
          type='text'
          placeholder={t('personalInfo.dateOfBirthInput.placeholder')}
          onConfirm={handleDateInput}
          hasErrors={!!errors.dateOfBirth}
          {...register('dateOfBirth', validationRules.dateOfBirth)}
          errorsMess={errors.dateOfBirth && errors.dateOfBirth.message}
        />
        {isCalendarVisible && (<div className={styles['profile-calendar']}>
          <Calendar region={region} selectDate={handleDateCalendar} selectedDate={selectedDate} />
          </div>
        )}
        </div>
      </div>
      <InputFormComponent
        isSaved={isConfirmPhone}
        isSetting={isSetting}
        onConfirm={handleContactsChange}
        label={t('personalInfo.phoneInput.phone')}
        control={control}
        type='phone'
        placeholder={t('personalInfo.phoneInput.placeholder')}
        hasErrors={!!errors.phone}
        {...register('phone', validationRules.phone)}
        errorsMess={errors.phone && errors.phone.message}
      />
      <InputFormComponent
        isSaved={isConfirmEmail}
        isSetting={isSetting}
        onConfirm={handleContactsChange}
        label={t('personalInfo.emailInput.email')}
        control={control}
        type='text'
        placeholder={t('personalInfo.emailInput.placeholder')}
        supportingText={t('personalInfo.emailInput.supportingText')}
        hasErrors={!!errors.email}
        {...register('email', validationRules.email)}
        errorsMess={errors.email && errors.email.message}
      />
    </form>
    <Button
      disabled={!isValid || !isChanged}
      type='submit'
      onClick={handleSubmit(formSubmitHandler)}
      iconType='save'
    >
      {t('personalInfo.button.save')}
    </Button>
  </section>
</>
);
});
