import { gql } from "@apollo/client";

export const createReaction = gql`mutation createReaction($input: CreateReactionInput) {
    createReaction(input: $input){
        id
        postId
        type
        username
        isHiden
        createdAt
        updatedAt
    }
}`;
