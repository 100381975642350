import moment from "moment";

export class DateUtil {
  static getFormattedDate = (date: string) => {
    return moment(new Date(date)).format('D MMM HH:mm').toString();
  };

  static getTimeDiff(dateStr: string): string {
    const now = moment();
    const date = moment(dateStr);
    const diff = moment.duration(now.diff(date));

    if (diff.asSeconds() < 60) {
      if (Math.floor(diff.asSeconds()) === 1) {
        return '1 s';
      } else {
        return `${Math.floor(diff.asSeconds())} s`;
      }
    } else if (diff.asMinutes() < 60) {
      if (Math.floor(diff.asMinutes()) === 1) {
        return '1 m';
      } else {
        return `${Math.floor(diff.asMinutes())} m`;
      }
    } else if (diff.asHours() < 24) {
      if (Math.floor(diff.asHours()) === 1) {
        return '1 h';
      } else {
        return `${Math.floor(diff.asHours())} h`;
      }
    } else {
      return moment(new Date(dateStr)).format('D MMM').toString();
    }
  }
}
