import styles from "./no-selected-chat.module.css";
import pointer from "../../../assets/icons/pointer.svg";
import { Button } from "@ui-kit";
import { useTranslation } from "react-i18next";

type Props = {
  onNewMessage?: () => void,
}

export const NoSelectedChat = ({ onNewMessage }: Props) => {
  const { t } = useTranslation('messenger');
  return <div className={styles['no-selected-chat']}>
    <img className={styles['no-selected-chat__image']} src={pointer} alt='pointer' />
    <h3 className={styles['no-selected-chat__title']}>
    {t('chat.noSelected.title')}
    </h3>
    <p className={styles['no-selected-chat__text']}>
      {t('chat.noSelected.message')}
    </p>
    <Button size='lg' hierarchy='primary' onClick={() => onNewMessage && onNewMessage()}>
    {t('button.writeMessage')}
    </Button>
  </div>
}