import { useState, useEffect } from "react";
import styles from "./confirm-page.module.css";
import { userStore } from "src/store/user.store";
import { useNavigate } from "react-router-dom";
import { AuthWindow, Button, InputFormComponent } from "@ui-kit";
import { ConfirmComponent } from "./components/confirm.comp";
import { toastsStore } from "@store";
import { v4 as uuidv4 } from "uuid";
import { useValidationRules } from "@ui-kit/components/input/helpers/useValidationRules";
import { useForm, SubmitHandler } from "react-hook-form";
import { ConfirmPageSmsWhats } from "./components/confirm-page.sms.whats";
import { useTranslation } from "react-i18next";

type Props = {
    phone: string;
    password?: string;
    passwordRepeat?: string;
};

enum RecoverPageState {
    pageConfirmPhone,
    pageSmsWhats,
    pageConfirmCode,
    pageEnterPassword
}

export const ConfirmPageRecover = () => {
    const [typeConfirmWhatsApp, setTypeConfirmWhatsApp] = useState(true);
    const [code, setCode] = useState('');
    const [isSendedText, setIsSendedText] = useState(false);
    const [formData, setFormData] = useState<Props | null>(null);
    const [pageState, setPageState] = useState<RecoverPageState>(RecoverPageState.pageConfirmPhone);

    const { register, handleSubmit, control, formState: { errors, isValid } } = useForm<Props>({ mode: 'onBlur' });

    const validationRules = useValidationRules();
    const { t } = useTranslation('authorization');

    const navigate = useNavigate();

    const formSubmitHandler: SubmitHandler<Props> = (data: Props) => {
        setFormData(data);
    };

    useEffect(() => {
        if (pageState === RecoverPageState.pageConfirmPhone && formData) {
            setPageState(RecoverPageState.pageSmsWhats);
        } else if (pageState === RecoverPageState.pageEnterPassword && formData) {
            recover();
        }
    }, [formData]);

    const forgotPassword = (data: Props) => {
        const { phone } = data;
        userStore.forgotUserPassword({ phone, whatsapp: typeConfirmWhatsApp }).then((success) => {
            if (success) {
                // Действия после успешного запроса
            }
        });
    };

    const sendConfirm = (isWhatsapp: boolean) => {
        if (formData) {
            forgotPassword(formData);
        }
        setTypeConfirmWhatsApp(isWhatsapp);
        setPageState(RecoverPageState.pageConfirmCode);
    };

    const isCodeComplete = code.length === 4;

    const confirm = () => {
        userStore.changeUserPassword({
            code,
            password: uuidv4(),
            phone: userStore.phone ?? '',
        }).then((confirm) => {
            if (confirm !== null) {
                // Действия после успешного подтверждения
            }
        });
        setPageState(RecoverPageState.pageEnterPassword);
    };

    const handleLinkTimer = () => {
        setIsSendedText(false);
    };

    const handleTimeout = () => {
        if (formData) {
            forgotPassword(formData);
        }
    };

    const handlebackwardButton = () => {
        setPageState(RecoverPageState.pageSmsWhats);
    };

    const recover = () => {
        if (formData) {
            const { phone, password, passwordRepeat } = formData;
            if (!phone || !password || !passwordRepeat) {
                return;
            }
            if (password !== passwordRepeat) {
                toastsStore.addErrorToast(t('toasts.error.password'));
                return;
            }
            userStore.changeUserPassword({ password, code, phone }).then((success) => {
                if (success) {
                    navigate('/');
                }
            });
        }
    };

    return (
        <>
            {pageState === RecoverPageState.pageConfirmPhone && (
                <AuthWindow pageType='confirm-phone-recover'>
                    <h2 className={styles.title}>{t('recover.title')}</h2>
                    <h3 className={styles.subtitle}>
                    {t('recover.subtitleFirst')}
                    </h3>
                    <div className={styles.inputBlock}>
                        <form className={styles['form-recover']} onSubmit={handleSubmit(formSubmitHandler)}>
                            <InputFormComponent
                                label={t('input.phone.label')}
                                type='phone'
                                control={control}
                                placeholder={t('input.phone.placeholder')}
                                hasErrors={!!errors.phone}
                                {...register('phone', validationRules.phone)}
                                errorsMess={errors.phone && errors.phone.message}
                            />
                            <Button type='submit' disabled={!isValid}>{t('recover.button.continue')}</Button>
                        </form>
                    </div>
                </AuthWindow>
            )}
            {pageState === RecoverPageState.pageSmsWhats && (
                <AuthWindow pageType='confirm-phone-recover'>
                    <ConfirmPageSmsWhats sendConfirm={sendConfirm} />
                </AuthWindow>
            )}
            {pageState === RecoverPageState.pageConfirmCode && (
                <AuthWindow pageType='confirm-phone-recover'>
                    <ConfirmComponent
                        onSendedTextChange={setIsSendedText}
                        typeConfirmWhatsApp={typeConfirmWhatsApp}
                        setCode={setCode}
                        confirm={confirm}
                        code={code}
                        handlebackwardButton={handlebackwardButton}
                        isCodeComplete={isCodeComplete}
                        handleLinkTimer={handleLinkTimer}
                        handleTimeout={handleTimeout}
                    />
                </AuthWindow>
            )}
            {pageState === RecoverPageState.pageEnterPassword && (
                <AuthWindow pageType='confirm-pass-recover'>
                    <h2 className={styles.title}>{t('recover.title')}</h2>
                    <h3 className={styles.subtitle}>
                    {t('recover.subtitleSecond')}
                    </h3>
                    <form className={styles['form-recover']} onSubmit={handleSubmit(formSubmitHandler)}>
                        <div className={styles.inputBlock}>
                            <InputFormComponent
                                label={t('input.password.label')}
                                type='password'
                                control={control}
                                placeholder={t('input.password.placeholder')}
                                supportingText={t('input.password.supportText')}
                                hasErrors={!!errors.password}
                                {...register('password', validationRules.password)}
                                errorsMess={errors.password && errors.password.message}
                            />
                            <InputFormComponent
                                label={t('input.repeatPassword.label')}
                                type='password'
                                control={control}
                                placeholder={t('input.repeatPassword.placeholder')}
                                hasErrors={!!errors.passwordRepeat}
                                {...register('passwordRepeat', validationRules.passwordRepeat)}
                                errorsMess={errors.passwordRepeat && errors.passwordRepeat.message}
                            />
                        </div>
                        <Button disabled={!isValid} type='submit'>{t('recover.button.recover')}</Button>
                    </form>
                </AuthWindow>
            )}
        </>
    );
};
