import { MenuItem } from "@ui-kit/components/drop-down/modele/menu-item";
import { useTranslation } from "react-i18next";

export const useSettingsMenu = () => {
  const { t } = useTranslation('settings');

  const initialMenuSettings: MenuItem[] = [
    {
      key: 'personal',
      type: 'menu-settings',
      title: t('main.menu.personalInfo'),
      showIcon: true,
      grayIcon: 'chevron-right'
    },
    {
      key: 'password',
      type: 'menu-settings',
      title: t('main.menu.password'),
      showIcon: true,
      grayIcon: 'chevron-right',
    },
    {
      key: 'notification',
      type: 'menu-settings',
      title: t('main.menu.notifications'),
      showIcon: true,
      grayIcon: 'chevron-right',
    },
    {
      key: 'language',
      type: 'menu-settings',
      title: t('main.menu.systemLanguage'),
      showIcon: true,
      grayIcon: 'chevron-right',
    },
  ];

  const showKeys = ['personal', 'password', 'notification', 'language'];

  const getInitialMenuState = (hasUser: boolean) => {
    return !hasUser ?
      initialMenuSettings.filter((menuItem) => showKeys.includes(menuItem.key)) :
      initialMenuSettings;
  };

  return { menuItems: initialMenuSettings, getInitialMenuState };
};
