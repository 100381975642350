import socialAplColor from "./social-apl-color.svg";
import socialAplGrey from "./social-apl-grey.svg";
import socialGlColor from "./social-gl-color.svg";
import socialGlGrey from "./social-gl-grey.svg";
import socialfbColor from "./social-fb-color.svg";
import socialfbGrey from "./social-fb-grey.svg";
import socialigGrey from "./social-ig-grey.svg";
import socialtgGrey from "./social-tg-grey.svg";
import socialtwGrey from "./social-tw-grey.svg";
import socialAplWhite from "./social-apl-white.svg";
import socialSmsColor from "./social-sms-blue.svg";
import socialWhatsColor from "./social-whats-blue.svg";
import socialWhatsGray from "./social-whats-grey.svg";

const data = {
  "social-apl-color": socialAplColor,
  "social-apl-grey": socialAplGrey,
  "social-apl-white": socialAplWhite,
  "social-gl-color": socialGlColor,
  "social-gl-grey": socialGlGrey,
  "social-fb-color": socialfbColor,
  "social-fb-grey": socialfbGrey,
  "social-ig-grey": socialigGrey,
  "social-tg-grey": socialtgGrey,
  "social-tw-grey": socialtwGrey,
  "social-sms-blue": socialSmsColor,
  "social-whats-blue": socialWhatsColor,
  "social-whats-grey": socialWhatsGray,
};

export default data;
