import { UserPicture } from "@graphql/graphql";
import { MenuItem } from "@ui-kit/components/drop-down/modele/menu-item";

export const userToMenuItem = (user: UserPicture, currentUser?: UserPicture|null): MenuItem => {
  return {
    key: user.username ?? 'user',
    type: 'user',
    iconUrl: user.picture?.url ?? undefined,
    title: `${user.firstname} ${user.lastname}`,
    subTitle: user.username ? `@${user.username}` : undefined,
    showIcon: true,
    selected: user.username === currentUser?.username
  }
}