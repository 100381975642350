import { gql } from "@apollo/client";

export const getHashtags = gql`query getHashtags {
    getHashtags{
        id
        hashtag
        length
        posts
        createdAt
        updatedAt
    }
}`;
