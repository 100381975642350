import premium from "../../../assets/icons/premium-icon.svg";

type Props = {
  size?: 'sm'|'md',
  className?: string,
}

export const PremiumIcon = ({ size, className }: Props) => {
  const sizePx = size === 'sm' ? '15px' : '18.75px';

  return <img className={className} src={premium} width={sizePx} height={sizePx} />
}