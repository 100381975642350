import { useEffect, useState } from "react";
import { ChatList } from "./components/chat-list/chat-list.comp";
import styles from "./messenger.module.css";
import { NoSelectedChat } from "./components/no-selected-chat/no-selected-chat.comp";
import { MessageList } from "./components/message-list/message-list.comp";
import { messagesStore, modalCustomDialogsStore, userStore } from "@store";
import { SearchUserInput } from "src/messenger/components/search-user-input/search-user-input.comp";
import { useLocation, useSearchParams } from "react-router-dom";
import { UserPicture } from "@graphql/graphql";
import { ChatUserInfo } from "./components/chat-user-info/chat-user-info.comp";
import { BottomMenu } from "@ui-kit/components/bottom-menu/bottom-menu.comp";
import { useTranslation } from "react-i18next";

export const MessengerPage = () => {
  const { pathname, search } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const chatId = searchParams.get('chat');

  const { t } = useTranslation('messenger');

  const [selectedChat, setSelectedChat] = useState<string>();
  const [userInfo, setUserInfo] = useState<UserPicture>();

  const selectChat = (id: string|undefined) => {
    setUserInfo(undefined);
    messagesStore.getChatMessages(id);
    setSelectedChat(id);
  }

  const onBackClick = () => {
    setUserInfo(undefined);
    setSelectedChat(undefined);
    setSearchParams();
  }

  useEffect(() => {
    if (!search) {
      setUserInfo(undefined);
      setSelectedChat(undefined);
    }
  }, [pathname, search])

  useEffect(() => {
    setSelectedChat(chatId ?? undefined);
    if (!chatId) {
      setSearchParams();
    } else {
      messagesStore.getChatMessages(chatId);
    }
  }, [chatId])

  const onNewMessage = () => {
    modalCustomDialogsStore.showDialog(
      (<div className={styles['messenger__new-message']}>
        <SearchUserInput />
      </div>),
      { title: t('header.title') }
    )
  }

  useEffect(() => {
    if (!selectedChat || !messagesStore.messages[selectedChat]) {
      return;
    }

    const notReadMessages = messagesStore.messages[selectedChat]
      .filter((msg) =>
        msg.username !== userStore.currentUser?.username
        && !msg.read?.includes(userStore.currentUser?.username ?? '-')
      );
    notReadMessages.forEach((msg) => messagesStore.readMessage(msg.id ?? ''));
    messagesStore.messagesCout -= notReadMessages.length;
  }, [selectedChat]);

  useEffect(() => {
    if (typeof selectedChat === 'string') {
      setSearchParams({ chat: selectedChat });
    }
  }, [selectedChat]);

  return <>
    <div className={styles.messenger}>
      <ChatList
        selectedChat={selectedChat}
        setSelectedChat={selectChat}
        hideChatOnMobile={selectedChat !== undefined || userInfo !== undefined}
        onNewMessage={onNewMessage} />
      {selectedChat
        ? !userInfo
          ? <MessageList selectedChat={selectedChat} setUserInfo={setUserInfo} onBackClick={onBackClick} />
          : <ChatUserInfo user={userInfo} onBackClick={() => setUserInfo(undefined)} chatId={chatId ?? ''} />
        : <NoSelectedChat onNewMessage={onNewMessage} />}
    </div>
    {(!selectedChat || !!userInfo)
      && <BottomMenu onCreateClick={() => {}} className='' />}
  </>
}