export const getFormatedTime = (time: number) => {
  const nowTime = new Date().getTime();

  const difTime = nowTime - time;

  if (difTime <= 1_000) {
    return '0s'
  }
  if (difTime <= 1_000 * 60) {
    return `${Math.round(difTime / 1_000)}s`;
  }
  if (difTime <= 1_000 * 60 * 60) {
    return `${Math.round(difTime / (1_000 * 60))}m`;
  }
  if (difTime <= 1_000 * 60 * 60 * 24) {
    return `${Math.round(difTime / (1_000 * 60 * 60))}h`;
  }
  if (difTime <= 1_000 * 60 * 60 * 24 * 7) {
    return `${Math.round(difTime / (1_000 * 60 * 60 * 24))}d`;
  }

  return `${Math.round(difTime / (1_000 * 60 * 60 * 24 * 7))}w`;
}