import { Post } from "@graphql/graphql";
import { postListStore, toastsStore } from "@store";

export const deletePostFn = (post: Post) => async (confirmDelete: boolean) => {
  if (!confirmDelete) return;

  try {
    if (post.id) {
      await postListStore.deletePost(post.id)
    }
  } catch (err: any) {
    toastsStore.addErrorToast('Can not delete post!');
  }
}