import styles from "./registration.module.css";
import { useState, useEffect } from "react";
import { userStore } from "src/store/user.store";
import { useNavigate } from "react-router-dom";
import { toastsStore, modalCustomDialogsStore } from "@store";
import { AuthWindow, Button, InputFormComponent, LinkComponent } from "@ui-kit";
import { GoogleButton, FacebookButton, AppleButton } from "@ui-kit/components/button/social-buttons/";
import { ConfirmComponent } from "./components/confirm.comp";
import { useForm, SubmitHandler } from "react-hook-form";
import { useValidationRules } from "@ui-kit/components/input/helpers/useValidationRules";
import { City, CreateCityInput } from "@graphql/graphql";
import { RegistrationMap } from "../component/registration-map/registration-map.component";
import { ConfirmPageSmsWhats } from "./components/confirm-page.sms.whats";
import { onGoogleClick } from "@shared/helpers/on-google-click";
import { UserAgreementComponent } from "../../agreement-page/user-agreement.comp";
import { OgMetaData } from "@shared/components/og-meta-data/og-meta-data.comp";
import { useTranslation } from "react-i18next";

export const SES_STOR_REG_CITY = 'regCityPlaceId';

interface NewUser {
  firstname: string,
  lastname: string,
  username: string,
  phone: string;
  password: string,
  passwordRepeat: string,
}

const defaultValues: NewUser = {
  firstname: '',
  lastname: '',
  username: '',
  password: '',
  phone: '',
  passwordRepeat: '',
};

enum RegistrationPageState {
  pageRegistr,
  pageSmsWhats,
  pageConfirmCode
}

const ENABLED_OAUTH = process.env.REACT_APP_DISABLE_OAUTH?.toLowerCase() !== 'true'

export const RegistrationPage = () => {
  const [city, setCity] = useState<City|null>(null);
  const [typeConfirmWhatsApp, setTypeConfirmWhatsApp] = useState(true);
  const [isSendedText, setIsSendedText] = useState(false);
  const [code, setCode] = useState('');
  const [formData, setFormData] = useState<NewUser | null>(null);
  const [pageState, setPageState] = useState<RegistrationPageState>(RegistrationPageState.pageRegistr);

  const validationRules = useValidationRules();
  const { t } = useTranslation('authorization');

  useEffect(() => {
    if (city?.place_id) {
      sessionStorage.setItem(SES_STOR_REG_CITY, city?.place_id);
    } else {
      sessionStorage.removeItem(SES_STOR_REG_CITY);
    }
  }, [city]);

  const { register, handleSubmit, control, formState: { errors, isValid } } = useForm<NewUser>({
    mode: 'onBlur',
    defaultValues
  });

  const navigate = useNavigate();

  const formSubmitHandler: SubmitHandler<NewUser> = (data: NewUser) => {
    setFormData(data);
  };

  useEffect(() => {
    if (formData) {
      registr();
    }
  }, [formData]);

  const newUser = (data: NewUser) => {
    const { phone, password, username, firstname, lastname } = data;
    userStore.phone = phone;
    const newCity: CreateCityInput = {
      name: city?.name ?? '',
      country: city?.country ?? '',
      longName: city?.longName ?? '',
      picture: city?.picture,
      place_id: city?.place_id ?? '',
    };
    userStore.newUser({
      password,
      phone,
      username,
      firstname,
      lastname,
      city: newCity,
      whatsapp: typeConfirmWhatsApp,
    });
  };

  const registr = () => {
    if (formData) {
      if (formData.password !== formData.passwordRepeat) {
        toastsStore.addErrorToast(t('toasts.error.password'));
        return;
      }
    }
    setPageState(RegistrationPageState.pageSmsWhats);
  }

  const sendConfirm = (isWhatsapp: boolean) => {
    if (formData) {
      newUser(formData);
      setTypeConfirmWhatsApp(isWhatsapp);
    }
    setPageState(RegistrationPageState.pageConfirmCode);
  }

  const confirm = () => {
    userStore.confirmUser({
      confirmID: code,
      phone: userStore.phone,
    }).then((confirm) => {
      if (confirm?.cityId) {
        userStore.selectCityInList(confirm.cityId);
      }
      if (confirm?.isPioner) {
        modalCustomDialogsStore.showDialog(undefined, {
          title: 'Вы стали первооткрывателем этого удивительного города!',
          message: 'Теперь вы обладаете уникальным статусом, который отличает вас от обычных пользователей.',
          typeIcon: 'unlock',
          firstButtonText: 'Поделиться новостью',
          firstButtonColor: 'secondary-color',
          secondButtonText: 'Что дает этот статус?',
          secondButtonColor: 'primary',
          confirmAction: () => navigate('/info/wall/'),
          cancelAction: () => navigate('/?add=true'),
        });
      } else if (confirm !== null) {
        navigate('/');
      }
    });
  }

  const handleLinkTimer = () => {
    setIsSendedText(false);
  };

  const handleTimeout = () => {
    if (formData) {
      newUser(formData);
    }
  };

  const handlebackwardButton = () => {
    setPageState(RegistrationPageState.pageSmsWhats);
  }

  const isCodeComplete = code.length === 4;
  const { showDialog } = modalCustomDialogsStore;

  const showUserAgreement = () => {
    modalCustomDialogsStore.hideDialog();
    return showDialog(
      <UserAgreementComponent isModal={true}/>,
      {
        title: t('modal.titleAgree'),
      }
    );
  }

  return (
    <>
      <OgMetaData type='main' data={t('register.header')} />
      {!city && <AuthWindow pageType='registration-map'><RegistrationMap selectCity={setCity}/></AuthWindow>}
      {pageState === RegistrationPageState.pageRegistr && city &&
        <AuthWindow pageType='registration'>
          <h2 className={styles.title}>{t('register.title')}</h2>
          <h3 className={styles.subtitle}>
          {t('register.subtitle')}
          </h3>
          {ENABLED_OAUTH && <div className={styles.socialButtonsBlock}>
            <GoogleButton btnSmall={true} supportingText={false} onClick={onGoogleClick}></GoogleButton>
            <FacebookButton btnSmall={true} supportingText={false}></FacebookButton>
            <AppleButton btnSmall={true} supportingText={false}></AppleButton>
          </div>}
          <form className={styles['form-registr']} onSubmit={handleSubmit(formSubmitHandler)}>
            <div className={styles.usernameBlock}>
              <InputFormComponent
                label={t('input.firstname.label')}
                control={control}
                type='text'
                placeholder={t('input.firstname.placeholder')}
                hasErrors={!!errors.firstname}
                {...register('firstname', validationRules.firstname)}
                errorsMess={errors.firstname && errors.firstname.message}
              />
              <InputFormComponent
                label={t('input.lastname.label')}
                type='text'
                control={control}
                placeholder={t('input.lastname.placeholder')}
                hasErrors={!!errors.lastname}
                {...register('lastname', validationRules.lastname)}
                errorsMess={errors.lastname && errors.lastname.message}
                />
            </div>
            <div className={styles.inputBlock}>
              <InputFormComponent
                label={t('input.username.label')}
                type='text'
                control={control}
                placeholder={t('input.username.placeholder')}
                supportingText={t('input.username.supportText')}
                hasErrors={!!errors.username}
                {...register('username', validationRules.username)}
                errorsMess={errors.username && errors.username.message}
                tips={t('input.username.tips')}
              />
              <InputFormComponent
                label={t('input.phone.label')}
                type='phone'
                control={control}
                placeholder={t('input.phone.placeholder')}
                hasErrors={!!errors.phone}
                {...register('phone', validationRules.phone)}
                errorsMess={errors.phone && errors.phone.message}
              />
              <InputFormComponent
                label={t('input.password.label')}
                type='password'
                control={control}
                placeholder={t('input.password.placeholder')}
                supportingText={t('input.password.supportText')}
                hasErrors={!!errors.password}
                {...register('password', validationRules.password)}
                errorsMess={errors.password && errors.password.message}
              />
              <InputFormComponent
                label={t('input.repeatPassword.label')}
                type='password'
                control={control}
                placeholder={t('input.repeatPassword.placeholder')}
                hasErrors={!!errors.passwordRepeat}
                {...register('passwordRepeat', validationRules.passwordRepeat)}
                errorsMess={errors.passwordRepeat && errors.passwordRepeat.message}
              />
            </div>
            <Button disabled={!isValid} type='submit'>{t('register.button.continue')}</Button>
          </form>
          <p className={styles.userAgreementBlock}>
            <LinkComponent typeLink='gray-underline' click={showUserAgreement}>
              <span>{t('link.agreement')}</span>
            </LinkComponent>
          </p>
          <p className={styles.bottomWindowBlock}>
            <span>{t('link.haveAccount')}</span>
            <LinkComponent link='/login'>{t('register.button.enter')}</LinkComponent>
          </p>
      </AuthWindow>}

      {pageState === RegistrationPageState.pageSmsWhats && <AuthWindow pageType='confirm-sms-or-whats'>
        <ConfirmPageSmsWhats sendConfirm={sendConfirm}/></AuthWindow>}

      {pageState === RegistrationPageState.pageConfirmCode && <AuthWindow pageType='confirm-password'>
        <ConfirmComponent
          onSendedTextChange={setIsSendedText}
          typeConfirmWhatsApp={typeConfirmWhatsApp}
          setCode={setCode}
          confirm={confirm}
          code={code}
          handlebackwardButton={handlebackwardButton}
          isCodeComplete={isCodeComplete}
          handleLinkTimer={handleLinkTimer}
          handleTimeout={handleTimeout}
          />
        </AuthWindow>}
    </>
  );
};