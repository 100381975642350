import { trendStore } from "@store";
import styles from "./trends.module.css";
import { useEffect } from "react";
import { RightMenuItem } from "@shared/components/right-menu-item/right-menu-item.comp";
import { TrendItem } from "@shared/components/trend-item/trend-item.comp";
import { useTranslation } from "react-i18next";

export const Trends = () => {
  const { loadTrends, trendList } = trendStore;

  const { t } = useTranslation('main');

  useEffect(() => { loadTrends() }, []);

  return trendList?.length
    ? <RightMenuItem>
        <div className={styles.header}>
          <h2 className={styles.title}>{t('trends.header')}</h2>
        </div>
        {trendList.slice(0, 5).map((trend, i) =>
          <TrendItem key={i} {...trend} />
        )}
      </RightMenuItem>
    : <></>;
}