import chadImg from "@assets/images/chad.png";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "@shared/components";
import { Container, Nav, Navbar, Stack } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import styles from "./header.module.css";
import { UserPicture } from "@graphql/graphql";
import { userStore } from "src/store/user.store";

type Props = {
  username: string;
};

export const Header = ({ username }: Props) => {
  const [userPicture, setUserPicture] = useState<string | undefined>();
  const [userData, setUserData] = useState<UserPicture | undefined | null>();
  const navigate = useNavigate();

  const goBack = () => navigate('/messages');

  const getUserFirstLastNames = () => {
    if (userData?.firstname && userData?.lastname) {
      return `${userData?.firstname} ${userData?.lastname}`;
    }
    if (userData?.firstname) {
      return `${userData?.firstname}`;
    }
    if (userData?.lastname) {
      return `${userData?.lastname}`;
    }
    return undefined;
  };

  useEffect(() => {
    if (!username) {
      return;
    }
    userStore.getUserData(username).then((userData) => {
      setUserData(userData);
      setUserPicture(userData?.picture?.url ?? undefined);
    });
  }, [username]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Navbar bg='light' expand='lg'>
        <Container>
          <Stack className={styles.topContainer} direction='horizontal' gap={3}>
            <Nav.Link onClick={goBack} className='pt-1'>
              <FontAwesomeIcon icon={solid('arrow-left')} size='xl' />
            </Nav.Link>
            <Navbar.Brand className={styles.container}>
              <p className={styles.userName}>
                {getUserFirstLastNames() ?? username}
              </p>
            </Navbar.Brand>
            <img
              className={styles.userProfilePicture}
              src={userPicture ?? chadImg}
              alt='user-picture'
            />
          </Stack>
        </Container>
      </Navbar>
    </ErrorBoundary>
  );
};
