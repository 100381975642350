import { City } from "@graphql/graphql";
import { getGeocode, getLatLng } from "use-places-autocomplete";

export type CityWithCords = {
  place_id: string,
  name: string,
  longName: string,
  country: string,
  lat: number,
  lng: number,
}

export const getCitiesCords = async (cities: (City|null|undefined)[]|null): Promise<CityWithCords[]> => {
  const clearCities = (cities?.filter(Boolean) ?? []) as City[];
  const cords = await Promise.all(
    clearCities.map(async (citiItem) => {
      try {
        const geocode = await getGeocode({ placeId: citiItem.place_id });
        const { lat, lng } = getLatLng(geocode[0]);
        return ({
          place_id: citiItem.place_id,
          name: citiItem.name,
          longName: citiItem.longName,
          country: citiItem.country,
          lat,
          lng,
        } as CityWithCords)
      } catch(err) {
        return null;
      }
    })
  );

  return cords.filter(Boolean) as CityWithCords[]
}