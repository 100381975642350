import { makeAutoObservable } from "mobx";

type ActionDialogSync = () => void;
type ActionDialogAsync = () => Promise<any>;

type ActionDialog = ActionDialogSync | ActionDialogAsync;

class ModalMessageStore {
  isShowingMessage = false;
  title = 'title';
  message = 'message';
  action: ActionDialog = () => {};

  constructor() {
    makeAutoObservable(this);
  }

  showMessage = (title?: string, message?: string, action?: ActionDialog ) => {
    this.title = title ? title : 'Message';
    this.message = message ? message : '';
    this.action = action ? action : () => {}

    this.isShowingMessage = true;
  }

  hideMessage = () => {
    this.action();
    this.isShowingMessage = false;
  }
}

export const modalMessageStore = new ModalMessageStore();