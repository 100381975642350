import { MapController } from "@add-city/types";
import { toastsStore } from "@store";
import { HookReturn, getGeocode, getLatLng } from "use-places-autocomplete";
import { DropdownInput } from "@ui-kit/components/input/dropdown-input/dropdown-input";
import { GeoButton } from "@ui-kit/components/button/geo-button/geo-button.comp";
import styles from "./places-autocomplete.module.css";
import { useTranslation } from "react-i18next";

export const ZOOM_CITY = 8;
interface Props {
  setCityByCords: (lat: number, lng: number) => Promise<any>,
  hookReturn: HookReturn,
  mapController: MapController,
  city: string,
  selectCityByGeo: () => void,
}

export const PlacesAutocomplete = ({
  city, setCityByCords, hookReturn, mapController, selectCityByGeo,
}: Props) => {
  const {
    value,
    setValue,
    suggestions: { data },
    clearSuggestions,
  } = hookReturn;

  const { t } = useTranslation('common');

  const handleSelect = async (address: any) => {
    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = getLatLng(results[0]);
    mapController.setCenter({ lat, lng });
    mapController.setZoom(ZOOM_CITY);

    await setCityByCords(lat, lng);
    mapController.setSelected({ lat, lng });
  };

  return (<div className={styles['autocomplete-container']}>
      <div className={styles['places-autocomplete']}>
        <DropdownInput
          text={value}
          onchange={(str: string) => setValue(str, true)}
          placeholder={t('map.placeholder')}
          type='search'
          items={
              data
                .filter((response) => response.types.includes('locality') && response.types.includes('political'))
                .map(({ place_id, description }) => ({
                  key: place_id,
                  title: description,
                  type: 'menu-item',
                }))
            }
            selecteItem={(address) => {
              handleSelect(address.title)
                .catch((error) => toastsStore.addErrorToast(t('toasts.error.setLocalCity')));
            }}
        />
      </div>
      <GeoButton className={styles['geo-button']} onClick={selectCityByGeo}>{t('map.button.local')}</GeoButton>
    </div>
  );
};