import { reportComment, reportPost, reportUser } from "@graphql/docs/mutations";
import { ReportInput, ReportUserInput } from "@graphql/graphql";
import { toastsStore } from "@store";
import { apolloClient } from "src/apollo-client";

class ReportService {
  reportPost = async (input: ReportInput): Promise<Report|null> => {
    try {
      const res = await apolloClient.mutate<{reportPost: Report}>({
        mutation: reportPost,
        variables: { input },
      })
      toastsStore.addSuccessToast("Жалоба отправлена");
      return res.data?.reportPost ?? null
    } catch {
      toastsStore.addErrorToast("Не удалось отправить жалобу на пост!");
      return null;
    }
  }

  reportComment = async (input: ReportInput): Promise<Report|null> => {
    try {
      const res = await apolloClient.mutate<{reportComment: Report}>({
        mutation: reportComment,
        variables: { input },
      })
      toastsStore.addSuccessToast("Жалоба отправлена");
      return res.data?.reportComment ?? null
    } catch {
      toastsStore.addErrorToast("Не удалось отправить жалобу на комментарий!");
      return null;
    }
  }

  reportUser = async (input: ReportUserInput) => {
    try {
      const res = await apolloClient.mutate<{reportUser: Report}>({
        mutation: reportUser,
        variables: { input },
      })
      toastsStore.addSuccessToast("Жалоба отправлена");
      return res.data?.reportUser ?? null
    } catch {
      toastsStore.addErrorToast("Не удалось отправить жалобу на комментарий!");
      return null;
    }
  }
}

export const reportService = new ReportService();