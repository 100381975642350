import styles from "./agreement.module.css";
import { observer } from "mobx-react-lite";
import { UserAgreementComponent } from "./user-agreement.comp";
import {Header} from "@shared/components";
import { OgMetaData } from "@shared/components/og-meta-data/og-meta-data.comp";
import { useTranslation } from "react-i18next";

export const AgeementPage = observer(() => {

  const { t } = useTranslation('agreement');

  return (
  <>
    <OgMetaData type='main' data={t('main.header')} />
      <Header
        className={styles['header-settigs']}
        title={t('main.title')}
        hideAvatar={true}
      />
    <UserAgreementComponent />
  </>
);
});
