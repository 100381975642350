import { MapComponent } from "@shared/components/map/map.comp";
import styles from "./registration-map.module.css";
import { Button } from "@ui-kit";
import { City } from "@graphql/graphql";
import { useState } from "react";
import { userStore } from "@store";
import { useTranslation } from "react-i18next";

type Props = {
  selectCity: (city: City) => void;
}

export const RegistrationMap = ({
  selectCity,
}: Props) => {
  const onSelectCity = () => {
    if(city) {
      userStore.city = city;
      selectCity(city);
    }
  }
  const { t } = useTranslation('authorization');
  const [city, setCity] = useState<City|null>(null);

  return <>
    <h2 className={styles.title}>{t('map.header')}</h2>
    <div className={styles.map}>
      <MapComponent
        autoGeo={true}
        hideCityList={true}
        selectCity={(city) => setCity(city)}
      />
    </div>
    <Button
      disabled={city?.place_id === ''}
      onClick={onSelectCity}
    >
      {t('map.button.continue')}
    </Button>
  </>
}