import { UserPicture } from "@graphql/graphql";

type UsernamePosType = {start?: number, end?: number};

export const onSelectUser = (
  user: UserPicture,
  stringIndex: number[],
  setUsername: React.Dispatch<React.SetStateAction<string>>,
  textRef: any,
  usernamePos: UsernamePosType,
  setUsernamePos: React.Dispatch<React.SetStateAction<UsernamePosType>>
) => {
  if (!user.username) {
    return setUsername('');
  }

  if (stringIndex.length === 1) {
    const [index] = stringIndex;
    const text: string =
      textRef.current.childNodes?.[index]?.nodeName === "#text" 
      || textRef.current.childNodes?.[index]?.nodeName === "DIV"
        ? textRef.current?.childNodes?.[index]?.innerHTML
          ?? textRef.current?.childNodes?.[index]?.textContent 
        : textRef.current?.childNodes?.[index]?.outerHTML;
    const { start, end } = usernamePos;

    if (start === undefined || end === undefined || !text) {
      return;
    }

    const resText = `${text.substring(0, start)}<span contenteditable="false">@${user.username}</span> ${text.substring(end)}`;
    const resHTML = [...textRef.current.childNodes].map((node: any, i) => {
      return i === index
        ? textRef.current.childNodes?.[index]?.nodeName === "#text"
          ? resText
          : `<div>${resText}</div>`
        : textRef.current.childNodes?.[i]?.nodeName === "#text"
          ? node.textContent
          : node.outerHTML
    }
    ).join('');

    textRef.current.innerHTML = resHTML;
    setUsername('');
    setUsernamePos({});
  } else {
    const [i, j] = stringIndex;
    const text: string =
      textRef.current.childNodes?.[i]?.childNodes?.[j]?.nodeName === "#text" 
      || textRef.current.childNodes?.[i]?.childNodes?.[j]?.nodeName === "DIV"
        ? textRef.current?.childNodes?.[i]?.childNodes?.[j]?.innerHTML
          ?? textRef.current?.childNodes?.[i]?.childNodes?.[j]?.textContent 
        : textRef.current?.childNodes?.[i]?.childNodes?.[j]?.outerHTML;
    const { start, end } = usernamePos;

    if (start === undefined || end === undefined || !text) {
      return;
    }

    const resText = `${text.substring(0, start)}<span contenteditable="false">@${user.username}</span> ${text.substring(end)}`;
    const resHTML = [...textRef.current.childNodes].map((node: any, iNode) => {
      return i === iNode
        ? `<div>${[...textRef.current.childNodes[i]?.childNodes]
          .map((childNode, jNode) =>
            jNode === j
              ? resText
              : childNode?.nodeName === "#text"
                ? childNode.textContent
                : childNode.outerHTML
          )
          .join('')}</div>`
        : node?.nodeName === "#text"
          ? node.textContent
          : node.outerHTML
    }
    ).join('');

    textRef.current.innerHTML = resHTML;
    setUsername('');
    setUsernamePos({});
  }
}