import { UserPicture } from "@graphql/graphql";
import { ReportModalContent } from "@shared/components/report-modal-content/report-modal-content.com";
import { modalCustomDialogsStore } from "@store";
import { getI18n } from "react-i18next";

export const reportUser = (user: UserPicture) => {
  const i18n = getI18n();

  modalCustomDialogsStore.showDialog(
    <ReportModalContent item={user} closeModal={() => modalCustomDialogsStore.hideDialog()} />,
    {
      title: i18n.t('common:dialogs.report.title'),
      message: i18n.t('common:dialogs.report.message')
    },
  )
}