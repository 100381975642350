import styles from "./auth-window.module.css";
import logoImg from "@assets/images/logo.svg";

export type pageType = "registration" | "registration-map" | "confirm-password" | "confirm-sms-or-whats" | "confirm-phone-recover" | "confirm-pass-recover" ;

type Props = {
  pageType?: string
  children: any
}

export const AuthWindow = ({ children, pageType }: Props) => {

  let pageTypeCssClass = '';
  switch (pageType) {
    case 'registration' : pageTypeCssClass = styles['container_registration']; break;
    case 'registration-map' : pageTypeCssClass = styles['container_registration-map']; break;
    case 'confirm-password' : pageTypeCssClass = styles['container_confirm-password']; break;
    case 'confirm-sms-or-whats' : pageTypeCssClass = styles['container_confirm-sms-or-whats']; break;
    case 'confirm-phone-recover' : pageTypeCssClass = styles['container_confirm-phone-recover']; break;
    case 'confirm-pass-recover' : pageTypeCssClass = styles['container_confirm-pass-recover']; break;
  }

  return (
    <div className={styles.body}>
        <div className={styles.logo}>
          <img className={styles.logoImg} src={logoImg} alt='logo'/>
        </div>
      <div className={`${styles.container} ${pageTypeCssClass}`}>
        <div className={styles.data}>
          {children}
        </div>
      </div>
    </div>
  )
};
