import { UserPicture } from "@graphql/graphql"

export type LiveUser = {
  user: UserPicture,
  online: number,
}

export const mockLiveData: LiveUser[] = [
  {
    user: {
      username: "olivia",
      firstname: "Olivia",
      lastname: "Rhye",
      cityId: "ChIJWQUt0lpGQ0IRM9RE0uBrC6k",
      picture: {
        key: "public/d372b1b5b44ffef41eed6ceb810ddf8a.jpeg",
        type: "image/jpeg",
        url: "https://ecitty.object.pscloud.io/public/d372b1b5b44ffef41eed6ceb810ddf8a.jpeg"
      }
    },
    online: 2224,
  },
  {
    user: {
      username: "pbaker",
      firstname: "Phoenix",
      lastname: "Baker",
      cityId: "ChIJCUa1fcSARUIRKJKx3Y0U-Zc",
      picture: {
        key: "public/a4147da7fea569d1940840845897657f.jpeg",
        type: "image/jpeg",
        url: "https://ecitty.object.pscloud.io/public/a4147da7fea569d1940840845897657f.jpeg"
      }
    },
    online: 1324467,
  },
  {
    user: {
      username: "drewcano",
      firstname: "Drew",
      lastname: "Cano",
      cityId: "ChIJCUa1fcSARUIRKJKx3Y0U-Zc",
      picture: {
        key: 'public/ab287460eef89ff5351e750f87e0634b.jpeg',
        type: 'image/jpeg',
        url: 'https://ecitty.object.pscloud.io/public/ab287460eef89ff5351e750f87e0634b.jpeg'
      }
    },
    online: 137,
  },
];
