import { Notification } from "@graphql/graphql";
import { useTranslation } from "react-i18next";

export const useNotificationLinkText = () => {

const { t } = useTranslation('notification');

const getNotificationLinkText = (item: Notification) => {
  switch(item.notifyType) {
    case 'post-reaction':
      return item.post?.text ?? t('linkText.post');
    case 'comment-reaction':
      return item.comment?.text ?? t('linkText.comment');
    case 'message':
      return item.message?.text ?? t('linkText.message');
    case 'message-reaction':
      return item.message?.text ?? t('linkText.message');
    case 'subscription':
      return '';
    case 'tagged-post':
      return item.post?.text ?? t('linkText.post');
    case 'tagged-comment':
      return item.comment?.text ?? t('linkText.comment');
    case 'new-post':
      return item.post?.text ?? t('linkText.post');
    case 'answer-comment':
      return item.answerForComment?.text ?? t('linkText.comment');
    case 'comment':
      return item.post?.text ?? t('linkText.post');
    default:
      return t('linkText.reaction');
  }
}
return getNotificationLinkText;
}