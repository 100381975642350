import { gql } from "@apollo/client";

export const unfollow = gql`mutation unfollow($username: String) {
    unfollow(username: $username){
        id
        username
        follow
        createdAt
        updatedAt
    }
}`;
