import { observer } from "mobx-react-lite";
import styles from "./post.module.css";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { commentListStore, postListStore } from "@store";
import { useEffect, useRef, useState } from "react";
import { Post } from "@graphql/graphql";
import { Loader } from "@ui-kit/components/loader/loader.comp";
import { Header } from "@shared/components/header/header.comp";
import { PostComponent } from "src/main-page/components/post/post.comp";
import { CommentList } from "src/main-page/components/comment-list/comment-list.comp";
import { CreateComment } from "src/main-page/components/post/create-comment/create-comment.comp";
import { convertHTMLTextToCorrectText } from "@shared/helpers/convert-html-text-to-correct-text";
import { OgMetaData } from "@shared/components/og-meta-data/og-meta-data.comp";
import { useTranslation } from "react-i18next";

export const PostPage = observer(() => {
  const navigate = useNavigate();
  const { getPostById } = postListStore;
  const { pathname, search } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const commentId = searchParams.get('comment');
  const subCommentId = searchParams.get('subComment');

  const { postId } = useParams();

  const [loading, setLoading] = useState<boolean>(true);
  const [post, setPost] = useState<Post>();

  const { t } = useTranslation('main');

  useEffect(() => {
    if(postId) {
      getPostById(postId, commentId, subCommentId).then(() => setLoading(false));
    } else {
      setLoading(true);
    }
  }, [postId]);

  useEffect(() => {
    document.body.scrollTo({top: 0, behavior: 'auto'});
    if (postId) {
      postListStore.addViewPost(postId);
    }
  }, []);

  useEffect(() => {
    const post = postListStore.posts?.find((p) => p?.id === postId);
    if (!post && !loading) {
      navigate('/');
    } else {
      setPost(post);
    }
  }, [postListStore.posts])

  const textRef = useRef<any>();

  const onFocusWithUsername = (username?: string) => {
    if (
      username
      && !convertHTMLTextToCorrectText(commentListStore.text)
        .split('\n')
        .map((line: string) => line.split(' '))
        .flat()
        .find((word: string) => word === `@${username}`)
    ) {
      commentListStore.setText(`@${username}&nbsp;${commentListStore.text}`);
      textRef.current.innerHTML = commentListStore.text;
    }

    textRef.current.focus();
    try {
      const selection = window.getSelection();
      const range = document.createRange();
      range.setStart(textRef.current, commentListStore.text.split('</div>').length);
      range.setEnd(textRef.current, commentListStore.text.split('</div>').length);
      selection?.removeAllRanges();
      selection?.addRange(range);
    } catch {}
  }

  return (<>
    <OgMetaData type='post' post={post} />
    <div className={styles['main-right']}>
      <Header
        isMainPage={false}
        title={t('postComments.title')}
        hideAvatar={true}
      />
      {loading || !post
        ? <Loader />
        : <>
          <PostComponent
            item={post}
            hideCommentsButton={true} />
          <div className={styles['comment-list']}>
            <CommentList
              mainComment={post.comment}
              mainSubComment={post.subComment}
              postId={post?.id}
              hideOnTabletAndMobile={false}
              onFocusWithUsername={onFocusWithUsername} />
          </div>
        </>
      }
    </div>
    {post && <CreateComment
        className={styles['create-comment']}
        post={post}
        textRef={textRef} />}
  </>);
});
