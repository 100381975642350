import { PostWithReaction } from "@graphql/graphql";
import styles from "./wall-reaction.module.css";
import { Avatar } from "@ui-kit/components/avatar/avatar.comp";
import { ReactionTypes } from "src/assets/reactions";
import { getCorrectPostDate } from "src/main-page/components/post/helpers/get-correct-post-date";
import { Link } from "react-router-dom";
import { ItemMenu } from "@shared/components/item-menu/item-menu.comp";
import { reaction } from "mobx";
import { userStore } from "@store";
import { MenuItem } from "@ui-kit/components/drop-down/modele/menu-item";
import { WALL_MENU_KEYS, useUserWallMenu } from "src/user-wall/helpers/use-user-wall-menu";
import { useState } from "react";
import { hideService } from "@shared/services/hide.service";
import { useTranslation } from "react-i18next";

type Props = {
  item: PostWithReaction,
}

export const WallReaction = ({ item }: Props) => {
  const [showItem, setShowItem] = useState(true);

  const getUserWallMenu = useUserWallMenu();
  const { t } = useTranslation('wall');

  const type = item.reaction?.type ? item.reaction.type as ReactionTypes : undefined;

  if (!item.post || !item.reaction) {
    return <></>
  }

  let postText = item.post?.text?.split('\n').join(' ');
  postText = postText?.split(' ').length === 1 && postText.length > 40
    ? `${postText.substring(0, 37)}...`
    : postText

  if (!item.post?.text) {
    postText = 'Без описания';
  }

  const reactionId = item.reaction.id

  const onMenuClick = (item: MenuItem) => {
    switch (item.key) {
      case WALL_MENU_KEYS.HIDE:
        hideService.hideReaction(reactionId)
          .then((res) => {
            if (res) {
              setShowItem(false);
            }
          });
        break;
    }
  }

  const className = !showItem
    ? `${styles['wall-reaction']} ${styles['wall-reaction_hide']}`
    : styles['wall-reaction'];

  if (item.reaction.isHiden) {
    return <></>
  }

  return <div className={className}>
    <Avatar
      className={styles['wall-reaction__avatar']}
      size='xl'
      avatarReaction={type}
      url={item.reaction?.user?.picture?.url ?? undefined}
      alt={item.reaction?.username ?? ''}
      />
    <Avatar
      className={styles['wall-reaction__avatar-mobile']}
      size='lg'
      avatarReaction={type}
      url={item.reaction?.user?.picture?.url ?? undefined}
      alt={item.reaction?.username ?? ''}
    />
    <div className={styles['wall-reaction__content']}>
      <div className={styles['wall-reaction__header']}>
        <h3 className={styles['wall-reaction__fullname']}>
          {item.reaction?.user?.firstname} {item.reaction?.user?.lastname}
        </h3>
        {!!item.reaction && <p className={styles['wall-reaction__time']}>
          {getCorrectPostDate(item.reaction)}
        </p>}
      </div>
      <div className={styles['wall-reaction__bottom']}>
        <span className={styles['wall-reaction__text']}>
          {t('message.reaction')}
        </span> <Link
          className={styles['wall-reaction__link']}
          to={`/post/${item.post?.id}`}
        >
          {postText}
        </Link>
      </div>
    </div>
    <ItemMenu
      className={styles['comment__item-menu']}
      toRight={true}
      items={getUserWallMenu(reaction, userStore.currentUser?.username ?? '')}
      onClick={onMenuClick} />
  </div>
}