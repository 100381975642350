import logo from "../../../assets/images/logo.svg";
import { Post, UserPicture } from "@graphql/graphql";
import { HOST_URL } from "@shared/data/api.constants";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

type Props = {
  type: 'main'|'post'|'user'|'data'
  data?: string,
  user?: UserPicture,
  post?: Post,
  // typeData?: 'music.song'|'video.movie'|'article'|'profile';
}


export const OgMetaData = ({ type, data, user, post }: Props) => {
  const { t } = useTranslation('common');

  const description = `${t('ogmeta.description')}`;

  const postTitle = post?.text?.substring(0, 32)
    ?? t('ogmeta.title');

  switch (type) {
    case 'main':
      if (!data) return <></>
      return <Helmet>
        <meta property="og:image" content={logo} />
        <meta property="twitter:image" content={logo} />
        <meta property="vk:image" content={logo} />
        <meta property="og:title" content={data} />
        <meta property="twitter:title" content={data} />
        <meta property="vk:title" content={data} />
        <meta property="twitter:card" content='summary_large_image' />
        <meta property="og:image:width" content='164' />
        <meta property="og:image:height" content='67' />
        <meta property="og:url" content={HOST_URL} />
        <meta property="description" content={description} />
        <meta property="og:description" content={description} />
        <meta property="twitter:description" content={description} />
        <meta property="vk:description" content={description} />
      </Helmet>
    case 'post':
      if (!post) return <></>
      return <Helmet>
        <meta property="og:type" content='article' />
        <meta property="og:site_name" content='Ecitty' />
        <meta property="og:title" content={postTitle} />
        <meta property="twitter:title" content={postTitle} />
        <meta property="vk:title" content={postTitle} />
        <meta property="og:author" content={`${post.userPicture?.firstname} ${post.userPicture?.lastname}`} />
        {!!post?.media?.[0]?.url && <meta property="og:image" content={post.media[0].url} />}
        {!!post?.media?.[0]?.url && <meta property="twitter:image" content={post.media[0].url} />}
        {!!post?.media?.[0]?.url && <meta property="vk:image" content={post.media[0].url} />}
        {!!post?.media?.[0]?.url && <meta property="twitter:card" content='summary_large_image' />}
        {!!post?.media?.[0]?.url && <meta property="og:image:width" content='1200' />}
        {!!post?.media?.[0]?.url && <meta property="og:image:height" content='630' />}
        <meta property="og:url" content={`${HOST_URL}/post/${post.id}`} />
        <meta property="twitter:url" content={`${HOST_URL}/post/${post.id}`} />
        <meta property="vk:url" content={`${HOST_URL}/post/${post.id}`} />
        <meta property="description" content={post.text ?? 'Нет текста'} />
        <meta property="og:description" content={post.text ?? 'Нет текста'} />
        <meta property="twitter:description" content={post.text ?? 'Нет текста'} />
        <meta property="vk:description" content={post.text ?? 'Нет текста'} />
      </Helmet>
    case 'user':
      if (!user) return <></>
      return <Helmet>
        <meta property="og:type" content='profile' />
        <meta property="og:site_name" content='Ecitty' />
        {!!user.picture?.url && <meta property="og:image" content={user.picture?.url} />}
        {!!user.picture?.url && <meta property="twitter:image" content={user.picture?.url} />}
        {!!user.picture?.url && <meta property="vk:image" content={user.picture?.url} />}
        {!!user.picture?.url && <meta property="twitter:card" content='summary_large_image' />}
        {!!user.picture?.url && <meta property="og:image:width" content='400' />}
        {!!user.picture?.url && <meta property="og:image:height" content='400' />}
        <meta property="og:title" content={`${user.firstname} ${user.lastname}`} />
        <meta property="twitter:title" content={`${user.firstname} ${user.lastname}`} />
        <meta property="vk:title" content={`${user.firstname} ${user.lastname}`} />
        <meta property="description" content={user.username ?? 'ecitty user'} />
        <meta property="og:description" content={user.username ?? 'ecitty user'} />
        <meta property="twitter:description" content={user.username ?? 'ecitty user'} />
        <meta property="vk:description" content={user.username ?? 'ecitty user'} />
        <meta property="og:url" content={`${HOST_URL}/wall/${user.username}`} />
        <meta property="twitter:url" content={`${HOST_URL}/wall/${user.username}`} />
        <meta property="vk:url" content={`${HOST_URL}/wall/${user.username}`} />
        {!!user.firstname && <meta property="profile:first_name" content={user.firstname} />}
        {!!user.lastname && <meta property="profile:last_name" content={user.lastname} />}
        {!!user.username && <meta property="profile:last_name" content={user.username} />}
        {!!user.gender && <meta property="profile:last_name" content={user.gender} />}
      </Helmet>
    default:
      return <></>
  }
}
