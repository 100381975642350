import { HIDE_TOAST_TIMEOUT } from "@shared/data";
import { makeAutoObservable } from "mobx";
import { v4 as uuid } from "uuid";

export interface ToastData {
  id: string,
  message: string,
  title: string,
  toastType: ToastType,
  action?: () => void
}

export enum ToastType {
  Error, Warning, Info, Success, Copy, NotifyOn, NotifyOff,
}

class ToastsStore {
  toastList: ToastData[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  addToast = (message: string, title: string = '', toastType: ToastType = ToastType.Info, action?: () => void) => {
    const id = uuid()
    this.toastList.push({ id, title, message, toastType, action })
    this.autoHide(id);
  }

  hideToast = (id: string) => {
    this.toastList = this.toastList.filter((toast) => toast.id !== id);
  }

  addErrorToast(message: string) {
    this.addToast(message, 'Error!', ToastType.Error);
  }

  addInfoToast(message: string) {
    this.addToast(message, 'Information', ToastType.Info);
  }

  addWarnToast(message: string) {
    this.addToast(message, 'Warning!', ToastType.Warning);
  }

  addSuccessToast(message: string) {
    this.addToast(message, 'Success!', ToastType.Success);
  }

  addCopyToast(message: string) {
    this.addToast(message, 'Success!', ToastType.Copy);
  }

  private autoHide(id: string) {
    setTimeout(() => this.hideToast(id), HIDE_TOAST_TIMEOUT)
  }

  addNotifyToast = (message: string, isOn: boolean) => {
    this.addToast(message, 'Success!', isOn ? ToastType.NotifyOn : ToastType.NotifyOff);
  }
}

export const toastsStore = new ToastsStore();