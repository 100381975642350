import { gql } from "@apollo/client";

export const createRole = gql`mutation createRole($input: CreateRoleInput) {
    createRole(input: $input){
        id
        name
        title
        type
        color
        textColor
        isVisible
    }
}`;
