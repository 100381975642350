import { MenuItem } from "../drop-down/modele/menu-item";
import styles from "./nav-menu.module.css";
import { NavButton } from "../nav-button/nav-button.comp";

type Props = {
  items: MenuItem[];
  onClick?: (item: MenuItem) => void;
  className?: string;
}

export const NavMenu = ({
  items, onClick, className
}: Props) => {
  return (
    <div className={className ? `${className} ${styles['nav-menu']}` : styles['nav-menu']}>
      {items.map((item) => (
        <NavButton
          key={item.key}
          active={!!item.selected}
          icon={item?.grayIcon ?? 'plus'}
          showDot={!!item.number}
          onClick={() => onClick && onClick(item)}
          disabled={item.disabled} />
      ))}
    </div>
  )
}