import { splitNumber } from "@ui-kit/helpers/split-number";
import COUNTRY_PHONE, { CountryCode } from "../data/countrie-phone";

export type FormatedPhone = {
  country: string,
  phone: string,
  label: string,
  position: number,
}

export const formatPhoneValue = (phone: string): FormatedPhone => {
  const phoneVal = phone[0] === '+' ? phone.substring(1) : phone;

  const { code, number } = splitNumber(phoneVal);
  
  let country: CountryCode|undefined = COUNTRY_PHONE.find((country) => country.phone === code);

  if (!country) {
    return { country: '___', phone, label: '', position: phone.length };
  }

  if (country.next?.length) {
    const foundCountry = checkNext(code, number[0]);
    country = foundCountry ?? country;
  }

  const { code: symbols, label, phoneLength, max: _max, min: _min } = country;
  const max = _max 
    ? _max 
    : Array.isArray(phoneLength) 
      ? phoneLength[0] 
      : phoneLength
        ?? 100;
  const min = _min
    ? _min
    : Array.isArray(phoneLength) 
      ? phoneLength[1] 
      : phoneLength 
        ?? 100;
  const current = phoneVal.length < min 
    ? min 
    : phoneVal.length < max
      ? phoneVal.length
      : max;

  const formated = format(number, current);

  return {
    country: symbols,
    phone: `+${code} ${formated.number}`,
    label,
    position: code.length + 2 + formated.pos,
  }
}

const format = (phone: string, num: number) => {
  const p = phone.length < num 
    ? [...phone.split(''), ...(new Array(num - phone.length).fill(' '))].join('')
    : phone;
  switch(num) {
    case 4:
      return {
        number: `${p[0]}${p[1]}-${p[2]}${p[3]}`,
        pos: getLength(phone.length, num),
      };
    case 5:
      return {
        number: `${p[0]}${p[1]}${p[2]}-${p[3]}${p[4]}`,
        pos: getLength(phone.length, num),
      };
    case 6:
      return {
        number: `${p[0]}${p[1]}${p[2]}-${p[3]}${p[4]}${p[5]}`,
        pos: getLength(phone.length, num),
      };
    case 7:
      return {
        number: `(${p[0]}${p[1]}${p[2]}) ${p[3]}${p[4]}-${p[5]}${p[6]}`,
        pos: getLength(phone.length, num),
      };
    case 8:
      return {
        number: `(${p[0]}${p[1]}${p[2]}) ${p[3]}${p[4]}${p[5]}-${p[6]}${p[7]}`,
        pos: getLength(phone.length, num),
      };
    case 9:
      return {
        number: `(${p[0]}${p[1]}${p[2]}) ${p[3]}${p[4]}${p[5]}-${p[6]}${p[7]}${p[8]}`,
        pos: getLength(phone.length, num),
      };
    case 10:
      return {
        number: `(${p[0]}${p[1]}${p[2]}) ${p[3]}${p[4]}${p[5]}-${p[6]}${p[7]}${p[8]}${p[9]}`,
        pos: getLength(phone.length, num),
      };
    case 11:
      return {
        number: `(${p[0]}${p[1]}${p[2]}) ${p[3]}${p[4]}${p[5]}${p[6]}-${p[7]}${p[8]}${p[9]}${p[10]}`,
        pos: getLength(phone.length, num),
      };
    case 12:
      return {
        number: `(${p[0]}${p[1]}${p[2]}) ${p[3]}${p[4]}${p[5]}${p[6]}-${p[7]}${p[8]}${p[9]}-${p[10]}${p[11]}`,
        pos: getLength(phone.length, num),
      };
    default:
      return {
        number: `(${p[0]}${p[1]}${p[2]}) ${p[3]}${p[4]}${p[5]}${p[6]}-${p[7]}${p[8]}${p[9]}-${p[10]}${p[11]}${p[12]}`,
        pos: getLength(phone.length, num),
      };
  }
}

const getLength = (length: number, num: number): number => {
  switch(num) {
    case 4:
      return (length < 2) ? length : length + 1;
    case 5:
    case 6:
      return (length < 3) ? length : length + 1;
    case 7:
      return (length < 3) 
        ? length + 1 
        : (length < 5)
          ? length + 3
          : length + 4;
    case 8:
    case 9:
    case 10:
      return (length < 3) 
        ? length + 1 
        : (length < 6)
          ? length + 3
          : length + 4;
    case 11:
      return (length < 3) 
        ? length + 1 
        : (length < 7)
          ? length + 3
          : length + 4;
    default:
      return (length < 3) 
        ? length + 1 
        : (length < 7)
          ? length + 3
          : (length < 9) ? length + 4 : length + 5;
  }
}

const checkNext = (code: string, next: string) =>
  COUNTRY_PHONE.find((country) => 
    code === country.phone 
    && (
      country.next?.includes(next) 
      || (country.next?.includes('') && !next)
    )
  );