import { useState } from "react";
import styles from "./what-post.module.css";
import { observer } from "mobx-react-lite";
import post from "@assets/images/post.svg";
import { Header } from "@shared/components";
import { useTranslation } from "react-i18next";

type Props = {
  onBack: () => void;
};

export const WhatPost = observer(({onBack}: Props) => {

  const { t } = useTranslation('help');

  const [isTabletMobile, setIsTabletMobile] = useState(window.innerWidth <= 1079 ? true : false );

  return (
<>
  <Header
    className={styles['header-help']}
    title={t('main.menu.whatPost')}
    hideAvatar={true}
    hideBackArrow={window.innerWidth > 1079}
    onBackClick={onBack}
  />
  <article className={styles['help-ecitty']}>
    <div className={styles['image-container']}>
        <img className={styles['help-image']} src={post} alt='Ecitty post' />
    </div>

    <p className={styles['help-text']}>{t('whatPost.intro')}</p>

    <section>
      <h6 className={styles['help-title']}>{t('whatPost.createPosts.header')}</h6>
      <p className={styles['help-text']}>{t('whatPost.createPosts.text')}</p>
    </section>

    <section>
      <h6 className={styles['help-title']}>{t('whatPost.multimediaContent.header')}</h6>
      <p className={styles['help-text']}>{t('whatPost.multimediaContent.text')}</p>
    </section>

    <section>
      <h6 className={styles['help-title']}>{t('whatPost.reactions.header')}</h6>
      <p className={styles['help-text']}>{t('whatPost.reactions.text')}</p>
    </section>

    <section>
      <h6 className={styles['help-title']}>{t('whatPost.comments.header')}</h6>
      <p className={styles['help-text']}>{t('whatPost.comments.text')}</p>
    </section>

    <section>
      <h6 className={styles['help-title']}>{t('whatPost.sharePost.header')}</h6>
      <p className={styles['help-text']}>{t('whatPost.sharePost.textPartOne')}</p>
      <p className={styles['help-text']}>{t('whatPost.sharePost.textPartTwo')}</p>
    </section>
  </article>
</>
);
});
