import styles from "../confirm-page.module.css";
import { SmsButton, WhatsapButton } from "@ui-kit/components/button/social-buttons/";
import { useTranslation } from "react-i18next";

type Props = {
  typeConfirmWhatsApp?: boolean;
  sendConfirm: (isWhatsapp: boolean) => void;
}

export const ENABLE_SMS_REGISTRATION = process.env.REACT_APP_ENABLE_SMS_REGISTRATION === 'True';

export const ConfirmPageSmsWhats = ({ sendConfirm }: Props) => {
  const { t } = useTranslation('authorization');


  return (
    <>
      <h2 className={styles.title}>{t('confirm.title')}</h2>
      <h3 className={styles.subtitle}>{t('confirm.subtitle.choice')}</h3>
      <div className={styles.buttonConfirmBlock}>
        {ENABLE_SMS_REGISTRATION && <SmsButton onClick={() => sendConfirm(false)} supportingText={true}></SmsButton>}
        <WhatsapButton onClick={() => sendConfirm(true)} supportingText={true}></WhatsapButton>
      </div>
    </>
  );
}
