import { UserPicture } from "@graphql/graphql";
import styles from "./user-chat-bottom-info.module.css";
import { Button } from "@ui-kit";
import { messagesStore, modalCustomDialogsStore } from "@store";
import { UserModalItem } from "@shared/components/user-modal-item/user-modal-item.comp";
import { useState } from "react";
import { ReportModalContent } from "@shared/components/report-modal-content/report-modal-content.com";
import { useSearchParams } from "react-router-dom";
import { useBlockUserFn } from "src/messenger/helpers/use-block-user";
import { reportUser } from "@shared/helpers/report-user";
import { useDeleteChat } from "src/messenger/helpers/use-delete-chat";
import { useTranslation } from "react-i18next";

type Props = {
  user: UserPicture,
  chatId: string,
}

export const UserChatBottomInfo = ({ user, chatId }: Props) => {
  const [isBlocked, setIsBlocked] = useState(user.isBlocked);
  const [searchParams, setSearchParams] = useSearchParams()
  const chatIdParam = searchParams.get('chat');

  const blockUserFn = useBlockUserFn(user, setIsBlocked);

  const { t } = useTranslation('messenger');

  const afterDelete = () => {
    if (chatIdParam == chatId && typeof user.username === 'string') {
      setSearchParams({ chat: user.username });
    }
  }

  const deleteChat = useDeleteChat();

  return <div className={styles['user-chat-bottom-info']}>
    <div className={styles['user-chat-bottom-info__top']}>
      <Button
        className={styles['user-chat-bottom-info__button']}
        hierarchy={isBlocked ? 'primary' : 'secondary-color'}
        onClick={blockUserFn}>
          {isBlocked ? t('chat.userInfo.unlock') : t('chat.userInfo.block')}
      </Button>
      <Button
        className={styles['user-chat-bottom-info__button']}
        hierarchy='secondary-color'
        onClick={() => reportUser(user)}>
          {t('button.report')}
      </Button>
    </div>
    <Button
      hierarchy='secondary-color'
      destructive={true}
      iconType='trash-2'
      onClick={() => deleteChat(user, chatId, afterDelete)}>
       {t('button.delete')}
    </Button>
  </div>
}