import { useNavigate } from "react-router-dom";
import styles from "./no-user-data.module.css";
import { Button } from "@ui-kit";
import { useTranslation } from "react-i18next";

type Props = {
  title: string,
  text: string,
  showButton?: boolean,
}

export const NoUserData = ({ title, text, showButton }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation('wall');

  const onAddPost = () => {
    navigate('/?add=true')
  }

  return <div className={styles['no-user-data']}>
    <h2 className={styles['no-user-data__title']}>
      {title}
    </h2>
    <p className={styles['no-user-data__text']}>
      {text}
    </p>
    {!!showButton && <Button iconType='plus' onClick={onAddPost}>{t('button.publish')}</Button>}
  </div>
}