import Icons, { IconTypes } from "src/assets/gray-icons";
import styles from "./icon.module.css";

export type IconSize= "xxxs" | "xxs" | "xxs-plus" | "xs" |"xs-plus" | "sm" | "md" | "lg" | "xl";
/* history for sizes featured icons "xs"="xxxs", "sm"="xxs", "md"="xxs-plus", "lg"="xs", "xl"="xs-plus" */

export type IconOption = "gray" | "circle" | "circle-shadow" | "square" | "square-shadow";

type Props = {
  size?: IconSize;
  option?: IconOption;
  icon: IconTypes;
  color?: string;
  bgColor?: string;
  borderColor?: string;
  className?: string;
}

export const Icon = ({ size, icon, color, className, option, bgColor, borderColor }: Props) => {
  option = option ?? 'gray';

  className = className ? `${className} ${styles.icon}` : styles.icon;
  let width = 12;

  size = size ?? 'xs';

  switch (size) {
    case 'xxxs':
      className += ` ${styles.icon_xxxs}`;
      width += 12;
      break;
    case 'xxs':
      className += ` ${styles.icon_xxs}`;
      width += 16;
      break;
    case 'xxs-plus':
      className += ` ${styles['icon_xxs-plus']}`;
      width += 20;
      break;
    case 'xs':
      className += ` ${styles.icon_xs}`;
      width += 24;
      break;
      case 'xs-plus':
      className += ` ${styles['icon_xs-plus']}`;
      width += 28;
      break;
    case 'sm':
      className += ` ${styles.icon_sm}`;
      width += 32;
      break;
    case 'md':
      className += ` ${styles.icon_md}`;
      width += 40;
      break;
    case 'lg':
      className += ` ${styles.icon_lg}`;
      width += 48;
      break;
    case 'xl':
      className += ` ${styles.icon_xl}`;
      break;
    default:
      className += ` ${styles.icon_sm}`;
      width += 56;
      break;
  }
  const SvgIcon = Icons[icon];

  const style = {
    ...(bgColor && { backgroundColor: bgColor }),
    ...(borderColor && { borderColor: borderColor })
};

  return (
  <div className={className}>
    {option === 'gray' ? (
        <SvgIcon stroke={color ?? '#101828'} />
    ) : (
        <div className={`${styles[option]} ${styles[`${option}_${size}`]}`}
        style={style}>
            <SvgIcon stroke={color ?? '#101828'} />
        </div>
    )}
</div>
  )
}