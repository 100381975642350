/// //<reference types="@types/googlemaps" />
import React from "react";
import { Cords, useAddCityReducer } from "@add-city";
import { Places } from "@add-city/components";
import { addNewCity, setByUserLocation, setCityByCords } from "@add-city/helpers";
import { useAddCityEffects, useMapController } from "@add-city/hooks";
import { PageNav } from "@shared/components";
import { citiesStore, modalCustomDialogsStore, toastsStore } from "@store";
import { observer } from "mobx-react-lite";
import { Button, Container } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay-ts";
import { useNavigate, useSearchParams } from "react-router-dom";
import usePlacesAutocomplete, { HookReturn } from "use-places-autocomplete";
import styles from "./add-city.module.css";
import { useTranslation } from "react-i18next";

interface Props {
  addToProfile?: boolean,
};

export const AddCity: React.FC<Props> = observer(({ addToProfile }: Props) => {
  const [state, dispatch] = useAddCityReducer();

  const { t } = useTranslation('common');

  const navigate = useNavigate();
  const { fetchCities } = citiesStore;
  const mapController = useMapController([state, dispatch]);

  const [searchParams] = useSearchParams();
  const cityName = searchParams.get('cityName');

  const hookReturn: HookReturn = usePlacesAutocomplete({
    requestOptions: { types: ['(regions)'] },
  });

  const handleMapClick = (event: any) => {
    dispatch({type: 'setError', payload: ''});
    dispatch({type: 'setCity', payload: ''});

    const lat: number = event?.latLng?.lat();
    const lng: number = event?.latLng?.lng();

    setCityByCords(hookReturn, dispatch)(lat, lng)
      .catch((err: any) => toastsStore.addErrorToast(t('toasts.error.getLocalCity')));
  };

  const addNewCityHandler = async (isConfirmed: boolean): Promise<any> => {
    try {
      if (isConfirmed) {
        await addNewCity(state, dispatch, navigate, addToProfile);
        await fetchCities();
      }
    } catch (err: any) {
      toastsStore.addErrorToast(t('toasts.error.setLocalCity'));
    }
  }

  const setShowDialog = () => {
    if (!state.goToMyGeo) {
      modalCustomDialogsStore.showDialog(
        '',
        {
          typeIcon: 'info',
          title: t('addCity.title'),
          message: `${t('addCity.message')} ${state.city ? state.city : ''} ?`,
          firstButtonText: t('addCity.buttonFirst'),
          firstButtonColor: 'secondary-color',
          secondButtonText: t('addCity.buttonSecond'),
          secondButtonColor: 'primary',
          confirmAction: addNewCityHandler,
        });
    } else {
      addNewCityHandler(true)
        .catch((err) => toastsStore.addErrorToast(t('toasts.error.setLocalCity')));
    }
  }

  useAddCityEffects(dispatch, state, setCityByCords(hookReturn, dispatch), navigate, cityName, addToProfile)

  return (
    <LoadingOverlay active={state.loading} spinner>
      {state.goToMyGeo ?
        <PageNav headerText={t('addCity.header')}/> :
        <PageNav headerText={t('addCity.title')} navigationPath={addToProfile ? '/profile' : '/'}/>}
      <Container className={styles.mapMainContainer}>
        <Places
          handleMapClick={handleMapClick}
          setCityByCords={setCityByCords(hookReturn, dispatch)}
          city={state.city}
          buttonText={t('addCity.title')}
          addNewCity={setShowDialog}
          hookReturn={hookReturn}
          mapController={mapController}
        />
        <Button
          style={{
            position: 'absolute',
            marginTop: '-2.8rem',
            marginLeft: '0.7rem',
            zIndex: 999,
          }}
          variant='secondary'
          onClick={() => { setByUserLocation(
            (center: Cords) => dispatch({ type: 'setCenter', payload: center }),
            (zoom: number) => dispatch({ type: 'setZoom', payload: zoom }),
            setCityByCords(hookReturn, dispatch),
          ).catch((err: any) => toastsStore.addErrorToast(t('toasts.error.getLocalCity'))); }}
        >
          Go to my location
        </Button>
        {state.error && <p className='form-error'>{state.error}</p>}
      </Container>
    </LoadingOverlay>
  );
});
