
export function determineFileType(file: File): string {
  const extension = file.name.split('.').pop()?.toLowerCase() ?? '';
  const imageExtensions = ['jpeg', 'jpg', 'png', 'gif', 'bmp', 'webp', 'svg'];
  const videoExtensions = ['mp4', 'avi', 'mkv', 'mov', 'wmv', 'flv', '3gp'];

  if (imageExtensions.includes(extension)) {
    console.log('filesize.before', file.size)
    return 'image';
  }

  if (videoExtensions.includes(extension)) {
    return 'video';
  }

  return 'unknown';
}