import { TabItem } from "@ui-kit/components/tabs/tabs.comp";
import { useTranslation } from "react-i18next";

export const useMainTabs = () => {

  const { t } = useTranslation('main');

  const mainTabs: TabItem[] = [
    {
      key: 'wall',
      title: t('tabsMainWall.wall'),
    },
    {
      key: 'read',
      title: t('tabsMainWall.read'),
    },
  ]

  return mainTabs;
}