import { createDate } from "./createDate";
import { getMonthNumberOfDays } from "./getMonthNumberOfDays";

interface CreateMonthProps {
  date?: Date;
  region?: string;
}

export const createMonth = (params?: CreateMonthProps) => {
  const date = params?.date ?? new Date();
  const region = params?.region ?? 'default';

  const dayCurrent = createDate({ date, region });
  const { month: monthName, year, monthNumber, monthIndex } = dayCurrent;

  const getDay = (dayNumber: number) => {
   return createDate({ date: new Date(year, monthIndex, dayNumber), region });
  }
  const createMonthDays = () => {
    const days = [];

    for (let i = 0; i <= getMonthNumberOfDays(monthIndex, year) - 1; i += 1) {
      days[i] = getDay(i + 1);
    }
    return days;
  };

  return {
    getDay,
    monthName,
    monthIndex,
    monthNumber,
    year,
    createMonthDays
  };
};