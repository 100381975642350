import styles from "../button.module.css";
import socialIcons from "../../../../assets/social-icons/index";
import { useTranslation } from "react-i18next";

type WhatsapButtonProps = {
  onClick?: () => void;
  disabled?: boolean;
  supportingText: boolean;
};

const WhatsapButton = ({
  onClick,
  disabled,
  supportingText,
}: WhatsapButtonProps) => {

  if (!onClick) {
    onClick = () => {};
  }

  const { t } = useTranslation('common');

  return (
    <button
      className={`${styles['button']} ${styles['button_size-xl']} ${styles['button_quaternary-color']}`}
      onClick={onClick}
      disabled={disabled}
    >
      <img
      src={disabled ? socialIcons['social-whats-grey'] : socialIcons['social-whats-blue']} alt='sms Icon' />
      {supportingText && <span>{t('socialButton.whatsApp')}</span>}
    </button>
  );
};


export default WhatsapButton;