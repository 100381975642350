import { MapController } from "@add-city/types";
import { toastsStore } from "@store";
import { Button, Container } from "react-bootstrap";
import { HookReturn, getGeocode, getLatLng } from "use-places-autocomplete";
import { DropdownInput } from "@ui-kit/components/input/dropdown-input/dropdown-input";
import { useTranslation } from "react-i18next";

export const ZOOM_CITY = 8;

interface Props {
  setCityByCords: (lat: number, lng: number) => Promise<any>,
  hookReturn: HookReturn,
  mapController: MapController,
  city: string,
  addNewCity: () => void,
  buttonText: string,
}

export const PlacesAutocomplete = ({
  city, setCityByCords, hookReturn, mapController, addNewCity, buttonText
}: Props) => {
  const {
    value,
    setValue,
    suggestions: { data },
    clearSuggestions,
  } = hookReturn;

  const { t } = useTranslation('common');

  const handleSelect = async (address: any) => {
    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = getLatLng(results[0]);
    mapController.setCenter({ lat, lng });
    mapController.setZoom(ZOOM_CITY);

    await setCityByCords(lat, lng);
    mapController.setSelected({ lat, lng });
  };

  return (
    <Container>
      <DropdownInput
          text={value}
          onchange={(str: string) => setValue(str, true)}
          placeholder={t('addCity.searchPlaceholder')}
          type='search'
          items={
            data
              .filter((response) => response.types.includes('locality') && response.types.includes('political'))
              .map(({ place_id, description }) => ({
                key: place_id,
                title: description,
                type: 'menu-item',
              }))
          }
          selecteItem={(address) => {
            handleSelect(address.title)
              .catch((error) => toastsStore.addErrorToast(t('toasts.error.setLocalCity')));
          }}
        />
        <Button
          className='gm-control-active'
          style={{minWidth: '130px', marginLeft: '8px'}}
          variant='primary'
          disabled={!city || city !== value}
          onClick={addNewCity} >
          {buttonText}
        </Button>
    </Container>
  );
};