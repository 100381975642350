import styles from "../confirm-page.module.css";
import { Button, CodeInput } from "@ui-kit";
import CountdownTimer from "./countdown.timer";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
    typeConfirmWhatsApp: boolean;
    setCode: React.Dispatch<React.SetStateAction<string>>;
    confirm: (code: string) => void;
    code: string;
    isCodeComplete: boolean;
    handleLinkTimer: () => void;
    handleTimeout: () => void;
    isBackButton?: boolean;
    handlebackwardButton?: () => void;
    onSendedTextChange: (isSendedText: boolean) => void;
    isEmailConfirm?: boolean;
}

export const ConfirmComponent = ({
    typeConfirmWhatsApp,
    setCode,
    confirm,
    code,
    isCodeComplete,
    handleTimeout,
    isBackButton,
    handlebackwardButton,
    onSendedTextChange,
    isEmailConfirm
}: Props) => {
    isBackButton = isBackButton ?? true;
    isEmailConfirm = isEmailConfirm ?? false;

    const { t } = useTranslation('authorization');

    const [localIsCodeComplete, setLocalIsCodeComplete] = useState(isCodeComplete);
    const [_code, _setCode] = useState('');

    useEffect(() => setCode(code), []);
    useEffect(() => setCode(_code), [_code]);

    useEffect(() => {
        setLocalIsCodeComplete(isCodeComplete);
    }, [isCodeComplete]);

    useEffect(() => {
        const complete = _code.length === 4;
        setLocalIsCodeComplete(complete);
    }, [_code]);

    return (
        <>
            <h2 className={styles.title}>{t('confirm.title')}</h2>
            <h3 className={styles.subtitle}>
                {!typeConfirmWhatsApp && !isEmailConfirm && t('confirm.subtitle.sms')}
                {typeConfirmWhatsApp && !isEmailConfirm && t('confirm.subtitle.whatsApp')}
                {isEmailConfirm && typeConfirmWhatsApp && t('confirm.subtitle.email')}
            </h3>
            <CodeInput
                className={styles['code-input']}
                digits={4}
                size='sm'
                onchange={_setCode}
                text={_code}
                placeholder='0000'
            />
            <div className={styles['bottom-text']}>
                <CountdownTimer
                    onSendedTextChange={onSendedTextChange}
                    typeConfirmWhatsApp={typeConfirmWhatsApp}
                    initialSeconds={60}
                    onTimeout={handleTimeout} />
            </div>
            <div className={styles.buttonConfirmBlock}>
                {isBackButton && <Button size='xl' onClick={handlebackwardButton} hierarchy='quaternary-color' iconType='chevron-left-color'>{t('confirm.button.back')}</Button>}
                <Button size='xl' onClick={() => confirm(_code)} disabled={!localIsCodeComplete}>{t('confirm.button.confirm')}</Button>
            </div>
        </>
    );
};
