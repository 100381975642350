import { Notification, UserPicture } from "@graphql/graphql";
import styles from "./notification-item.module.css";
import { Avatar, Button } from "@ui-kit";
import { ReactionTypes } from "src/assets/reactions";
import { getNotificationMesssage } from "src/notifications/helpers/get-notification-message";
import { useState } from "react";
import { getCorrectPostDate } from "src/main-page/components/post/helpers/get-correct-post-date";
import { useNotificationLinkText } from "src/notifications/helpers/use-notification-link-text";
import { getNotificationLink } from "src/notifications/helpers/get-notification-link";
import { followersStore, toastsStore } from "@store";
import { useNavigate } from "react-router-dom";
import { ItemMenu } from "@shared/components/item-menu/item-menu.comp";
import { NOTIFICATION_MENU_KEYS, useNotificationMenu } from "src/notifications/helpers/use-notification-menu";
import { MenuItem } from "@ui-kit/components/drop-down/modele/menu-item";
import { notificationsStore } from "src/store/notifications.store";
import { useTranslation } from "react-i18next";

type Props = {
  item: Notification,
  following: UserPicture[],
}

export const NotificationItem = ({ item, following }: Props) => {
  let className = item.read
    ? styles['notification-item']
    : `${styles['notification-item']} ${styles['notification-item_new']}`;

  if (item.isHide) {
    className += ` ${styles['notification-item_hide']}`
  }

  const [disabled, setDisabled] = useState(false);

  const onMessageMenuClick = (menuItem: MenuItem) => {
    if (menuItem.key === NOTIFICATION_MENU_KEYS.HIDE) {
      notificationsStore.hideNotification(item.id);
    }
  };

  const navigation = useNavigate();

  const { t } = useTranslation('notification');

  const getNotificationLinkText = useNotificationLinkText();
  const getNotificationMenu = useNotificationMenu();

  return <div className={className}>
    {!item.read && <div className={styles['notification-item__dot']}></div>}
    <Avatar
      alt={item.from.username ?? 'avatar'}
      url={item.from.picture?.url}
      size='xl'
      statusIcon={item.from.isOnline && !item.reaction?.type ? 'online' : undefined}
      avatarReaction={item.reaction?.type as ReactionTypes ?? undefined} />
    <div className={styles['notification-item__content']}>
      <div className={styles['notification-item__title']}>
        <span className={styles['notification-item__fullname']}>
          {item.from.firstname} {item.from.lastname}
        </span> <span className={styles['notification-item__time']}>
          {getCorrectPostDate({ createdAt: item.createdAt })} {t('message.back')}
        </span>
      </div>
      <a className={styles['notification-item__text']} href={getNotificationLink(item)}>
        <span className={styles['notification-item__main-text']}>
          {getNotificationMesssage(item.notifyType, t)}
        </span> <span className={styles['notification-item__link']}>
          {getNotificationLinkText(item)}
        </span>
      </a>
      {item.notifyType === 'message'
        && <Button
            size='lg'
            hierarchy='link-gray'
            onClick={() => navigation(`/messages?chat=${item.from?.username}`)}
          >
            Ответить
          </Button>}
      {item.notifyType === 'subscription'
        && !following.find((user) => user.username === item.from.username)
        && <Button
            className={styles['notification-item__button']}
            size='md'
            hierarchy='primary'
            onClick={() =>
              item.from.username
              && followersStore.follow(item.from.username)
                .then(() => setDisabled(true))
            }
            disabled={disabled}
          >
            {t('button.follow')}
          </Button>
        }
    </div>
    <ItemMenu
      className={styles['notification-item__more-menu']}
      toRight={true}
      items={getNotificationMenu(item)}
      onClick={(menuItem) => onMessageMenuClick(menuItem)} />
  </div>
}