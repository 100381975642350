import styles from "./liked-posts.module.css";
import { useEffect, useState } from "react";
import { toastsStore, userStore, wallPostsStore } from "@store";
import { Loader } from "@ui-kit/components/loader/loader.comp";
import { PostComponent } from "src/main-page/components/post/post.comp";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
import { Post } from "@graphql/graphql";
import { WallReaction } from "../wall-reaction/wall-reaction.comp";
import { NoUserData } from "../no-user-data/no-user-data.comp";
import { useTranslation } from "react-i18next";

type Props = {
  className?: string;
  username?: string;
}

export const LikedPosts = observer(({ username, className }: Props) => {
  className = className ? `${className} ${styles.posts}` : styles.posts

  const [loadMore, setLoadMore] = useState<boolean>();

  const { loading, fetchPosts, postsWithReactions, nextToken } = wallPostsStore;

  const { t } = useTranslation('wall');

  const location = useLocation();

  useEffect(() => {
    if (username) {
      fetchPosts(username, 'liked')
        .catch(() => toastsStore.addErrorToast(t('toasts.error.loadPosts')));
    }
  }, [username]);

  useEffect(() => {
    const scrollHandler = (e: any) => {
      const position = e.target.scrollTop + window.innerHeight + 10;
      if (position >= e.target.scrollHeight ) {
        setLoadMore(true);
      }
    }

    document.body.addEventListener('scroll', scrollHandler, false);
    return () => {
      document.body.removeEventListener('scroll', scrollHandler);
    };
  }, [location.pathname]);

  useEffect(() => {
    if (loadMore && username && nextToken) {
      fetchPosts(username, 'liked')
        .then(() => setLoadMore(false))
        .catch(() => toastsStore.addErrorToast(t('toasts.error.loadPosts')));
    } else {
      setLoadMore(false);
    }
  }, [loadMore]);

  return <div className={className}>
    {!!postsWithReactions?.length && postsWithReactions?.filter((item) => item.post)?.map((item) => (
      <WallReaction key={`${item.post?.id}-${item.reaction?.id}`} item={item} />
    ))}
    {!!loading && <div className={styles.loader}>
      <Loader />
    </div>}
    {!loading && postsWithReactions?.length === 0 && <NoUserData
      title={
        userStore.currentUser?.username === username
          ? t('message.notLikes.yet')
          : t('message.notLikes.not')
        }
      text={
        userStore.currentUser?.username === username
          ? t('message.yourOpinion.try')
          : t('message.yourOpinion.write')
        }
      />}
  </div>
});
