import { createLinkData } from "@graphql/docs/mutations";
import { LinkData } from "@graphql/graphql";
import { apolloClient } from "src/apollo-client";

class LinkService {
  createLinkData = async (url: string): Promise<LinkData|null> => {
    try {
      const res = await apolloClient.mutate<{createLinkData: LinkData}>({
        mutation: createLinkData,
        variables: { url },
      })
      return res.data?.createLinkData ?? null
    } catch {
      return null;
    }
  }
}

export const linkService = new LinkService();