import { ErrorFallback } from "@shared/components";
import { citiesStore, postListStore, toastsStore } from "@store";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Loader } from "react-bootstrap-typeahead";
import Stack from "react-bootstrap/Stack";
import { ErrorBoundary } from "react-error-boundary";
import { PostItem } from "./post-item";

export const PostList = observer(() => {
  const { currentCity } = citiesStore;
  const [loadMore, setLoadMore] = useState(false);
  const { posts, loading, fetchPosts, cityId } = postListStore;

  useEffect(() => {
    if (currentCity?.place_id === cityId) {
      return;
    }
    fetchPosts(true).catch((err: any) => toastsStore.addErrorToast('Can not get posts!'));
  }, [currentCity])

  useEffect(() => {
    if (loadMore && !loading) {
      fetchPosts().then(() => setLoadMore(false)).catch((err: any) => toastsStore.addErrorToast('Can not get posts!'));
    }
  }, [loadMore])

  const scrollHandler = (e: any) => {
    const position = e.target.documentElement.scrollTop + window.innerHeight + 10
    if (position >= e.target.documentElement.scrollHeight ) {
      setLoadMore(true);
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler);
    return () => {
      document.removeEventListener('scroll', scrollHandler);
    }
  }, [])

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Stack gap={2}>
        {(posts?.length !== undefined) &&  posts?.map((post) => post && <PostItem key={post?.id} post={post} />)}
        {loading && <Loader />}
      </Stack>
    </ErrorBoundary>
  );
});
