import { GMap } from "@add-city/components";
import { MapController } from "@add-city/types";
import "@reach/combobox/styles.css";
import { HookReturn } from "use-places-autocomplete";

interface Props {
  handleMapClick: (event: any) => void,
  city: string,
  setCityByCords: (lat: number, lng: number) => Promise<any>,
  addNewCity: () => void,
  hookReturn: HookReturn,
  mapController: MapController,
  buttonText: string,
}

export function Places({
  handleMapClick, city, setCityByCords, addNewCity, hookReturn, mapController, buttonText
}: Props) {
  return <GMap
    setCityByCords={setCityByCords}
    handleMapClick={handleMapClick}
    city={city}
    addNewCity={addNewCity}
    hookReturn={hookReturn}
    mapController={mapController}
    buttonText={buttonText}/>;
}
