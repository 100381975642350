import { InputComponent } from "../input.comp";
import { MenuItem } from "@ui-kit/components/drop-down/modele/menu-item";
import { Menu } from "@ui-kit/components/drop-down/comp/dropdown-menu/menu.comp";
import styles from "./dropdown-input.module.css";

type Props = {
  label?: string,
  text : string,
  placeholder?: string,
  onchange: (str: string) => void,
  className?: React.HTMLAttributes<HTMLDivElement>|string,
  items: MenuItem[],
  type: "text"|"password"|"phone"|"number"|"search"|"user",
  selecteItem: (item: MenuItem) => void,
  item?: MenuItem,
  onFocus?: () => void;
  onBlur?: () => void;
  hideInput?: boolean;
}

export const DropdownInput = (props: Props) => {
  const { items, selecteItem } = props;

  return <>
    {!props.hideInput && <InputComponent {...props} className={styles.input}/>}
    {items.length > 0 && <Menu
      className={styles.dropdown__menu}
      items={items} onClick={selecteItem}
    />}
  </>
}