import styles from "./user-notification-switcher.module.css";
import { Toggle } from "@ui-kit/components/toggle/toggle.comp";
import { UserPicture } from "@graphql/graphql";
import { useEffect, useState } from "react";
import { messagesStore, userStore } from "@store";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

type Props = {
  user: UserPicture,
  chatId: string,
}

export const UserNotificationSwitcher = observer(({ user, chatId }: Props) => {
  const [isOn, setIsOn] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const { t } = useTranslation('messenger');

  useEffect(() => {
    const chat = messagesStore.chats.find((chat) => chat.chatId === chatId);
    if (!chat) {
      setDisabled(true);
      return;
    }
    setIsOn(
      !!userStore.currentUser?.username
      && !!chat.offNotifyUsernames?.includes(userStore.currentUser.username)
    )
  }, [messagesStore.chats, chatId]);

  const onSwitch = async () => {
    messagesStore.switchNotification(chatId, !isOn);
  }

  return <div className={styles['user-notification-switcher']}>
    <p className={styles['user-notification-switcher__title']}>{t('chat.userInfo.notification')} {user.firstname} {user.lastname}</p>
    <div className={styles['user-notification-switcher__switcher']}>
      <Toggle initialState={isOn} onToggle={onSwitch} title='' disabled={disabled} />
    </div>
  </div>
});
