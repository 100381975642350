import styles from "./link.module.css";
import { useNavigate } from "react-router-dom"

export type LinkTypes = "gray" | "gray-underline";

type Props = {
  children: any,
  link?: string,
  click?: () => void,
  typeLink?:LinkTypes,
  className?: string,
}

export const LinkComponent = ({children, link, click, typeLink, className}: Props) => {
  const navigate = useNavigate();

  const onClick = () => {
    if (link) {
      navigate(link);
    }

    if (click) {
      click();
    }
  }

  let typeLinkStyle = '';
  switch (typeLink) {
    case 'gray': typeLinkStyle = styles['link_gray']; break;
    case 'gray-underline': typeLinkStyle = styles['link_gray-underline']; break;
  }

  return (
    <a className={`${styles.link} ${typeLinkStyle} ${className ? className : ''}`} onClick={() => onClick()}>{children}</a>
  )
}