import { citiesStore, userStore } from "@store";
import styles from "./city-selector.module.css";
import { City } from "@graphql/graphql";
import { Button } from "@ui-kit";
import { useEffect, useState } from "react";
import { Menu } from "@ui-kit/components/drop-down/comp/dropdown-menu/menu.comp";
import { MenuItem } from "@ui-kit/components/drop-down/modele/menu-item";
import { cityToMenuItem } from "src/main-page/helpers/city-to-menu-item";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Props = {
  className: string,
}

export const CitySelector = observer(({ className }: Props) => {
  className = className ? `${className} ${styles['city-selector']}` : styles['city-selector']

  const { t } = useTranslation('main');

  const clearCity: City = {
    id: 'no-city',
    place_id: 'no-city',
    langs: [],
    longName: t('citySelector.noCity'),
  }

  const { currentUser, me } = userStore;
  const { cities, currentCity, onChangeCurrentCity, fetchCities } = citiesStore;

  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [item, setItem] = useState<MenuItem|undefined>();
  const [isMenuVisible, setMenuVisible] = useState<boolean>(false);
  const [dropdownAnimation, setDropdownAnimation] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    if(userStore.currentUser || userStore.currentUser === null) {
      fetchCities(true);
    }
  }, [userStore.currentUser]);

  useEffect(() => {
    const mainCity = cities.find((city) => city.place_id === currentUser?.cityId) ?? clearCity;
    const otherCities = (
      currentUser?.cityList
        ?.filter((city) => !!city)
        ?.filter((city) => city?.place_id !== mainCity?.place_id)
        ?.slice(0, 2) ?? []) as City[];

    const cityList: City[] = [
      mainCity, ...otherCities
    ]

    const newMenuItems: MenuItem[] = currentUser
      ? [
        ...cityList
          .map((city, i) => cityToMenuItem(city, i, currentCity?.place_id === city.place_id)),
        {
          key: 'add-new',
          type: 'button',
          grayIcon: 'plus',
          showIcon: true,
          title: t('citySelector.addCity.title')
        }
      ]
      : [
        ...cities.map((city, i) => cityToMenuItem(city, i, currentCity?.place_id === city.place_id))
      ];
    setMenuItems(newMenuItems);
    const cityItem = newMenuItems.find((item) => item.key === currentCity?.place_id);
    setItem(cityItem);
  }, [currentCity]);

  const onClick = () => {
    setMenuVisible(false);
  }

  const changeItemHandler = (item: MenuItem) => {
    const city = cities.find((city) => city.place_id === item.key);
    if (city) {
      menuItems.forEach((currentItem) => {
        currentItem.selected = currentItem.key === item.key;
      });
      onChangeCurrentCity(city);
      setItem(item);
    }

    if (item.key === 'add-new') {
      navigate({ pathname: '/map/select' });
    }
  }

  useEffect(() => {
    if (isMenuVisible) {
      setTimeout(() => {
        document.addEventListener('click', onClick, { once: true });
      }, 0)
    }
    setDropdownAnimation(isMenuVisible);
  }, [isMenuVisible])

  const showMenu = () => {
    if (currentUser) {
      setMenuVisible((prev) => !prev);
    } else {
      setMenuVisible(false);
      navigate('/map/select', { state: { goToMyGeo: true }});
    }
  }

  return <div className={className}>
    <Button
      className={styles.button}
      onClick={showMenu}
      iconType={isMenuVisible ? 'chevron-up' : 'chevron-down'}
      size='lg'
      hierarchy='secondary-color'
      rightIcon={true}
    >
      {item?.title ?? t('loader')}
    </Button>
    {isMenuVisible &&
      <Menu
        className={`${styles.dropdown__menu} ${!dropdownAnimation ? styles.dropdown__menu_hide : ''}`}
        itemClassName={styles.dropdown__menu_item}
        checkClassName={styles.dropdown__menu_check}
        items={menuItems}
        onClick={changeItemHandler}
      />
    }
  </div>
});
