import { Post, Comment, Message, Reaction } from "@graphql/graphql";
import styles from "./reactions.module.css";
import { ReactionComponent } from "../reaction/reaction.comp";
import { ReactionTypes } from "src/assets/reactions";
import { userStore } from "@store";
import { makeReaction } from "../helpers/make-reaction";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

type Props = {
  item: Post|Comment|Message,
  showReactions?: boolean,
  isComment?: boolean,
  hideOneDigit?: boolean,
}

const types = ["heart", "like", "dislike", "joy", "sad", "angry" ];
const commentTypes = ["like", "dislike" ];

export const ReactionsComponent = observer(({ item, showReactions, isComment, hideOneDigit }: Props) => {
  const { currentUser } = userStore;
  const username = currentUser?.username

  const handleClick = async (type: ReactionTypes) => {
    if (!currentUser || !item?.id) {
      return;
    }

    const postId = item.__typename === 'Comment'
      ? item.postId ?? item.commentId
      : undefined;
    await makeReaction(item, type, postId ?? undefined);
  }

  return <div className={styles.reactions}>
    {(isComment ? commentTypes : types).map((type) => {
      const count = item?.reactions?.reduce((sum, reaction) =>
        reaction?.type === type ? sum + 1 : sum, 0
      ) ?? 0;
      const isShow = !!showReactions || count > 0;

      return isShow && <ReactionComponent
        key={type}
        onClick={() => handleClick(type as ReactionTypes)}
        type={type as ReactionTypes}
        choose={!!item?.reactions?.find((reaction) =>
          reaction?.type === type && reaction.username === username
        )}
        count={(count === 1 && !!hideOneDigit && !showReactions) ? undefined : count} />}) ?? 0
    }
  </div>
});
