import { gql } from "@apollo/client";

export const blockUser = gql`mutation blockUser($username: String) {
    blockUser(username: $username){
        username
        blockedUsername
        isBlocked
        blockData{
            isBlocked
            createdAt
            updatedAt
        }
        createdAt
        updatedAt
    }
}`;
