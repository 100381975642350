import { MenuItem } from "@ui-kit/components/drop-down/modele/menu-item";
import { CountryCode } from "@ui-kit/data/countrie-phone";

export const phoneCodesToMenuItems = (codes: CountryCode[], countryCode: string): MenuItem[] =>
  codes.map((code, i) => ({
    key: i.toString(),
    type: 'phone',
    title: code.label,
    countryCode: code.code,
    subTitle: code.phone,
    selected: code.code === countryCode,
  }));