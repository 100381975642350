/* eslint-disable react-hooks/exhaustive-deps */
import { AddCityAction, AddCityState, Cords } from "@add-city/add-city.reducer";
import { setByUserLocation } from "@add-city/helpers";
import { getUserLocation } from "@shared/helpers";
import { toastsStore } from '@store';
import { useEffect } from 'react';
import { userStore } from "src/store/user.store";
import { useTranslation } from "react-i18next";

export const useAddCityEffects = (
  dispatch: React.Dispatch<AddCityAction>,
  state: AddCityState,
  setCityByCords: (lat: number, lng: number) => Promise<any>,
  navigate: (path: string) => void,
  cityName: string | null,
  addToProfile?: boolean
) => {
  const { t } = useTranslation('common');

  useEffect(() => {
    if (userStore.currentUser && !userStore.currentUser?.cityId) {
      dispatch({ type: 'letsGoToMyGeo' });
    };
  }, [userStore.currentUser]);

  useEffect(() => {
    if(addToProfile) {
      getUserLocation()
        .catch((err: any) => toastsStore.addErrorToast(t('toasts.error.setLocalCity')));
    }
  },[addToProfile]);

  useEffect(() => {
    if (cityName) {
      setByUserLocation(
        (center: Cords) => dispatch({ type: 'setCenter', payload: center }),
        (zoom: number) => dispatch({ type: 'setZoom', payload: zoom }),
        setCityByCords
      ).catch((err) => toastsStore.addErrorToast(t('toasts.error.getLocalCity'))) }
  }, [])

  useEffect(() => {
    if (state.goToMyGeo) {
      setByUserLocation(
        (center: Cords) => dispatch({ type: 'setCenter', payload: center }),
        (zoom: number) => dispatch({ type: 'setZoom', payload: zoom }),
        setCityByCords
      ).catch((err: any) => toastsStore.addErrorToast(t('toasts.error.getLocalCity'))) }
  }, [state.goToMyGeo]);

  useEffect(() => {
    if (userStore.currentUser?.cityId && state.goToMyGeo) {
      navigate('/')
    };
  }, [userStore.currentUser])
}