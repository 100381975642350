import { Button } from "@ui-kit";
import styles from "./live.module.css";
import { LiveUser } from "@shared/data/live-user";
import { observer } from "mobx-react-lite";
import { liveStore } from "@store";
import { useEffect } from "react";
import { LiveUserItem } from "@shared/components/live-user-item/live-user-item.comp";
import { RightMenuItem } from "@shared/components/right-menu-item/right-menu-item.comp";
import { useTranslation } from "react-i18next";

export const LiveComponent = observer(() => {
  const { loadLiveList, liveList } = liveStore;

  const { t } = useTranslation('main');

  useEffect(() => { loadLiveList(); }, []);

  return liveList?.length
    ? <RightMenuItem>
        <div className={styles.header}>
          <h2 className={styles.title}>{t('live.header')}</h2>
          <Button size='sm' hierarchy='link-gray'>{t('live.link')}</Button>
        </div>
        {liveList.slice(0, 3).map((liveUser) =>
          <LiveUserItem key={liveUser.user.username} user={liveUser} />
        )}
      </RightMenuItem>
    : <></>;
});
