import { Avatar } from "@ui-kit/components/avatar/avatar.comp";
import styles from "./wall-comment.module.css";
import { CommentWithPost } from "@graphql/graphql";
import { Link } from "react-router-dom";
import { UserPicture } from "src/types";
import { getCorrectPostDate } from "src/main-page/components/post/helpers/get-correct-post-date";
import { ItemMenu } from "@shared/components/item-menu/item-menu.comp";
import { WALL_MENU_KEYS, useUserWallMenu } from "src/user-wall/helpers/use-user-wall-menu";
import { userStore } from "@store";
import { useState } from "react";
import { MenuItem } from "@ui-kit/components/drop-down/modele/menu-item";
import { hideService } from "@shared/services/hide.service";
import { useTranslation } from "react-i18next";

type Props = {
  item: CommentWithPost,
  user: UserPicture,
}

export const WallComment = ({ item, user }: Props) => {
  const [showItem, setShowItem] = useState(true);

  const { t } = useTranslation('wall');

  const getUserWallMenu = useUserWallMenu();

  let postText = item.post?.text?.split('\n').join(' ');
  postText = postText?.split(' ').length === 1 && postText.length > 40
    ? `${postText.substring(0, 37)}...`
    : postText;

  if (!item.post?.text) {
    postText = t('message.notDescription');
  }

  const commentId = item.id;

  const onMenuClick = (item: MenuItem) => {
    switch (item.key) {
      case WALL_MENU_KEYS.HIDE:
        hideService.hideComment(commentId)
          .then((res) => {
            if (res) {
              setShowItem(false);
            }
          });
        break;
    }
  }

  const className = !showItem
    ? `${styles['wall-comment']} ${styles['wall-comment_hide']}`
    : styles['wall-comment']

  if (item.isHiden) {
    return <></>
  }

  return <div className={className}>
    <Avatar
      className={styles['wall-comment__avatar']}
      size='xl'
      url={user.picture?.url ?? undefined}
      alt={user.username ?? ''}
    />
    <Avatar
      className={styles['wall-comment__avatar-mobile']}
      size='lg'
      url={user.picture?.url ?? undefined}
      alt={user.username ?? ''}
    />
    <div className={styles['wall-comment__content']}>
      <div className={styles['wall-comment__header']}>
        <h3 className={styles['wall-comment__fullname']}>
          {user.firstname ?? ''} {user.lastname ?? ''}
        </h3>
        {!!item && <p className={styles['wall-comment__time']}>
          {getCorrectPostDate(item)}
        </p>}
      </div>
      <div className={styles['wall-comment__bottom']}>
        <span className={styles['wall-comment__text']}>
          Комментирует публикацию
        </span> <Link
          className={styles['wall-comment__link']}
          to={`/post/${item.post?.id}`}
        >
          {postText}
        </Link>
      </div>
      <div className={styles['wall-comment__comment-text']}>
        «{item.text}»
      </div>
    </div>
    <ItemMenu
      className={styles['comment__item-menu']}
      toRight={true}
      items={getUserWallMenu(item, userStore.currentUser?.username ?? '')}
      onClick={onMenuClick} />
  </div>
}