import { UserPicture } from "@graphql/graphql";
import { UserModalItem } from "@shared/components/user-modal-item/user-modal-item.comp";
import { messagesStore, modalCustomDialogsStore } from "@store";
import { useTranslation } from "react-i18next";

export const useDeleteChat = () => {
  const { t } = useTranslation('messenger');

  const handleDelete = (user: UserPicture, chatId: string, afterDelete?: () => void) => {
    modalCustomDialogsStore.showDialog(
      <UserModalItem item={user} />,
      {
      typeIcon: 'warning',
      title: t('dialogs.deleteChat.title'),
      message: t('dialogs.deleteChat.message'),
      secondButtonColor: 'primary',
      secondButtonDestructive: true,
      secondButtonText: t('dialogs.deleteChat.secondButton'),
      firstButtonColor: 'secondary-color',
      firstButtonText: t('dialogs.deleteChat.firstButton'),
      confirmAction: () => messagesStore.deleteChat(chatId).then(() => afterDelete && afterDelete())
      },
    )
  }
  return handleDelete;
}