import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		supportedLngs: ['ru', 'en', 'kk'],
		fallbackLng: 'ru',
		debug: true,
		ns: ['agreement', 'authorization', 'common', 'main', 'messenger', 'notification', 'settings', 'search', 'wall'],
		defaultNS: 'common',
		interpolation: {
			escapeValue: false,
		backend: {
			loadPath: '/public/locales/{{lng}}/{{ns}}.json',
		},
		detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
      lookupLocalStorage: 'i18nextLng'
    },
		react: {
      wait: true
    }
  }
	});

export default i18n;
