import { Cords } from "@add-city/add-city.reducer";

export const getTwoPointsDistance = (cords: Cords[]): number => {
  if (!cords[0] || !cords[1]) {
    return Infinity;
  }
  return Math.sqrt(
    Math.abs(cords[0].lat - cords[1].lat) * Math.abs(cords[0].lat - cords[1].lat) + 
    Math.abs(cords[0].lng - cords[1].lng) * Math.abs(cords[0].lng - cords[1].lng),
  )
};
