import { citiesStore, userStore } from "@store";
import styles from "./search-selector.module.css";
import { City } from "@graphql/graphql";
import { Button } from "@ui-kit";
import { useEffect, useState } from "react";
import { Menu } from "@ui-kit/components/drop-down/comp/dropdown-menu/menu.comp";
import { MenuItem } from "@ui-kit/components/drop-down/modele/menu-item";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Props = {
  className: string,
};


export const SearchSelector = observer(({ className }: Props) => {
  const { t } = useTranslation('search');

  const clearCity: City = {
    id: 'no-city',
    place_id: 'no-city',
    langs: [],
    longName: t('search.button.noCity'),
  };

  className = className ? `${className} ${styles['city-selector']}` : styles['city-selector'];

  const { currentUser, me } = userStore;
  const { currentCity, fetchCities } = citiesStore;

  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [item, setItem] = useState<MenuItem | undefined>();
  const [isMenuVisible, setMenuVisible] = useState<boolean>(false);
  const [dropdownAnimation, setDropdownAnimation] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    me().then(() => {
      fetchCities(true);
    });
  }, []);

  useEffect(() => {
    const updateMenuItems = async () => {
      let newMenuItems: MenuItem[] = [
        {
          key: 'add-new',
          type: 'button',
          grayIcon: 'plus',
          showIcon: true,
          title: t('search.button.addCity'),
        },
      ];
      if (!currentCity || currentCity.id === 'no-city') {
        newMenuItems.unshift({
          key: `${clearCity.id}`,
          type: 'menu-item',
          title: `${clearCity.longName}`,
        });
      }

      setMenuItems(newMenuItems);
    };

    updateMenuItems();
  }, [currentCity, currentUser]);

  const onClick = () => {
    setMenuVisible(false);
  };

  const changeItemHandler = (item: MenuItem) => {
    if (item.key === 'add-new') {
      navigate({ pathname: '/map/select' });
    }
    setItem(item);
  };

  useEffect(() => {
    if (isMenuVisible) {
      setTimeout(() => {
        document.addEventListener('click', onClick, { once: true });
      }, 0);
    }
    setDropdownAnimation(isMenuVisible);
  }, [isMenuVisible]);

  const showMenu = () => {
    if (currentUser) {
      setMenuVisible((prev) => !prev);
    } else {
      setMenuVisible(false);
      navigate('/map/select', { state: { goToMyGeo: true }});
    }
  };

  return (
    <div className={className}>
      <Button
        className={styles.button}
        onClick={showMenu}
        iconType={isMenuVisible ? 'chevron-up' : 'chevron-down'}
        size='lg'
        hierarchy='secondary-color'
        rightIcon={true}
      >
        {currentCity?.longName ?? t('search.loading')}
      </Button>
      {isMenuVisible &&
        <Menu
          className={`${styles.dropdown__menu} ${!dropdownAnimation ? styles.dropdown__menu_hide : ''}`}
          itemClassName={styles.dropdown__menu_item}
          checkClassName={styles.dropdown__menu_check}
          items={menuItems}
          onClick={changeItemHandler}
        />
      }
    </div>
  );
});