import { City } from "@graphql/graphql";
import { Header } from "@shared/components/header/header.comp";
import { MapComponent } from "@shared/components/map/map.comp";
import { citiesStore, toastsStore, userStore } from "@store";
import { Button } from "@ui-kit";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import styles from "./map.module.css";
import { OgMetaData } from "@shared/components/og-meta-data/og-meta-data.comp";
import { useTranslation } from "react-i18next";

export const SELECT = 'select'

export const MapPage = () => {
  const { mapType } = useParams();
  const navigate = useNavigate();

  const [city, setCity] = useState<City|null>(null);
  const { t } = useTranslation('common');

  useEffect(() => {
    if(userStore.currentUser) {
      setCity(null);
      citiesStore.fetchCities().then(() => { setCity(null); });
    }
  }, [userStore.currentUser]);

  const selectCity = () => {
    if (city?.place_id) {
      if (userStore.currentUser && !userStore.currentUser?.cityId) {
        return userStore.addUserCity(city.place_id)
          .then(() => citiesStore.fetchCities())
          .then(() => citiesStore.onChangeCurrentCity(city))
          .then(() => navigate('/'))
          .catch(() => toastsStore.addErrorToast(t('toasts.error.isNoCity')));
      } else
      citiesStore.onChangeCurrentCity(city)
        .then(() => navigate('/'))
        .catch(() => toastsStore.addErrorToast(t('toasts.error.isNoCity')));
    }
  }

  return <>
    <OgMetaData type='main' data={t('map.header')} />
    <Header
      className={styles['header_max-width']}
      isMainPage={false}
      title={mapType === SELECT ? t('map.select.city') : t('map.select.map')}
      hideAvatar={true}
    />
    <MapComponent
      selectCity={setCity}
      className={styles.map}
      hideCityList={mapType !== SELECT}/>
    {mapType === SELECT && !!city?.place_id && <div className={styles['button-container']}>
      <Button className={styles.button} onClick={selectCity}>{t('map.button.select')}</Button>
    </div>}
  </>
}