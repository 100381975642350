import React, { useRef, useEffect, useState } from "react";
import styles from "./tooltip.module.css";
import { Icon } from "@home/components/icon/icon.comp";

export type TooltipSize = "small" | "big";

export type TooltipPosition =
  | "center-down"
  | "center-up"
  | "arrow-left"
  | "arrow-right"
  | "arrow-up"
  | "arrow-down"
  | "arrow-down-left"
  | "arrow-down-right";

type Props = {
  className?: React.HTMLAttributes<HTMLDivElement>|string;
  sizeTips?: TooltipSize;
  positionTips?: TooltipPosition;
  tips: string;
};

export const Tooltips = ({
  className,
  sizeTips,
  positionTips,
  tips
}: Props) => {

  sizeTips = sizeTips ?? 'small';
  positionTips = positionTips ?? 'center-up';

  const tooltipRef = useRef<HTMLSpanElement>(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  let positionCssClass = styles[`tooltiptext-${positionTips}`];

  const updateVisibility = () => {
    const tooltip = tooltipRef.current;
    if (tooltip) {
      const rect = tooltip.getBoundingClientRect();

      let newLeft = rect.left;
      let newTop = rect.top;
      let isVisible = true;

      if (rect.right > window.innerWidth) {
        newLeft -= rect.right - window.innerWidth;
        isVisible = false;
      }

      if (rect.left < 0) {
        newLeft -= rect.left;
        isVisible = false;
      }

      if (rect.top < 0) {
        newTop -= rect.top;
        isVisible = false;
      }

      if (rect.bottom > window.innerHeight) {
        newTop -= rect.bottom - window.innerHeight;
        isVisible = false;
      }

      setIsTooltipVisible(isVisible);
    }
  }

  const handleVisibility = (visible: boolean) => {
    setIsTooltipVisible(visible);
    if (visible) {
      updateVisibility();
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateVisibility);
    window.addEventListener('scroll', updateVisibility);

    return () => {
      window.removeEventListener('resize', updateVisibility);
      window.removeEventListener('scroll', updateVisibility);
    };
  }, []);

 return (
    <div className={`${styles.tooltip}`}
        onMouseOver={() => handleVisibility(true)}
        onMouseLeave={() => handleVisibility(false)}
        onFocus={() => handleVisibility(true)}
        onBlur={() => handleVisibility(false)}>
          <Icon icon='help-circle-gray' size='xxs' />
          <span ref={tooltipRef} className={`${styles['tooltiptext']}
            ${positionCssClass}
            ${sizeTips === 'big' ? styles['tooltiptext-big'] :''}
            ${isTooltipVisible ? styles['visible'] : styles['hidden']}`}
        >{tips}</span>
    </div>
  );
};