/* eslint-disable react-hooks/exhaustive-deps */
import { getUserCity } from "@shared/helpers";
import { citiesStore, modalCustomDialogsStore, toastsStore } from "@store";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Container, Row, Stack } from "react-bootstrap";
import { createSearchParams, useNavigate } from "react-router-dom";
import { AppNavigation, PostCreate, PostList } from "./components";
import { AppFooter } from "./components/app-footer/app-footer.comp";
import { CityCover } from "./components/city-cover/city-cover";
import styles from "./home-page.module.css";
import { userStore } from "src/store/user.store";

export const LOCALSTORAGE_CITI_ID_KEY = "requestGeoCityId";

type Props = {};

export const HomePage = observer((props: Props) => {
  const { showDialog } = modalCustomDialogsStore;
  const { cities, currentCity, onChangeCurrentCity } = citiesStore;

  const navigate = useNavigate();

  useEffect(() => {
    if (userStore.currentUser && !userStore.currentUser?.cityId) {
      navigate('/add-city', { state: { goToMyGeo: true }})
    };
  }, [userStore.currentUser])

  const changeCity = (cityName: string) => {
    const city = cities?.find((city) => city?.name === cityName);
    if(city) {
      onChangeCurrentCity(city);
    } else {
      const searchParams = createSearchParams({ cityName }).toString();
      navigate({
        pathname: '/add-city',
        search: searchParams,
      });
    }
  }

  useEffect(() => {
    getUserCity().then((cityByGeo) => {
        const requestGeoCityId = localStorage.getItem(LOCALSTORAGE_CITI_ID_KEY)
        if (!cityByGeo.fullCityName || !currentCity?.name || !currentCity?.place_id) return;
        if (currentCity?.name === cityByGeo.fullCityName || requestGeoCityId === cityByGeo.id) return;
        const onNoButtonClick = () => localStorage.setItem(LOCALSTORAGE_CITI_ID_KEY, cityByGeo.id);
        if (userStore.currentUser) {
          showDialog('', {
            typeIcon: 'info',
            title: 'Change city?',
            message: `Your current location is ${cityByGeo.fullCityName}. Do you want to change city?`,
            firstButtonText: 'No',
            firstButtonColor: 'secondary-color',
            secondButtonText: 'Yes',
            secondButtonColor: 'primary',
            confirmAction: (isConfirmed: boolean) => {
              if (isConfirmed) {
                changeCity(cityByGeo.fullCityName)
              } else {
                onNoButtonClick();
              }
            },
          });
        }
      }).catch((error) => toastsStore.addErrorToast('Can not get user city!'));
  }, [cities]);

  return (
    <>
      <AppNavigation />
      <CityCover />
      <Container fluid className={styles.mainPageContainer}>
        <Row md={6} className='py-3 order-md-0 order-0'>
          <Stack gap={2} className={styles.postsStack}>
            <PostCreate />
            <PostList />
          </Stack>
        </Row>
      </Container>
      <AppFooter />
    </>
  );
});
