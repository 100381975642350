import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

interface Props {
  show: boolean,
  onHide: () => void,
  title: string,
  message: string,
}

export function ModalMessage({ show, onHide, title, message }: Props) {
  const { t } = useTranslation('common');

  return (
    <Modal
      show={show}
      onHide={onHide}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {message}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>{t('modal.button.ok')}</Button>
      </Modal.Footer>
    </Modal>
  );
}