import { useState, useEffect, useRef } from "react";
import styles from "./password.module.css";
import { useForm, SubmitHandler } from "react-hook-form";
import { observer } from "mobx-react-lite";
import { userStore, toastsStore } from "@store";
import { Button, InputFormComponent, Loader, LinkComponent } from "@ui-kit/components/";
import { useValidationRules } from "@ui-kit/components/input/helpers/useValidationRules";
import { Header } from "@shared/components";
import { User } from "src/profile-page/profile.page";
import { useTranslation } from "react-i18next";

type Props = {
  onBack: () => void;
};

export const PasswordSettings = observer(({onBack}: Props) => {
  const [formData, setFormData] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [isTabletMobile, setIsTabletMobile] = useState(window.innerWidth <= 1080 ? true : false );

  const {register, handleSubmit, control, formState:{errors, isValid} } = useForm<User>({mode: 'onBlur'});

  const validationRules = useValidationRules();

  const { t } = useTranslation('settings');

  const formsPrevent = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const formSubmitHandler: SubmitHandler<User> = (data: User) => {
    setFormData(data);
  };

  useEffect(() => {
    if (formData) {
      changeExistsPassword(formData);
    }
  }, [formData]);

  const changeExistsPassword = async (data: User) => {
    const { passwordCurrent, password, passwordRepeat } = data;
  if (password === passwordRepeat && passwordCurrent && password){
  try {
      setIsLoading(true);
      await userStore.changeExistsPassword({
        currentPassword: passwordCurrent,
        newPassword: password
      });
      await userStore.me();
      toastsStore.addSuccessToast(t('toasts.success.password'));
    } catch (err) {
      console.error(err);
      toastsStore.addErrorToast(t('toasts.error.save'));
    } finally {
      setIsLoading(false);
    }
  } else {
      toastsStore.addErrorToast(t('toasts.error.passwordMatch'));
    }
  }

 if (isLoading) {
      return (<div className={styles['loading']}>
                <Loader loaderType='line' />
            </div>
  )}

  return (
<>
  <Header
    className={styles['header-settigs']}
    title={t('password.header')}
    hideAvatar={true}
    hideBackArrow={window.innerWidth > 1079}
    onBackClick={onBack}
  />
  <section className={styles['profile-password']}>
    <form onSubmit={formsPrevent}>
      <div className={styles['input-block']}>
        <InputFormComponent
          className={styles['input-old-password']}
          label={t('password.oldPasswordInput.password')}
          type='password'
          control={control}
          placeholder={t('password.oldPasswordInput.placeholder')}
          hasErrors={!!errors.passwordCurrent}
          {...register('passwordCurrent', validationRules.passwordCurrent)}
          errorsMess={errors.passwordCurrent && errors.passwordCurrent.message}
        />
        <LinkComponent
          className={styles['link-forgot-password']}
          typeLink='gray-underline'
          link='/confirm-recover'
        >
          {t('password.link.forgot')}
        </LinkComponent>
        <InputFormComponent
          label={t('password.newPasswordInput.password')}
          type='password'
          control={control}
          placeholder={t('password.newPasswordInput.placeholder')}
          supportingText={t('password.newPasswordInput.supportingText')}
          hasErrors={!!errors.password}
          {...register('password', validationRules.password)}
          errorsMess={errors.password && errors.password.message}
        />
        <InputFormComponent
          label={t('password.repeatPasswordInput.password')}
          type='password'
          control={control}
          placeholder={t('password.repeatPasswordInput.placeholder')}
          hasErrors={!!errors.passwordRepeat}
          {...register('passwordRepeat', validationRules.passwordRepeat)}
          errorsMess={errors.passwordRepeat && errors.passwordRepeat.message}
        />
      </div>
    </form>
    <Button
      className={styles['button-password']}
      disabled={!isValid}
      type='submit'
      onClick={handleSubmit(formSubmitHandler)}
      iconType='save'
    >
      {t('password.button.save')}
    </Button>
  </section>
</>
);
});