import { MediaFile } from "@graphql/graphql";
import styles from "./galery.module.css";
import { MediaItemComponent } from "../media-item/media-item.comp";
import { Icon } from "@home/components/icon/icon.comp";
import ReactPlayer from "react-player";

type Props = {
  media?: MediaFile[]|null,
  pictureState: [number, React.Dispatch<React.SetStateAction<number>>],
  className?: string,
}

export const Galery = ({ media, pictureState, className }: Props) => {
  const [current, setCurrent] = pictureState;

  className = className ? `${className} ${styles.galery}` : styles.galery;

  if (!media || media.length <= 0 || current < 0 || current >= media.length) {
    return <></>
  }

  return <div className={className}>
      <div className={styles['prev-button']}>
        {current > 0 && <button className={styles.button} onClick={() => setCurrent((prev) => prev - 1)}>
          <Icon icon='chevron-left' color='#344054' size='xxs-plus' />
        </button>}
      </div>
      <div className={styles.picture}>
        {media[current].type?.includes('image')
          ? (<img
              className={styles['media-item']}
              src={media[current].url ?? ''}
              alt={media[current].type ?? 'picture'} />)
          : (<ReactPlayer
              className={styles['media-item']}
              controls={true}
              url={media[current]?.url ?? ''}
            />)}
      </div>
      <div className={styles['next-button']}>
        {current <= media.length - 2 && <button className={styles.button} onClick={() => setCurrent((prev) => prev + 1)}>
          <Icon icon='chevron-right' color='#344054' size='xxs-plus' />
        </button>}
      </div>
    </div>
}
