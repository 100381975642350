import { useEffect, useState } from "react";
import styles from "./login.module.css";
import { userStore } from "src/store/user.store";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { AuthWindow, Button, Checkbox, LinkComponent, InputFormComponent } from "@ui-kit";
import { useForm, SubmitHandler } from "react-hook-form";
import { GoogleButton, FacebookButton, AppleButton } from "@ui-kit/components/button/social-buttons/";
import { onGoogleClick } from "@shared/helpers/on-google-click";
import { OgMetaData } from "@shared/components/og-meta-data/og-meta-data.comp";
import { useTranslation } from "react-i18next";
import { toastsStore } from "@store";

type Props = {
  phone: string;
  password: string;
};

const ENABLED_OAUTH = process.env.REACT_APP_DISABLE_OAUTH?.toLowerCase() !== 'true';

export const LoginPage = observer(() => {
  const [rememberMe, setRememberMe] = useState(false);
  const [formData, setFormData] = useState<Props | null>(null);

  const { register, handleSubmit, control, formState: { errors, isValid } } = useForm<Props>({ mode: 'onBlur' });
  const { t } = useTranslation('authorization');

  const formSubmitHandler: SubmitHandler<Props> = (data: Props) => {
    setFormData(data);
  };

  useEffect(() => {
    if (formData) {
      login(formData);
    }
  }, [formData]);

  const navigate = useNavigate();

  const login = async (data: Props) => {
    const { phone, password } = data;
    userStore.userLogin({
      password,
      phone,
    });
  };

  useEffect(() => {
    if (userStore.currentUser) {
      navigate('/');
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.currentUser]);

    return (
      <>
        <OgMetaData type='main' data={t('login.header')} />
          <AuthWindow>
            <h2 className={styles.title}>{t('login.title')}</h2>
            <h3 className={styles.subtitle}>
              {t('login.subtitle')}
            </h3>
            <form className={styles['form-login']} onSubmit={handleSubmit(formSubmitHandler)}>
              <div className={styles.inputBlock}>
                <InputFormComponent
                  label={t('input.phone.label')}
                  type='phone'
                  control={control}
                  placeholder={t('input.phone.placeholder')}
                  hasErrors={!!errors.phone}
                  {...register('phone', { required: true })}
                  errorsMess={errors.phone && errors.phone.message}
                />
                <InputFormComponent
                  label={t('input.password.label')}
                  type='password'
                  control={control}
                  placeholder={t('input.password.placeholder')}
                  hasErrors={!!errors.password}
                  {...register('password', { required: true })}
                  errorsMess={errors.password && errors.password.message}
                />
              </div>
              <div className={styles.bottomInputBlock}>
                <Checkbox
                  label={t('checkbox.rememberMe')}
                  size='sm'
                  checked={rememberMe}
                  onClick={() => setRememberMe(prev => !prev)}
                />
                <LinkComponent typeLink='gray-underline' link='/confirm-recover'>{t('link.forgotPass')}</LinkComponent>
              </div>
              <Button disabled={!isValid} type='submit'>{t('login.button.enter')}</Button>
            </form>
            {ENABLED_OAUTH && <div className={styles.socialButtonsBlock}>
              <GoogleButton supportingText={true} onClick={onGoogleClick}></GoogleButton>
              <FacebookButton supportingText={true}></FacebookButton>
              <AppleButton supportingText={true}></AppleButton>
            </div>}
            <p className={styles.bottomWindowBlock}>
              <span>{t('link.haventAccount')}</span>
                <LinkComponent link='/registration'>{t('link.register')}</LinkComponent>
            </p>
          </AuthWindow>
      </>
    );
});
