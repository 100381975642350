import styles from "./report-modal-content.module.css";
import { ForReportItem } from "../for-report-item/for-report-item.comp";
import { Comment, Post, UserPicture } from "@graphql/graphql";
import { Button, Checkbox, InputComponent } from "@ui-kit";
import { useEffect, useState } from "react";
import { reportService } from "@shared/services/report.service";
import { useTranslation } from "react-i18next";


type Props = {
  item?: Post|Comment|UserPicture,
  closeModal: () => void,
}

type CheckType = 'spam'|'offense'|'inappropriate'|'other';

export const ReportModalContent = ({ item, closeModal }: Props) => {
  const [reasonType, setReasonType] = useState<CheckType>();
  const [reason, setReason] = useState<string>();

  const { t } = useTranslation('common');

  const onSendClick = async () => {
    if (item?.__typename === 'Post') {
      await reportService.reportPost({
        id: item.id ?? '',
        reasonType: reasonType as string,
        reason,
      })
    }

    if (item?.__typename === 'Comment') {
      await reportService.reportComment({
        id: item.id ?? '',
        reasonType: reasonType as string,
        reason,
      })
    }

    if (item?.__typename === 'UserPicture') {
      await reportService.reportUser({
        username: item.username ?? '',
        reasonType: reasonType as string,
        reason,
      })
    }

    closeModal()
  }

  if (item?.__typename === 'UserPicture') {
    return <div className={styles['report-content']}>
      <ForReportItem item={item} />
      <div className={styles['report-content__selector']}>
        <h3 className={styles['report-content__title']}>
        {t('modal.report.title')}
        </h3>
        <Checkbox size='md' type='radio' checked={reasonType === 'spam'} onClick={() => setReasonType('spam')}>
          {t('modal.report.spam')}
        </Checkbox>
        <Checkbox size='md' type='radio' checked={reasonType === 'offense'} onClick={() => setReasonType('offense')}>
        {t('modal.report.offense')}
        </Checkbox>
        <Button
          className={styles['report-content__button']}
          size='lg'
          disabled={!reasonType || (reasonType === 'other' && !reason)}
          onClick={onSendClick}
        >
          {t('modal.button.send')}
        </Button>
      </div>
    </div>
  }

  return <div className={styles['report-content']}>
    <ForReportItem item={item} />
    <div className={styles['report-content__selector']}>
      <h3 className={styles['report-content__title']}>
        {t('modal.report.whatsWrong.title')}{item?.__typename === 'Comment' ? t('modal.report.whatsWrong.comment') : t('modal.report.whatsWrong.post')}
      </h3>
      <Checkbox size='md' type='radio' checked={reasonType === 'spam'} onClick={() => setReasonType('spam')}>
      {t('modal.report.spam')}
      </Checkbox>
      <Checkbox size='md' type='radio' checked={reasonType === 'offense'} onClick={() => setReasonType('offense')}>
        {item?.__typename === 'Comment' ? t('modal.report.comment') + ' ' + t('modal.report.whatsWrong.offense') : t('modal.report.post') + ' ' + t('modal.report.whatsWrong.offense')}
      </Checkbox>
      <Checkbox size='md' type='radio' checked={reasonType === 'inappropriate'} onClick={() => setReasonType('inappropriate')}>
        {item?.__typename === 'Comment' ? t('modal.report.comment') + ' ' + t('modal.report.whatsWrong.inappropriate') : t('modal.report.post') + ' ' + t('modal.report.whatsWrong.inappropriate')}
      </Checkbox>
      <Checkbox size='md' type='radio' checked={reasonType === 'other'} onClick={() => setReasonType('other')}>
      {t('modal.report.whatsWrong.other')}
      </Checkbox>
      {reasonType === 'other' && <InputComponent
        className={styles['report-content__input']}
        placeholder={t('modal.report.placeholder')}
        onchange={(text) => setReason(text)}
        text={reason ?? ''}
        type='text'
      />}
      <Button
        className={styles['report-content__button']}
        size='lg'
        disabled={!reasonType || (reasonType === 'other' && !reason)}
        onClick={onSendClick}
      >
        {t('modal.button.send')}
      </Button>
    </div>
  </div>
}