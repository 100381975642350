import styles from "./create-message.module.css";
import { messagesStore, userStore } from "@store";
import { useEffect, useRef, useState } from "react";
import { Button } from "@ui-kit";
import { observer } from "mobx-react-lite";
import { createNewChat } from "../../helpers/create-new-chat";
import { TYPING_TIMEOUT } from "src/messenger/data/message.constants";
import { useTranslation } from "react-i18next";

type Props = {
  chatId?: string,
  className?: string,
}

let timeoutId: any;

export const CreateMessage = observer(({ chatId, className }: Props) => {
  className = className ? `${className} ${styles['create-message']}` : styles['create-message'];

  const { t } = useTranslation('messenger');

  const [text, setText] = useState('');
  const [typing, setTyping] = useState(false);

  const textRef: any = useRef();

  const onInput = () => {
    setTyping(true);
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setTyping(false);
    }, TYPING_TIMEOUT);
    setText((
      textRef
        ?.current
        ?.innerHTML
        ?.replaceAll('&lt;', '<')
        ?.replaceAll('&gt;', '>')
        ?.replace(/<[^>]*>/g, '')
      ?? ''
    ) as string);
  }

  const sendMessage = async () => {
    setTyping(false);
    const chat = await messagesStore.chats.find((chat) => chat.chatId === chatId);

    if (chat && chatId) {
      messagesStore.sendMessage({
        chatId,
        text,
      }).then(() => {
        setText('');
        textRef.current.innerHTML = '';
      });
    } else {
      const user = await userStore.getUserData(chatId ?? '');
      if (!user?.username) {
        return
      }
      createNewChat(user?.username).then((res) => {
        if (!res?.chatId) {
          return;
        }
        messagesStore.sendMessage({
          chatId: res.chatId,
          text,
        }).then(() => {
          setText('');
          textRef.current.innerHTML = '';
        });
      })
    }
  }

  useEffect(() => {
    setText('');
    textRef.current.innerHTML = '';
  }, []);

  useEffect(() => {
    if (!chatId) {
      return;
    }

    if (typing) {
      messagesStore.startTyping(chatId);
    } else {
      messagesStore.stopTyping(chatId);
    }
  }, [typing]);

  return <div className={className}>
    <div className={styles['create-message__input-container']}>
      <div
        ref={textRef}
        contentEditable='true'
        className={styles['create-message__input']}
        onInput={onInput}
      />
      {!textRef?.current?.innerHTML && <div
        className={styles['create-message__input_placeholder']}
      >
        {t('button.createMessage')}
      </div>}
    </div>
    <Button
      size='lg'
      hierarchy='secondary-gray'
      iconType='smile'
      onClick={() => {}} />
    <Button
      size='lg'
      hierarchy='primary'
      iconType='send'
      loading={messagesStore.loading}
      disabled={!textRef?.current?.innerHTML}
      onClick={sendMessage} />
  </div>
});
