import {useEffect, useState } from "react";
import styles from "../persolal.module.css";
import {WhatsapButton, SmsButton} from "@ui-kit/components/button/social-buttons";
import { userStore, modalCustomDialogsStore, toastsStore } from "@store";
import {ConfirmComponent} from "../../../../auth/pages/components/confirm.comp";
import { useTranslation } from "react-i18next";
import { ENABLE_SMS_REGISTRATION } from "../../../../auth/pages/components/confirm-page.sms.whats";

export const useHandleConfirmAccount = () => {

  const [code, setCode] = useState('');
  const [isSendedText, setIsSendedText] = useState(false);
  const { showDialog } = modalCustomDialogsStore;
  const { currentUser } = userStore;
  const [isConfirmEmail, setIsConfirmEmail] = useState(false);
  const [isConfirmPhone, setIsConfirmPhone] = useState(false);
  const [isCodeComplete, setCodeComplete] = useState(false);
  const [changingPhone, setChangingPhone] = useState('');
  const [changingEmail, setChangingEmail] = useState('');
  const [isWhatsapp, setIsWhatsapp] = useState(Boolean);

  const { t } = useTranslation('common');

  const setIsConfirmEmailInitial = (value: boolean) => {
    setIsConfirmEmail(value);
  };

  const setIsConfirmPhoneInitial = (value: boolean) => {
    setIsConfirmPhone(value);
  };

  const confirmPhone = async (code: string, newPhone?: string) => {

    if(code !== '') {
      try {
        const confirm = await userStore.changePhoneConfirm({
          phone: newPhone,
          confirmID: code
        });

        if (confirm !== null) {
          toastsStore.addSuccessToast(t('toasts.success.confirmPhone'));
        } else {
          toastsStore.addErrorToast(t('toasts.error.confirmPhone'));
        }
      } catch (error) {
        console.error('Ошибка подтверждения: ', error);
      } finally {
        await userStore.me();
        setIsConfirmPhone(true);
        modalCustomDialogsStore.hideDialog();
      }
    }
  };

  const confirmEmail = async (code: string, newEmail?: string) => {

    if(code !== '') {
      try {
        const confirm = await userStore.changeEmailConfirm({
          email: newEmail,
          confirmID: code
        });

        if (confirm !== null) {
          toastsStore.addSuccessToast(t('toasts.success.confirmEmail'));
        } else {
          toastsStore.addErrorToast(t('toasts.error.confirmEmail'));
        }
      } catch (error) {
        console.error('Ошибка подтверждения: ', error);
      } finally {
        await userStore.me();
        setIsConfirmEmail(true);
        modalCustomDialogsStore.hideDialog();
      }
    }
  };


  const handleTimeout = () => {
    if (changingPhone && isWhatsapp) {
      userStore.changeUserPhone({ newPhone: changingPhone, whatsapp: isWhatsapp })
    } else if (changingEmail) {
      userStore.changeUserEmail({ newEmail: changingEmail })
    }
  };

  const handleLinkTimer = () => {
    setIsSendedText(false);
  };

  useEffect(() => {
    if(code.length===4){
      setCodeComplete(code.length === 4);
    }
  }, [code])


  const handlePhoneChange = (newPhone : string) => {
  if (newPhone !== currentUser?.phone) {
    showDialog(
      <div className={styles['modal-buttons']}>
       {ENABLE_SMS_REGISTRATION && <SmsButton supportingText={true} onClick={()=>handleTypeConfirmButtonClick(newPhone, false)}/>}
        <WhatsapButton supportingText={true} onClick={()=>handleTypeConfirmButtonClick(newPhone, true)}/>
      </div>,
      {
        title: t('typeConfirm.title'),
        message: t('typeConfirm.message'),
      }
    );
  }
}

  const handleTypeConfirmButtonClick = async (newPhone: string, isWhatsapp: boolean) => {
    setChangingPhone(newPhone)
    setIsWhatsapp(isWhatsapp)

    try {
      await userStore.changeUserPhone({ newPhone, whatsapp: isWhatsapp })
      modalCustomDialogsStore.hideDialog();
      return showDialog(
        <ConfirmComponent
          onSendedTextChange={setIsSendedText}
          typeConfirmWhatsApp={isWhatsapp}
          setCode={setCode}
          confirm={(code: string) => confirmPhone(code)}
          code={code}
          isCodeComplete={isCodeComplete}
          handleLinkTimer={handleLinkTimer}
          handleTimeout={handleTimeout}
          isBackButton = {false} />,
        {},
      );
    } catch {
      modalCustomDialogsStore.hideDialog();
    }
  }

const handleEmailChange = async (newEmail: string) => {
  setChangingEmail(newEmail)

  try{
    await userStore.changeUserEmail({ newEmail })
    modalCustomDialogsStore.hideDialog();
    return showDialog(
      <ConfirmComponent
        onSendedTextChange={setIsSendedText}
        typeConfirmWhatsApp={true}
        setCode={setCode}
        confirm={(code: string)=>confirmEmail(code)}
        code={code}
        isCodeComplete={isCodeComplete}
        handleLinkTimer={handleLinkTimer}
        handleTimeout={handleTimeout}
        isEmailConfirm={true}
        isBackButton = {false} />,
      {},
    );
  } catch {
    modalCustomDialogsStore.hideDialog();
  }
}

return {isConfirmEmail, isConfirmPhone, handlePhoneChange, handleEmailChange, setIsConfirmEmailInitial, setIsConfirmPhoneInitial }
}