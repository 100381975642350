import styles from './container.module.css';

type Props = {
  className?: string,
  children: any,
}

export const Container = ({ className, children }: Props) =>
  <div className={styles.outbox}>
    <div className={`${styles.container} ${className ?? ''}`}>
      {children}
    </div>
  </div>
