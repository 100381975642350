import { commentListStore, toastsStore } from "@store";
import styles from "./sub-coment-list.module.css";
import { useEffect, useState } from "react";
import { CommentComponent } from "../comment/comment.comp";
import { Comment } from "@graphql/graphql";
import { observer } from "mobx-react-lite";
import { Button, Loader } from "@ui-kit";
import { useTranslation } from "react-i18next";

type Props = {
  className?: string,
  commentId?: string|null,
  hideOnTabletAndMobile?: boolean,
  length: number,
  onAnswerSubClick: (username: string) => void,
  firstSubComment?: Comment|null,
}

export const SubCommentList = observer(({
  className, commentId, hideOnTabletAndMobile, length, onAnswerSubClick, firstSubComment
}: Props) => {
  hideOnTabletAndMobile = hideOnTabletAndMobile === undefined ? true : false;
  className = className ? `${className} ${styles['comment-list']}` : styles['comment-list'];
  if (hideOnTabletAndMobile) {
    className += `${className} ${styles['comment-list_not-desktop-hide']}`
  }

  const { clearSubComments, fetchSubComments, subLoading, comments } = commentListStore;

  const [loadMore, setLoadMore] = useState(false);

  const { t } = useTranslation('main');

  useEffect(() => {
    if (!commentId) {
      return;
    }

    clearSubComments(commentId);
  }, []);

  useEffect(() => {
    if (loadMore && commentId && !subLoading[commentId]) {
      fetchSubComments(commentId)
        .then(() => setLoadMore(false))
        .catch((err: any) => toastsStore.addErrorToast(t('toasts.error.loadPost')));
    }
  }, [loadMore]);

  if (!commentId) {
    return <></>;
  }

  const onAnswerClick = (username?: string|null) => {
    if (username) {
      onAnswerSubClick(username)
    }
  }

  return <div className={className}>
    {(!!comments[commentId]?.length || !!firstSubComment) && <div className={styles['comment-list__sub-comments']}>
      {!!firstSubComment && <CommentComponent
          key={firstSubComment.id}
          item={firstSubComment}
          onAnswerClick={() => onAnswerClick(firstSubComment.username)} />}
      {comments[commentId]?.map((comment: Comment) => (
        firstSubComment?.id !== comment.id 
          && <CommentComponent
            key={comment.id}
            item={comment}
            onAnswerClick={() => onAnswerClick(comment.username)} />
      ))}
    </div>}
    {(length - (!!comments[commentId] ? comments[commentId].length : 0) > 0) && <Button
        className={styles.comment__answers}
        size='lg'
        hierarchy='link-gray'
        onClick={() => setLoadMore(true)}>
        {t('post.button.viewAnswers')}({length - (!!comments[commentId] ? comments[commentId].length : 0)})
      </Button>}
    {!!subLoading[commentId] && <Loader />}
  </div>
});
