import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LikeTypes } from "@home/data/like-types";
import { ErrorFallback } from "@shared/components";
import React from "react";
import { Stack } from "react-bootstrap";
import { ErrorBoundary } from "react-error-boundary";
import { ReactionsBlock } from "../reactions-block/reactions-block";
import styles from "./post-item-footer.module.css";
import { Post } from "@graphql/graphql";
import { ReactionTypes } from "src/assets/reactions";

type Props = {
  post: Post;
  handleLikePost: (type?: ReactionTypes) => void;
  setCommentsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  commentsCounter?: number;
};

export const PostItemFooter = ({
  post,
  commentsCounter,
  handleLikePost,
  setCommentsOpen,
}: Props) => {
  const toggleCommentsOpen = () => {
    setCommentsOpen((prev) => !prev);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Stack direction='horizontal' className={styles.postInfo} gap={1}>
        <ReactionsBlock post={post} type={LikeTypes.Like} handleLikePost={handleLikePost}/>
        <ReactionsBlock post={post} type={LikeTypes.Heart} handleLikePost={handleLikePost}/>
        <ReactionsBlock post={post} type={LikeTypes.Joy} handleLikePost={handleLikePost}/>
        <ReactionsBlock post={post} type={LikeTypes.Sad} handleLikePost={handleLikePost}/>
        <ReactionsBlock post={post} type={LikeTypes.Angry} handleLikePost={handleLikePost}/>
        <span onClick={toggleCommentsOpen} id={styles.postCommentBtn}>
          <FontAwesomeIcon icon={regular("comment")} size='xl' />
        </span>
        <span onClick={toggleCommentsOpen}>
          {commentsCounter ? `${commentsCounter}` : null}
        </span>
      </Stack>
    </ErrorBoundary>
  );
};
