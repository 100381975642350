const getCorrectLastChar = (ch?: string) =>
  ch?.match(/[a-z]/i) || ch?.match(/[0-9]/i) || ch === '_';

export const trimUsername = (username: string) => {
  let realUsername = username;
  let symbols = '';

  while(!getCorrectLastChar(realUsername.at(-1)) && realUsername.length > 0) {
    symbols += realUsername[realUsername.length - 1] ?? '';
    realUsername = realUsername.substring(0, realUsername.length -1);
  };

  return [realUsername, symbols];
};
