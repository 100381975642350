import Compressor from "compressorjs";

export const compressImage = (file: File, quality: number = 0.8): Promise<File> => {
  return new Promise<File>((resolve, reject) => {
    new Compressor(file, {
      quality,
      success(compressedResult) {
        resolve(compressedResult as File);
      },
      error(err) {
        console.error('Ошибка при сжатии изображения:', err);
        reject(err);
      },
    });
  });
};