import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

interface Props {
  show: boolean,
  onHide: (isConfirmed: boolean) => void,
  title: string,
  message: string,
}

export function ModalDialog({ show, onHide, title, message }: Props) {
  const { t } = useTranslation('common');

  return (
    <Modal
      show={show}
      onHide={() => onHide(false)}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {message}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onHide(false)}>{t('modal.button.no')}</Button>
        <Button onClick={() => onHide(true)}>{t('modal.button.yes')}</Button>
      </Modal.Footer>
    </Modal>
  );
}