import { MediaFile } from "@graphql/graphql";
import { uploadFile } from "@shared/uploader/upload-file";
import { userStore } from "@store";
import { v4 as uuidv4 } from "uuid";

export const loadPictureToDB = async (key: string, inputFile: any, picture: any, onChangePercent?: (val: number) => void): Promise<MediaFile|null> => {
  if (inputFile || inputFile?.type?.includes('image') || inputFile?.type.includes('video')) {
    const fileName = picture
      ? `${uuidv4()}-${userStore.currentUser?.username ?? 'user'}`
      : '';

    const mediaFile = await uploadFile(fileName, inputFile, (data: any) => {
      if (onChangePercent) {
        onChangePercent(data);
      }
    });

    if (onChangePercent) {
      onChangePercent(100);
    }
    return {...mediaFile, key};
  }

  return null;
};


