import { Avatar, Loader } from "@ui-kit";
import styles from "./for-report-item.module.css";
import { Comment, Post, UserPicture } from "@graphql/graphql";
import { getCorrectPostDate } from "src/main-page/components/post/helpers/get-correct-post-date";
import { User } from "src/profile-page/profile.page";
import { UserModalItem } from "../user-modal-item/user-modal-item.comp";
import { MiniMediaContent } from "../mini-media-content/mini-media-content.comp";

type Props = {
  item?: Post|Comment|UserPicture,
}

export const ForReportItem = ({ item }: Props) => {
  if (!item) {
    return <div className={styles['for-report-item__loader']}>
      <Loader />
    </div>
  }

  if (item.__typename === 'Post' || item.__typename === 'Comment') {
    return <div className={styles['for-report-item']}>
      <Avatar
        size='lg'
        alt={item.userPicture?.username ?? 'avatar'}
        url={item.userPicture?.picture?.url}
        statusIcon={item.userPicture?.isOnline ? 'online' : undefined} />
      <div className={styles['for-report-item__right']}>
        <div className={styles['for-report-item__header']}>
          <p className={styles['for-report-item__fullname']}>
            {item.userPicture?.firstname} {item.userPicture?.lastname}
          </p>
          <p className={styles['for-report-item__time']}>
            {getCorrectPostDate(item)}
          </p>
        </div>
        <p className={styles['for-report-item__text']}>
          {item.text}
        </p>
        <MiniMediaContent media={item.media} />
      </div>
    </div>
  }

  if (item.__typename === 'UserPicture') {
    return <UserModalItem item={item} />
  }
}