import { useState, useEffect } from "react";
import styles from "./toggle.module.css";

type Props = {
  title: string;
  supportingText?: string;
  onToggle: (isToggled: boolean) => void;
  initialState: boolean;
  disabled?: boolean;
};

export const Toggle = ({ onToggle, title, supportingText, initialState, disabled }: Props) => {
  const [isToggled, setIsToggled] = useState(initialState);

  const toggle = () => {
    if (!disabled){
      setIsToggled((prev) => !prev);
      onToggle(!isToggled);
    }
  };

  useEffect(() => {
    setIsToggled(initialState);
  }, [initialState]);

 return (
  <>
 <div className={styles['toggle-container']}>
  {title && <p className={`${styles['toggle-title']} ${disabled ? styles['disabled'] : ''}`}>{title}</p>}
    <label className={`${styles['toggle-switch']} ${disabled ? styles['toggle-switch_disabled'] : ''}`}>
      <input type='checkbox' checked={isToggled} onChange={toggle} />
      <span className={`${styles['toggle-slider']} ${disabled ? styles['toggle-slider_disabled'] : ''}`}></span>
    </label>
    </div>
    <span className={styles['toggle-supportingText']}>{supportingText}</span>
      </>
 )
};