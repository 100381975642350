import { Button, HierarchyType } from "../button.component";
import styles from "./geo-button.module.css";
import {ReactComponent as GeoIcon} from "src/assets/icons/geo-btn-icon.svg";

type Props = {
  onClick?: () => void;
  className?: React.HTMLAttributes<HTMLDivElement>|string;
  children: any;
  hierarchy?: HierarchyType;
  destructive?: boolean;
  iconTypes?: string;
  disabled?: boolean;
};

export const GeoButton = (props: Props) => {
  const { children } = props;
  return <Button {...props} className={`${styles['geo-button']} ${props.className}`}>
    <GeoIcon width='20px' height='20px' className={styles['geo-button__icon']}/>
    <div className={styles['geo-button__text']}>{children}</div>
  </Button>
}