import { Chat } from "@graphql/graphql";
import { messagesStore } from "@store";
import { v4 as uuid } from "uuid";

export const createNewChat = async (username: string): Promise<Chat|null> => {
  return await messagesStore.createChat({
    chatId: uuid(),
    usernames: [username],
  });
};
