import { AddCityPage } from "@add-city/add-city-page";
import { HomePage } from "@home/home-page";
import { NotFoundPage } from "./not-found-page/not-found-page";
import { useLoadScript } from "@react-google-maps/api";
import { SearchPage } from "./search-page/search-page";
import { ErrorFallback, ModalWindows, ToastList } from "@shared/components";
import { Loader } from "react-bootstrap-typeahead";
import { ErrorBoundary } from "react-error-boundary";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ChatPage } from "@messages/pages/chat-page/chat-page";
import { MessageStoreComp } from "@shared/components/message-store-comp/message-store.comp";
import { userStore } from "./store/user.store";
import { LoginPage } from "./auth/pages/login.page";
import { RegistrationPage } from "./auth/pages/registration.page";
import { ConfirmPageRecover } from "./auth/pages/confirm-page.recover";
import { MainPage } from "./main-page/main.page";
import { ProfilePage } from "./profile-page/profile.page";
import { register } from 'swiper/element/bundle';
import { PostPage } from "./post-page/post.page";
import { useEffect, useState } from "react";
import { Container } from "@ui-kit/components/container/container.comp";
import { MainMenu } from "./main-page/components/main-menu/main-menu.comp";
import { Content } from "@shared/components/content/content.comp";
import styles from "./App.module.css";
import { MainRight } from "./main-page/components/main-right/main-right.comp";
import { UserMenu } from "@shared/components/user-menu/user-menu.comp";
import { MapPage } from "./map/map.page";
import { UserWallPage } from "./user-wall/user-wall.page";
import { FollowPage } from "./follow-page/follow.page";
import { SavedPosts } from "./saved-posts/saved-posts.page";
import {AgeementPage} from "./agreement-page/agreement.page"
import { MessengerPage } from "./messenger/messenger.page";
import { MessageNotifications } from "@shared/components/message-notifications/message-notifications.comp";
import { NotificationsPage } from "./notifications/notifications.page";
import { notificationsStore } from "./store/notifications.store";
import { apolloClient } from "./apollo-client";
import { onlineHeartbeat } from "@graphql/docs/mutations";
import { requestPermission } from "@shared/helpers/notification";
import { HelpPage } from "./help-page/help.page";

register();

function App() {

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY ?? '',
    language: 'en',
    libraries: ['places'],
  });

  const userMenuState = useState(false);
  const [isOverlay, setIsOverlay] = useState(false);
  const HEARTBIT_INTERVAL_MS = 60_000;
  useEffect(() => {
    userStore.currentUser = undefined;
    userStore.me().finally(() => {
      notificationsStore
        .getNotificationLength()
        .catch();
    });

    setTimeout(() => {
      setInterval(() => {
        if(userStore.currentUser) {
          apolloClient.mutate({ mutation: onlineHeartbeat })
            .catch()
        }
      }, HEARTBIT_INTERVAL_MS)
    }, HEARTBIT_INTERVAL_MS)
  }, []);

  const REQUEST_PERMISSION_TIMEOUT_MS = 5_000;
  setTimeout(() => {
    requestPermission();
  }, REQUEST_PERMISSION_TIMEOUT_MS);

  const handleOverlay = (overlay: boolean) => {
    setIsOverlay(overlay);
  };

  useEffect(() => {
    notificationsStore.createStream();
  }, [userStore.currentUser])

  if (!isLoaded) return <Loader />;
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className={`${styles['overlay']} ${isOverlay ? styles['overlay_active'] : ''}`} onClick={() => setIsOverlay(false)}></div>
      <>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/registration" element={<RegistrationPage />} />
          <Route path="/confirm-recover" element={<ConfirmPageRecover/>} />
          <Route path="/old" element={<HomePage />} />
          <Route path="/chat/:id" element={<ChatPage />} />
          <Route path="/add-city" element={<AddCityPage />} />
          <Route path="*" element={<>
            <Container className={styles.container}>
              <MainMenu userMenuState={userMenuState} />
              <Content className={styles['main-page']}>
                <MessageStoreComp />
                <Routes>
                  <Route path="/" element={<MainPage onOverlay={handleOverlay}/>} />
                  <Route path="/messages" element={<MessengerPage />} />
                  <Route path="/search" element={<SearchPage />} />
                  <Route path="/profile" element={<ProfilePage />} />
                  <Route path="/post/:postId" element={<PostPage />} />
                  <Route path="/map/:mapType" element={<MapPage />} />
                  <Route path="/wall/:username" element={<UserWallPage />} />
                  <Route path="/followers/:username" element={<FollowPage isFollowing={false} />} />
                  <Route path="/following/:username" element={<FollowPage isFollowing={true} />} />
                  <Route path="/saved" element={<SavedPosts />} />
                  <Route path="/agreement" element={<AgeementPage />} />
                  <Route path="/notifications" element={<NotificationsPage />} />
                  <Route path="/help" element={<HelpPage />} />
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </Content>
              <MainRight />
            </Container>
            <UserMenu userMenuState={userMenuState}
              user={userStore.currentUser}
              onOverlay={handleOverlay} />
          </>}/>
        </Routes>
        <ToastList />
        <ModalWindows />
        <MessageNotifications />
      </>
    </ErrorBoundary>
  );
}

export default App;
