import { City } from "@graphql/graphql";
import usePlacesAutocomplete, { HookReturn } from "use-places-autocomplete";
import { Cords, useMapReducer } from "./map.reducer";
import { setByUserLocation, setCityByCords } from "./helpers";
import { LOCALSTORAGE_KEY_LANG, toastsStore } from "@store";
import { useMapController } from "./hooks";
import { useEffect } from "react";
import { GMap } from "./components/g-map/g-map.comp";
import styles from "./map.module.css";
import { useTranslation } from "react-i18next";

type Props = {
  autoGeo?: boolean,
  hideCityList?: boolean
  selectCity?: (city: City) => void,
  className?: React.HTMLAttributes<HTMLDivElement>|string,
}

export const MapComponent = ({
  autoGeo, selectCity, hideCityList, className
}: Props) => {
  const hookReturn: HookReturn = usePlacesAutocomplete({
    requestOptions: { types: ['(regions)'] },
  });
  const [state, dispatch] = useMapReducer();
  const mapController = useMapController([state, dispatch]);

  const { t } = useTranslation('common');

  const handleMapClick = (event: any) => {
    dispatch({type: 'setError', payload: ''});
    dispatch({type: 'setCity', payload: ''});

    const lat: number = event?.latLng?.lat();
    const lng: number = event?.latLng?.lng();

    setCityByCords(hookReturn, dispatch)(lat, lng)
      .catch((err: any) => toastsStore.addErrorToast(t('toasts.error.getLocalCity')));
  };

  const selectCityByGeo = () => {
    setByUserLocation(
      (center: Cords) => dispatch({ type: 'setCenter', payload: center }),
      (zoom: number) => dispatch({ type: 'setZoom', payload: zoom }),
      setCityByCords(hookReturn, dispatch),
    ).catch((err: any) => toastsStore.addErrorToast(t('toasts.error.getLocalCity')))
  }

  useEffect(() => {
    if(autoGeo) {
      selectCityByGeo();
    }
  }, []);

  useEffect(() => {
   // const lang = localStorage.getItem(LOCALSTORAGE_KEY_LANG) ?? navigator.language ?? 'en';

   const lang = localStorage.getItem('i18nextLng') ?? navigator.language;

    if(selectCity) {
      selectCity({
        __typename: 'City',
        country: state.country,
        longName: state.longName,
        name: state.city,
        place_id: state.placeId,
        langs: [
          { lang, country: state.country, name: state.longName }
        ]
      })
    }
  }, [state])

  return(
    <div className={`${styles.map} ${className}`}>
      <GMap
        handleMapClick={handleMapClick}
        setCityByCords={setCityByCords(hookReturn, dispatch)}
        city={state.city}
        hookReturn={hookReturn}
        mapController={mapController}
        hideCityList={hideCityList}
        selectCityByGeo={selectCityByGeo}
      />
    </div>
  )
}
