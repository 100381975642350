import Icons, { ReactionTypes } from "src/assets/reactions";
import style from "./reaction.module.css";

type Props = {
  count?: number,
  choose?: boolean,
  type: ReactionTypes,
  onClick: () => void
}

export const ReactionComponent = ({ count, choose, type, onClick }: Props) => {
  const url = `url(${Icons[type]})`;
  const className = choose ? `${style.choose} ${style.reaction}` : style.reaction

  return <div className={className} onClick={() => onClick()}>
    <div className={style.icon} style={{backgroundImage: url}} />
    {!!count && <p className={style.count}>{count}</p>}
  </div>
}