import { citiesStore, postListStore, toastsStore } from "@store";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { PostComponent } from "../post/post.comp";
import styles from "./post-list.module.css";
import { NoPostsComponent } from "../no-posts/no-posts.comp";
import { Loader } from "@ui-kit/components/loader/loader.comp";
import { useTranslation } from "react-i18next";

type Props = {
  selectedTab: 'all'|'follow',
}

export const PostList = observer(({ selectedTab: selectedTabItem }: Props) => {
  const { currentCity } = citiesStore;
  const [loadMore, setLoadMore] = useState(false);
  const { posts, loading, fetchPosts, cityId, selectedTab } = postListStore;

  const { t } = useTranslation('main');

  useEffect(() => {
    if (currentCity?.place_id === cityId && selectedTabItem === selectedTab ) {
      return;
    }
    fetchPosts(true, selectedTabItem)
      .catch((err: any) => toastsStore.addErrorToast(t('toasts.error.loadPost')));
  }, [currentCity, selectedTabItem])

  useEffect(() => {
    if (loadMore && !loading) {
      fetchPosts(false, selectedTabItem)
        .then(() => setLoadMore(false))
        .catch((err: any) => toastsStore.addErrorToast(t('toasts.error.loadPost')));
    }
  }, [loadMore])

  const scrollHandler = (e: any) => {
    const position = e.target.scrollTop + window.innerHeight + 10
    if (position >= e.target.scrollHeight ) {
      setLoadMore(true);
    }
  }

  useEffect(() => {
    document.body.addEventListener('scroll', function(e) { scrollHandler(e) }, false);
    return () => {
      document.body.removeEventListener('scroll', scrollHandler);
    }
  }, []);

  return <>
    {posts?.map((item) =>
      <PostComponent key={item.id} item={item} />
    )}
    {loading && <div className={styles.loader}>
      <Loader />
    </div>}
    {
      ((!posts?.length || posts.length <= 0) && !loading && !!currentCity)
        && <NoPostsComponent selectedTab={selectedTab} />
    }
  </>
});
