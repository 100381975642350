import { Menu } from "@ui-kit/components/drop-down/comp/dropdown-menu/menu.comp";
import styles from "./item-menu.module.css";
import { MenuItem } from "@ui-kit/components/drop-down/modele/menu-item";
import { useEffect, useState } from "react";
import { Button } from "@ui-kit";

type Props = {
  className?: string
  items: MenuItem[],
  onClick: (item: MenuItem) => void,
  onClose?: () => void,
  toRight?: boolean,
}

export const ItemMenu = ({ className, items, onClick, onClose, toRight }: Props) => {
  className = className ? `${className} ${styles['item-menu']}` : styles['item-menu'];

  const [showMenu, setShowMenu] = useState<boolean>();
  const [shown, setShown] = useState<boolean>(false);

  const onHideMenu = () => {
    setShowMenu(() => false);
  }

  useEffect(() => {
    document.body.addEventListener('click', onHideMenu);
    return () => document.body.removeEventListener('click', onHideMenu);
  }, []);

  useEffect(() => {
    !showMenu && shown && onClose && onClose();
  }, [showMenu]);

  const classMenu = toRight ? `${styles['item-menu__menu']} ${styles['item-menu__menu_right']}` : styles['item-menu__menu']
  const classContainer = toRight
    ? `${styles['item-menu__container']} ${styles['item-menu__container_right']}`
    : styles['item-menu__container'];

  return <div className={className}>
    <div className={classContainer}>
      <Button
        className={styles['item-menu__button']}
        size='sm'
        hierarchy='tertiary-gray'
        iconType='more-vertical'
        onClick={() => setTimeout(() => {
          setShowMenu(() => true)
          setShown(true)
        }, 1)} />
      {showMenu && <Menu
        className={classMenu}
        onClick={onClick}
        items={items} />}
    </div>
  </div>
}