import { useTranslation } from "react-i18next";

export type TextType = 'posts'|'followers'|'following';

export const useTextByNumber = () => {

  const { t } = useTranslation('wall');

  const getTextByNumber = (text: TextType | string, number: number) => {

  const posts = [
    t('badgeNum.posts.one'), t('badgeNum.posts.few'), t('badgeNum.posts.many'),
    ];

  const followers = [
    t('badgeNum.followers.one'), t('badgeNum.followers.few'), t('badgeNum.followers.many'),
    ];

  const following = [
    t('badgeNum.following.one'), t('badgeNum.following.few'), t('badgeNum.following.many'),
    ];

  let curentCase = 2;

  const strNumber = number.toString();

  if (strNumber[1] === '1') {
    curentCase = 2;
  } else if (strNumber[0] === '1') {
    curentCase = 0;
  } else if (strNumber[0] === '2' || strNumber[0] === '3' || strNumber[0] === '4') {
    curentCase = 1;
  } else {
    curentCase = 2;
  }

  if (number > 1000) {
    curentCase = 2;
  }
  switch(text) {
    case 'posts': return posts[curentCase];
    case 'followers': return followers[curentCase];
    case 'following': return following[curentCase];
    default: return text;
  }
  }
  return getTextByNumber;
}

