import { useRef } from "react";
import styles from "./link-data.module.css";
import { LinkData } from "@graphql/graphql";
import { getDomainNameFromUrl } from "@shared/helpers/get-domain-name-from-url";

type Props = {
  linkData: LinkData,
  onClose?: () => void,
  className?: string,
}

export const LinkDataComponent = ({ linkData, onClose, className }: Props) => {
  className = className ? `${className} ${styles['link-data']}` : styles['link-data'];

  const closeRef = useRef<any>()

  const onClick = (e: any) => {
    if (e.target === closeRef.current) {
      return onClose && onClose()
    }
    window.open(encodeURI(linkData.url), '_blank');
  }

  return <div className={className} onClick={onClick}>
    <div className={styles['link-data__picture']} style={{
      backgroundImage: `url(${linkData.image?.url})`,
      backgroundSize: linkData.image?.url ? 'cover' : 'auto'
    }} />
    <div className={styles['link-data__right']}>
      <h4 className={styles['link-data__link']}>{getDomainNameFromUrl(linkData.url ?? '')}</h4>
      <h3 className={styles['link-data__title']}>{linkData.title}</h3>
      <h3 className={styles['link-data__text']}>{linkData.text}</h3>
    </div>
    {!!onClose && <button onClick={onClose} className={styles['link-data__close']} ref={closeRef} />}
  </div>
}