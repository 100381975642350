import { AddCityAction } from "@add-city/add-city.reducer";
import { ZOOM_CITY } from "@add-city/components";
import { getFullCityByCords } from "@shared/helpers";
import { toastsStore } from "@store";
import { HookReturn } from "use-places-autocomplete";

export const setCityByCords = (
  hookReturn: HookReturn,
  dispatch :React.Dispatch<AddCityAction>,
) => async (lat: number, lng: number): Promise<any> => {
  try {
    const cityData = await getFullCityByCords(lat, lng);
    hookReturn.clearSuggestions();
    dispatch({type: 'setPlaceId', payload: cityData?.id ?? ''});
    dispatch({type: 'setCity', payload: cityData?.fullCityName ?? ''});
    dispatch({type: 'setLongName', payload: cityData?.longName ?? ''});
    dispatch({type: 'setCountry', payload: cityData?.country ?? ''});
    hookReturn.setValue(cityData?.fullCityName, false);

    dispatch({type: 'setCenter', payload: { lat, lng }});
    dispatch({type: 'setZoom', payload: ZOOM_CITY});
    dispatch({type: 'setSelection', payload: { lat, lng }});
  } catch(err: any) {
    toastsStore.addErrorToast('Can not set city by cords!')
  }
}