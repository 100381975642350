import styles from "../button.module.css";
import socialIcons from "../../../../assets/social-icons/index";
import { useTranslation } from "react-i18next";

type FacebookButtonProps = {
  onClick?: () => void;
  btnSmall?:boolean;
  supportingText: boolean;
  disabled?: boolean;
  className?: string;
  isRegistration?: boolean;
};

const FacebookButton = ({
  onClick,
  btnSmall,
  disabled,
  supportingText,
  className,
  isRegistration,
}: FacebookButtonProps) => {

  if (!onClick) {
    onClick = () => {};
  }

  const { t } = useTranslation('common');

  const buttonText = isRegistration
    ? t('socialButton.facebookRegistr')
    : t('socialButton.facebookEnter')

  const classNameStyle = `${styles['button']} ${btnSmall? styles['button_size-social-sm'] : styles['button_size-md']} ${styles['button_secondary-gray']}`
  className = className ? `${className} ${classNameStyle}` : classNameStyle;

  return (
    <button
    className={className}
      onClick={onClick}
      disabled={disabled}
    >
      <img src={disabled? socialIcons['social-fb-grey'] : socialIcons['social-fb-color']} alt='Facebook Icon' />
      {supportingText && <span>{buttonText}</span>}
    </button>
  );
};


export default FacebookButton;