import { gql } from "@apollo/client";

export const getUserFollowing = gql`query getUserFollowing($input: FollowInput) {
    getUserFollowing(input: $input){
        id
        username
        follow
        createdAt
        updatedAt
    }
}`;
