import { useTextByNumber } from "src/user-wall/helpers/use-text-by-number";
import styles from "./number-data.module.css";
import { getShortNumber } from "@shared/helpers/get-short-number";

type Props = {
  number: number;
  text: string;
  onClick?: () => void;
}

export const NumberData = ({ number, text, onClick }: Props) => {
  const getTextByNumber= useTextByNumber()
  text = getTextByNumber(text, number);
  const className = !!onClick ? `${styles.number} ${styles.number_pointer}` : styles.number
  onClick = !!onClick ? onClick : () => {};


  return <div className={className} onClick={onClick}>
    <span className={styles.value}>{getShortNumber(number)}</span> <span className={styles.text}>{text}</span>
  </div>
}