import styles from "./search-people.module.css";
import { useEffect, useState } from "react";
import { Loader } from "@ui-kit/components/";
import { useNavigate } from "react-router-dom";
import { UserPicture } from "@graphql/graphql";
import { FollowComponent } from "../../../follow-page/components/follow.comp";
import { dataRecommends } from "../../helpers/recommends";
import { citiesStore, toastsStore } from "@store";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

type Props = {
  users: UserPicture[];
  initialToken: string | null;
};

export const SearchPeople = observer(({ users: initialUsers, initialToken }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<UserPicture[]>(initialUsers);
  const [loadMore, setLoadMore] = useState(false);
  const [nextToken, setNextToken] = useState<string | null>(initialToken);
  const [hasMoreData, setHasMoreData] = useState(true);

  const { currentCity } = citiesStore;
  const { t } = useTranslation('search');

  const navigate = useNavigate();

  useEffect(() => {
    const scrollHandler = (e: any) => {
      const position = e.target.scrollTop + window.innerHeight + 10;
      if (position >= e.target.scrollHeight) {
        setLoadMore(true);
      }
    }

    document.body.addEventListener('scroll', scrollHandler, false);
    return () => {
      document.body.removeEventListener('scroll', scrollHandler);
    };
  }, [loadMore]);

  useEffect(() => {
    if (loadMore && users && nextToken !== null) {
      fetchUsers()
        .then(() => setLoadMore(false))
        .catch(() => toastsStore.addErrorToast(t('toasts.error.people')));
    } else {
      setLoadMore(false);
    }
  }, [loadMore])

  const fetchUsers = async () => {
    if (loadMore && nextToken !== null && hasMoreData) {
      setIsLoading(true);
      try {
        const result = await dataRecommends({
          place_id: currentCity?.place_id,
          nextToken: nextToken,
        });

        const newUsers = result.userItems?.filter((item): item is UserPicture => item !== null) ?? [];
        if (newUsers.length === 0 || result.nextToken === null) {
          setLoadMore(false);
          setHasMoreData(false);
        } else {
          setUsers(users => [...users, ...newUsers]);
          setNextToken(result.nextToken ?? null);
        }
      } catch (error) {
        console.error('Ошибка:', error);
        toastsStore.addErrorToast(t('toasts.error.people'));
      } finally {
        setIsLoading(false);
        setLoadMore(false);
      }
    }
  };

  useEffect(() => {
    setUsers(initialUsers);
    setIsLoading(true);
    setHasMoreData(true);
  }, [initialUsers]);

  return (
    <section className={styles['search-people__section']}>
      {users.length > 0 &&
        <>
          <div className={styles['search-people__content']}>
            {users.map((user) => (
              <FollowComponent
                onClick={() => navigate(`/wall/${user.username}`)}
                user={user}
                key={user.username}
              />
            ))}
          </div>
        </>
      }
      {!!isLoading && <div className={styles.loader}>
        <Loader />
      </div>}
    </section>
  );
});
