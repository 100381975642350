import styles from "./main-right.module.css";
import { useEffect, useState } from "react";
import { LiveComponent } from "../live/live.comp";
import { Trends } from "../trends/trends.comp";
import { userStore } from "@store";
import { LoginRightComp } from "../login-right/login-right.comp";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
import { LinkComponent, InputComponent } from "@ui-kit";
import { PATHES_WITH_RIGHT } from "./knownPaths";
import { useTranslation } from "react-i18next";

export const MainRight = observer(() => {
  const [searchString, setSearchString] = useState('');

  const { t } = useTranslation('main');

  const location = useLocation();

  const { currentUser } = userStore;

  if (!PATHES_WITH_RIGHT.includes(location.pathname.split('/')?.[1])) {
    return <></>
  }

  return <div className={styles.right}>
    <div className={styles.header}>
      <InputComponent className={styles.search} type='search' onchange={setSearchString} text={searchString} />
    </div>
    <div className={styles.header_back} />
    {!!currentUser?.token
      ? <>
        <LiveComponent />
        <Trends />
      </>
      : <LoginRightComp />}
      <LinkComponent typeLink='gray' className={styles.link} link='/agreement'>{t('mainRight.link')}</LinkComponent>
  </div>
});
