import { useEffect, useState, useCallback } from "react";
import styles from "./profile.module.css";
import { observer } from "mobx-react-lite";
import { Header } from "@shared/components/header/header.comp";
import { Menu } from "@ui-kit/components/drop-down/comp/dropdown-menu/menu.comp";
import { useSettingsMenu } from "./components/useSettingsMenu";
import { MenuItem } from "@ui-kit/components/drop-down/modele/menu-item";
import { PersonalSettings, PasswordSettings, NotificationSettings, LanguageSettings } from "./components/";
import { MediaFile } from "@graphql/graphql";
import { BottomMenu } from "@ui-kit/components/bottom-menu/bottom-menu.comp";
import { useTranslation } from "react-i18next";

export interface User {
  firstname?: string;
  lastname?: string;
  city?: string;
  username?: string;
  phone?: string;
  gender?: string;
  email?: string;
  passwordCurrent?:string;
  password?: string;
  passwordRepeat?: string;
  picture?: MediaFile;
  dateOfBirth?: string;
}

enum SettingsPageState{
  pagePersonal,
  pagePassword,
  pageNotification,
  pageLanguage,
}

export const ProfilePage = observer(() => {
  const [currentItem, setItem] = useState<MenuItem>({ key: 'personal', type: 'menu-settings' });
  const [pageState, setPageState] = useState<SettingsPageState>(SettingsPageState.pagePersonal);

  const [isTabletMobile, setIsTabletMobile] = useState(window.innerWidth <= 1080);
  const [isMenuVisible, setIsMenuVisible] = useState(true);

  const { t } = useTranslation('settings');
  const { menuItems, getInitialMenuState } = useSettingsMenu();

  const handleOnBack = () => {
    setIsMenuVisible(true);
  }

  const handleMenuItemClick =  useCallback((item: MenuItem) => {
    setItem(item);
    setIsMenuVisible(window.innerWidth <= 1080 ? false : true);

    if (window.innerWidth <= 1080) {
      setIsMenuVisible(false);
    }

    switch (item.key) {
      case 'personal':
        setPageState(SettingsPageState.pagePersonal);
        break;
      case 'password':
        setPageState(SettingsPageState.pagePassword);
        break;
      case 'notification':
        setPageState(SettingsPageState.pageNotification);
        break;
      case 'language':
        setPageState(SettingsPageState.pageLanguage);
        break;
      default: return;
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1080) {
        setIsTabletMobile(false);
        setIsMenuVisible(true);
      } else {
        setIsTabletMobile(true);
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderSettingsPage = () => {
    switch (pageState) {
      case SettingsPageState.pagePersonal:
        return <PersonalSettings onBack={handleOnBack} />;
      case SettingsPageState.pagePassword:
        return <PasswordSettings onBack={handleOnBack}/>;
      case SettingsPageState.pageNotification:
        return <NotificationSettings onBack={handleOnBack}/>;
      case SettingsPageState.pageLanguage:
        return <LanguageSettings onBack={handleOnBack}/>;
      default:
        return null;
    }
  };

  return (<>
    <section className={styles['profile-page']}>
      {isMenuVisible && (
        <section className={styles['profile-menu']}>
          <Header
            className={styles['header-settigs']}
            title={t('main.header')}
            hideAvatar={true}
          />
          <Menu className={styles['drop-menu-setting']}
            withoutShadow={true}
            withoutScroll={true}
            indicateSelection={true}
            items={menuItems}
            onClick={handleMenuItemClick}
          />
        </section>
      )}
      {isTabletMobile && !isMenuVisible && (
        <section className={styles['profile-item']}>
          {renderSettingsPage()}
        </section>
      )}
      {isMenuVisible && !isTabletMobile && (
        <section className={styles['profile-item']}>
          {renderSettingsPage()}
        </section>
      )}
    </section>
    <BottomMenu
      className={styles['bottom-menu']}
      onCreateClick={() => {}}/>
  </>)
});
