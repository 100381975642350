export const getIconColor = (color: 'primary'|'error'|'warning'|'success'|'indigo'|'purple'|'pink'|'rose'|'orange'|'blue-gray'|'gray') => {
  switch(color) {
    case 'gray': return '#344054';
    case 'primary': return '#1E5BCB';
    case 'error': return '#B42318';
    case 'warning': return '#B54708';
    case 'success': return '#027A48';
    case 'blue-gray': return '#367DFF';
    case 'indigo': return '#3538CD';
    case 'purple': return '#5925DC';
    case 'pink': return '#C11574';
    case 'rose': return '#C01048';
    case 'orange': return '#C4320A';
  }
}
