import { toastsStore, userStore, postListStore } from "@store";
import styles from "./edit.post.module.css";
import { Avatar, Button, UploadingFile } from "@ui-kit/components";
import { Icon } from "@home/components/icon/icon.comp";
import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { loadPictureToDB } from "src/main-page/helpers/load-pictures-to-db";
import { LinkData, MediaFile, Post, EditPostInput, MediaFileInput } from "@graphql/graphql";
import { v4 as uuid } from "uuid";
import { useLocation } from "react-router-dom";
import { getLinksInText } from "@home";
import { linkService } from "@shared/services/link.service";
import { LinkDataComponent } from "@shared/components/link-data/link-data.comp";
import { filterText } from "src/main-page/helpers/filter-text";
import { useTranslation } from "react-i18next";
import { DropdownUserList } from "@shared/components/dropdown-user-list/dropdown-user-list.comp";
import { foundLastWord } from "src/main-page/helpers/found-last-word";
import { onSelectUser } from "@shared/helpers/on-select-user";
import { useFormEditState } from "@shared/hooks/use-form-edit-state";

const initialState = {
  text: '',
  media: [] as string[],
  linkIds: [] as string[],
};

const buttonParams = {
  size: 'sm' as any, hierarchy: 'tertiary-color' as any,
}

export type FilesToUpload = {
  key: string,
  url: string,
  type: string,
  file: any,
  value: any,
  percent: number,
}

type Props = {
  className?: string;
  showOnMobile?: boolean;
  onSendSuccess?: () => void;
  postToEdit: Post;
  onCancelEdit?: () => void;
}

export const EditPostComponent = observer(({ className, showOnMobile, onSendSuccess, postToEdit, onCancelEdit }: Props) => {
  const { currentUser } = userStore;
  const { t } = useTranslation('main');

  const textRef = useRef<any>();

  const { formState, setInput, setFormState } = useFormEditState({
    text: postToEdit?.text || initialState.text,
    media: postToEdit?.media?.map(media => media.url) || initialState.media,
    linkIds: postToEdit?.linkData?.map(link => link.id) || initialState.linkIds,
  });

  const [textContent, setTextContent] = useState(formState.text);
  const [filesToUpload, setFilesToUpload] = useState<FilesToUpload[]>([]);
  const [mediaFiles, setMediaFiles] = useState<MediaFile[]>(postToEdit?.media || []);
  const [mediaToRemove, setMediaToRemove] = useState<MediaFile[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('');
  const [stringIndex, setStringIndex] = useState<number[]>([0]);
  const [usernamePos, setUsernamePos] = useState<{ start?: number, end?: number }>({});

  className = className ? `${className} ${styles['edit-post']}` : styles['edit-post'];
  className = showOnMobile ? `${showOnMobile} ${styles['edit-post_mobile']}` : className;

  let { search } = useLocation();

  const removeLink = (id: string) => {
    setInput('linkIds', formState.linkIds.filter((link: LinkData|null) => link?.id !== id));
  }

  const sanitizeMediaFile = (file: MediaFile): MediaFileInput => {
    const { __typename, ...sanitizedFile } = file;
    return sanitizedFile as MediaFileInput;
  }

  useEffect(() => {
    if (search.substring(0, 5) === '?add=' && textRef.current) {
      textRef.current.focus();
      const selection = window.getSelection();
      const range = document.createRange();
      try {
        range.setStart(textRef.current, 0);
        range.setEnd(textRef.current, 0);
        selection?.removeAllRanges();
        selection?.addRange(range);
      } catch { }
    }
  }, []);


  let selection = window.getSelection();

  useEffect(() => {
    if (textRef.current) {
      textRef.current.innerHTML = textContent;
    }
  }, [textContent]);

  useEffect(() => {
    if (!textRef.current?.innerHTML) {
      return;
    }

    selection = window.getSelection();
    const { lastWord, start, end } = foundLastWord(
      selection?.anchorNode?.textContent ?? '',
      selection?.anchorOffset ?? 0,
    );

    const index = [...textRef.current.childNodes]
      .findIndex((node: any) =>
        selection?.anchorNode?.isEqualNode(node)
        || selection?.anchorNode?.isEqualNode(node?.firstChild)
      );
      if (index >= 0) {
        setStringIndex([index]);
      } else {
        let [indexX, indexY] = [-1, -1];
        [...textRef.current.childNodes]
          ?.forEach((parentNode, i) => {
            if (parentNode.nodeName === 'DIV') {
              [...parentNode?.childNodes]?.forEach((node, j) => {
                if(
                  selection?.anchorNode?.isEqualNode(node)
                  || selection?.anchorNode?.isEqualNode(node?.firstChild)
                ) {
                  indexX = i;
                  indexY = j;
                }
              });
            }
          });
        setStringIndex([indexX, indexY]);
      }

    if (lastWord.at(0) === '@') {
      if (lastWord.length >= 3) {
        setUsername(lastWord.substring(1));
      } else {
        setUsername('');
      }
      setUsernamePos({ start, end });
    } else {
      setUsername('');
      setUsernamePos({});
    };
  }, [textRef.current?.textContent])

  useEffect(() => {
    const links = getLinksInText(formState.text);
    Promise.all(
      links.map(async (link) => {
        if (!formState.linkIds.find((linkId: string) => linkId === link)) {
          return await linkService.createLinkData(link)
        };

        return null;
      })
    ).then((linkDataList) => {
      setInput('linkIds', [...formState.linkIds, ...linkDataList.filter(Boolean).map((linkData: LinkData | null) => linkData!.id)]);
    });
  }, [formState.text]);

  const onSaveClick = () => {
    if (postToEdit.id) {
      const mediaToAdd = mediaFiles.filter(media => !postToEdit.media?.some(existingMedia => existingMedia.key === media.key));
      const mediaToRemoveConverted = JSON.parse(JSON.stringify(mediaToRemove));

      const sanitizedMediaToRemove = mediaToRemoveConverted.map(sanitizeMediaFile);

      const updatedPostInput: EditPostInput = {
        id: postToEdit.id,
        text: formState.text,
        mediaToAdd,
        mediaToRemove: sanitizedMediaToRemove,
      };

      postListStore.editPost(updatedPostInput)
      .then((res) => {
        if (res) {
          setFormState(initialState);
          setTextContent('');
          setFilesToUpload([]);
          setMediaFiles([]);
          setMediaToRemove([]);
          setUsername('');
          setUsernamePos({});
          onSendSuccess && onSendSuccess();
        }
      }).catch((err) => {
        console.error('Ошибка при обновлении поста:', err);
      });
  }
};

  const handleFileChange = (event: any) => {
    if (event.target.files) {
      const inputFiles = [...event.target.files].map((f: any) => ({
        key: uuid(),
        url: URL.createObjectURL(f),
        type: f.type,
        file: f,
        value: event.target.value,
        percent: 0,
      }));
      setFilesToUpload((prev) => [...prev, ...inputFiles]);

      inputFiles.forEach((inputFile) => {
        loadPictureToDB(
          inputFile.key,
          inputFile.file,
          inputFile.value,
          (percent: number) => {
            setFilesToUpload((prev) => prev.map((file) => {
              if (file.key === inputFile.key) {
                return { ...file, percent };
              }
              return file;
            }));
          }
        ).then((mediaFile) => {
          if (mediaFile?.url) {
            setMediaFiles((prev) => [...prev, mediaFile]);
          } else {
            removeNewFile(inputFile);
          }
        }).catch((err) => {
          toastsStore.addErrorToast(t('toasts.error.loadMedia'));
          console.error(err);
        });
      });
    }
  };

  const removeExistingFile = (file: MediaFile) => {
    setMediaToRemove(prev => [...prev, file]);

    setMediaFiles((prev) => [
      ...prev.filter((media: MediaFile) => media.key !== file.key),
    ]);
  }

  const removeNewFile = (file: FilesToUpload) => {
    setFilesToUpload((prev) => [
      ...prev.filter((curFile) => curFile.key !== file.key),
    ]);

    setMediaFiles((prev) => [
      ...prev.filter((media: MediaFile) => media.key !== file.key),
    ]);
  }

  if (!currentUser) {
    return <></>
  }

  const id = uuid();

  return (
    <div className={className}>
      <Avatar url={currentUser.picture?.url ?? undefined} />
      <div className={styles['edit-post_right']}>
        <div className={styles['edit-post__close']} onClick={onCancelEdit}>
          <Icon size='xxxs' icon='x' color='#475467' />
        </div>
        <div className={styles['text-container']}>
          <div
            ref={textRef}
            contentEditable='true'
            className={styles['edit-post__input']}
            onInput={() => {
              setInput('text', filterText(textRef?.current?.innerHTML))}}
          />
        </div>

        {formState.linkIds.length > 0 &&
          <div className={styles['links-data']}>
            {formState.linkIds.filter(Boolean).map((linkId: string) =>
              <LinkDataComponent key={linkId} linkData={{ id: linkId, url: '' }} onClose={() => removeLink(linkId)} />
            )}
          </div>}

        {mediaFiles.length > 0 &&
          <div className={styles['editing-files']}>
            {mediaFiles.map((file) => {
              return (
                <UploadingFile
                  key={file.key}
                  file={{
                    key: file.key || '',
                    url: file.url || '',
                    type: file.type || '',
                    file: undefined,
                    value: undefined,
                    percent: 100,
                  }}
                  onClose={() => removeExistingFile(file)}
                />
              )
            })}
          </div>}

        {filesToUpload.length > 0 &&
          <div className={styles['uploading-files']}>
            {filesToUpload.map((file) => {
              return (
                <UploadingFile
                  precent={file.percent}
                  key={file.key}
                  file={file}
                  onClose={() => removeNewFile(file)}
                />
              )
            })}
          </div>}


        <div className={styles['edit-post_bottom']}>
          <div className={styles['edit-post_buttons']}>
            <form className={styles['form-picture']}>
              <label htmlFor={`upload-post-media-${id}`} className={styles['custom-file-upload']} />
              <input
                id={`upload-post-media-${id}`}
                className={styles.input}
                type='file'
                multiple={true}
                onChange={(event) => handleFileChange(event)}
              />
            </form>
            <Button className={styles['upload-media-button']} type='button' {...buttonParams} iconType='image' />
            <Button {...buttonParams} disabled={true} iconType='map-pin' />
            <Button {...buttonParams} disabled={true} iconType='smile' />
          </div>
          <Button
            className={styles['edit-post__send-button']}
            disabled={(formState.text === '' && mediaFiles.length <= 0 && filesToUpload.every((f) => f.percent === undefined || f.percent >= 100))}
            onClick={onSaveClick} size='md'
            loading={loading}
          >
            {t('createPost.button.publish')}
          </Button>
          <Button
            className={styles['edit-post__send-button-mobile']}
            iconType='send'
            size='md'
            disabled={(formState.text === '' && mediaFiles.length <= 0 && filesToUpload.every((f) => f.percent === undefined || f.percent >= 100))}
            onClick={onSaveClick}
            loading={loading}
          />
          {username !== '' &&
            <DropdownUserList
              onSelect={(user) => {
                onSelectUser(user, stringIndex, setUsername, textRef, usernamePos, setUsernamePos);
                setInput('text', textRef.current?.innerText || '');
              }}
              top={
                window.innerWidth <= 580
                  ? 110 + 24 * [...(textRef.current?.childNodes ?? [])].length
                  : undefined
              }
              className={styles['edit-post__dropdown-user-list']}
              setText={setUsername}
              text={username}
            />}
          </div>
        </div>
      </div>
  )
});
