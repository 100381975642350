import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Nav, Navbar, Stack } from "react-bootstrap";
import { ErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import { ErrorFallback } from "./error";

type Props = {
  headerText: string;
  navigationPath?: string;
};

export const PageNav = ({ headerText, navigationPath }: Props) => {
  const navigate = useNavigate();

  const goBack = () => navigate(navigationPath ?? '/')

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Navbar bg='light' expand='lg'>
        <Container>
          <Stack direction='horizontal' gap={3}>
            {!!navigationPath && <Nav.Link onClick={goBack} className='pt-1'>
              <FontAwesomeIcon icon={solid("arrow-left")} size='xl' />
            </Nav.Link>}
            <Navbar.Brand>{headerText}</Navbar.Brand>
          </Stack>
        </Container>
      </Navbar>
    </ErrorBoundary>
  );
};
