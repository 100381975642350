import AngryIcon from './angry.png';
import DislikeIcon from './dislike.png';
import HeartIcon from './heart.png';
import JoyIcon from './joy.png';
import LikeIcon from './like.png';
import SadIcon from './sad.png';

export type ReactionTypes = "angry"|"dislike"|"heart"|"joy"|"like"|"sad"

const data = {
  "angry": AngryIcon,
  "dislike": DislikeIcon,
  "heart": HeartIcon,
  "joy": JoyIcon,
  "like": LikeIcon,
  "sad": SadIcon
}

export default data;