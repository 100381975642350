import { UserPicture } from "@graphql/graphql";
import styles from "./user-modal-item.module.css";
import { Avatar } from "@ui-kit";

type Props = {
  item: UserPicture,
}

export const UserModalItem = ({ item }: Props) => {
  return <div className={styles['user-modal-item']}>
    <Avatar
      size='lg'
      alt={item.username ?? 'avatar'}
      url={item.picture?.url}
      statusIcon={item?.isOnline ? 'online' : undefined} />
    <div className={styles['user-modal-item__right']}>
      <div className={styles['user-modal-item__header']}>
        <p className={styles['user-modal-item__fullname']}>
          {item.firstname} {item.lastname}
        </p>
      </div>
      <p className={styles['user-modal-item__time']}>
        {item.username}
      </p>
    </div>
  </div>
}