import { createNewPost } from "@home/helpers/create-new-post";
import { ErrorFallback } from "@shared/components";
import { MAX_FILE_SIZE, MAX_FILE_SIZE_MB, MAX_POST_CHARACTERS } from "@shared/data";
import { useFormState } from "@shared/hooks";
import { modalMessageStore, toastsStore } from "@store";
import { observer } from "mobx-react-lite";
import { FormEventHandler, useState } from "react";
import { Accordion, Button, Form } from "react-bootstrap";
import { ErrorBoundary } from "react-error-boundary";
import LoadingOverlay from "react-loading-overlay-ts";
import { userStore } from "src/store/user.store";
import { useTranslation } from "react-i18next";

const initialState = { text: '', media: [''] };

export const PostCreate = observer(() => {
  const [loading, setLoading] = useState(false);
  const [activeKey, setActiveKey] = useState('');
  const [files, setFiles] = useState<File[]>([]);
  const { formState, setInput, setFormState } = useFormState(initialState);

  const [validated, setValidated] = useState(false);

  const setInitialFormSate = () => setFormState(initialState);

  const { t } = useTranslation('main');

  const handleAccordionSelect = () => {
    setValidated(false);
    setActiveKey((prev) => (prev === '' ? '0' : ''));
  };

  const handleFileChange = (event: any) => {
    if (event.target.files) {
      setFiles(event.target.files);
      setInput('media', event.target.value);
    }
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (
      formState.text.length > MAX_POST_CHARACTERS ||
      (files.length && ([...files].every((file) =>
        file.size > MAX_FILE_SIZE ||
        (!file?.type.includes('image') && !file?.type.includes('video'))
      )))
    ) {
      toastsStore.addWarnToast(t('toasts.warning.inputField'));
      setValidated(false);
    } else {
      setValidated(true);
      createNewPost(formState, [...files], setInitialFormSate, setLoading, setActiveKey)
        .catch((err: any) => toastsStore.addErrorToast(t('toasts.error.createPost')));
    }
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <LoadingOverlay active={loading} spinner text='Creating your post...'>
        {userStore.currentUser &&
          <Accordion activeKey={activeKey} onSelect={handleAccordionSelect}>
            <Accordion.Item eventKey={'0'}>
              <Accordion.Header>Create a new post</Accordion.Header>
              <Accordion.Body>
                <Form noValidate onSubmit={handleSubmit} validated={validated}>
                  <Form.Group className='mb-2' controlId='formText'>
                    <Form.Control
                      as='textarea'
                      rows={3}
                      placeholder='Enter text'
                      value={formState.text}
                      onChange={(event) => setInput('text', event.target.value)}
                      isInvalid={formState.text.length > MAX_POST_CHARACTERS}
                    />
                    <Form.Control.Feedback type='invalid'>
                      Your text cannot be more than {MAX_POST_CHARACTERS} characters
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId='formFile' className='mb-3'>
                    <Form.Label>Add image to your post</Form.Label>
                    <Form.Control
                      type='file'
                      multiple={true}
                      value={formState.media}
                      onChange={(event) => handleFileChange(event)}
                      isInvalid={(
                        !files.length ? false : [...files].some((file) =>
                          file.size > MAX_FILE_SIZE ||
                          (!file?.type.includes('image') && !file?.type.includes('video'))
                        )
                      )}
                    />
                    <Form.Control.Feedback type='invalid'>
                      Your file cannot be more than {MAX_FILE_SIZE_MB} megabytes. And must contain an image or video.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button
                    type='submit'
                    variant='primary'
                    disabled={
                      !formState.text &&
                      (Array.isArray(formState.media) && formState.media.some((file: any) => file === ''))
                    }
                  >
                    Post
                  </Button>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        }
      </LoadingOverlay>
    </ErrorBoundary>
  );
});
