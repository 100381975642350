import { Chat } from "@graphql/graphql";
import { messagesStore } from "@store";

const isChat = (chat: Chat | null) : chat is Chat => chat !== null;

const usernameFilter = (chat: Chat, username?: string): boolean =>
  chat.usernames?.includes(username ?? '') === true;

export const getChatList = (username?: string) => 
  messagesStore.getAllChats()
    .then((res) => res.filter(isChat).filter((chat) => usernameFilter(chat, username)));