import { UserPicture } from "@graphql/graphql";
import { toastsStore, userStore, wallPostsStore } from "@store";
import { Loader } from "@ui-kit/components/loader/loader.comp";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { WallComment } from "../wall-comment/wall-comment.comp";
import styles from "./user-comments.module.css";
import { NoUserData } from "../no-user-data/no-user-data.comp";
import { useTranslation } from "react-i18next";

type Props = {
  username?: string,
  className?: string,
}

export const UserComments = observer(({ username, className }: Props) => {
  className = className ? `${className} ${styles['user-comments']}` : styles['user-comments'];

  const [loadMore, setLoadMore] = useState<boolean>();
  const [user, setUser] = useState<UserPicture|null>();

  const { t } = useTranslation('wall');

  const { loading, fetchPosts, userComments, nextToken } = wallPostsStore;

  const location = useLocation();

  useEffect(() => {
    if (username) {
      userStore.getUserData(username)
        .then(setUser)
        .then(() => {
          fetchPosts(username, 'comments')
            .catch(() => toastsStore.addErrorToast(t('toasts.error.loadComments')));
        });
    }
  }, [username]);

  useEffect(() => {
    const scrollHandler = (e: any) => {
      const position = e.target.scrollTop + window.innerHeight + 10;
      if (position >= e.target.scrollHeight ) {
        setLoadMore(true);
      }
    }

    document.body.addEventListener('scroll', scrollHandler, false);
    return () => {
      document.body.removeEventListener('scroll', scrollHandler);
    };
  }, [location.pathname]);

  useEffect(() => {
    if (loadMore && username && nextToken) {
      fetchPosts(username, 'comments')
        .then(() => setLoadMore(false))
        .catch(() => toastsStore.addErrorToast(t('toasts.error.loadComments')));
    } else {
      setLoadMore(false);
    }
  }, [loadMore]);

  return <div className={className}>
    {!!user && !!userComments?.length && userComments?.filter((item) => item.post)?.map((item) => (
      <WallComment key={item.id} item={item} user={user} />
    ))}
    {!!loading && <div className={styles.loader}>
      <Loader />
    </div>}
    {!loading && userComments?.length === 0 && <NoUserData
      title={
        userStore.currentUser?.username === username
          ? t('message.notComment.yet')
          : t('message.notComment.not')
        }
      text={
        userStore.currentUser?.username === username
          ? t('message.yourOpinion.try')
          : t('message.yourOpinion.notShare')
        }
      />}
  </div>
});
