import { Comment, Message, Post } from "@graphql/graphql";
import { commentListStore, messagesStore, postListStore, wallPostsStore } from "@store";
import { userStore } from "src/store/user.store";

export const makeReaction = async (
  item: Post|Comment|Message,
  type?: string,
  postId?: string,
) => {
  if (item.__typename === 'Post') {
    let foundPost = postListStore.posts?.find((pst) => pst?.id === item.id);
    let storeType = 'city';
    if (!foundPost) {
      foundPost = wallPostsStore.posts?.find((pst) => pst?.id === item.id);
      storeType = 'wall';
    };
    if (!foundPost) {
      foundPost = wallPostsStore.postsWithReactions?.find((pst) => pst.post?.id === item.id)?.post ?? undefined;
      storeType = 'likes';
    };
    if(!foundPost) {
      return;
    }

    const copyPost = { ...foundPost } as Post;

    const reactionItem = {...copyPost.reactions?.find((reaction) =>
      reaction?.username === userStore.currentUser?.username && reaction?.type === type,
    )}

    if (reactionItem?.id) {
      postListStore.deleteReaction(reactionItem.id, storeType);
    } else {
      postListStore.createReaction({
        postId: item.id ?? '',
        type: type ?? '',
      }, storeType);
    }
  } else if (item.__typename ==='Comment') {
    if (!postId) {
      return;
    }

    const foundComment = commentListStore.comments[postId]?.find((comment) => comment?.id === item.id);

    if (!foundComment) {
      return;
    }

    const copyComment = { ...foundComment } as Post;

    const reactionItem = {...copyComment.reactions?.find((reaction) =>
      reaction?.username === userStore.currentUser?.username,
    )}

    if (reactionItem?.id) {
      commentListStore.deleteReaction(reactionItem.id, postId);
    }

    if (reactionItem?.type !== type) {
      commentListStore.createReaction({
        commentId: item.id ?? '',
        type: type ?? '',
      }, postId);
    }
  } else if (item.__typename === 'Message') {
    if (!item.chatId) {
      return;
    }

    let foundMessage = messagesStore
      .messages[item.chatId]
      ?.find((message) => message.id === item.id);

    if(!foundMessage) {
      return;
    }

    messagesStore.makeReaction(item.id ?? '', type ?? 'like')
  }
}