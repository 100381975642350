import { City } from "@graphql/graphql";
import { MenuItem } from "@ui-kit/components/drop-down/modele/menu-item";
import { getI18n } from "react-i18next";


export const cityToMenuItem = (city: City, i: number, selected?: boolean): MenuItem => {
  const i18n = getI18n();

  const mainSub = city.place_id !== 'no-city' ? i18n.t('addCity.hometown') : undefined

  const subTitle = i === 0 ? mainSub : undefined;

  return {
    key: city.place_id ?? 'key',
    type: 'user',
    selected,
    title: city.longName ?? '',
    subTitle,
  }
}