import chadImg from "@assets/images/chad.png";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorFallback } from "@shared/components";
import { DateUtil } from "@shared/utils";
import { toastsStore } from "@store";
import { useEffect, useState } from "react";
import { Button, ListGroupItem, Stack } from "react-bootstrap";
import { ErrorBoundary } from "react-error-boundary";
import styles from "./comment.module.css";
import { Link } from "react-router-dom";
import { Comment } from "@graphql/graphql";
import { userStore } from "src/store/user.store";
import { useTranslation } from "react-i18next";

type Props = {
  comment: Comment;
  fetchComments: () => Promise<void>;
  handleCommentDelete: (commentId: string) => Promise<void>;
};

export const CommentItem = ({
  comment,
  fetchComments,
  handleCommentDelete,
}: Props) => {
  const [picture, setPicture] = useState<string | null>(null);

  const { t } = useTranslation('main');

  useEffect(() => {
    setPicture(comment.userPicture?.picture?.url ?? '');
  }, [comment.userPicture])

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ListGroupItem variant='secondary'>
        <Stack direction='horizontal' gap={3}>
          <div className='ms-2 me-auto'>
            <Stack direction='horizontal' className={styles.CommentUser} gap={2}>
              <Link className={styles.userWallLink} to={`/wall/${comment.username}`}>
                <img
                  src={picture || chadImg}
                  className={styles.profilePic}
                  alt='profile'
                />
                <strong className={styles.username}>{comment?.username}</strong>
              </Link>
              <span>·</span>
              <span>{DateUtil.getTimeDiff(new Date(comment?.createdAt ?? '').toISOString())}</span>
            </Stack>
            <div className={styles.commentContent}>{comment?.text}</div>
          </div>
          {!!comment?.username && !!comment.id && comment?.username === userStore.currentUser?.username && (
            <Button
              size='sm'
              variant='secondary'
              className='ms-auto'
              onClick={() => {
                handleCommentDelete(comment.id ?? '')
                  .catch((err: any) => { toastsStore.addErrorToast(t('toasts.error.deleteComment')) });
              }}
            >
              <FontAwesomeIcon icon={faTrashCan} />
            </Button>
          )}
        </Stack>
      </ListGroupItem>
    </ErrorBoundary>
  );
};
