import { UserPicture } from "@graphql/graphql";
import styles from "./chat-user-info.module.css";
import { IconButton } from "@ui-kit/components/button/icon-button/icon-button.comp";
import { Avatar } from "@ui-kit";
import { PremiumIcon } from "@shared/components/premium-icon/premium-icon.comp";
import { FollowUnfollowButton } from "@shared/components/follow-unfollow-button/follow-unfollow-button.comp";
import { Toggle } from "@ui-kit/components/toggle/toggle.comp";
import { UserNotificationSwitcher } from "../user-notification-switcher/user-notification-switcher";
import { UserChatBottomInfo } from "../user-chat-bottom-info/user-chat-bottom-info.comp";
import { useTranslation } from "react-i18next";

type Props = {
  user: UserPicture,
  chatId: string,
  onBackClick: () => void
}

export const ChatUserInfo = ({ user, onBackClick, chatId }: Props) => {
  const { t } = useTranslation('messenger');

  return <div className={styles['chat-user-info']}>
    <div className={styles['chat-user-info__header']}>
      <IconButton
        iconType='arrow-left'
        size='xl'
        onClick={onBackClick} />
      <div className={styles['chat-user-info__header-container']}>
        <h2 className={styles['chat-user-info__title']}>{t('chat.userInfo.header')}</h2>
        <p className={styles['chat-user-info__username']}>@{user.username}</p>
      </div>
    </div>
    <div className={styles['chat-user-info__user']}>
      <Avatar
        alt={user.username ?? 'user'}
        size='lg'
        url={user.picture?.url}
        statusIcon={user.isOnline ? 'online' : undefined} />
      <div className={styles['chat-user-info__user-info']}>
        <p className={styles['chat-user-info__fullname']}>
          {user.firstname} {user.lastname}
          {!!user.isPremiun && <PremiumIcon size='sm' className={styles['chat-user-info__premium']} />}
        </p>
        <p className={styles['chat-user-info__city']}>
          {user.city?.longName}
        </p>
      </div>
      <FollowUnfollowButton hidePlus={true} user={user} className={styles['chat-user-info__follow']} />
    </div>
    <UserNotificationSwitcher user={user} chatId={chatId} />
    <UserChatBottomInfo user={user} chatId={chatId} />
  </div>
}