import { MenuItem } from "@ui-kit/components/drop-down/modele/menu-item";
import { useTranslation } from "react-i18next";

export enum MAIN_MENU_KEYS {
  WALL = 'wall',
  SEARCH = 'search',
  MAP = 'map',
  BROADCAST = 'broadcast',
  NOTIFICATION = 'notifications',
  MESSAGES = 'messages',
  SETTINGS = 'settings'
}

export const useMainMenu = () => {

  const { t } = useTranslation('main');

  const initialMenuState: MenuItem[] = [
  {
    key: MAIN_MENU_KEYS.WALL,
    type: 'menu-item',
    title: t('mainMenu.wall'),
    showIcon: true,
    grayIcon: 'grid'
  },
  {
    key: MAIN_MENU_KEYS.SEARCH,
    type: 'menu-item',
    title: t('mainMenu.search'),
    showIcon: true,
    grayIcon: 'search',
  },
  {
    key: MAIN_MENU_KEYS.MAP,
    type: 'menu-item',
    title: t('mainMenu.map'),
    showIcon: true,
    grayIcon: 'map',
  },
  {
    disabled: true,
    key: MAIN_MENU_KEYS.BROADCAST,
    type: 'menu-item',
    title: t('mainMenu.lives'),
    showIcon: true,
    grayIcon: 'radio',
  },
  {
    key: MAIN_MENU_KEYS.NOTIFICATION,
    type: 'menu-item',
    title: t('mainMenu.notifications'),
    showIcon: true,
    grayIcon: 'bell',
  },
  {
    key: MAIN_MENU_KEYS.MESSAGES,
    type: 'menu-item',
    title: t('mainMenu.messages'),
    showIcon: true,
    grayIcon: 'message-circle',
  },
  {
    key: MAIN_MENU_KEYS.SETTINGS,
    type: 'menu-item',
    title: t('mainMenu.settings'),
    showIcon: true,
    grayIcon: 'settings',
  },
];

const showKeys: MAIN_MENU_KEYS[] = [
  MAIN_MENU_KEYS.WALL,
  MAIN_MENU_KEYS.SEARCH,
  MAIN_MENU_KEYS.MAP,
  MAIN_MENU_KEYS.BROADCAST,
  MAIN_MENU_KEYS.SETTINGS
];

const getInitialMenuState = (hasUser: boolean, messages?: number) => {
  if (!hasUser) {
    return initialMenuState
      .filter((menuItem) => showKeys.includes(menuItem.key as MAIN_MENU_KEYS))
      .map((menuItem) => menuItem.key === MAIN_MENU_KEYS.MESSAGES && messages ? { ...menuItem, number: messages } : menuItem);
  }
  return initialMenuState;
};

  return { menuItems: initialMenuState, getInitialMenuState };
}