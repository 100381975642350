import { MenuItem } from "@ui-kit/components/drop-down/modele/menu-item";
import { useTranslation } from "react-i18next";

export enum WALL_MENU_KEYS {
  HIDE = 'hide',
};

export const useUserWallMenu = () => {
  const { t } = useTranslation('wall');

  const getUserWallMenu = (item: any, username?: string): MenuItem[] => {
  let menuItems: MenuItem[] = []

  if (username) {
    menuItems.push({
      key: WALL_MENU_KEYS.HIDE,
      type: 'menu-item',
      grayIcon: 'x',
      title: t('menuItem.hide'),
    });
  }
  return menuItems;
  }
  return getUserWallMenu;
}