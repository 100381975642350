import { Header } from "@shared/components/header/header.comp";
import styles from "./follow.module.css";
import { followersStore } from "@store";
import { Loader } from "@ui-kit/components/loader/loader.comp";
import { FollowComponent } from "./components/follow.comp";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

type Props = {
  isFollowing?: boolean
}

export const FollowPage = observer(({ isFollowing }: Props) => {
  const { username } = useParams();
  const navigate = useNavigate();

  const { loading, followers, following, fetchFoolows } = followersStore;
  const { t } = useTranslation('common');

  useEffect(() => {
    if (username) {
      fetchFoolows(username, isFollowing)
    }
  }, [username])

  return <>
    <Header
      title={isFollowing ? t('followPage.subscriptions') : t('followPage.subscribers') }
      subTitle={isFollowing
        ? following.length > 0 ? following.length.toString() : ''
        : followers.length > 0 ? followers.length.toString() : ''
      } />

    {loading && <Loader />}
    {!loading && <div className={styles.follow}>
      {!!isFollowing
        ? following.map((follow) => <FollowComponent onClick={() => navigate(`/wall/${follow.username}`)} user={follow} />)
        : followers.map((follow) => <FollowComponent onClick={() => navigate(`/wall/${follow.username}`)} user={follow} />)}
    </div>}
  </>
});
