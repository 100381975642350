import { Cords } from "@add-city/add-city.reducer";
import { ZOOM_CITY } from "@add-city/components";
import { getUserLocation } from "@shared/helpers";
import { toastsStore } from "@store";
import i18n from 'i18next';

export const setByUserLocation = async (
  setCenter: (center: Cords) => void,
  setZoom: (zoom: number) => void,
  setCityByCords: (latitude: number, longitude: number) => Promise<any>
): Promise<void> => {
  try {
    const location = await getUserLocation()
    if (location) {
      const { latitude, longitude } = location;
      setCenter({ lat: latitude, lng: longitude });
      setZoom(ZOOM_CITY);
      setCityByCords(latitude, longitude)
        .catch((err: any) => toastsStore.addErrorToast(i18n.t('common: toasts.error.getLocalCity')));
    } else {
      toastsStore.addErrorToast(i18n.t('common: toasts.error.geoSupport'));
    }
  } catch (err: any) {
    toastsStore.addErrorToast((i18n.t('common: toasts.error.getLocalCity')))
  }
};