import { gql } from "@apollo/client";

export const deleteCommentReaction = gql`mutation deleteCommentReaction($id: ID) {
    deleteCommentReaction(id: $id){
        id
        postId
        type
        username
        isHiden
        createdAt
        updatedAt
    }
}`;
