import { Button, LinkComponent } from "@ui-kit";
import styles from "./no-found-page.module.css";
import { BottomMenu } from "@ui-kit/components/bottom-menu/bottom-menu.comp";
import { useTranslation } from "react-i18next";

export function NotFoundPage() {

  const { t } = useTranslation('common');

  return (<>
  <div className={styles['no-page__container']}>
    <div className={styles['no-page']}>
      <div className={styles['no-page__picture']}></div>
      <div className={styles['no-page__footer']}>
        <h3 className={styles['no-page__title']}>{t('404.header')}</h3>
        <div className={styles['no-page__text']}>{t('404.message')}</div>
        <Button
          size='lg'>
          <LinkComponent link='/' className={styles['no-page__link']}>{t('404.link')}</LinkComponent>
        </Button>
      </div>
    </div>
  </div>
  <BottomMenu className={styles['bottom-menu']} onCreateClick={() => {}}/>
</>)
}