import { Chat } from "@graphql/graphql";
import { MenuItem } from "@ui-kit/components/drop-down/modele/menu-item";
import { useTranslation } from "react-i18next";

export enum MESSAGE_MENU_KEYS {
  NOTIFY_ON = 'notify-on',
  NOTIFY_OFF = 'notify-off',
  REPORT = 'report',
  DELETE = 'delete',
};

export const useMessageMenu = () => {
  const { t } = useTranslation('messenger');

  const getMessageMenu = (chat?: Chat, username?: string|null): MenuItem[] => {
  let menuItems: MenuItem[] = [];

  if (!chat) {
    return menuItems;
  }

  if (username && chat?.offNotifyUsernames?.includes(username)) {
    menuItems.push({
      key: MESSAGE_MENU_KEYS.NOTIFY_ON,
      type: 'menu-item',
      grayIcon: 'bell',
      iconCollor: '#367DFF',
      title: t('menuItems.notifyOn'),
    });
  } else {
    menuItems.push({
      key: MESSAGE_MENU_KEYS.NOTIFY_OFF,
      type: 'menu-item',
      grayIcon: 'bell-off',
      title: t('menuItems.notifyOff'),
    });
  }

  menuItems.push({
    key: MESSAGE_MENU_KEYS.REPORT,
    type: 'menu-item',
    grayIcon: 'alert-circle',
    title: t('menuItems.report'),
  });

  menuItems.push({
    key: MESSAGE_MENU_KEYS.DELETE,
    type: 'menu-item',
    grayIcon: 'trash-2',
    iconCollor: '#D92D20',
    textCollor: '#D92D20',
    title: t('menuItems.delete'),
  });
  return menuItems;
  }
  return getMessageMenu;
}