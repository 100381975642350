import { Notification } from "@graphql/graphql";
import { MenuItem } from "@ui-kit/components/drop-down/modele/menu-item";
import { useTranslation } from "react-i18next";

export enum NOTIFICATION_MENU_KEYS {
  HIDE = 'hide',
};

export const useNotificationMenu = () => {

  const { t } = useTranslation('notification');

  const getNotificationMenu = (item: Notification) => {
    let menuItems: MenuItem[] = [];

    if (!item) {
      return menuItems;
    }

    menuItems.push({
      key: NOTIFICATION_MENU_KEYS.HIDE,
      type: 'menu-item',
      grayIcon: 'eye-off',
      title: t('menu.hide'),
    });

    return menuItems;
  }
  return getNotificationMenu;
}