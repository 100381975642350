export function convertSecToDate(dateString: string) {
  const dateNumber = Number(dateString);

  if (!dateNumber || isNaN(dateNumber)) {
    return '';
  }

  const date = new Date(dateNumber);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
}