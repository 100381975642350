import React, { useState, useEffect } from "react";
import styles from "../confirm-page.module.css";
import { useTranslation } from "react-i18next";

  type Props = {
    initialSeconds: number;
    onTimeout: () => void;
    typeConfirmWhatsApp: boolean;
    onSendedTextChange: (isSendedText: boolean) => void;
    isEmailConfirm?: boolean;
}

  const CountdownTimer: React.FC<Props> = ({ initialSeconds, onTimeout, typeConfirmWhatsApp, onSendedTextChange, isEmailConfirm }) => {
    const [countdown, setCountdown] = useState(initialSeconds);
    const [isCounting, setIsCounting] = useState(false);
    const [isSendedText, setIsSendedText] = useState(false);

    const { t } = useTranslation('authorization');

    useEffect(() => {
      let timerId: NodeJS.Timeout;

      if (isCounting && countdown > 0) {
        timerId = setInterval(() => {
          setCountdown((prevCount) => prevCount - 1);
        }, 1000);
      }

      return () => {
        clearInterval(timerId);
        onSendedTextChange(isSendedText)
      };
    }, [isCounting, countdown, onSendedTextChange, isSendedText]);

    useEffect(() => {
      if (countdown === 0) {
        setIsCounting(false);
        setIsSendedText(true);
        onTimeout();
      }
    }, [countdown,setIsSendedText]);

  const startTimer = () => {
    setIsCounting(true);
    setIsSendedText(false);
    setCountdown(initialSeconds);
  };

  return (
    <span className={styles['countdown-block']} onClick={startTimer}>
      {!typeConfirmWhatsApp && t('countdown.title.dontHaveSms')}
      {typeConfirmWhatsApp && t('countdown.title.dontHaveCode')}
      {isEmailConfirm && t('countdown.title.dontHaveCode')}
      <span className={styles['countdownText']}>{isCounting && ` ${t('countdown.sendAgain')} ${Math.floor(countdown / 60)}:${countdown % 60}`}
        {isSendedText && t('countdown.isSent')}</span>
      </span>
  );
};

export default CountdownTimer;