import styles from "./main-menu.module.css";
import logo from "@assets/images/logo.svg";
import logomark from "@assets/images/Logomark.svg";
import { Menu } from "@ui-kit/components/drop-down/comp/dropdown-menu/menu.comp";
import { MenuItem } from "@ui-kit/components/drop-down/modele/menu-item";
import { useEffect, useState } from "react";
import { useMainMenu, MAIN_MENU_KEYS } from "./useMainMenu";
import { Button } from"@ui-kit";
import { AvatarLabelGroup } from "@ui-kit/components/avatar-label-group/avatar-label-group.comp";
import { NavMenu } from "@ui-kit/components/nav-menu/nav-menu.comp";
import { messagesStore, userStore } from "@store";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router-dom";
import { notificationsStore } from "src/store/notifications.store";
import { useTranslation } from "react-i18next";

type Props = {
  userMenuState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}

const PATHNAMES: { [key: string]: string } = {
  'wall': '/',
  'map': '/map/map',
  'search': '/search',
  'messages': '/messages',
  'settings': '/profile',
  'notifications': '/notifications',
}

export const MainMenu = observer(({ userMenuState }: Props) => {
  const { currentUser } = userStore;

  const { t } = useTranslation('main');

  const { menuItems, getInitialMenuState } = useMainMenu();
  const [currentItem, setItem] = useState<MenuItem>({ key: 'wall', type: 'menu-item' });
  const [mainMenuItems, setMainMenuItems] = useState<MenuItem[]>(getInitialMenuState(false));
  const [_, setMenuVisible] = userMenuState;

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const menuItems = getInitialMenuState(!!currentUser);
    let currentItem: MenuItem|undefined;

    const searchItem = menuItems.find((item) => item.key === 'search');
    if (location.pathname.includes('search') && searchItem) {
      currentItem = searchItem;
    }

    const messagesItem = menuItems.find((item) => item.key === 'messages');
    if (location.pathname.includes('messages') && messagesItem) {
      currentItem = messagesItem;
    }

    const mapItem = menuItems.find((item) => item.key === 'map');
    if (location.pathname.includes('map') && mapItem) {
      currentItem = mapItem;
    }

    const broadcastItem = menuItems.find((item) => item.key === 'broadcast');
    if (location.pathname.includes('broadcast') && broadcastItem) {
      currentItem = broadcastItem;
    }

    const notificationItem = menuItems.find((item) => item.key === 'notifications');
    if (location.pathname.includes('notifications') && notificationItem) {
      currentItem = notificationItem;
    }

    const settingsItem = menuItems.find((item) => item.key === 'settings');
    if (location.pathname.includes('profile') && settingsItem) {
      currentItem = settingsItem;
    }

    const wallItem = menuItems.find((item) => item.key === 'wall');
    if (location.pathname === '/' && wallItem) {
      currentItem = wallItem;
    }

    if(!currentItem) {
      currentItem = mainMenuItems.find((item) => item.selected);
    }

    setMainMenuItems(menuItems.map((item) => ({
      ...item,
      selected: item.key === currentItem?.key,
      number: item.key === MAIN_MENU_KEYS.MESSAGES && (messagesStore.messagesCout > 0)
        ? messagesStore.messagesCout
        : item.key === MAIN_MENU_KEYS.NOTIFICATION && (notificationsStore.notificationsLength > 0)
          ? notificationsStore.notificationsLength
          : undefined,
    })));
  }, [currentUser, location.pathname, messagesStore.messagesCout, notificationsStore.notificationsLength]);

  return (<>
    <div className={styles['main-menu_background']}></div>
    <div className={styles['main-menu']}>
      <div className={styles.logo} onClick={() => document.body.scrollTo({top: 0, behavior: "smooth"})}>
        <img className={styles['full-logo']} src={logo} alt='Ecitty' />
        <img className={styles['logomark']} src={logomark} alt='Ecitty' />
      </div>
      <Menu className={styles.menu}
            iconSize={'xs'}
            itemClassName={styles.menu__item}
            items={mainMenuItems}
            onClick={(item) => { navigate(PATHNAMES[item.key] ?? '/'); }}
            withoutScroll={true}
            indicateSelection={true} />
      <NavMenu className={styles['nav-menu']}
            items={mainMenuItems}
            onClick={(item) => { navigate(PATHNAMES[item.key] ?? '/'); }} />
      {!!currentUser && <>
        {/* <Button className={styles.button} iconType='plus' size='md' hierarchy='secondary-color'>{t('mainMenu.button.publish')}</Button> */}
        {/* <Button className={styles['tab-button']} iconType='plus' size='md'></Button> */}
        <div className={styles['avatar-group']}>
          <AvatarLabelGroup
            className={styles['left']}
            fullName={`${currentUser.firstname} ${currentUser.lastname}`}
            username={`${currentUser.username}`}
            url={currentUser.picture?.url ?? ''} />
          <Button
            hierarchy='link-gray'
            iconType='more-vertical'
            onClick={() => setMenuVisible((prev) => !prev)} />
        </div>
      </>}
    </div>
  </>)
});