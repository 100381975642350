import { createComment, createCommentReaction, deleteComment, deleteCommentReaction } from "@graphql/docs/mutations";
import { getPostComments, getSubComments } from "@graphql/docs/queries";
import { Comment, CreateCommentInput, CreateCommentReactionInput, GetSubCommentsInput, ModelCommentConnection, Post, Reaction } from "@graphql/graphql";
import { COMMENTS_FIRST_LIMIT, COMMENTS_SECOND_LIMIT } from "@shared/data";
import { makeAutoObservable, runInAction } from "mobx";
import { apolloClient } from "src/apollo-client";
import { toastsStore } from "./toasts.store";
import { getAnswerUsernames } from "@shared/helpers/get-answer-usernames";
import { userStore } from "./user.store";
import { convertHTMLTextToCorrectText } from "@shared/helpers/convert-html-text-to-correct-text";
import { getI18n } from "react-i18next";
import { postListStore } from "./post-list.store";

type AllComments = {
  [key: string]: any[]
}

type AllNextTokens = {
  [key: string]: string | null | undefined
}

class CommentListStore {
  comments: AllComments = {};
  loading = false;
  subLoading: {[key: string]: boolean} = {}
  loadingNewComment = false;
  nextTokens: AllNextTokens = {};
  commentId?: string;

  constructor() {
    makeAutoObservable(this);
  }

  text: string = ''

  setText = (text: string) => {
    this.text = text;
  }

  get t() {
    const i18n = getI18n();
    return i18n.isInitialized ? i18n.t.bind(i18n) : () => "";
  }

  setComments = (postId: string, value: Comment[], isSubcomment = false) => {
    const newCommentsArr = this.comments[postId] ? [...this.comments[postId], ...value] : value;
    this.comments[postId] = newCommentsArr.filter((comment, index) =>
      newCommentsArr.reduce((sum, com, i) => comment.id === com.id && i >= index ? sum += 1 : sum, 0) === 1
    );
  }

  setAnswerCommentId = (commentId: string) => {
    this.commentId = commentId;
  }

  clearAnswerCommentId = () => {
    this.commentId = undefined;
  }

  clearComments = (postId: string) => {
    this.comments[postId] = []
    this.nextTokens[postId] = undefined;
  }

  clearSubComments = (commentId: string) => {
    this.comments[commentId] = []
    this.nextTokens[commentId] = undefined;
  }

  removeComent = (postId: string, commentId: string) => {
    this.comments[postId] = this.comments[postId].filter((item: Comment) => item.id !== commentId )
  }

  addComment = (postId: string, comment: Comment) => {
    this.comments[postId] = this.comments[postId]
      ? [comment, ...this.comments[postId]]
      : [comment];
  }

  setLoading = (value: boolean) => {
    this.loading = value;
  }

  setSubLoading = (value: boolean, commentId: string) => {
    this.subLoading[commentId] = value;
  }

  setLoadingNewComment = (value: boolean) => {
    this.loadingNewComment = value;
  }

  fetchComments = async (postId: string) => {
    if (!postId) return;

    this.setLoading(true);

    if(this.nextTokens[postId] === null) {
      this.setLoading(false);
      return
    }

    try {
      const input = {
        postId: postId,
        limit: this.nextTokens[postId] ? COMMENTS_SECOND_LIMIT : COMMENTS_FIRST_LIMIT,
        nextToken: this.nextTokens[postId],
      }
      const response = await apolloClient.query<{getPostComments: ModelCommentConnection}>({
        query: getPostComments,
        variables:{ input },
      });
      const items = response.data.getPostComments?.items?.filter(Boolean) as Comment[] ?? [];
      if (items) {
        this.setComments(postId, items);
        this.nextTokens[postId] = response?.data.getPostComments?.nextToken;
      }
    } catch (error) {
      console.error(error);
    }
    this.setLoading(false);
  };

  fetchSubComments = async (commentId: string) => {
    if (!commentId) return;

    this.setSubLoading(true, commentId);

    if(this.nextTokens[commentId] === null) {
      this.setSubLoading(false, commentId);
      return;
    }

    try {
      const input = {
        commentId,
        limit: this.nextTokens[commentId] ? COMMENTS_SECOND_LIMIT : COMMENTS_FIRST_LIMIT,
        nextToken: this.nextTokens[commentId],
      } as GetSubCommentsInput;
      const response = await apolloClient.query<{getSubComments: ModelCommentConnection}>({
        query: getSubComments,
        variables:{ input },
      });
      const items = response.data.getSubComments?.items?.filter(Boolean) as Comment[] ?? [];
      if (items) {
        this.setComments(commentId, items, true);
        this.nextTokens[commentId] = response?.data.getSubComments?.nextToken;
      }
    } catch (error) {
      console.error(error);
    }
    this.setSubLoading(false, commentId);
  }

  postComment = async (input: CreateCommentInput) => {
    this.setLoadingNewComment(true);
    const answersInText = input.text
      ? getAnswerUsernames(convertHTMLTextToCorrectText(input.text))
      : [];

    const answers = (await Promise.allSettled([
      ...answersInText
        .map((user) => user.substring(1))
        .map((username) => userStore.getUserData(username))
    ])).filter((data) => data.status === 'fulfilled')
        .filter((data: any) => data.value !== null)
        .map((data: any) => data.value.username);

    input.text = convertHTMLTextToCorrectText(input.text);

    input.answer = answers;
    const { postId } = input;
    if (this.commentId) {
      input.commentId = this.commentId;
      input.postId = undefined;
    }

    try {
      const resNewComment = await apolloClient.mutate<{createComment: Comment}>({
        mutation: createComment,
        variables: { input }
      });
      if (resNewComment?.data?.createComment && input.postId) {
        this.addComment(input.postId, resNewComment.data.createComment);
      }
      if (resNewComment?.data?.createComment && input.commentId) {
        if (postId) {
          const fatherComment = this.comments[postId].find((com) => com.id === input.commentId);
          if (fatherComment.commentsLength) {
            fatherComment.commentsLength += 1;
          }
        }
        this.addComment(input.commentId, resNewComment.data.createComment);
      }
      postListStore.posts = postListStore.posts
        ?.map((post: Post): Post =>
          post.id !== resNewComment.data?.createComment.postId
            ? post
            : {...post, commentsLength: post.commentsLength ? post.commentsLength + 1 : 1}
        )
    } catch (err) {
      console.error('error creating comment:', err);
    }
    this.setLoadingNewComment(false);
  };

  deleteCommentFn =async (id: string) => {
    try {
      const resDelComment = await apolloClient.mutate<{deleteComment: Comment}>({
        mutation: deleteComment,
        variables: { id }
      });
      postListStore.posts = postListStore.posts
        ?.map((post: Post): Post =>
          post.id !== resDelComment.data?.deleteComment.postId
            ? post
            : {...post, commentsLength: post.commentsLength ? post.commentsLength - 1 : 0}
        )
      return resDelComment.data?.deleteComment;
    } catch (err) {
      console.error('Can not delete comment!');
      return null;
    }
  }

  createReaction = async (input: CreateCommentReactionInput, postId: string): Promise<Reaction|null> => {
    try {
      const newReaction = await apolloClient.mutate<{createCommentReaction: Reaction}>({
        mutation: createCommentReaction,
        variables: { input },
      })
      runInAction(() => {
        if (!newReaction.data) {
          return;
        }
        this.comments[postId] = this.comments?.[postId]?.map((comment) => {
          if (comment.id === newReaction.data?.createCommentReaction?.postId) {
            comment.reactions = comment.reactions ?
              [...comment.reactions, newReaction.data?.createCommentReaction] as Reaction[] :
              [newReaction.data?.createCommentReaction] as Reaction[];
          }
          return {...comment};
        });
      });
      return newReaction.data?.createCommentReaction ?? null;
    } catch {
      toastsStore.addErrorToast(this.t('common:toasts.error.deleteReaction'));
      return null;
    }
  }

  deleteReaction = async (id: string, postId: string): Promise<Reaction|null> => {
    try {
      const delReaction = await apolloClient.mutate<{deleteCommentReaction: Reaction}>({
        mutation: deleteCommentReaction,
        variables: { id },
      })
      runInAction(() => {
        if (!delReaction.data) {
          return;
        }
        this.comments[postId] = this.comments?.[postId]?.map((comment) => {
          if(comment.id === delReaction.data?.deleteCommentReaction?.postId) {
            comment.reactions = comment.reactions?.filter((reac: Comment) => reac?.id !== id);
          }
          return {...comment};
        });
      });

      return delReaction.data?.deleteCommentReaction ?? null;
    } catch {
      toastsStore.addErrorToast(this.t('common:toasts.error.deleteReaction'));
      return null;
    }
  }
}

export const commentListStore = new CommentListStore()