import { useState } from "react";
import styles from "./what-wall.module.css";
import { observer } from "mobx-react-lite";
import wall from "@assets/images/wall.svg";
import { Header } from "@shared/components";
import { useTranslation } from "react-i18next";

type Props = {
  onBack: () => void;
};

export const WhatWall = observer(({onBack}: Props) => {

 const { t } = useTranslation('help');

  const [isTabletMobile, setIsTabletMobile] = useState(window.innerWidth <= 1079 ? true : false );

  return (
<>
  <Header
    className={styles['header-help']}
    title={t('main.menu.whatWall')}
    hideAvatar={true}
    hideBackArrow={window.innerWidth > 1079}
    onBackClick={onBack}
  />
  <article className={styles['help-ecitty']}>
    <div className={styles['image-container']}>
        <img className={styles['help-image']} src={wall} alt='Ecitty wall' />
    </div>

    <p className={styles['help-text']}>{t('whatWall.intro')}</p>

    <section>
      <h6 className={styles['help-title']}>{t('whatWall.userPosts.header')}</h6>
      <p className={styles['help-text']}>{t('whatWall.userPosts.text')}</p>
    </section>

    <section>
      <h6 className={styles['help-title']}>{t('whatWall.popularPosts.header')}</h6>
      <p className={styles['help-text']}>{t('whatWall.popularPosts.text')}</p>
    </section>

    <section>
      <h6 className={styles['help-title']}>{t('whatWall.discoveringCities.header')}</h6>
      <p className={styles['help-text']}>{t('whatWall.discoveringCities.text')}</p>
    </section>

    <section>
      <h6 className={styles['help-title']}>{t('whatWall.postWriting.header')}</h6>
      <p className={styles['help-text']}>{t('whatWall.postWriting.text')}</p>
    </section>

    <section>
      <h6 className={styles['help-title']}>{t('whatWall.cityCover.header')}</h6>
      <p className={styles['help-text']}>{t('whatWall.cityCover.textPartOne')}</p>
      <p className={styles['help-text']}>{t('whatWall.cityCover.textPartTwo')}</p>
    </section>
  </article>
</>
);
});
