import { Post } from "@graphql/graphql";
import styles from "./create-comment.module.css";
import { commentListStore, userStore } from "@store";
import { useEffect, useRef, useState } from "react";
import {Avatar, Button, Loader } from "@ui-kit";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { onSelectUser } from "@shared/helpers/on-select-user";
import { DropdownUserList } from "@shared/components/dropdown-user-list/dropdown-user-list.comp";
import { foundLastWord } from "src/main-page/helpers/found-last-word";


type Props = {
  post?: Post,
  className?: string,
  textRef: any
}

export const CreateComment = observer(({ post, className, textRef }: Props) => {
  className = className ? `${className} ${styles['create-comment']}` : styles['create-comment'];

  const { currentUser } = userStore;
  const { postComment, loadingNewComment, text, setText } = commentListStore;


  const { t } = useTranslation('main');

  const [username, setUsername] = useState<string>('');
  const [stringIndex, setStringIndex] = useState<number[]>([0]);
  const [usernamePos, setUsernamePos] = useState<{start?: number, end?: number}>({});

  let selection = window.getSelection();

  useEffect(() => {
    if (!textRef.current?.innerHTML) {
      return;
    }

    selection = window.getSelection();
    const { lastWord, start, end } = foundLastWord(
      selection?.anchorNode?.textContent ?? '',
      selection?.anchorOffset ?? 0,
    );

    const index = [...textRef.current.childNodes]
      .findIndex((node: any) =>
        selection?.anchorNode?.isEqualNode(node)
        || selection?.anchorNode?.isEqualNode(node?.firstChild)
      );
    if (index >= 0) {
      setStringIndex([index]);
    } else {
      let [indexX, indexY] = [-1, -1];
      [...textRef.current.childNodes]
        ?.forEach((parentNode, i) => {
          if (parentNode.nodeName === 'DIV') {
            [...parentNode?.childNodes]?.forEach((node, j) => {
              if(
                selection?.anchorNode?.isEqualNode(node)
                || selection?.anchorNode?.isEqualNode(node?.firstChild)
              ) {
                indexX = i;
                indexY = j;
              }
            });
          }
        });
      setStringIndex([indexX, indexY]);
    }
    
    if (lastWord.at(0) === '@') {
      if (lastWord.length >= 3) {
        setUsername(lastWord.substring(1));
      } else {
        setUsername('');
      }
      setUsernamePos({ start, end });
    } else {
      setUsername('');
      setUsernamePos({});
    };
  }, [textRef.current?.textContent])


  const sendComment = () => {
    if (post?.id) {
      postComment({
        postId: post.id,
        text
      }).then(() => {
        setText('');
        textRef.current.innerHTML = '';
      });
    }
  }

  useEffect(() => {
    setText('');
    textRef.current.innerHTML = '';
  }, []);

  return <div className={className}>
    <Avatar
      className={styles['create-comment__avatar']}
      size='sm'
      url={currentUser?.picture?.url ?? undefined} />
    <div className={styles['create-comment__input-container']}>
      <div
        ref={textRef}
        contentEditable='true'
        className={styles['create-comment__input']}
        onInput={() => setText((textRef?.current?.innerHTML ?? '') as string)}
      />
      {!textRef?.current?.innerHTML && <div
        className={styles['create-comment__input_placeholder']}
      >
        {t('postComments.placeholder')}
      </div>}
    </div>
    <Button
      size='lg'
      hierarchy='primary'
      iconType='send'
      loading={loadingNewComment}
      disabled={!textRef?.current?.innerHTML}
      onClick={sendComment} />
    {username !== '' 
      && <DropdownUserList
        onSelect={(user) => {
          onSelectUser(user, stringIndex, setUsername, textRef, usernamePos, setUsernamePos);
          setText((textRef?.current?.innerHTML ?? "") as string);
        }}
        top={
          window.innerWidth <= 580
            ? 110 + 24 * [...(textRef.current?.childNodes ?? [])].length
            : undefined
        }
        className={styles['create-comment__dropdown-user-list']}
        setText={setUsername}
        text={username}
      />}
  </div>
});
