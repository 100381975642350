// eslint-disable-next-line no-useless-escape
export const urlRegExp = /^(https?:\/\/|http?:\/\/|ftp?:\/\/|)(?:www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_.~#?&/=]*)$/gm

export function findLinksInText(text: string | null | undefined): string {
  if (!text) {
    return '';
  }

  return text
    .replaceAll(/<\/?[A-Z]+>/gi,'')
    .split(' ')
    .map((word) => word.split('\n'))
    .flat()
    .map((word) => urlRegExp.test(word) ? `<a href='${word}'>${word}</a>` : word)
    .join(' ')
};

export function getLinksInText(text: string | null | undefined): string[] {
  if (!text) {
    return [];
  }

  return text
    .replaceAll(/&nbsp;/gi, ' ')
    .replaceAll(/&amp;/gi, '&')
    .replaceAll(/(\<(\/?[^>]+)>)/g, '')
    .split('\n')
    .join(' ')
    .split(' ')
    .filter((word) => urlRegExp.test(word));
};

