import { UserPicture } from "@graphql/graphql";
import styles from "./message-list-header.module.css";
import { Avatar, Button } from "@ui-kit";
import { IconButton } from "@ui-kit/components/button/icon-button/icon-button.comp";

type Props = {
  userPicture?: UserPicture|null,
  setUserInfo: React.Dispatch<React.SetStateAction<UserPicture | undefined>>,
  onBackClick: () => void,
}

export const MessageListHeader = ({ userPicture, setUserInfo, onBackClick }: Props) => {
  return <div className={styles['message-list-header']}>
    <IconButton className={styles['message-list-header__back']} iconType='arrow-left' size='xl' onClick={onBackClick} />
    <Avatar
      alt={userPicture?.username ?? 'avatar'}
      url={userPicture?.picture?.url}
      statusIcon={userPicture?.isOnline ? 'online' : undefined} />
    <div className={styles['message-list-header__center']}>
      <div className={styles['message-list-header__fullname']}>
        {userPicture?.firstname} {userPicture?.lastname}
      </div>
      <div className={styles['message-list-header__username']}>
        @{userPicture?.username}
      </div>
    </div>
    <div className={styles['message-list-header__right']}>
      <Button size='md' hierarchy='tertiary-gray' iconType='info' onClick={() => setUserInfo(userPicture ?? undefined)} />
    </div>
  </div>
}