import { MediaFile } from "@graphql/graphql";
import styles from "./media-content-component.module.css";
import { MediaItemComponent } from "../media-item/media-item.comp";
import { MediaMoreItemsComponent } from "../media-more-items/media-more-items.comp";

type Props = {
  items: MediaFile[]|null|undefined,
  className?: string,
  onClick?: (value: number) => void,
}

export const MediaContentComponent = ({ items, className, onClick }: Props) => {
  if(!items || items.length === 0) {
    return <></>
  }

  switch (items?.length) {
    case 1:
      return <div className={className ? `${className} ${styles[`media-one`]}` : styles[`media-one`]}>
        <MediaItemComponent className={styles[`media-one_first`]} mediaFile={items[0]} onClick={() => !!onClick && onClick(0)} />
      </div>
    case 2:
      return <div className={className ? `${className} ${styles[`media-two`]}` : styles[`media-two`]}>
        <MediaItemComponent className={styles[`media-two_item`]} mediaFile={items[0]} onClick={() => !!onClick && onClick(0)} />
        <MediaItemComponent className={styles[`media-two_item`]} mediaFile={items[1]} onClick={() => !!onClick && onClick(1)} />
      </div>
    case 3:
      return <div className={className ? `${className} ${styles[`media-three`]}` : styles[`media-three`]}>
        <MediaItemComponent className={styles[`media-three_left`]} mediaFile={items[0]} onClick={() => !!onClick && onClick(0)} />
        <MediaItemComponent className={styles[`media-three_top`]} mediaFile={items[1]} onClick={() => !!onClick && onClick(1)} />
        <MediaItemComponent className={styles[`media-three_bottom`]} mediaFile={items[2]} onClick={() => !!onClick && onClick(2)} />
      </div>
    case 4:
      return <div className={className ? `${className} ${styles[`media-four`]}` : styles[`media-four`]}>
        <MediaItemComponent className={styles[`media-four_first`]} mediaFile={items[0]} onClick={() => !!onClick && onClick(0)} />
        <MediaItemComponent className={styles[`media-four_second`]} mediaFile={items[1]} onClick={() => !!onClick && onClick(1)} />
        <MediaItemComponent className={styles[`media-four_third`]} mediaFile={items[2]} onClick={() => !!onClick && onClick(2)} />
        <MediaItemComponent className={styles[`media-four_fourth`]} mediaFile={items[3]} onClick={() => !!onClick && onClick(3)} />
      </div>
  }

  return <div className={className ? `${className} ${styles[`media-more`]}` : styles[`media-more`]}>
    <div className={styles[`media-more__top`]}>
      <MediaItemComponent className={styles[`media-more_first`]} mediaFile={items[0]} onClick={() => !!onClick && onClick(0)} />
      <MediaItemComponent className={styles[`media-more_second`]} mediaFile={items[1]} onClick={() => !!onClick && onClick(1)} />
    </div>
    <div className={styles[`media-more__bottom`
  ]}>
      <MediaItemComponent className={styles[`media-more_third`]} mediaFile={items[2]} onClick={() => !!onClick && onClick(2)} />
      <MediaItemComponent className={styles[`media-more_fourth`]} mediaFile={items[3]} onClick={() => !!onClick && onClick(3)} />
      <MediaMoreItemsComponent className={styles['media-more__more']} mediaFiles={items} onClick={() => !!onClick && onClick(4)} />
    </div>
  </div>
}