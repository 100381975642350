import { City } from "@graphql/graphql";
import { getUserLocation } from "./user-location";
import { getTwoPointsDistance } from "./get-two-points-distance";
import { getCitiesCords } from "./get-cities-cords";

export const findClosestCity = async (cities: (City|null|undefined)[]): Promise<City|null|undefined> => {
  const cityWithCords = await getCitiesCords(cities);
  const userCords = await getUserLocation();
  if (!userCords || !userCords.latitude || !userCords.longitude) {
    return cityWithCords[0];
  }
  let place_id: string;
  let distance = Infinity;
  cityWithCords.forEach((city, i) => { 
    const currentDistance = getTwoPointsDistance([
      { lat: userCords.latitude, lng: userCords.longitude },
      { lat: city.lat, lng: city.lng }
    ]);
    if (distance > currentDistance) {
      distance = currentDistance;
      place_id = city.place_id;
    };
  });
  return cities.find((city) => city?.place_id === place_id) ?? cities[0];
}