import styles from "./calendar.module.css";
import { useCalendar } from "./helpers/useCalendar";
import { IconButton } from "../button/icon-button/icon-button.comp";
import { checkDatesIsEqual, checkIsToday } from "./helpers";

interface CalendarProps {
  region?: string;
  selectedDate?: Date;
  selectDate?: (date: Date) => void;
}

export const Calendar = ({ region, selectedDate, selectDate }: CalendarProps) => {
  selectedDate = selectedDate ?? new Date();
  const { state, functions } = useCalendar({ region, selectedDate })

  return (
    <div className={styles['calendar-container']}>
      {state.mode === 'days' && (
        <div className={styles['header-calendar_days']}>
          <IconButton iconType='chevron-left' size='lg' onClick={() => functions.onclickArrow('left')} />
          <div className={styles['calendar-title']}>
            <div className={styles['calendar-title_month']}
              onClick={() => { functions.setMode('months') }}>
              {state.monthsNames[state.selectedMonth.monthIndex].month}
            </div>
            <div className={styles['calendar-title_year']} onClick={() => { functions.setMode('years') }}>
              {state.selectedYear}
            </div>
          </div>
          <IconButton iconType='chevron-right' size='lg' onClick={() => functions.onclickArrow('right')} />
        </div>)
      }'
      {state.mode === 'months' && (
        <div className={styles['header-calendar_months']}>
          <IconButton iconType='chevron-left' size='lg' onClick={() => functions.onclickArrow('left')} />
            <div className={styles['calendar-title']}>
              <div className={styles['calendar-title_month']}>
                {state.monthsNames[state.selectedMonth.monthIndex].month}
              </div>
              <div className={styles['calendar-title_year']}
              onClick={() => { functions.setMode('years') }}>
                {state.selectedYear}
              </div>
            </div>
          <IconButton iconType='chevron-right' size='lg' onClick={() => functions.onclickArrow('right')} />
        </div>)
      }

      {state.mode === 'years' && (
        <div className={styles['header-calendar_years']}>
          <IconButton iconType='chevron-left' size='lg' onClick={() => functions.onclickArrow('left')} />
            <div className={styles['calendar-title']}>
              <div className={styles['calendar-title_month']}
              onClick={() => { functions.setMode('months') }}>
                {state.monthsNames[state.selectedMonth.monthIndex].month}
              </div><div className={styles['calendar-title_year']}>
                {state.selectedYearsInterval[0]}-{state.selectedYearsInterval[state.selectedYearsInterval.length - 1]}
              </div>
            </div>
          <IconButton iconType='chevron-right' size='lg' onClick={() => functions.onclickArrow('right')} />
        </div>)
      }

      {state.mode === 'days' && (
        <div className={styles['calendar-body_days']}>
          <div className={styles['calendar-week_names']}>
            {state.weekDaysNames.map((weekDaysNames) => (
              <div key={weekDaysNames.dayShort}>
                {weekDaysNames.dayShort}
              </div>))
            }
          </div>
          <div className={styles['calendar-days']}>
            {state.calendarDays.map(day => {
              const isToday = checkIsToday(day.date);
              const isSelectedDay = checkDatesIsEqual(day.date, state.selectedDate.date);
              const isExtraDay = day.monthIndex !== state.selectedMonth.monthIndex;

              return <div key={`${day.dayNumber}-${day.monthIndex}`}
                onClick={() => {
                  functions.setSelectedDate(day)
                  if (selectDate) {
                    selectDate(day.date);
                  }
                }}
                className={`${styles['calendar-day']} ${isToday ? styles['calendar-today'] : ''}
                  ${isSelectedDay ? styles['calendar-selected'] : ''}
                  ${isExtraDay ? styles['calendar-extra-day'] : ''}`}>
                {day.dayNumber}
              </div>
            })
            }
          </div>
        </div>)
      }

      {state.mode === 'months' && (
        <div className={styles['calendar-body_months']}>
          {state.monthsNames.map(monthsName => {
            const isSelectedMonth = monthsName.monthIndex === state.selectedMonth.monthIndex;

            return <div className={`${styles['month']} ${isSelectedMonth ? styles['month-selected'] : ''}`}
              key={`${monthsName.month}`}
              onClick={() => {
                functions.setSelectedMonthIndex(monthsName.monthIndex);
                functions.setMode('days')
              }}>
              {monthsName.monthShort}
            </div>
          })}
        </div>)
      }

      {state.mode === 'years' && (
        <div className={styles['calendar-body_years']}>
          {state.selectedYearsInterval.map((year) => {
            const isSelectedYear = year === state.selectedYear;

            return (
              <div className={`${styles['year']} ${isSelectedYear ? styles['year-selected'] : ''}`}
                key={year}
                aria-hidden
                onClick={() => {
                  functions.setSelectedYear(year);
                  functions.setMode('months');
                }}>
                {year}
              </div>
            );
          })}
        </div>
      )}
    </div>
  )
}
