import { FormEvent, KeyboardEvent } from "react";
import styles from "./digit.module.css"

type Props = {
  value?: string;
  onChange: (val: string) => void;
  placeholder?: string;
  size: 'sm'|'md'|'lg',
  inputRef: any,
}

export const Digit = ({value, onChange, placeholder, size, inputRef}: Props) => {
  const _onInput = (e: FormEvent<HTMLInputElement>) => {
    const key = (e.nativeEvent as InputEvent)?.data ?? '-1';
    if (!Number.isNaN(key) && +key >= 0 && +key <= 9) {
      onChange(key);
    } else {
      onChange('');
    }
  };

  const _onChange = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace') {
      onChange('Backspace');
    }
  };

  return (
    <div className={value ? styles.entered : ''}>
      <input
        className={`${styles['digit-input']} ${
          size === 'md' && styles['digit-input_md']} ${
          size === 'lg' && styles['digit-input_lg']}
        }`}
        placeholder={placeholder?.[0] || '0'}
        value={value || ''}
        onKeyUpCapture={_onChange}
        onInput={_onInput}
        type='number'
        ref={inputRef}
      />
    </div>
  );
}