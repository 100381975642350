import chadImg from "@assets/images/chad.png";
import { faSquarePen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { loadCoverPictureToDB } from "@home/helpers/load-cover-picture-to-db";
import { useFormState } from "@shared/hooks";
import { citiesStore, toastsStore } from "@store";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import styles from "./city-cover.module.css";
import { Link } from "react-router-dom";
import { userStore } from "src/store/user.store";
import { useTranslation } from "react-i18next";

const initialState = { picture: '' };

export const CityCover = observer(() => {
  const [picture, setPicture] = useState('');

  const [userPicture, setUserPicture] = useState('');

  const { formState, setInput } = useFormState(initialState);

  const { t } = useTranslation('main');

  useEffect(() => {
    setUserPicture(citiesStore?.currentCity?.userPicture?.picture?.url ?? '');
  }, [citiesStore.currentCity])

  useEffect(() => {
    setPicture(citiesStore.currentCity?.picture?.url ?? '');
    setInput('picture', '');
  }, [citiesStore.currentCity]);

  const handleFileChange = (event: any) => {
    if (event.target.files) {
      const inputFile = event.target.files[0];
      setInput('picture', event.target.value);
      setPicture(URL.createObjectURL(inputFile));
      loadCoverPictureToDB(
        inputFile,
        event.target.value
      ).then(() => setInput('picture', ''))
      .catch((err) => {
        toastsStore.addErrorToast(t('toasts.error.updateCover'));
        console.error(err);
      });
    }
  };

  return (
    <Col
      className={`p-3 ${styles.cityCover}`}
      style={{backgroundImage: picture ? `url(${picture})` : `url()`}}
    >
      <h2 className={`${styles.backShadow} ${styles.city}`}>{citiesStore.currentCity?.longName}</h2>
      <p className={`${styles.backShadow} ${styles.country}`}>{citiesStore.currentCity?.country}</p>
      <div className={styles.coverFooter}>
        <Link className={styles.userBlock} to={`/wall/${citiesStore.currentCity?.username}`}>
          <div className={styles.userPicture} style={{backgroundImage: userPicture ? `url(${userPicture})` : `url(${chadImg})` }}></div>
          <div className={`${styles.userName} ${styles.backShadow}`}>{citiesStore.currentCity?.username}</div>
        </Link>
        {citiesStore.currentCity?.username === userStore.currentUser?.username &&
        <form className={styles.formFile + ' mt-4'}>
          <label
            htmlFor='upload-cover'
            className={styles.customFileUpload}
          >
            <FontAwesomeIcon icon={faSquarePen} size={'xl'} />
          </label>
          <input
            id='upload-cover'
            className={styles.fileUploadInput}
            type='file'
            value={formState.picture}
            onChange={(event) => handleFileChange(event)}
          />
        </form>}
      </div>
    </Col>
  )
});
