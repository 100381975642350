export function getVideoDuration(file: File): Promise<number> {
  return new Promise((resolve, reject) => {
    const videoElement = document.createElement('video');
    videoElement.preload = 'metadata';

    videoElement.onloadedmetadata = function () {
      const duration = videoElement.duration;

      resolve(duration);
      URL.revokeObjectURL(videoElement.src);
    };

    videoElement.onerror = function () {
      reject(new Error('Ошибка при загрузке видео'));
    };

    const fileURL = URL.createObjectURL(file);
    videoElement.src = fileURL;
  });
}