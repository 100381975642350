import flag from "../../../../../assets/geo-icons/flag.svg";
import point from "../../../../../assets/geo-icons/point.svg";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { citiesStore, toastsStore } from "@store";
import { useState, useEffect } from "react";
import { HookReturn } from "use-places-autocomplete";
import styles from "./g-map.module.css";
import { CityWithCords, getCitiesCords } from "@shared/helpers/get-cities-cords";
import { MapController } from "../../types";
import { PlacesAutocomplete } from "../places-autocomplete/places-autocomplete.comp";
import { useTranslation } from "react-i18next";

interface Props {
  handleMapClick: (event: any) => void,
  setCityByCords: (lat: number, lng: number) => Promise<any>,
  city: string,
  hookReturn: HookReturn,
  mapController: MapController,
  hideCityList?: boolean,
  selectCityByGeo: () => void,
}

export function GMap({
  handleMapClick, setCityByCords, city, hookReturn, mapController, hideCityList, selectCityByGeo
}: Props) {
  const [citiesCords, setCityCords] = useState<CityWithCords[]>([])
  const { t } = useTranslation('common');

  useEffect(() => {
    getCitiesCords(citiesStore.cities).then((data) => {
      setCityCords(data);
    }).catch((err) => {
      console.error(err);
      toastsStore.addErrorToast(t('toasts.error.getLocalCity'));
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citiesStore.cities])

  useEffect(() => { citiesStore.fetchCities().catch(() => toastsStore.addErrorToast(t('toasts.error.getCities'))); }, [])

  return (
    <>
      <div className={styles.placesContainer}>
        <div className={styles.placesInnerContainer}>
          <PlacesAutocomplete
            city={city}
            setCityByCords={setCityByCords}
            hookReturn={hookReturn}
            mapController={mapController}
            selectCityByGeo={selectCityByGeo}
          />
        </div>
      </div>

      <GoogleMap
        center={mapController.center}
        zoom={mapController.zoom}
        mapContainerClassName={styles.mapContainer}
        onClick={handleMapClick}
        options={{fullscreenControl: false, streetViewControl: false, mapTypeControl: false}}
      >
        {!hideCityList && citiesCords.map((cityItem) =>
          <Marker
            key={cityItem.place_id}
            position={{ lat: cityItem.lat, lng: cityItem.lng }}
            icon={flag}
            onClick={() => {
              setCityByCords(cityItem.lat, cityItem.lng).catch(() => toastsStore.addErrorToast(t('toasts.error.setLocalCity')))
            }}
            />
        )}
        {mapController.selected && <Marker
          position={mapController.selected}
          icon={point} />}
      </GoogleMap>
    </>
  );
}