export const  
 foundLastWord = (text: string, position: number) => {
  let lastWord = '';
  let ch = '';
  let start = position;

  do {
    start -= 1;
    ch = start >= 0 ? text.at(start) ?? '' : '';
    if (ch !== ' ' && ch !== '\n' && ch !== '' && ch.charCodeAt(0) !== 160) {
      lastWord = ch + lastWord;
    }
  } while (ch !== ' ' && ch !== '\n' && ch !== '' && ch.charCodeAt(0) !== 160);

  start += 1;
  
  return { lastWord: lastWord.trim(), start, end: position };
}