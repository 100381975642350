import { IconTypes } from "src/assets";
import { Avatar } from "../avatar/avatar.comp";
import { ColoredDot } from "../drop-down/comp/colored-dot/colored-dot.comp";
import styles from "./badge.module.css";
import { Icon } from "@home/components/icon/icon.comp";
import { getIconColor } from "./helper/get-icon-color";

type Props = {
  iconUrl?: string,
  icon?: 'false'|'dot'|'country'|'close'|'avatar'|'icon-right'|'icon-left'|'only',
  size?: 'sm'|'md'|'lg',
  color?: 'primary'|'error'|'warning'|'success'|'indigo'|'purple'|'pink'|'rose'|'orange'|'blue-gray'|'gray',
  customColor?: string,
  customTextColor?: string,
  children?: any,
  iconType?: IconTypes,
  className?: string,
  onClick?: () => void,
}

export const Badge = ({
  iconUrl,
  icon,
  size,
  color,
  customColor,
  customTextColor,
  children,
  className,
  iconType,
  onClick
}: Props) => {
  icon = icon ?? 'false';
  size = size ?? 'md';
  let badgeClassName = className ?? '';
  const classColor = `badge-${color ?? 'none'}`;
  const classSize = `badge-${size}`;
  badgeClassName += ` ${styles.badge} ${styles[classColor]} ${styles[classSize]}`;
  badgeClassName += (icon === 'icon-left' || icon === 'icon-right') && !children ? ` ${styles[`badge_only-${size}`]}` : ''
  badgeClassName += !!onClick ? ` ${styles.badge_pointer}` : ''

  onClick = onClick ? onClick : () => {};

  return (<div className={badgeClassName} onClick={onClick} style={{backgroundColor: customColor, color: customTextColor}}>
    {icon === 'dot' && <ColoredDot type={color ?? 'success'} />}
    {icon === 'avatar' && <Avatar size={'xxs'} alt='user' url={iconUrl} />}
    {icon === 'icon-left' && <Icon icon={iconType ?? 'plus'} size='xxxs' color={getIconColor(color ?? 'success')} />}
    {children ?? ''}
    {icon === 'icon-right' && <Icon icon={iconType ?? 'plus'} size='xxxs' color={getIconColor(color ?? 'success')} />}
  </div>)
}