import { ColoredDotType } from "../../modele/menu-item";
import styles from "./colored-dot.module.css"

type Props = {
  type: ColoredDotType;
}

export const ColoredDot = ({ type }: Props) => {
  const typeClassName = `dot-${type}`;
  const className = `${styles.dot} ${styles[typeClassName]}`;

  return (<div className={className}/>)
};
